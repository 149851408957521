import { useEffect, useRef, useState } from 'react';

type UseCountDownParams = {
  length: number; // In seconds not milliseconds
  callback: Function;
};

export const useCountdown: (
  length: UseCountDownParams['length'],
  callback: UseCountDownParams['callback']
) => { countDownStarted: boolean; startCountdown: () => void; remainingTime: number } = (length, callback) => {
  const interval = useRef<any>();
  const [remainingTime, setRemainingTime] = useState<number | null>(null);
  const countDownStarted = !!remainingTime;

  const executeCallback = async () => {
    callback && (await callback());
    clearInterval(interval.current);
  };

  useEffect(() => {
    if (remainingTime === 0) {
      executeCallback();
    }
  }, [remainingTime]);

  const startCountdown = () => {
    interval.current = setInterval(() => {
      setRemainingTime((remaining) => (remaining || (length + 1) * 1000) - 1000);
    }, 1000);
  };

  return { countDownStarted, startCountdown, remainingTime: remainingTime / 1000 };
};
