export const FacebookRound = (props) => {
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="6702.77 18309.17 6561.66 6561.66"
      {...props}
    >
      <linearGradient id="prefix__a" gradientUnits="userSpaceOnUse" x1={9983.6} x2={9983.6} y1={18249.39} y2={25150.62}>
        <stop offset={0} stopColor="#00b2ff" />
        <stop offset={1} stopColor="#006aff" />
      </linearGradient>
      <path
        d="M9983.6 18309.17c1811.95 0 3280.83 1468.88 3280.83 3280.83s-1468.88 3280.83-3280.83 3280.83S6702.77 23401.95 6702.77 21590s1468.88-3280.83 3280.83-3280.83z"
        fill="url(#prefix__a)"
      />
      <path
        d="M10409.89 24843.29v-2534.17h714.43l94.7-891.91h-809.13l1.2-446.44c0-232.63 22.1-357.22 356.24-357.22h446.68v-892.06h-714.59c-858.35 0-1160.42 432.65-1160.42 1160.34v535.45h-535.07v891.99H9339v2498.09c208.45 41.53 423.95 63.47 644.6 63.47a3310.9 3310.9 0 00426.29-27.54z"
        fill="#fff"
        fillRule="nonzero"
      />
    </svg>
  );
};
