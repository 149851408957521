import { Trans } from '@lingui/macro';
import { Experience } from '@milo/types/index';
import { FC } from 'react';

export const VisitHeader: FC<{ experience: Experience; firstScreen?: boolean }> = ({
  experience,
  firstScreen = false,
}) => {
  return (
    <div className="mb-1 text-center">
      <p className="font-medium">
        {firstScreen ? (
          <Trans>
            Bienvenue chez <span className="text-raspberry">{experience.name}</span>
          </Trans>
        ) : (
          <Trans>
            Visite chez <span className="text-raspberry">{experience.name}</span>
          </Trans>
        )}
      </p>
    </div>
  );
};
