import { colorFn, ColorType } from '../utils';

export const CrossIcon = ({ fill = 'currentColor' as ColorType, strokeWidth = 2, ...props }) => {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="5 5 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.295 7.115a.997.997 0 1 0-1.41-1.41L12 10.59 7.115 5.705a.997.997 0 0 0-1.41 1.41L10.59 12l-4.885 4.885a.997.997 0 0 0 1.41 1.41L12 13.41l4.885 4.885a.997.997 0 1 0 1.41-1.41L13.41 12l4.885-4.885Z"
        fill={_fill}
      />
    </svg>
  );
};
