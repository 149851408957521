export const ChartIcon = (props) => (
  <svg viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.666 19.167V9.833M14 19.167v-5.834M9.334 19.166v-3.5"
      stroke="currentColor"
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 24.3V4.7a.7.7 0 0 1 .7-.7h19.6a.7.7 0 0 1 .7.7v19.6a.7.7 0 0 1-.7.7H4.2a.7.7 0 0 1-.7-.7Z"
      stroke="currentColor"
      strokeWidth={1.75}
    />
  </svg>
);
