import { t, Trans } from '@lingui/macro';
import { EMAIL_ADDRESS_MEMBERS } from '@milo/constants';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Link2 } from '../../../components';
import { Loading } from '../../../components/Route/Base';
import { UserGetOnboardingData } from '../../../graphql-queries';
import { useGraphqlClient } from '../../../hooks';
import { InfoCircleBold, WavingHand } from '../../../icons';
import { Button2 } from '../../Button';
import { Modal, ModalBody } from '../../Modal';
import { Text } from '../../Text';

export const OnboardingWelcomeBack = () => {
  const { client } = useGraphqlClient();
  const [showPopup, setShowPopup] = useState(false);

  const { data: userData, isFetched } = useQuery(['UserOnboardingData'], async () => {
    const response = await client.request(UserGetOnboardingData);
    const ret = response?.UserGetOnboardingData || {};
    return ret;
  });

  if (!isFetched) {
    return <Loading />;
  }

  if (!userData?.email) {
    location.replace('experiences');
    return <Loading />;
  }

  const first_name = userData?.first_name;
  const freeDays = userData?.free_days || 0;

  return (
    <div className="relative overflow-x-clip">
      <Text size="h1" className="mb-4 font-bold">
        <Trans>
          Bonjour {first_name}, <br /> ravi de vous voir!
        </Trans>
        <WavingHand className="absolute -right-2 top-[-55px] h-36" />
      </Text>

      <Text className="mb-4">
        <Trans>
          Vous avez accès à un pass M ta région pour une période de {freeDays} jours. Complétez la prochaine étape pour
          pouvoir l'activer!
        </Trans>
      </Text>

      {userData.user_source === 'legacy' && (
        <Button2 variant="tertiary" className="px-0" onClick={() => setShowPopup(true)} StartIcon={InfoCircleBold}>
          <Trans>Détail du calcul de votre pass M ta région</Trans>
        </Button2>
      )}

      <div className="text-right">
        <Link2 href="/onboarding/prepaid/profile">
          <Button2 className="w-full">
            <Trans>Continuer</Trans>
          </Button2>
        </Link2>
      </div>

      <Modal onRequestClose={() => setShowPopup(false)} open={showPopup}>
        <ModalBody>
          <OnboardingSubscriptionDetailsModal />
        </ModalBody>
      </Modal>
    </div>
  );
};

const OnboardingSubscriptionDetailsModal = () => {
  return (
    <div className="space-y-2">
      <Text size="h1" className="font-bold">
        <Trans>Détail du calcul de votre pass M ta région</Trans>
      </Text>
      <Text>
        <Trans>
          M ta région a temporairement gelé vos réductions-découvertes du 1er avril 2020 au 15 juin 2020, puis du 1er
          octobre 2020 au 15 décembre 2021.
        </Trans>
      </Text>
      <Text>
        <Trans>
          Votre pass M ta région actif au moment du gel des réductions-découvertes a été automatiquement prolongé, selon
          la date initiale d'activation de votre pass M ta région. Votre pass M ta région conserve intact le nombre de
          jours restants dont vous n'avez pas pu profiter, incluant les potentiels abonnements achetés par anticipation.{' '}
        </Trans>
      </Text>
      <Text className="mb-5">
        <Trans>
          Vos détails du pass M ta région avec la date ajustée de fin du pass M ta région se trouvent dans la section
          "Compte et pass M ta région", accessible après avoir réactivé votre pass M ta région.
        </Trans>
      </Text>
      <Text size="small" className="rounded-xl bg-gray-lighter p-4 font-bold">
        **
        <Trans>
          Si vous pensez que nos calculs sont erronés,{' '}
          <Link2
            href={`mailto:${EMAIL_ADDRESS_MEMBERS}?subject=${encodeURIComponent(t`Problème de réactivation`)}`}
            underline
          >
            envoyez-nous
          </Link2>{' '}
          une preuve de votre plus récent pass M ta région et il nous fera plaisir de corriger notre erreur.
        </Trans>
      </Text>
    </div>
  );
};
