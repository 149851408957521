import { t, Trans } from '@lingui/macro';
import { VALIDATION_VIDEO_URL } from '@milo/constants';
import { convertDateToReadableFormat } from '@milo/helpers';
import {
  APP_STORE_LINK,
  Button2,
  GOOGLE_STORE_LINK,
  isNativeApp,
  Link2,
  Loading,
  ReactFC,
  Text,
  useAuthentication,
  useProducts,
  YoutubeEmbed,
} from '@milo/react';

const MobileAppPromoBlock = () => {
  if (isNativeApp()) {
    return null;
  }

  return (
    <div className="space-y-4">
      <p className="text-xl font-semibold">
        <Trans>Téléchargez l'application M ta région pour obtenir une meilleure expérience!</Trans>
      </p>
      <div className="flex gap-2">
        <Link2 href={APP_STORE_LINK}>
          <img className="h-10" src="/images/app-stores/apple/download/fr.svg" />
        </Link2>
        <Link2 href={GOOGLE_STORE_LINK}>
          <img className="h-10" src="/images/app-stores/google/download/fr.png" />
        </Link2>
      </div>
    </div>
  );
};

export type PurchaseDoneProps = {
  showHeaderText?: boolean;
  showSubHeaderText?: boolean;
};

export const GettingStartedBlock = () => {
  return (
    <div>
      <p className="mb-4 text-2xl font-semibold">
        <Trans>Vous ne savez pas où commencer? Nous vous aidons à bien démarrer.</Trans>
      </p>
      <div className="mb-4">
        <div className="h-72 overflow-hidden rounded-xl shadow-xl">
          <YoutubeEmbed src={VALIDATION_VIDEO_URL} />
        </div>
      </div>
    </div>
  );
};

export const PurchaseDone: ReactFC<PurchaseDoneProps> = ({ showHeaderText = true, showSubHeaderText = true }) => {
  const { user, isLoading: userLoading } = useAuthentication();
  const { data: products = [], isFetching: productsFetching } = useProducts();

  const hasScheduleSubs = user?.scheduled_subscriptions.length > 0;
  const loading = userLoading || productsFetching;

  if (loading) return <Loading />;

  const earliestScheduledSub = hasScheduleSubs
    ? user.scheduled_subscriptions.sort((a, b) => {
        if (a.startDate > b.startDate) return 0;
        if (a.startDate < b.startDate) return 1;
        return 0;
      })[0]
    : null;

  const pendingActivationDate = earliestScheduledSub ? earliestScheduledSub.startDate : null;
  const purchasedProduct = products.find((product) => product.id === user?.subscription?.product_id);
  const productName = purchasedProduct?.name || t`pass M ta région`;

  return (
    <div className="mx-auto max-w-2xl animate-fade-in-top space-y-4">
      <div className="space-y-4">
        {showHeaderText && (
          <div className="space-y-4">
            <Text bold size="h1">
              {!pendingActivationDate && <Trans>Votre pass M ta région est maintenant actif!</Trans>}
              {pendingActivationDate && <Trans>C'est réussi!</Trans>} 🍾🎉
            </Text>

            {!pendingActivationDate && (
              <Text size="p">
                <Trans>
                  Votre <b data-testid="milopass_name">{productName}</b> sera valide jusqu'au{' '}
                  <b data-testid="milopass_period_end">
                    {convertDateToReadableFormat(user?.subscription?.current_period_end)}
                  </b>
                  .
                </Trans>
              </Text>
            )}

            {pendingActivationDate && (
              <Text size="p">
                <Trans>
                  Votre <b>{productName}</b> sera automatiquement activé le{' '}
                  <b>{convertDateToReadableFormat(pendingActivationDate)}</b>.
                </Trans>
              </Text>
            )}
          </div>
        )}

        {showSubHeaderText && !pendingActivationDate && (
          <p>
            <Trans>
              Partez à la découverte de M ta région, et profitez sans attendre des avantages de votre pass M ta région.
            </Trans>
          </p>
        )}
      </div>

      <MobileAppPromoBlock />

      <div>
        <Link2 href="/experiences">
          <Button2 className="w-full">
            <Trans>Parcourir les expériences</Trans>
          </Button2>
        </Link2>
      </div>
    </div>
  );
};
