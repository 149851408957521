import { MiloSVGProps } from '.';
import { ReactFC } from '../types';
import { colorFn, ColorType } from '../utils';

export const User: ReactFC<MiloSVGProps> = ({ fill = 'currentColor' as ColorType, ...props }) => {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="-3 -3 26 26" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.03 8a3.006 3.006 0 01-3.012-3c0-1.652 1.344-3 3.013-3a3.006 3.006 0 013.013 3c0 1.652-1.344 3-3.013 3zm0 2a5.006 5.006 0 01-5.012-5c0-2.761 2.244-5 5.013-5a5.006 5.006 0 015.013 5c0 2.761-2.245 5-5.013 5zM1.009 20l1 .001v-.006l.001-.048a7.993 7.993 0 01.12-1.017c.123-.678.37-1.569.852-2.45a6.244 6.244 0 012.247-2.376C6.284 13.463 7.744 13 9.78 13c2.036 0 3.496.462 4.552 1.104a6.245 6.245 0 012.247 2.377 8.22 8.22 0 01.853 2.449 7.993 7.993 0 01.12 1.017V20l1-.001 1-.001V19.959a4.916 4.916 0 00-.02-.38 9.992 9.992 0 00-.133-1.009 10.216 10.216 0 00-1.066-3.05 8.246 8.246 0 00-2.962-3.124c-1.41-.858-3.24-1.396-5.59-1.396-2.351 0-4.18.538-5.592 1.396a8.245 8.245 0 00-2.962 3.123A10.217 10.217 0 00.161 18.57a9.989 9.989 0 00-.15 1.304 4.588 4.588 0 00-.003.085V20l1 .001zm1 .003v-.001.001zm15.545 0z"
        fill={_fill}
      />
    </svg>
  );
};

export const UserCircle: ReactFC<MiloSVGProps> = ({ fill = 'currentColor' as ColorType, ...props }) => {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="0 0 32 32" fill="none" {...props} data-icon-name={UserCircle.name}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.639 13.65a3.25 3.25 0 1 1 0-6.5 3.25 3.25 0 0 1 0 6.5Zm-4.243.778a5.85 5.85 0 1 1 8.464.022c4.355 1.704 7.44 5.942 7.44 10.9a1.3 1.3 0 1 1-2.6 0 9.1 9.1 0 1 0-18.2 0 1.3 1.3 0 1 1-2.6 0c0-4.98 3.11-9.234 7.496-10.922Z"
        fill={_fill}
      />
    </svg>
  );
};
