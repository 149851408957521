import type { SVGProps } from 'react';
import * as React from 'react';
type Props = {
  primaryColor?: string;
  secondaryColor?: string;
  tertiaryColor?: string;
} & SVGProps<SVGSVGElement>;
const SvgAutumnLeaf = ({
  primaryColor = 'currentColor',
  secondaryColor = 'currentColor',
  tertiaryColor = 'currentColor',
  ...props
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1097 1011" {...props}>
    <mask
      id="autumn-leaf_svg__a"
      width={41}
      height={273}
      x={444}
      y={738}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'luminance',
      }}
    >
      <path fill="#fff" d="M444.713 738.109h39.308v272.861h-39.308V738.109Z" />
    </mask>
    <g mask="url(#autumn-leaf_svg__a)">
      <path
        fill="#E73A0F"
        d="M475.411 1010.67a6.492 6.492 0 0 1-4.125-3.94c-28.687-77.719-28.135-145.417-22.614-188.527 6-46.901 18.724-75.114 19.26-76.291a6.505 6.505 0 0 1 8.615-3.209c3.26 1.495 4.703 5.349 3.203 8.615-.229.521-12.578 28.182-18.255 73.083-5.25 41.552-5.698 106.823 21.984 181.819a6.494 6.494 0 0 1-3.844 8.35c-1.411.52-2.89.52-4.224.1Z"
      />
    </g>
    <mask
      id="autumn-leaf_svg__b"
      width={1097}
      height={953}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'luminance',
      }}
    >
      <path fill="#fff" d="M0 0h1096.89v952.146H0V0Z" />
    </mask>
    <g mask="url(#autumn-leaf_svg__b)">
      <path
        fill="#FF7236"
        d="M622.224 952.094s-145.698-67.646-160.063-178.656c0 0-89.239 133.661-160.796 127.927 0 0 47.25-140.302-140.459-98.881 0 0 20.985-115.177-160.906-163.458 0 0 272.073-46.198 53.266-231.354 0 0 81.297-20.604 9.609-96.073 0 0 76.141-40.927 18.776-189.411 0 0 112.031 190.583 176.443 100.39 0 0 38.906 159.505 95.156 120.995 0 0 60.115-10.016 61.646-204.943 0 0 68.89 119.636 109.281-83.094 0 0 7.276 165.652 142.552-55.536 0 0-40.385 202.729 47.203 177.542 0 0-73.302 164.062 71.683 128.432 0 0-207.412 287.849 126.651 45.12 0 0-42.813 129.849 184.624-4.266 0 0-145.713 110.792-79.88 135.141 0 0-162.166 91.463-30.218 120.692 0 0-426.938 144.396-47.964 162.49 0 0-230.854 49.938-153.297 123.537 0 0-229.265-83.636-163.307 63.406Z"
      />
    </g>
    <path
      fill="#E73A0F"
      d="M460.922 777.312a4.064 4.064 0 0 1-2.797-4.297c13.385-126.349 59.557-308.062 95.927-438.25 39.391-140.979 76.094-253.594 76.458-254.708a4.066 4.066 0 0 1 5.099-2.615c.005.006.016.006.026.01a4.066 4.066 0 0 1 2.599 5.126c-.37 1.12-37.026 113.594-76.364 254.406-36.287 129.88-82.344 311.104-95.662 436.885a4.065 4.065 0 0 1-4.474 3.61 4.09 4.09 0 0 1-.812-.167Z"
    />
    <path
      fill="#E73A0F"
      d="M460.922 777.312a4.065 4.065 0 0 1-1.776-1.146c-63.969-70.849-152.188-219.807-214.932-332.281-67.917-121.75-121.454-228.188-121.98-229.245a4.058 4.058 0 0 1 1.808-5.453 4.06 4.06 0 0 1 5.453 1.802c.526 1.063 53.995 107.36 121.823 228.958C341 600.713 414.948 715.088 465.177 770.718a4.061 4.061 0 0 1-.292 5.735 4.07 4.07 0 0 1-3.963.859Z"
    />
    <path
      fill="#E73A0F"
      d="M460.932 777.312a4.063 4.063 0 0 1 .453-7.859c60.537-11.875 165.511-74.104 303.552-179.959 104.563-80.177 191.979-157.76 192.849-158.536a4.067 4.067 0 0 1 5.735.333 4.075 4.075 0 0 1-.334 5.745c-.88.771-88.515 78.552-193.307 158.906-61.583 47.23-116.974 86.105-164.62 115.542-59.838 36.969-107.719 59.161-142.312 65.943a4.103 4.103 0 0 1-2.016-.115Z"
    />
    <path
      fill="#E73A0F"
      d="M497.312 795.765c-15.448-4.932-28.031-11.322-37.703-19.171a4.062 4.062 0 0 1-.604-5.714 4.068 4.068 0 0 1 5.719-.594c28.489 23.099 87.703 33.068 171.244 28.828 63.662-3.234 119.354-13.677 119.912-13.781a4.061 4.061 0 0 1 4.745 3.24 4.063 4.063 0 0 1-3.23 4.745c-.562.109-56.729 10.645-120.927 13.906-59.312 3.021-105.875-.834-139.156-11.459Z"
    />
    <path
      fill="#E73A0F"
      d="M332.516 763.625c-31.105-9.933-65.683-23.969-103.11-41.865C151.651 684.583 87.583 643 86.943 642.583a4.065 4.065 0 0 1-1.183-5.62 4.058 4.058 0 0 1 5.62-1.192c.63.411 64.318 41.744 141.604 78.697 101.683 48.605 180.573 67.636 228.141 55.042a4.066 4.066 0 0 1 4.969 2.891 4.06 4.06 0 0 1-2.891 4.963c-27.776 7.36-64.937 4.761-110.464-7.724a514.73 514.73 0 0 1-20.223-6.015ZM538.296 409.589l-89.583-143.026a4.07 4.07 0 0 1 1.286-5.599 4.068 4.068 0 0 1 5.605 1.286l84.942 135.62 115.584-84.599a4.059 4.059 0 0 1 5.677.88 4.068 4.068 0 0 1-.886 5.677l-122.625 89.761ZM499.052 551.161l-89.578-143.026a4.063 4.063 0 1 1 6.885-4.312l84.943 135.619 115.583-84.599a4.062 4.062 0 0 1 5.677.875 4.07 4.07 0 0 1-.885 5.683l-122.625 89.76ZM384.776 680l-157.323-60.963a4.069 4.069 0 0 1-2.318-5.261c.807-2.094 3.167-3.125 5.255-2.323l149.183 57.818 41.994-136.974a4.071 4.071 0 0 1 5.078-2.698c2.136.641 3.349 2.932 2.693 5.078L384.776 680ZM276.812 496.901l-129.213-50.078a4.061 4.061 0 0 1-2.318-5.256 4.066 4.066 0 0 1 5.255-2.322l120.782 46.807 29.421-109.406a4.059 4.059 0 0 1 4.974-2.865c2.167.563 3.453 2.813 2.87 4.979l-31.771 118.141ZM801.963 561.323l15.771-137.719a4.074 4.074 0 0 1 4.5-3.578 4.075 4.075 0 0 1 3.573 4.505l-14.739 128.734 113.25.823a4.069 4.069 0 0 1 4.031 4.094 4.06 4.06 0 0 1-4.094 4.031l-122.292-.89ZM641.75 690.005l15.77-137.724a4.065 4.065 0 0 1 4.5-3.578 4.067 4.067 0 0 1 3.568 4.505l-14.74 128.735 113.25.822a4.062 4.062 0 0 1 4.032 4.094 4.057 4.057 0 0 1-4.089 4.031l-122.291-.885Z"
    />
    <path
      fill="#330700"
      d="M414.13 506.594c-4.948 20.385-25.484 32.901-45.854 27.942-20.38-4.948-32.886-25.489-27.938-45.869 4.953-20.386 25.485-32.896 45.86-27.943 20.38 4.948 32.885 25.489 27.932 45.87Z"
    />
    <path
      fill="#fff"
      d="M403.62 491.578c-1.573 6.5-8.125 10.49-14.62 8.911-6.5-1.578-10.485-8.13-8.906-14.63 1.578-6.5 8.125-10.484 14.619-8.906 6.5 1.578 10.485 8.125 8.907 14.625Z"
    />
    <path
      fill="#330700"
      d="M673.994 569.745c-4.948 20.385-25.479 32.896-45.854 27.948-20.375-4.954-32.885-25.495-27.932-45.875 4.948-20.386 25.479-32.901 45.854-27.943 20.38 4.948 32.885 25.489 27.932 45.87Z"
    />
    <path
      fill="#fff"
      d="M663.484 554.729c-1.573 6.5-8.125 10.49-14.62 8.906-6.495-1.573-10.479-8.12-8.906-14.62 1.578-6.5 8.13-10.489 14.62-8.911 6.5 1.578 10.484 8.125 8.906 14.625Z"
    />
    <path
      fill="#330700"
      d="M498.885 585.172c-6.38-1.547-11.984-3.792-16.703-6.172-17.5-8.813-27.588-21.521-29.151-24.193-1.828-3.135-.198-5.516 1.057-6.422 1.282-.927 3.907-1.953 7.073 1.615.558.63.907 1.375 1.058 2.151 3.166 3.854 11.38 12.396 23.838 18.719 19.485 9.885 40.11 9.968 61.292.244a4.504 4.504 0 0 1 5.969 2.219 4.51 4.51 0 0 1-2.214 5.974c-20.339 9.328-38.156 9.281-52.219 5.865Z"
    />
    <path
      fill="#FFB2C3"
      d="M326.578 545.338c-3.156 13.016-21.401 19.75-40.745 15.052-19.338-4.697-32.463-19.057-29.297-32.072 3.157-13.011 21.396-19.75 40.745-15.053 19.339 4.704 32.453 19.063 29.297 32.073ZM730.281 643.442c-3.156 13.011-21.401 19.756-40.745 15.052-19.344-4.697-32.458-19.057-29.302-32.072 3.156-13.011 21.401-19.75 40.745-15.053 19.343 4.704 32.463 19.063 29.302 32.073Z"
    />
  </svg>
);
export default SvgAutumnLeaf;
