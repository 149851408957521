import { t } from '@lingui/macro';
import ReactDom from 'react-dom';
import { useScrollHijack } from '../../hooks';
import { colorFn } from '../../utils';
import { MiloRoundLoader } from '../Loaders/MiloRoundLoader';

export const Loading = ({ text = t`Chargement...`, inline = false }) => {
  useScrollHijack({ isOpen: true });

  if (inline) {
    return <MiloRoundLoader text={text} />;
  }

  if (typeof window === 'object') {
    return ReactDom.createPortal(
      <div className="fixed bottom-0 left-0 right-0 top-0 z-loading flex justify-center bg-white">
        <MiloRoundLoader text={text} />
      </div>,
      document.body
    );
  }

  return null;
};

export const Title = ({ children }) => {
  return <p style={{ color: colorFn('medium-dark'), fontSize: 24, fontWeight: 700, marginBottom: 20 }}>{children}</p>;
};

export const Body = ({ children }) => {
  return <p style={{ color: colorFn('medium-dark'), marginBottom: 20, lineHeight: 20 }}>{children}</p>;
};
