import { MiloSVGProps } from '.';
import { ReactFC } from '../types';
import { colorFn, ColorType } from '../utils';

const SvgComponent: ReactFC<MiloSVGProps> = ({ fill = 'currentColor' as ColorType, ...props }) => {
  const _fill = colorFn(fill);

  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fill={_fill}
        d="M24 8.9835C24.0033 9.18617 23.9449 9.38504 23.8326 9.55377C23.7203 9.7225 23.5593 9.85311 23.3711 9.9283L13.7696 13.7692L9.92889 23.3711C9.85468 23.5568 9.72651 23.716 9.56093 23.8281C9.39535 23.9402 9.19996 24.0001 8.99999 24H8.98379C8.7813 23.9966 8.58459 23.9319 8.41965 23.8144C8.25471 23.6969 8.1293 23.5321 8.05999 23.3418L0.0599918 1.3411C-0.00514694 1.1622 -0.0178576 0.968417 0.0233517 0.782536C0.0645609 0.596655 0.157977 0.426401 0.292622 0.291788C0.427266 0.157175 0.597543 0.0637974 0.783434 0.0226317C0.969324 -0.0185341 1.1631 -0.00577672 1.34199 0.0594039L23.342 8.0594C23.5323 8.12879 23.697 8.25427 23.8145 8.41926C23.932 8.58425 23.9967 8.78099 24 8.9835V8.9835Z"
      />
    </svg>
  );
};

export { SvgComponent as CurrentPosition };
