import classNames from 'classnames';
import React, { forwardRef, InputHTMLAttributes } from 'react';

export const baseInputDigitClassNames = ({ error = false, success = false }: InputExtraProps) =>
  classNames(
    'outline-none select-all text-[28px] leading-5 w-12 font-extrabold h-12 text-center shadow-input disabled:bg-white transition disabled:shadow-none placeholder-medium-gray text-medium-dark w-full leading-5 border rounded-xl disabled:border-gray-lighter',
    {
      'border-error focus-within:border-error': error,
      'border-success focus-within:border-success': success,
      'border-gray-light focus-within:border-medium-dark': !error && !success,
    }
  );

type InputExtraProps = {
  error?: boolean;
  success?: boolean;
  onChangeText?: (value: string) => void; // backward compatibility
};

export type InputDigitProps = InputHTMLAttributes<HTMLInputElement> & InputExtraProps;

const InputDigitRenderFunction: React.ForwardRefRenderFunction<HTMLInputElement, InputDigitProps> = (props, ref) => {
  const {
    success = false,
    error = false,
    type = 'text',
    className = '',
    enterKeyHint = 'enter',
    value: initialValue,
    ...inputProps
  } = props;

  const inputClassNames = classNames(
    className,
    baseInputDigitClassNames({
      error,
      success,
    })
  );

  return (
    <input
      className={inputClassNames}
      ref={ref}
      type="text"
      inputMode="decimal"
      maxLength={1}
      spellCheck={false}
      {...inputProps}
    />
  );
};

export const InputDigit = forwardRef(InputDigitRenderFunction);
