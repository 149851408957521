import { t } from '@lingui/macro';
import { convertDateToReadableFormat } from '@milo/helpers';
import { formatCurrency } from '@milo/react';
import { Invoice } from '@milo/types';
import { ReactFC } from 'src/types';

export const InvoiceItem: ReactFC<Invoice> = ({ name, amount, date_created, offered_as_gift = false }) => {
  const amountFormatted = formatCurrency(amount, { isCents: true });
  const dateFormatted = convertDateToReadableFormat(date_created);

  return (
    <div className="mb-3 rounded-xl border bg-white p-6 md:cursor-default ">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <p className="text-xl font-semibold">{dateFormatted}</p>
          <p className="text-lg">{name || t`[Produit manquant]`}</p>
        </div>
        <div className="flex flex-row justify-center">
          {offered_as_gift && <div className="pr-3 text-2xl">{`🎁`}</div>}
          <div className="text-2xl font-semibold">{amountFormatted}</div>
        </div>
      </div>
    </div>
  );
};
