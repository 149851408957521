import { ReactFC } from 'src/types';
import { Button2, Button2Props } from '.';
import { Link2, LinkProps } from '../Link/Link';

export type LinkButtonProps = LinkProps & Button2Props;

export const LinkButton: ReactFC<LinkButtonProps> = ({ href, ...props }) => {
  return (
    <Link2 href={href}>
      <Button2 {...props} />
    </Link2>
  );
};
