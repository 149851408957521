const roundToClosestFive: (number: number) => number = (number) => {
  if (Math.round(number) < 5) return 0;
  if (Math.round(number) > 5) return 10;
  return 5;
};

export const convertCentsIntegerToClosestFiveDollarString: (cents: number) => string = (cents) => {
  const dollarPrice = Math.round(convertCentsToDollars(cents));

  const dollarPriceArray = String(dollarPrice).split('');
  const lastDigit = dollarPriceArray.splice(-1, 1, '0')[0];
  const numberWithLastDigitAtZero = parseInt(dollarPriceArray.join(''));
  const roundedLastDigit = roundToClosestFive(parseInt(lastDigit));

  return numberWithLastDigitAtZero + roundedLastDigit + '$';
};

export const convertCentsIntegerToDollarString: (cents: number) => string = (cents) => {
  // TODO - remove maybe at some point
  const dollarPrice = convertCentsToDollars(cents);

  // TODO - remove maybe at some point since we might not need
  // // Only return cents after dot if not a integer
  // if (cents % 100 === 0) {
  //   return dollarPrice.toFixed(0) + '$';
  // }

  return dollarPrice.toFixed(2) + '$';
};

const convertCentsToDollars: (cents: number) => number = (cents) => {
  return cents / 100;
};
