import { t, Trans } from '@lingui/macro';
import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OtpGet, OtpVerify, UserOtpLogin } from '../../graphql-queries';
import { useCountdown } from '../../hooks/useCountdown';
import { useGraphqlClient } from '../../hooks/useGraphqlClient';
import { useHttpClient } from '../../hooks/useHttpClient';
import { EmailIcon, PaperAirplaneIcon } from '../../icons';
import { Visited } from '../../icons/Visited';
import { ReactFC } from '../../types';
import { isValidPhoneNumber } from '../../utils/strings';
import { Button2 } from '../Button/Button2';
import { InputSingleFieldForm } from '../Input';
import { InputText2 } from '../Input/InputText2';
import { Spinner } from '../Loaders/Spinner';
import { Text } from '../Text';

const Heading = (props) => <p className="mb-2 text-3xl font-bold" {...props} />;
const NeutralText = (props) => <p className="font-medium leading-3 text-medium-dark" {...props} />;

const CODE_INPUT_LENGTH = 4;

export type CodeInputProps = {
  centered?: boolean;
  align?: 'left' | 'center';
  length?: number;
  loginType?: 'user' | 'verify';
  onComplete?: (val: any) => void;
  onError?: Function;
  onSuccess?: Function;
  to: string;
  value?: string;
};

export const CodeInput: FC<CodeInputProps> = ({
  to,
  length = CODE_INPUT_LENGTH,
  onSuccess,
  onError,
  loginType,
  ...props
}) => {
  if (!loginType) throw new Error('You need to specify a loginType for CodeInput');

  const [isVerifying, setIsVerifying] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [codeResent, setCodeResent] = useState(false);
  const [value, setValue] = useState(props?.value || '');
  const verificationType = isValidPhoneNumber(to) ? 'sms' : 'email';
  const httpClient = useHttpClient();
  const { client: graphqlClient } = useGraphqlClient();
  const [secret, setSecret] = useState(null);

  const codeArray = useMemo(() => Array(CODE_INPUT_LENGTH).fill(''), [value]);
  codeArray.forEach((el, index) => {
    const val = value.split('')[index] || '';
    codeArray.splice(index, 1, val);
  });

  const codeString = codeArray.join('');
  const inputs = useRef(new Array(length));

  useEffect(() => {
    const indexToFocus = codeString.length >= CODE_INPUT_LENGTH ? CODE_INPUT_LENGTH - 1 : codeString.length;
    inputs.current[indexToFocus]?.focus();
  }, [codeArray]);

  const onValueUpdated = async (newValue: string) => {
    setIsError(false);
    setIsSuccess(false);
    const newVal = value + newValue;
    setValue(newVal);

    if (newVal.length === CODE_INPUT_LENGTH) {
      setIsVerifying(true);
      try {
        const query = loginType === 'user' ? UserOtpLogin : OtpVerify;

        await graphqlClient.request(query, {
          input: { email: to, code: newVal, secret: secret ?? '' },
        });

        setIsSuccess(true);
        onSuccess && onSuccess();
      } catch (error) {
        setIsError(true);
        onError && onError();
      } finally {
        setIsVerifying(false);
      }
    }
  };

  const handleBackspace = useCallback(
    (e) => {
      if (e.key === 'Backspace') {
        setIsError(false);
        setIsSuccess(false);
        const newCodeString = codeString.slice(0, codeString.length - 1);
        setValue(newCodeString);
      }
    },
    [codeArray]
  );

  const {
    isFetching: isSendingCode,
    refetch: resend,
    isSuccess: codeSent,
  } = useQuery(
    ['Send code for user'],
    async () => {
      if (verificationType === 'sms') {
        return await httpClient.request({
          path: '/api/auth/sms/send-otp',
          method: 'POST',
          data: { to },
        });
      }

      await graphqlClient.request(OtpGet, { input: { email: to, secret } });
    },
    { retry: false, enabled: !!to }
  );
  const RESEND_TIMEOUT = 15;

  const { countDownStarted, remainingTime, startCountdown } = useCountdown(RESEND_TIMEOUT, () => setCodeResent(false));

  const resendCode = async () => {
    await resend();
    setValue('');
    setIsError(false);
    setCodeResent(true);
    startCountdown();
  };

  const inputDisabled = verificationType !== 'email' || isSendingCode;

  const bottom = (() => {
    const containerClasses = 'inline-flex mb-3 items-center text-medium-gray gap-2 animate-fade-in';

    if (isVerifying) {
      return (
        <div className={cn(containerClasses)}>
          <p>
            <Trans>Vérification du code</Trans>
          </p>
          <Spinner />
        </div>
      );
    }

    if (isSuccess) {
      return (
        <div className={cn(containerClasses)}>
          <p className={'text-success'}>
            <Trans>Code valide</Trans>
          </p>
          <Spinner color="success" />
        </div>
      );
    }

    if (isSendingCode) {
      return (
        <div className={cn(containerClasses)}>
          <p>
            <Trans>Envoi du code en cours </Trans>
          </p>
          <Spinner color="medium" />
        </div>
      );
    }

    return (
      <>
        {codeSent && !isError && (
          <>
            <div className={containerClasses}>
              <p className={'text-success'}>
                <Trans>Code envoyé</Trans>
              </p>
              <Visited fill="success" className="ml-2 w-4" />
            </div>
            <br />
          </>
        )}

        <div className="h-5">
          {countDownStarted && <p>{t`Attendez pour réessayer ${remainingTime}`}</p>}
          {!countDownStarted && (
            <Button2
              disabled={inputDisabled}
              onPress={resendCode}
              variant="muted"
              className="!p-0"
              StartIcon={PaperAirplaneIcon}
            >
              <Trans>Renvoyer le code</Trans>
            </Button2>
          )}
        </div>
      </>
    );
  })();

  return (
    <>
      <div className={`${props.centered && 'mx-auto'} flex w-fit gap-3`}>
        {codeArray.map((digit, i) => (
          <InputText2
            key={`code-input-digit-${i}`}
            containerClassName="!w-fit"
            className="mb-2 h-12 !w-12 rounded-md p-0 text-center text-2xl font-extrabold"
            ref={(ref) => {
              inputs.current[i] = ref;
            }}
            success={isSuccess}
            error={isError}
            maxLength={1}
            type="numeric"
            value={digit}
            onKeyDown={handleBackspace}
            spellCheck={false}
            onChange={(e) => onValueUpdated(e.target.value)}
          />
        ))}
      </div>
      <div>
        {isError && (
          <p className="mb-2 font-bold leading-5 text-error">
            <Trans>
              Le code entré est incorrect ou expiré.
              <br />
              Veuillez réessayer.
            </Trans>
          </p>
        )}
        {bottom}
      </div>
    </>
  );
};

type CustomLoginCodeProps = {
  centered?: boolean;
  body?: string;
  loginType?: CodeInputProps['loginType'];
  onSuccess?: CodeInputProps['onSuccess'];
  title?: string | null;
  to: CodeInputProps['to'];
};

export const CustomLoginCode: ReactFC<CustomLoginCodeProps> = ({ to, ...rest }) => {
  const title = (() => {
    if (rest?.title === undefined) {
      return t`Finalisez votre connexion`;
    }

    if (rest?.title === null) {
      return null;
    }

    return rest.title;
  })();

  const body = rest.body || (
    <Text>
      <Trans>
        <span className="mb-1">Code d'accès envoyé sur:</span>
        <b className="mb-2 block break-all">{to}</b>
        <span>Entrez le ici:</span>
      </Trans>
    </Text>
  );

  return (
    <div className="w-fit">
      <Heading>{title}</Heading>
      <NeutralText>{body}</NeutralText>
      <br />
      <CodeInput to={to} {...rest} />
    </div>
  );
};

type QuickLoginProps = {
  placeholder?: string;
  onSuccess: Function;
  centered?: boolean;
  validationErrorText?: string;
  validationFn?: (val: string) => boolean;
};

export const QuickLogin: ReactFC<QuickLoginProps> = ({
  validationErrorText,
  placeholder = t`Entrez votre courriel`,
  onSuccess,
  centered = false,
  validationFn = () => true,
}) => {
  const [email, setEmail] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);

  return (
    <div className={`w-full ${centered && 'mx-auto flex flex-col items-center justify-center text-center'}`}>
      {!showCodeInput && (
        <InputSingleFieldForm
          type="email"
          autoFocus={true}
          placeholder={placeholder}
          onSubmit={() => setShowCodeInput(true)}
          validationFn={validationFn}
          onChange={setEmail}
          data-testid="quick-login-email-input"
          validationErrorText={validationErrorText}
        />
      )}
      {showCodeInput && (
        <>
          <CustomLoginCode loginType="user" to={email} title={null} onSuccess={onSuccess} centered={centered} />
          <Button2 variant="muted" onClick={() => setShowCodeInput(false)} StartIcon={EmailIcon} className="!px-0">
            <Trans>Changer le courriel</Trans>
          </Button2>
        </>
      )}
    </div>
  );
};
