import { useEffect, useState } from 'react';

export const deleteFromSessionStorage = (id: string) => {
  return sessionStorage.removeItem(id);
};

export const writeToSessionStorage = (id: string, value: number | string | object | boolean) => {
  const persistedValue = typeof value === 'object' ? JSON.stringify(value) : String(value);
  sessionStorage.setItem(id, persistedValue);
  return persistedValue;
};

export const readFromSessionStorage = (id: string) => {
  const value = sessionStorage.getItem(id);

  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export const useSessionStorage: (key: string, defaultValue?: any) => [any, Function, Function] = (
  key,
  defaultValue = null
) => {
  const [value, setValue] = useState(() => {
    const valueFromStorage = readFromSessionStorage(key);
    return valueFromStorage ?? defaultValue;
  });

  const updateSessionValue = (value: any) => {
    writeToSessionStorage(key, value);
    setValue(value);
  };

  useEffect(() => {
    if (value !== undefined) {
      writeToSessionStorage(key, JSON.stringify(value));
    }
  }, [value, key]);

  return [value, updateSessionValue, () => deleteFromSessionStorage(key)];
};
