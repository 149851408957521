import config from '../../config';
import { useSessionStorage } from '../../hooks';
import { MapPinAlt2 } from '../../icons';
import { MiloRoundLogo } from '../../logos';
import { Button } from '../Button';
import { Link } from '../Link';
import { Modal, ModalBody } from '../Modal';

export const ContestModal = () => {
  const [open, setOpen] = useSessionStorage('contest-modal-open', true);
  return null;

  return (
    <Modal
      open={open}
      onRequestClose={() => {
        console.log('close');
        setOpen(false);
      }}
    >
      <ModalBody
        className="relative h-[500px] bg-cover bg-no-repeat p-[40px]"
        style={{ backgroundImage: `url("https://${config.APP_DOMAIN}/images/concours/cap-au-leste.png")` }}
      >
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-black/20"></div>
        <div className="relative h-full space-y-10 rounded-3xl border-4 border-white pt-2">
          <MiloRoundLogo width={77} className="absolute bottom-full left-1/2 translate-y-1/2 -translate-x-1/2" />
          <h1 className="text-center text-3xl font-black text-white md:text-6xl">
            JEU
            <br />
            CONCOURS
          </h1>
          <p className="text-center text-lg font-semibold text-white md:text-2xl">
            TENTEZ DE REMPORTER
            <br />
            <span className="rounded-full bg-red px-2 font-black text-inherit md:text-3xl">UN WEEK-END CHALET</span>
            <br />
            AVEC DÉJEUNER POUR 2
          </p>
          <p className="flex items-center justify-center gap-2 text-xl font-bold text-white">
            <MapPinAlt2 strokeWidth={10} fill="red" className="h-6 w-6" />
            Au Cap au Leste
          </p>
          <Link href="https://miloguide.nyc3.digitaloceanspaces.com/prd/files/cap-au-leste-reglements.pdf">
            <Button className="!absolute bottom-0 left-1/2 w-3/4 -translate-x-1/2 translate-y-1/2 text-xl !font-black">
              RÈGLEMENTS DU JEU
            </Button>
          </Link>
        </div>
      </ModalBody>
    </Modal>
  );
};
