export const UserSearch = /* GraphQL */ `
  query UserSearch($string: String!) {
    UserSearch(string: $string) {
      id
      member_code
      first_name
      last_name
      status
      privilege_interval {
        start
        end
      }
      visits {
        experience_id
        date_created
      }
      visits_this_year {
        experience_id
        date_created
      }
      has_pending_free_visit
    }
  }
`;
