import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { InputSelect2 as InputSelect, InputSelect2Props as InputSelectProps } from '.';
import { useProvinces } from '../../hooks';
import { ReactFC } from '../../types';

type InputProvinceProps = {
  countryCode?: string;
} & InputSelectProps;

export const InputProvince2: ReactFC<InputProvinceProps> = (props) => {
  const { countryCode, ...rest } = props;
  const [provinces] = useProvinces(countryCode || undefined);
  const [provincesOptions, setProvincesOptions] = useState([]);

  useEffect(() => {
    if (provinces && countryCode) {
      const mappedCountriesOptions = provinces
        .map((p) => {
          return { key: p.code, label: p.name, value: p.code };
        })
        .filter((opt) => opt.value === 'QC');

      setProvincesOptions(mappedCountriesOptions);
    }
  }, [countryCode, provinces]);

  return <InputSelect placeholder={t`Choisir province`} disabled={!countryCode} items={provincesOptions} {...rest} />;
};
