import { FreeVisitFragment, VisitFragment } from './fragments/Visits';

const SubscriptionFragment = /* GraphQL */ `
  id
  name
  description
  auto_renew
  cancel_date
  current_period_end
  current_period_start
  product_type
  started_at
  status
  pending_activation_date
  type
  product_id
  price_cents
  interval_count
  interval
`;

export const UserCurrent = /* GraphQL */ `
  query UserCurrent {
    user: UserCurrent {
      id
      promotional_partners_memberships {
        partner_id
        membership_number
        membership_type
        membership_status
      }
      address {
        city
        country
        postal_code
        province
        route
        street_number
      }
      birth_date
      email
      date_created
      first_name
      gender
      has_passed_onboarding
      has_pending_prepaid
      pending_promo_codes {
        id
        code
        applicable_product_id
        percent_off
        amount_off
        valid_at
        expires_at
      }
      pending_free_visit {
        ${FreeVisitFragment}
      }
      last_used_free_visit {
        ${FreeVisitFragment}
      }
      free_visits {
        ${FreeVisitFragment}
      }
      invoices {
        id
        name
        amount
        offered_as_gift
        date_created
      }
      last_name
      is_legacy
      member_code
      payment_method {
        id
        brand
        last4
        expiry_year
      }
      privilege_interval {
        start
        end
      }
      privilege_reset_day
      region
      region_id
      stripe_customer_id
      customer {
        id
        balance
      }
      subscription {
        ${SubscriptionFragment}
      }
      subscriptions {
        ${SubscriptionFragment}
      }
      scheduled_subscriptions {
        priceId
        startDate
      } 
      status
      visited_this_year
      visits_this_year {
        ${VisitFragment}
      }
      visits {
        ${VisitFragment}
      }
      wishlists {
        id
        name
        experiences {
          id
          name
          slug
          main_image
          savings_max
          additional_images
        }
        date_created
      }
    }
  }
`;
