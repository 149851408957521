import config from '@config';
import { t } from '@lingui/macro';
import { analytics, ErrorBoundary, NextBasePageProps, ReactFC } from '@milo/react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import pathNormalize from 'path-normalize';
import 'raf/polyfill';
import { useEffect } from 'react';
import '../styles/global.css';

Router.events.on('routeChangeComplete', () => {
  analytics.event('page');
});

const App: ReactFC<AppProps<NextBasePageProps>> = ({ Component, pageProps, router }) => {
  const isProd = config.APP_STAGE === 'prd';
  const title = 'M ta région';
  const description =
    pageProps.seo?.description ||
    t`En recherche d’inspiration d’activités, en balade autour du Québec? M ta région rassemble en une seule place toutes les bonnes adresses à découvrir et soutenir, tout en économisant.`;
  const imageUrl =
    pageProps.seo?.imagePath ?? 'https://' + pathNormalize(`${config.APP_DOMAIN}/images/open-graph/default.png`);

  const pageUrl = 'https://' + pathNormalize(`${config.APP_DOMAIN}${router.asPath}`);

  useEffect(() => {
    analytics.event('page');
  }, []);

  useEffect(() => {
    if (isProd) {
      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(config.FACEBOOK_PIXEL_ID);
          ReactPixel.pageView();

          router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView();
          });
        });
    }
  }, [router.events]);

  const trackingScripts = <Script src="https://www.google-analytics.com/analytics.js" />;

  return (
    <ErrorBoundary>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="canonical" href={pageUrl} />

        {'<!-- Facebook Meta Tags -->'}
        <meta property="og:url" content={pageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />

        {'<!-- Twitter Meta Tags -->'}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content={config.APP_DOMAIN} />
        <meta property="twitter:url" content={pageUrl} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />

        <Script src="https://cdnjs.cloudflare.com/ajax/libs/iamdustan-smoothscroll/0.4.0/smoothscroll.min.js"></Script>
        {isProd && trackingScripts}
      </Head>
      <Component {...pageProps} />
    </ErrorBoundary>
  );
};

export default App;
