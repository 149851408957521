import { Trans } from '@lingui/macro';
import { Experience, Wishlist } from '@milo/types/index';
import { isEqual } from 'lodash';
import React, { FormEventHandler, useEffect, useState } from 'react';
import { useAuthentication, useWishlistMutations } from '../../hooks';
import { AuthedAction } from '../AuthedAction';
import { Button2 } from '../Button';
import { Link2 } from '../Link';
import { Modal, ModalBody } from '../Modal';
import { Text } from '../Text';
import { NewWishlistForm } from '../Wishlist';

type AddWishlistModalProps = {
  experience: Experience;
  children: React.ReactNode;
};

export const AddWishlistModal = ({ children, experience }: AddWishlistModalProps) => {
  const { user, isFetching: userFetching } = useAuthentication();
  const wishlists = (user?.wishlists || []).sort((a, b) => b.id - a.id) as Wishlist[];
  const mappedWishlists = wishlists
    .filter((w) => w.experiences.some((exp) => exp.id === experience.id))
    .map((wishlist) => wishlist.id)
    .sort((a, b) => a - b);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedWishlists, setSelectedWishlists] = useState<Wishlist['id'][]>([]);
  const [createLoading, setCreateLoading] = useState(false);

  const { updateWishlistExperiences } = useWishlistMutations();

  useEffect(() => {
    setSelectedWishlists(mappedWishlists);
  }, [JSON.stringify(mappedWishlists.sort((a, b) => a - b))]);

  const saveAvailable =
    !isEqual(
      selectedWishlists.sort((a, b) => a - b),
      mappedWishlists.sort((a, b) => a - b)
    ) && !userFetching;

  const commitChanges: FormEventHandler = async () => {
    const toAdd = selectedWishlists.filter((id) => !mappedWishlists.includes(id));
    const toRemove = mappedWishlists.filter((id) => !selectedWishlists.includes(id));
    const response = await updateWishlistExperiences.mutateAsync({ experienceId: experience.id, toAdd, toRemove });
    const returnPayload = response?.['WishlistUpdateExperiences']?.data;

    if (returnPayload) {
      setSelectedWishlists(returnPayload);
    }
  };

  const addOrRemoveWishlist = (wishlistId: number) => {
    if (selectedWishlists.includes(wishlistId)) {
      return setSelectedWishlists((prev) => prev.filter((id) => id !== wishlistId));
    }

    setSelectedWishlists((prev) => [...prev, wishlistId]);
  };

  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <>
      <AuthedAction authLevel="logged-in">
        <button onClick={handleOpen}>{children}</button>
      </AuthedAction>
      <Modal className="relative" open={isOpen} onRequestClose={() => setIsOpen(false)}>
        <ModalBody className={`thin-scrollbar h-[600px] max-h-[60vh] overflow-y-scroll ${saveAvailable && 'pb-20'}`}>
          <div onSubmit={commitChanges}>
            <h2 className="mb-10 text-center text-3xl font-semibold">
              Ajouter <br /> <i>"{experience.name}"</i> <br /> à vos listes de favoris
            </h2>

            <div className="mb-8">
              <NewWishlistForm onCreate={() => setCreateLoading(true)} onSettled={() => setCreateLoading(false)} />
            </div>

            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2">
                <Text size="h3" className="font-semibold">
                  <Trans>Listes existantes</Trans>
                </Text>

                <Link2 href="/profile/wishlist" underline className="inline-flex items-center gap-2">
                  <Text muted>
                    <Trans>Gérer les listes</Trans>
                  </Text>
                </Link2>
              </div>

              {createLoading && (
                <Text muted>
                  <Trans>Sauvegarde en cours...</Trans>
                </Text>
              )}

              {wishlists.length > 0 && (
                <>
                  {!createLoading && (
                    <Text muted>
                      <Trans>Sélectionnez les listes auxquelles vous voulez ajouter cette expérience.</Trans>
                    </Text>
                  )}

                  {wishlists.map((wishlist: Wishlist, index) => {
                    const checked = selectedWishlists.includes(wishlist.id);

                    return (
                      <label
                        className="flex w-full items-center gap-4 px-2 py-1 hover:bg-charcoal/5"
                        htmlFor={`wishlist-${wishlist.id}`}
                        key={`wishlist-${wishlist.id}`}
                      >
                        <input
                          disabled={createLoading}
                          id={`wishlist-${wishlist.id}`}
                          type="checkbox"
                          onClick={(e) => {
                            e.stopPropagation();
                            addOrRemoveWishlist(wishlist.id);
                          }}
                          checked={checked}
                          className="h-7 w-7 rounded border-gray-light"
                        />
                        <h3 className="max-w-[350px] truncate text-xl">{wishlist.name || `liste n°${index + 1}`}</h3>
                      </label>
                    );
                  })}
                </>
              )}

              {wishlists.length < 1 && (
                <Text muted>
                  <Trans>
                    Vous n'avez liste de favoris, commencez par en créer une et ajoutez-y les expériences qui vous
                    intéressent.
                  </Trans>
                </Text>
              )}
            </div>
            {saveAvailable && (
              <Button2
                onClick={commitChanges}
                loading={updateWishlistExperiences.isLoading}
                type="submit"
                variant="dark"
                className="!absolute bottom-3 left-1/2 w-3/4 -translate-x-1/2 animate-fade-in"
              >
                <Trans>Mettre à jour les listes</Trans>
              </Button2>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
