import classNames from 'classnames';
import { ButtonHTMLAttributes, FC, HTMLAttributes, ReactNode } from 'react';
import { Text } from '../Text';

export const basePresentationCardClassName = (className = '') => {
  return classNames(
    className,
    'flex flex-col align-start justify-start text-left bg-white rounded-xl shadow-[0_6px_24px_rgba(0,0,0,0.1)] p-6 md:cursor-default'
  );
};

export type PresentationCardProps = HTMLAttributes<HTMLDivElement>;

export const PresentationCard: FC<PresentationCardProps> = ({ className = '', ...divProps }) => {
  return <div className={basePresentationCardClassName(className)} {...divProps} />;
};

export type HomePageCardProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  heading: ReactNode;
  emoji: ReactNode;
  body: ReactNode;
};

export const HomePageCard: FC<HomePageCardProps> = ({ heading, body, emoji, className = '' }) => {
  return (
    <div className={basePresentationCardClassName(className)}>
      <Text size="h3" className="mb-2 flex h-14 w-14 items-center justify-center rounded-full bg-gray-lighter">
        {emoji}
      </Text>
      <div className="flex flex-col">
        <Text size="h4" className="mb-3 font-bold">
          {heading}
        </Text>
        <Text lineHeight="spaced" className="text-medium-dark">
          {body}
        </Text>
      </div>
    </div>
  );
};
