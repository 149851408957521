import React from 'react';

export const copyToClipboard = (e: MouseEvent | React.MouseEvent, str: string, text: string = `C'est copié`) => {
  const yPositionStart = e.clientY;
  const yPositionEnd = yPositionStart - 15;
  const xPosition = e.clientX + 10;
  const clientWidth = document.body.clientWidth;

  const fadeInProperties = [
    { opacity: '0', top: `${yPositionStart}px` },
    { opacity: '1', top: `${yPositionEnd}px` },
  ];
  const fadeInTiming = { duration: 50, iterations: 1 };

  document.getElementById('clipboard-feedback')?.remove();
  const fb = document.createElement('span');
  fb.textContent = text;
  fb.id = 'clipboard-feedback';
  fb.style.backgroundColor = '#282828';
  fb.style.borderRadius = '10px';
  fb.style.color = 'white';
  fb.style.fontSize = '12px';
  fb.style.fontWeight = 'bold';
  fb.style.left = `0px`;
  fb.style.padding = '5px 10px';
  fb.style.position = 'fixed';
  fb.style.top = `${yPositionEnd}px`;
  fb.style.zIndex = '999999';
  document.body.appendChild(fb);

  const actualElementWidth = fb.offsetWidth;
  if (xPosition + actualElementWidth > clientWidth) {
    fb.style.left = 'auto';
    fb.style.right = '0px';
  } else {
    fb.style.left = `${xPosition}px`;
  }

  fb.animate(fadeInProperties, fadeInTiming);
  setTimeout(() => fb.remove(), 2000);

  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};
