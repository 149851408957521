import { ColorType } from 'src/utils/palette';
import { colorFn } from '../utils';

const SvgComponent = ({ fill = 'currentColor' as ColorType, strokeWidth = 2, ...props }) => {
  const _fill = colorFn(fill);

  return (
    <svg viewBox="0 0 14 20" fill={_fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.18428 20C9.57898 16.3379 14 9.5775 14 7.13607C14 3.1925 10.8683 0 7 0C3.13166 0 0 3.19254 0 7.13607C0 9.57732 4.7894 16.3379 7.18428 20ZM7.00008 3.84964C8.8422 3.84964 10.3159 5.352 10.3159 7.13607C10.3159 9.014 8.8422 10.5164 7.00008 10.5164C5.25006 10.5164 3.77631 9.014 3.77631 7.13607C3.77631 5.35204 5.25002 3.84964 7.00008 3.84964Z"
        fill={_fill}
        strokeWidth={0}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
};

export { SvgComponent as MapPinAlt2 };
