import { useAuthentication, useSessionStorage } from '../../hooks';
import { POST_PROFILE_COMPLETION_REDIRECT_PATH } from '../AuthedAction';
import { Loading, Route } from '../Route';
import { UpdateUserInformationForm } from './UpdateUserInformationForm';

export const CompleteProfile = () => {
  const { user } = useAuthentication();
  const [redirectUrl = '/experiences', , deletePostProfileRedirectUrl] = useSessionStorage(
    POST_PROFILE_COMPLETION_REDIRECT_PATH
  );

  if (user?.has_passed_onboarding) {
    location.href = redirectUrl;
    return <Loading />;
  }

  return (
    <Route.LoggedIn>
      <UpdateUserInformationForm
        onSuccess={() => {
          deletePostProfileRedirectUrl();
          location.href = redirectUrl;
        }}
      />
    </Route.LoggedIn>
  );
};
