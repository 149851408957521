import { colorFn, ColorType } from '../utils';

function SvgComponent({ fill = 'currentColor' as ColorType, ...props }) {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill={_fill}
        d="M10.0216 7.36041L1.96015 0.289635C1.49088 -0.096467 0.773041 -0.096467 0.331252 0.289635C-0.110458 0.675736 -0.110458 1.32729 0.331252 1.71346L7.59209 8.06021L0.331252 14.407C-0.110458 14.8171 -0.110458 15.4446 0.331252 15.8308C0.772962 16.2169 1.49077 16.2169 1.96015 15.8308L10.0216 8.78419C10.4633 8.374 10.4633 7.74658 10.0216 7.36041Z"
      />
    </svg>
  );
}

export { SvgComponent as BasicArrowRight };
