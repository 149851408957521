import { colorFn } from '../utils';

function SvgComponent({ strokeWidth = 6, fill = colorFn('dark'), ...props }) {
  return (
    <svg viewBox="0 0 114 114" fill="none" {...props}>
      <path
        d="M108.788 43.698l.813 4.83a53.232 53.232 0 01-62.413 61.105A53.232 53.232 0 014.133 62.686 53.235 53.235 0 0170.398 5.78m32.138.749L58.878 67.91l-18.399-13.1"
        stroke={fill}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { SvgComponent as CheckCircleValidation };
