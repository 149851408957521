import cn from 'classnames';
import {
  createContext,
  CSSProperties,
  FC,
  HTMLAttributes,
  MouseEventHandler,
  PropsWithChildren,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useDeviceInfo } from '../../hooks';
import { useScrollHijack } from '../../hooks/useScrollHijack';
import { ArrowLeft, ModalCloseIcon } from '../../icons';
import { ReactFC } from '../../types';
import { zIndexFn } from '../../utils/style';

type OnBackEventHandler = () => void;

export const ModalContext = createContext<{
  setOnBack?: (onBack: OnBackEventHandler) => void;
  setTitle: (title: string) => void;
  setShowCloseBtn?: (show: boolean) => void;
  onClose?: () => void;
}>({
  setTitle: () => {},
  setShowCloseBtn: () => {},
  onClose: () => {},
});

export const ModalContainer: FC<HTMLAttributes<HTMLDivElement> & { stickToBottom?: boolean }> = ({
  stickToBottom = false,
  className = '',
  ...divProps
}) => {
  return (
    <div
      className={`${className} animate-fade-in-fast ${
        stickToBottom ? 'absolute -bottom-1 w-screen sm:relative sm:bottom-auto sm:w-auto' : 'relative'
      }`}
      {...divProps}
      style={{ zIndex: zIndexFn('modal', 1) }}
    />
  );
};

export const ModalBody = ({ className = '', ...divProps }) => {
  return (
    <div
      className={`no-scrollbar max-h-[85vh] w-screen overflow-y-auto rounded-b-2xl rounded-t-2xl bg-white p-8 sm:max-w-lg ${className}`}
      {...divProps}
    />
  );
};

type ModalBackButtonProps = {
  onPress: (x: any) => void;
};

const ModalBackButton: ReactFC<ModalBackButtonProps> = ({ onPress }) => {
  return (
    <button
      className="absolute left-4 top-4 flex items-center justify-center rounded-full bg-gray-lighter p-2"
      onClick={onPress}
    >
      <ArrowLeft className="h-3 w-3 text-medium-dark" fill="currentColor" />
    </button>
  );
};

export type ModalProps = PropsWithChildren<{
  closeOnBackdropClick?: boolean;
  dismissable?: boolean;
  onRequestClose?: () => void;
  opaque?: boolean;
  open: boolean;
  showCloseButton?: boolean;
  stickToBottom?: boolean;
  style?: CSSProperties;
}>;

export const Modal: ReactFC<ModalProps> = ({
  children,
  closeOnBackdropClick = true,
  onRequestClose,
  opaque = false,
  open,
  showCloseButton = true,
  stickToBottom = true,
  style,
  dismissable = true,
}) => {
  const backdropRef = useRef(null);
  const [onBack, setOnBack] = useState<OnBackEventHandler>();
  const [title, setTitle] = useState<string>();
  const [showCloseBtn, setShowCloseBtn] = useState(showCloseButton);
  useScrollHijack({ isOpen: open, onEscapeKeyPress: onRequestClose });
  const { safeAreas } = useDeviceInfo();

  const handleModalClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    if (closeOnBackdropClick && e.target === backdropRef.current) {
      if (!dismissable) return;
      onRequestClose?.();
    }
  };

  if (!open) return null;

  return ReactDOM.createPortal(
    <ModalContext.Provider
      value={{
        setOnBack,
        setTitle: setTitle,
        setShowCloseBtn: setShowCloseBtn,
        onClose: () => onRequestClose && onRequestClose(),
      }}
    >
      {showCloseBtn && dismissable && (
        <button
          data-testid="modal-close-button"
          className="fixed z-toast flex h-10 w-10 items-center justify-center rounded-full bg-white leading-10 transition"
          style={{ top: safeAreas.top, right: safeAreas.right }}
          onClick={(e) => {
            e.preventDefault();
            onRequestClose?.();
          }}
        >
          <ModalCloseIcon className="h-4 w-4 text-charcoal" />
        </button>
      )}
      <div
        ref={backdropRef}
        onMouseDown={handleModalClick}
        id="modal"
        className={cn(
          'fixed top-0 z-modal flex h-screen w-screen animate-fade-in-fast items-center justify-center bg-black/50',
          {
            'bg-black': opaque,
          }
        )}
      >
        <ModalContainer style={style} stickToBottom={stickToBottom}>
          <div>{children}</div>
          {title !== undefined && (
            <p className="absolute left-1/4 right-1/4 top-6 text-center text-lg font-semibold leading-5 text-charcoal">
              {title}
            </p>
          )}
          {onBack !== undefined && <ModalBackButton onPress={onBack} />}
        </ModalContainer>
      </div>
    </ModalContext.Provider>,
    document.querySelector('body')
  );
};
