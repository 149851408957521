import { t, Trans } from '@lingui/macro';
import { REGION_OTHER_ID } from '@milo/constants';
import { get, pick } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { FormEventHandler, useEffect, useMemo, useState } from 'react';
import { UserUpdate } from '../../graphql-queries';
import { useAuthentication, useGraphqlClient, useMutation } from '../../hooks';
import { ArrowRight } from '../../icons';
import { ReactFC } from '../../types';
import { Button2 } from '../Button';
import { Field2, InputAge, InputGender2, InputGooglePlaces, InputRegion2, InputText2 } from '../Input';
import { Link2 } from '../Link';
import { Skeleton } from '../Skeleton';

export type UpdateUserInformationFormProps = {
  onSuccess?: Function;
  showAddressHeaderText?: boolean;
  showHeaderText?: boolean;
  submitText?: string;
  showTerms?: boolean;
};

type FormErrors = {
  [x: string]: string;
};

const mandatoryFields = ['birth_date', 'email', 'first_name', 'gender', 'last_name', 'region_id'];

export const containerClasses = 'mx-auto w-full max-w-xl mb-5';

export const UpdateUserInformationForm: ReactFC<UpdateUserInformationFormProps> = ({
  onSuccess,
  showTerms = true,
  showHeaderText = true,
  showAddressHeaderText = true,
  submitText = t`Continuer`,
}) => {
  const { user: userCurrent, isLoading: userLoading } = useAuthentication();
  const [user, setUser] = useState(null);
  const [visibleErrors, setVisibleErrors] = useState<FormErrors>({});
  const { client } = useGraphqlClient();

  useEffect(() => {
    setUser(userCurrent);
  }, [userCurrent]);

  const _age = useMemo(() => {
    if (!user?.birth_date) {
      return '';
    }
    const iv = Interval.fromDateTimes(DateTime.fromISO(user?.birth_date), DateTime.now());
    const age = Math.round(iv.length('year'));
    return age;
  }, [user?.birth_date]);

  const { mutate: updateUser, isLoading: updateUserLoading } = useMutation(
    async (e) => {
      //
      const baseInfo = pick(user, [...mandatoryFields, 'address']);
      await client.request(UserUpdate, {
        input: {
          ...baseInfo,
          onboarding_type: 'new',
        },
      });
    },
    { onSuccess: async () => onSuccess && (await onSuccess()) }
  );

  const getMissingFields = () => {
    const fields = mandatoryFields;
    const missing = fields.filter((field) => !get(user, field));
    return missing;
  };

  const _missingFields = getMissingFields();
  const _disabled = updateUserLoading;
  const _loading = updateUserLoading;

  const _onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (_missingFields.length > 0) {
      const errors: { [x: string]: string } = {};
      _missingFields.forEach((m) => {
        errors[m] = t`Ce champ est obligatoire`;
      });
      setVisibleErrors(errors);
      return false;
    }

    updateUser();
  };

  if (userLoading) {
    return (
      <div className={containerClasses}>
        <Skeleton className="mb-5 h-4 w-2/4 rounded-md" />
        <Skeleton className="mb-14 h-4 w-3/4 rounded-md" />
        <Skeleton className="mb-10 h-10 w-full rounded-md" times={5} />
        <Skeleton className="mb-10 ml-auto h-10 w-44 rounded-full" />
      </div>
    );
  }

  return (
    <div className={containerClasses}>
      <form onSubmit={_onSubmit}>
        <div className="mb-6">
          {showHeaderText && (
            <div className="mb-6">
              <p className="mb-2 text-3xl font-bold">
                <Trans>Informations de profil</Trans>
              </p>
              <p className="leading-tight text-medium-dark">
                <Trans>
                  Similaire à d'autres services fonctionnant par abonnement tel Netflix ou votre fournisseur Internet, M
                  ta région a besoin de certaines informations pour vous identifier adéquatement et considérer votre
                  abonnement valide dans nos systèmes.
                </Trans>
              </p>
            </div>
          )}

          <Field2
            label={t`Prénom`}
            helpText={t`Tel qu'inscrit sur votre pièce d'identité`}
            errorText={visibleErrors?.first_name}
          >
            <InputText2
              autoFocus
              value={user?.first_name || ''}
              onChange={(e) => setUser((user) => ({ ...user, first_name: e.target.value }))}
              placeholder={t`Prénom`}
            />
          </Field2>

          <Field2
            label={t`Nom`}
            helpText={t`Tel qu'inscrit sur votre pièce d'identité`}
            errorText={visibleErrors?.last_name}
          >
            <InputText2
              placeholder={t`Nom`}
              value={user?.last_name || ''}
              onChange={(e) => setUser((user) => ({ ...user, last_name: e.target.value }))}
            />
          </Field2>

          <Field2 label={t`Quel âge avez-vous?`} errorText={visibleErrors?.birth_date}>
            <InputAge
              value={_age}
              onChange={(e) => {
                const age = e.target.value;

                if (!age) {
                  setUser((user) => ({ ...user, age: null }));
                }

                const now = DateTime.now();
                const then = now.minus({ years: Number(age) });
                const birthDate = now.set({ year: then.year }).toString();
                setUser((user) => ({ ...user, birth_date: birthDate }));
              }}
            />
          </Field2>

          <Field2 label={t`Genre`} errorText={visibleErrors?.gender}>
            <InputGender2
              value={user?.gender || ''}
              onChange={(e) => setUser((user) => ({ ...user, gender: e.target.value }))}
            />
          </Field2>
        </div>
        {showAddressHeaderText && (
          <div className="mb-5">
            <p className="text-3xl font-bold">
              <Trans>Où habitez-vous?</Trans>
            </p>
            <p className="text-medium-dark">
              <Trans>
                Connaître votre localisation nous permet de vous envoyer des suggestions d'expériences à vivre autour de
                vous.
              </Trans>
            </p>
          </div>
        )}
        <Field2 label={t`Votre région de domicile`} errorText={visibleErrors?.region_id}>
          <InputRegion2
            value={user?.region_id || ''}
            onChange={(e) => setUser((user) => ({ ...user, region_id: Number(e.target.value) }))}
          />
        </Field2>
        {user?.region_id !== REGION_OTHER_ID && (
          <Field2
            style={{ zIndex: 10000, marginBottom: 20 }} // Need to apply the zIndex here for google search results element to show up on top: ;
            label={t`Votre code postal`}
            errorText={visibleErrors?.address}
          >
            <InputGooglePlaces
              onChange={(e) => setUser((user) => ({ ...user, address: e }))}
              initialValue={user?.address || null}
            />
          </Field2>
        )}
        {user?.region_id === REGION_OTHER_ID && (
          <Field2
            style={{ zIndex: 10000, marginBottom: 20 }} // Need to apply the zIndex here for google search results element to show up on top: ;
            label={t`Ville de résidence`}
            errorText={visibleErrors?.address}
          >
            <InputText2
              onChange={(e) => setUser((user) => ({ ...user, address: { ...user.address, city: e.target.value } }))}
              value={user?.address?.city || null}
            />
          </Field2>
        )}
        {showTerms && (
          <p className="mb-4 text-sm text-medium">
            <Trans>
              En poursuivant, vous confirmez avoir lu et accepté les{' '}
              <Link2 popup href="/terms" underline>
                Conditions d'utilisation
              </Link2>{' '}
              et la{' '}
              <Link2 popup href="/terms" underline>
                Politique de confidentialité
              </Link2>{' '}
              M ta région.
            </Trans>
          </p>
        )}
        <Button2 className="w-full" EndIcon={ArrowRight} type="submit" disabled={_disabled} loading={_loading}>
          {submitText}
        </Button2>
      </form>
    </div>
  );
};
