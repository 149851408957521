import { Preferences } from '@capacitor/preferences';
import config from '@milo/config/config-public.json';
import { PUSH_NOTIFICATIONS_TOKEN_KEY } from '@milo/constants';
import { LooseObject, Platform } from '@milo/types';
import * as rudderanalytics from 'rudder-sdk-js';
import { v4 as uuidv4 } from 'uuid';
import { getPlatform } from '../hooks/useDeviceInfo';
import { createHttpClient } from '../hooks/useHttpClient';
type RudderAnalytics = typeof rudderanalytics;

const ANONYMOUS_ID_KEY = 'anonymous_tracking_id';
const ANALYTICS_SCRIPT_ID = 'analytics_script';

async function getWebAnalyticsInstance() {
  let rudder;
  const existingRudderScript = document.getElementById(ANALYTICS_SCRIPT_ID);

  if (!rudder || !existingRudderScript) {
    rudder = await new Promise((resolve) => {
      const rudderScriptNode = document.createElement('script');
      rudderScriptNode.id = ANALYTICS_SCRIPT_ID;
      rudderScriptNode.src = '/analytics-sdk';
      document.body.appendChild(rudderScriptNode);
      rudderScriptNode.onload = () => {
        // @ts-ignore
        const rudderstack = window.rudderanalytics;
        rudderstack.load(config.RUDDERSTACK_WEB_WRITE_KEY, '/analytics', {
          onLoaded: () => {
            resolve(rudderstack);
          },
        });
      };
    });
  }

  return rudder as RudderAnalytics;
}

/**
 * Resets the web analytics client to get rid of all the previous data in case the
 * user logs in with another account
 */
const reset = async () => {
  const platform = getPlatform();
  if (platform === Platform.Web) {
    const a = await getWebAnalyticsInstance();
    a.reset();
  }
};

const getAnonymousId = async () => {
  const platform = getPlatform();
  if (platform === Platform.Web) {
    const a = await getWebAnalyticsInstance();
    return a.getAnonymousId();
  }

  const trackingId = await Preferences.get({
    key: ANONYMOUS_ID_KEY,
  });

  if (typeof trackingId?.value === 'string') return trackingId.value;

  const anonymousId = uuidv4();
  await Preferences.set({ key: ANONYMOUS_ID_KEY, value: anonymousId });
  return anonymousId;
};

/**
 * # Mobile only
 *
 * Used to register the device token when the application is opened
 *
 * The token is used to send push notifications to the device
 *
 * @param identifier id, email or cio_id of the user on customer.io
 */
const registerUserMobileDevice = async (identifier: string) => {
  const mobileDeviceFirebaseToken = await getMobileDeviceToken();
  const platform = getPlatform();

  const http = createHttpClient();

  const response = await http.request({
    method: 'POST',
    data: { identifier, device: { id: mobileDeviceFirebaseToken, platform } },
    url: `https://${config.APP_DOMAIN}/analytics/register-mobile-device`,
  });

  return response.statusCode;
};

const getMobileDeviceToken = async () => {
  const token = await Preferences.get({ key: PUSH_NOTIFICATIONS_TOKEN_KEY });
  return token.value;
};

type EventType = 'track' | 'page' | 'screen' | 'identify';

type IdentifyPayload = LooseObject & {
  userId: string | number;
  email: string;
};

type PagePayload = LooseObject;

type ScreenPayload = LooseObject & {
  path: string;
};

type TrackPayload = LooseObject & {
  eventName: string;
};

type EventPayload = {
  identify: IdentifyPayload;
  page: PagePayload;
  screen: ScreenPayload;
  track: TrackPayload;
};

type TrackEventFn = <T extends EventType>(type: T, payload?: EventPayload[T]) => Promise<void>;
/**
 *
 * Used to track any user action or user data that happens within the app
 *
 * @param type The type of event that is being tracked. Can be one of the following: track, page, screen, identify.
 *
 * - ### track
 * is for tracking user actions and can be called anywhere
 *
 * - ### identify
 * is for tracking user traits and should be called when the user logs
 * in or when we want to add more information about the user
 *
 * - ### page
 * is only for tracking web page navigation
 *
 * - ### screen
 * is only for tracking mobile screen navigation
 *
 * @param payload An arbitrary object of data attached to the tracking of an event
 *
 */
const event: TrackEventFn = async (type, payload = {}) => {
  try {
    // *** Customer.io's API would error out if the event property is not a string ***
    // So, depending on the event type, we are using either the path (for screen tracks on mobile)
    // or the eventName ('for activity tracking') and the fallback is just the type of the event
    // To make sure that there is always an event name set on the object
    const event = payload?.path ?? payload?.eventName ?? type;

    // Also the data sent is a 'properties' object for all events or a 'traits' object in the case of an 'identify' event
    const dataKey = type === 'identify' ? 'traits' : 'properties';

    // Always add the platform boolean so that we can filter based on it in the analytics platforms
    const platform = getPlatform();
    payload['ios'] = platform === Platform.Ios;
    payload['web'] = platform === Platform.Web;
    payload['android'] = platform === Platform.Android;

    // Track web activity
    if (platform === Platform.Web) {
      const a = await getWebAnalyticsInstance();

      if (type === 'track') {
        return a.track(event, payload);
      }

      if (type === 'page') {
        return a.page('', '', payload);
      }

      if (type === 'identify') {
        return a.identify(payload.userId.toString(), payload);
      }
    }

    // Track mobile activity
    const http = createHttpClient();
    const anonymousId = await getAnonymousId();

    const sourceWriteKey = config.RUDDERSTACK_WEB_WRITE_KEY;
    const dataObject = {
      channel: 'mobile',
      anonymousId,
      event,
      type,
      [dataKey]: {
        ...payload,
      },
    };

    // Register the device token when on mobile and the user is logging in
    if (type === 'identify' && platform !== Platform.Web) {
      if (payload.userId) await registerUserMobileDevice(payload.userId);
      else if (payload.email) await registerUserMobileDevice(payload.email);
    }

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Basic ${btoa(`${sourceWriteKey}:`)}`,
    };

    await http.request({
      method: 'POST',
      headers: headers,
      data: dataObject,
      url: `https://${config.APP_DOMAIN}/analytics/v1/${type}`,
    });
  } catch (error) {
    console.log('Tracking failed:', error);
  }
};

export const analytics = {
  reset,
  event,
};
