import { Trans } from '@lingui/macro';
import { Experience } from '@milo/types';
import { useMemo } from 'react';
import { Button2, ExperienceCard, GuideCard, ImageKitImage, InfoBox, Link2, SideScroller } from '../../components';
import { useAuthentication, useExperiences, useGuides } from '../../hooks';
import { Fire, MapAlt, MapPinAlt2, Money, Star } from '../../icons';
import { ReactFC } from '../../types';

type ExperienceAroundMeProps = {
  showTitle?: boolean;
  showLikeButtons?: boolean;
  experiencesClickable?: boolean;
};

export const ExperienceAroundMe: ReactFC<ExperienceAroundMeProps> = ({
  showTitle = true,
  showLikeButtons = true,
  experiencesClickable = true,
}) => {
  const { data: exps = [] } = useExperiences();
  const experiences = exps.slice(0, 40);

  return (
    <div className="flex flex-col">
      {showTitle && (
        <div className="mb-10 flex items-center justify-between">
          <h2 className="flex items-center text-xl font-bold">
            <MapPinAlt2 className="mr-2 h-4 w-4 text-raspberry-red" />
            <Trans>Expériences autour de vous</Trans>
          </h2>
          <Link2 href="/map">
            <Button2 className="flex gap-2 !bg-medium-dark !px-3 !py-1 text-xs font-normal">
              <MapAlt className="h-4 w-4" />
              <p>
                <Trans>Voir sur la carte</Trans>
              </p>
            </Button2>
          </Link2>
        </div>
      )}

      <SideScroller>
        {experiences.map((experience) => (
          <ExperienceCard
            key={`exp-card-${experience.id}`}
            clickable={experiencesClickable}
            showLikeButton={showLikeButtons}
            experience={experience}
          />
        ))}
      </SideScroller>
    </div>
  );
};

export const CheapExperience: ReactFC = () => {
  const { data } = useExperiences();
  const experiences = [...data]
    .sort((a, b) => {
      return a.savings_max - b.savings_max;
    })
    .slice(0, 40);

  return (
    <div className="flex flex-col">
      <div className="mb-5 flex items-center">
        <Money className="mr-2 h-4 w-4 text-raspberry-red" />
        <h2 className="text-xl font-bold">
          <Trans>Les plus belles économies</Trans>
        </h2>
      </div>

      <SideScroller>
        {experiences.map((experience) => {
          return <ExperienceCard key={`exp-card-${experience.id}`} experience={experience} />;
        })}
      </SideScroller>
    </div>
  );
};

export const MostPopularGuides = () => {
  const { data = [] } = useGuides();

  const getTotalsVisits = (experiences: Experience[]) => {
    if (!experiences) return 0;
    return experiences.reduce((acc, curr) => {
      return acc + curr.visits;
    }, 0);
  };

  const guides = [...data]
    .sort((a, b) => {
      return getTotalsVisits(b.experiences) - getTotalsVisits(a.experiences);
    })
    .slice(0, 40);

  return (
    <div className="flex flex-col">
      <div className="mb-5 flex items-center">
        <Fire className="mr-2 h-4 w-4 text-raspberry-red" />
        <h2 className="text-xl font-bold">
          <Trans>Guides les plus populaires</Trans>
        </h2>
      </div>

      <SideScroller>
        {guides.map((guide) => {
          return (
            <div className=" max-w-xs shrink-0">
              <GuideCard key={`guide-${guide.id}`} guide={guide} />
            </div>
          );
        })}
      </SideScroller>
    </div>
  );
};

export const PersonnalizedGuides = () => {
  const { user, loggedIn } = useAuthentication();

  const { data = [] } = useGuides();

  const favourite_regions = useMemo(() => {
    if (!user) return [];
    const experiences = user?.wishlists?.reduce((acc, curr) => {
      return [...acc, ...curr.experiences];
    }, []);

    const regions = experiences?.map((experience) => {
      return experience.region;
    });

    return regions?.filter((region, index) => {
      return regions?.indexOf(region) === index;
    });
  }, [user]);

  const guides = [...data]
    .filter((guide) => {
      const checkRegion = guide.experiences?.find((experience) => {
        return favourite_regions?.includes(experience.region);
      });

      const checkWishlist = guide.experiences?.find((experience) => {
        return user?.wishlists?.find((wishlist) => {
          const experiencesId = wishlist.experiences.map((experience) => experience.id);
          return experiencesId?.includes(experience.id);
        });
      });

      return checkRegion || checkWishlist;
    })
    .slice(0, 40);

  if (!loggedIn) return null;

  return (
    <div className="flex flex-col">
      <div className="mb-5 flex items-center">
        <Star className="mr-2 h-4 w-4 text-raspberry-red" />
        <h2 className="text-xl font-bold">
          <Trans>Selon vos préférences</Trans>
        </h2>
      </div>

      {guides.length > 0 && (
        <SideScroller>
          {guides.map((guide) => (
            <GuideCard key={`guide-${guide.id}`} guide={guide} />
          ))}
        </SideScroller>
      )}

      {guides.length < 1 && (
        <InfoBox.Default>
          <Trans>Vous n'avez pas encore de liste de souhaits sur lesquelles nous basons les recommandations.</Trans>
        </InfoBox.Default>
      )}
    </div>
  );
};

export const GuidesLanding = () => {
  return (
    <>
      <div className="relative h-[60vw] max-h-[400px] w-full">
        <ImageKitImage
          className="absolute left-0 top-0 z-0 h-full w-full object-cover"
          src={'/images/guide/guide-main.png'}
        />

        <h1 className="xs:text-5xl relative flex h-full w-full items-center justify-center text-center text-3xl font-bold text-white sm:text-4xl">
          <Trans>Quelle expérience recherchez vous?</Trans>
        </h1>
      </div>

      <div className="web-container">
        <div className="web-container flex flex-col gap-6 py-9">
          <ExperienceAroundMe />
          <CheapExperience />
          <MostPopularGuides />
          <PersonnalizedGuides />

          <div className="flex justify-center">
            <Link2 href="/experiences">
              <Button2 className="w-fit">
                <Trans>Voir toutes les expériences</Trans>
              </Button2>
            </Link2>
          </div>
        </div>
      </div>
    </>
  );
};
