import { ExperienceFragment } from './fragments/Experience';

export const GuidesFragment = /* GraphQL */ `
  id
  title
  meta_description
  long_description
  slug
  region {
    name
  }
  city_id
  vertical {
    id
    name
  }
  tags {
    id
    name
    description
  }
  related_guides {
    id
    title
    slug
    main_image_url
  }
  experiences {
    id
    additional_images
    main_image
    thumbnail_image
    savings_max
    visits
  }
`;

export const GuidesList = /* GraphQL */ `
  query GuidesList {
    GuidesList {
      ${GuidesFragment}
    }
  }
`;
