import { t } from '@lingui/macro';
import { MiloRoundLogo } from '../../logos';

export const MiloRoundLoader = ({
  text = undefined as string | boolean,
  shadow = false as boolean,
  width = 50,
  height = 50,
  className = '',
  ...props
}) => {
  return (
    <div className={`flex w-fit flex-col items-center justify-center ${className}`} {...props}>
      <div className="flex max-w-xs flex-col items-center justify-center text-center">
        <MiloRoundLogo
          width={width}
          height={height}
          className={`mb-2 animate-beat rounded-full border-raspberry ${shadow && 'shadow-lg shadow-raspberry'}`}
        />
        {text !== false && <p className="text-lg font-bold text-charcoal">{text || t`Chargement...`}</p>}
      </div>
    </div>
  );
};
