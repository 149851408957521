import { REDEEM_CODES_TRANSACTION_STATUSES } from '@milo/constants';
import { ExpressContext } from 'apollo-server-express';
import { Request, Response } from 'express';
import Stripe from 'stripe';
import { Country, ProductId, Province, PurchaseMode, Resolver as BaseResolver, User } from './graphql_schema';
import { PartnersRow, PromoCodesRow } from './sql_schema';

export * from './graphql_schema';
export * from './sql_schema';

export type Context = {
  timezone: string;
  lang: Lang;
  currentUser: UserCurrentType | null;
  currentPartner: CurrentPartnerType | null;
  req: Request;
  res: Response;
};

export const Statuses = {
  quit: 'quit',
  qualified_prospect: 'qualified_prospect',
  prospect: 'prospect',
  online: 'online',
  declined: 'declined',
  declined_prospect: 'declined_prospect',
  in_creation: 'in_creation',
};

export type Status = keyof typeof Statuses;

export type Locale = 'fr-CA' | 'en-CA';

export type Lang = 'fr' | 'en';

export type LocalizedObject = {
  [x in Lang]: string;
};

export type BusinessContact = {
  role?: string;
  email: string;
  last_name?: string;
  cell_phone?: string;
  first_name?: string;
  office_phone?: string;
};

export type MiloGeoLocation = {
  lat?: number | null;
  lng?: number | null;
  permission?: 'granted' | 'denied' | null;
};

export type LoadingStatus = 'loading' | 'success' | 'error';

export type RatingsType = 'bad' | 'good' | 'great';

export type UserCurrentType = User;

export type CurrentPartnerType = PartnersRow & { is_admin: boolean };

export type GetPriceDetailsProps = {
  country: Country['code'];
  credit?: number;
  promo_code?: string;
  province: Province['code'];
  quantity?: number;
  unit_amount: number;
};

export type TaxDetail = {
  type: string;
  amount: number;
  percentage: number;
};

export type PriceDetails = {
  amountOff: number;
  balanceChargeAmount: number;
  baseAmount: number;
  creditCardChargeAmount: number;
  discount: number;
  percentOff: number;
  priceBeforeTax: number;
  quantity: number;
  taxDetails: TaxDetail[];
  validCode: PromoCodesRow | null;
  distributorDiscount: number;
};

export type DistributorDiscountApplicableProductIds = ProductId[] | 'all';

export type LooseObject = {
  [x: string]: any;
};

export type GenericResolver<TParent, ARGS, TResult> = BaseResolver<TResult | null | undefined, TParent, Context, ARGS>;

export type ExpressPayload = {
  req: ExpressContext['req'];
  res: ExpressContext['res'];
};

export type StripeWebhookHandler = (event: Stripe.Event, payload: ExpressPayload) => void;

export type JSONTranslationObject = {
  fr?: string;
  en?: string;
};

export type QuestionAnswer = {
  question: string;
  answer: string;
};

export type RedeemCodesTransactionStatus = (typeof REDEEM_CODES_TRANSACTION_STATUSES)[number];

export type AppVersionString = `${number}.${number}.${number}`;
export type AppBuildString = `${number}`;

// This is used when buying a product
export type PurchaseMetadata = {
  distributor_discount?: string;
  distributor_id?: string;
  milo_product_id: ProductId;
  promo_code?: string;
  promo_discount?: string;
  purchase_date?: string;
  purchase_mode: PurchaseMode;
  purchase_quantity: number;
  purchase_stripe_customer_id: Stripe.Customer['id'];
  purchase_user_email: Stripe.Customer['email'];
  [x: `tax:${string}`]: number;
};
