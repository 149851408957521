import { Trans } from '@lingui/macro';
import { convertDateToReadableFormat } from '@milo/helpers';
import { FreeVisit } from '@milo/types/index';
import { Button2, Link2, Text } from '../../components';
import { ReactFC } from '../../types';

export const AlreadyHasFreeVisit: ReactFC<{ visit: FreeVisit }> = ({ visit }) => {
  return (
    <div className="w-full max-w-lg">
      <h1 className="mb-4 text-3xl font-bold">
        <Trans>Vous avez déjà activé votre essai gratuit</Trans> 🙂
      </h1>

      <Text className="mb-6">
        <Trans>
          Vous pouvez en profiter jusqu'au <b>{convertDateToReadableFormat(visit.valid_until_date)}</b>.
        </Trans>
      </Text>

      <div className="flex flex-wrap gap-2">
        <Link2 href="/experiences">
          <Button2 size="md" variant="secondary">
            <Trans>Voir les expériences</Trans>
          </Button2>
        </Link2>
        <Link2 href="/purchase">
          <Button2 size="md">
            <Trans>Voir les produits M ta région</Trans>
          </Button2>
        </Link2>
      </div>
    </div>
  );
};

export const AlreadyUsedFreeVisit: ReactFC<{ visit: FreeVisit }> = ({ visit }) => {
  return (
    <div>
      <Text size="h1" className="mb-4 font-bold">
        <Trans>Vous avez déjà utilisé un essai gratuit</Trans> 🙂
      </Text>

      <Text className="mb-6">
        <Trans>
          Il a été effectué le <b>{convertDateToReadableFormat(visit.valid_until_date)}</b>.
        </Trans>
      </Text>

      <div className="flex flex-wrap gap-2">
        <Link2 href="/experiences">
          <Button2 variant="secondary">
            <Trans>Voir les expériences</Trans>
          </Button2>
        </Link2>
        <Link2 href="/purchase">
          <Button2>
            <Trans>Acheter ou offrir un pass M ta région</Trans>
          </Button2>
        </Link2>
      </div>
    </div>
  );
};
