import { FC, ReactNode } from 'react';

type OnboardingLayoutProps = {
  navigation?: ReactNode;
  formNavigation?: boolean;
  children: ReactNode | string;
};

export const OnboardingLayout: FC<OnboardingLayoutProps> = ({ children, navigation, formNavigation = false }) => {
  return (
    <div className="web-container mx-auto mt-6 mb-6 flex !max-w-xl flex-col">
      <div className="px-6">{children}</div>
      {navigation !== undefined && (
        <div className="my-3">
          <span className="float-right">{navigation}</span>
        </div>
      )}
    </div>
  );
};
