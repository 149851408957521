import { Trans } from '@lingui/macro';
import { FC, useEffect } from 'react';
import { InputToggleGroup } from '../Input/InputToggleGroup';
import { StepProps } from '../Stepper';
import { VisitHeader } from './VisitHeader';

export const ValidationDiscovery: FC<StepProps> = ({ changeStep, setState, state }) => {
  useEffect(() => {
    if (state.discovered_with_milo !== undefined) {
      changeStep('amount');
    }
  }, [state.discovered_with_milo]);

  return (
    <div className="flex flex-col pt-20">
      <div className="flex flex-1 flex-col items-center">
        <VisitHeader experience={state?.experience} firstScreen />
        <h1 className="mb-10 text-center text-[28px] font-bold leading-8">
          <Trans>Avez-vous découvert grâce à M ta région?</Trans>
        </h1>
        <InputToggleGroup
          spacing="flex-start"
          onChange={(e) => setState('discovered_with_milo', e)}
          items={[
            {
              value: 'yes',
              label: (
                <span className="inline-flex h-12 items-center justify-center px-4 text-[32px] font-bold leading-none">
                  <Trans>Oui</Trans>
                </span>
              ),
            },
            {
              value: 'no',
              label: (
                <span className="inline-flex h-12 items-center justify-center px-4 text-[32px] font-bold leading-none">
                  <Trans>Non</Trans>
                </span>
              ),
            },
          ]}
        />
        <p className="mt-10 text-center text-sm leading-[18px]">
          <Trans>Pas d'inquiétude! Votre réponse n'affecte pas votre accès au réduction-découverte.</Trans>
        </p>
      </div>
    </div>
  );
};
