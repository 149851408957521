export const BookIcon = (props) => (
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.666 22.167V5.833A2.333 2.333 0 0 1 6.999 3.5h15.634a.7.7 0 0 1 .7.7v15.3M7 19.834h16.333M7 24.5h16.333"
      stroke="currentColor"
      strokeWidth={1.75}
      strokeLinecap="round"
    />
    <path
      d="M7 24.5a2.333 2.333 0 0 1 0-4.666"
      stroke="currentColor"
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.5 8.166h7" stroke="currentColor" strokeWidth={1.75} strokeLinecap="round" />
  </svg>
);
