import type { SVGProps } from 'react';
import * as React from 'react';
type Props = {
  primaryColor?: string;
  secondaryColor?: string;
  tertiaryColor?: string;
} & SVGProps<SVGSVGElement>;
const SvgExternalLink = ({
  primaryColor = 'currentColor',
  secondaryColor = 'currentColor',
  tertiaryColor = 'currentColor',
  ...props
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 567 567" {...props}>
    <path
      stroke={primaryColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={66.667}
      d="M200.34 66.667h-60c-37.337 0-56.02 0-70.28 7.266a66.63 66.63 0 0 0-29.127 29.127c-7.266 14.261-7.266 32.943-7.266 70.28v253.333c0 37.337 0 55.997 7.266 70.257a66.666 66.666 0 0 0 29.127 29.143c14.247 7.26 32.906 7.26 70.17 7.26h253.54c37.263 0 55.897 0 70.143-7.26a66.756 66.756 0 0 0 29.16-29.153c7.26-14.247 7.26-32.887 7.26-70.15v-60.103M533.667 200V33.333m0 0H367m166.667 0L300.333 266.667"
    />
  </svg>
);
export default SvgExternalLink;
