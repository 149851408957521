import { colorFn, ColorType } from '../utils';

const SvgComponent = ({ fill = 'currentColor' as ColorType, ...props }) => {
  const _fill = colorFn(fill);

  return (
    <svg viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.8664 5.10245e-05C10.6223 5.10245e-05 9.57185 0.865132 9.17354 2.06213L6.27127 0.83506C6.16468 0.790585 6.05608 0.813176 5.98409 0.83506L0.239382 3.26393C0.0977954 3.3245 -0.0015846 3.48193 1.91313e-05 3.64345V16.5976C0.00188917 16.8608 0.295882 17.0695 0.526549 16.9708L6.12764 14.6052L11.7287 16.9708C11.8197 17.0097 11.9249 17.0097 12.0159 16.9708L17.7606 14.5419C17.9005 14.4822 17.9992 14.3281 18 14.1687V1.21458C18.0027 0.949284 17.706 0.735663 17.4734 0.83506L14.5772 2.06213C14.1615 0.777157 13.0167 -0.0072863 11.8664 5.10245e-05ZM11.8664 0.809614C13.0281 0.809614 13.9787 1.829 13.9787 3.11834C13.9787 3.38589 13.8947 3.79974 13.8052 4.05441C13.1652 5.49086 12.5173 6.92677 11.8724 8.36211C11.2303 6.92551 10.5767 5.49242 9.9396 4.05441C9.85024 3.79886 9.76609 3.38516 9.76609 3.11834C9.81391 1.64784 10.8598 0.82006 11.8664 0.809614ZM17.2343 1.80894V13.8902L12.2555 15.9903V9.39934C13.0023 7.711 13.7855 6.03509 14.5234 4.34563C14.6483 3.99322 14.7449 3.53928 14.7449 3.11856C14.7449 3.03201 14.7388 2.94927 14.733 2.86555L17.2343 1.80894ZM5.74484 1.81515V13.8899L0.766096 15.9962V3.91489L5.74484 1.81515ZM6.51074 1.81515L9.01201 2.87154C9.00613 2.95343 9.00012 3.03476 9.00012 3.1182C9.00012 3.53936 9.09763 3.99387 9.22159 4.34527C9.97295 6.0288 10.7334 7.71516 11.4895 9.39898V15.9899L6.51077 13.8899L6.51074 1.81515ZM11.8725 1.82136C11.2424 1.82136 10.7235 2.36989 10.7235 3.03589C10.7235 3.70188 11.2424 4.25041 11.8725 4.25041C12.5024 4.25041 13.0215 3.70188 13.0215 3.03589C13.0215 2.36989 12.5025 1.82136 11.8725 1.82136ZM11.8725 2.63092C12.0885 2.63092 12.2554 2.80741 12.2554 3.03571C12.2554 3.26401 12.0884 3.44049 11.8725 3.44049C11.6563 3.44049 11.4895 3.264 11.4895 3.03571C11.4895 2.80726 11.6565 2.63092 11.8725 2.63092Z"
        fill={_fill}
      />
    </svg>
  );
};

export { SvgComponent as MapAlt };
