import { User, Wishlist, WishlistCreateInput, WishlistUpdateExperiencesInput } from '@milo/types/index';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../components/Providers/ReactQueryProvider';
import { WishlistCreate, WishlistDelete, WishlistUpdate, WishlistUpdateExperiences } from '../graphql-queries';
import { useGraphqlClient } from './useGraphqlClient';

export const useWishlistMutations = () => {
  const { client } = useGraphqlClient();

  const updateWishlistExperiences = useMutation(
    async (input) => {
      const { toAdd = [], toRemove = [], experienceId } = input;
      const response = await client.request<string, { input: WishlistUpdateExperiencesInput }>(
        WishlistUpdateExperiences,
        { input: { toAdd, toRemove, experienceId } }
      );

      return response;
    },
    {
      onMutate: async (input: WishlistUpdateExperiencesInput) => {
        const { toRemove = [] } = input;

        await queryClient.cancelQueries(['UserCurrent']);
        const previousUser = queryClient.getQueryData(['UserCurrent']);

        queryClient.setQueryData(['UserCurrent'], (old: User) => ({
          ...old,
          wishlists: [
            ...old?.wishlists.map((w) => {
              if (toRemove.includes(w.id)) {
                return {
                  ...w,
                  experiences: w.experiences.filter((exp) => exp.id !== input.experienceId),
                };
              }

              return w;
            }),
          ],
        }));

        return { previousUser };
      },
      onSuccess: (r) => {
        queryClient.invalidateQueries(['UserCurrent']);
      },
      onError: (err, newUser, context) => {
        queryClient.setQueryData(['UserCurrent'], context.previousUser);
      },
    }
  );

  const createWishlist = useMutation(
    async ({ name, experiencesIds }) => {
      await client.request(WishlistCreate, {
        input: { name, experiencesIds },
      });
    },
    {
      onMutate: async ({ name }: WishlistCreateInput) => {
        await queryClient.cancelQueries(['UserCurrent']);
        const previousUser = queryClient.getQueryData(['UserCurrent']);

        queryClient.setQueryData(['UserCurrent'], (old: User) => ({
          ...old,
          // Using a big id before creating the wishlist to sort it at the top of the list
          wishlists: [
            {
              id: 999999999,
              name,
              experiences: [],
              date_created: new Date().toISOString().substring(0, 10),
            } as Wishlist,
            ...old?.wishlists,
          ],
        }));

        return { previousUser };
      },
      onSettled: () => queryClient.invalidateQueries(['UserCurrent']),
      onError: (err, newUser, context) => {
        queryClient.setQueryData(['UserCurrent'], context.previousUser);
      },
    }
  );

  const updateWishlist = useMutation(
    async (input: { name: string; wishlist: Wishlist }): Promise<any> => {
      await client.request(WishlistUpdate, {
        input: { name: input.name, wishlistId: input.wishlist.id },
      });
    },
    {
      onMutate: async (input: { name: string; wishlist: Wishlist }) => {
        await queryClient.cancelQueries(['UserCurrent']);
        const previousUser = queryClient.getQueryData(['UserCurrent']);

        queryClient.setQueryData(['UserCurrent'], (old: User) => {
          const newOptimisticData = {
            ...old,
            wishlists: old?.wishlists.map((w) => {
              if (w.id === input.wishlist.id) {
                return {
                  ...w,
                  name: input.name,
                };
              }
              return w;
            }),
          };

          return newOptimisticData;
        });

        return { previousUser };
      },
      onSettled: () => queryClient.invalidateQueries(['UserCurrent']),
      onError: (err, newUser, context) => {
        queryClient.setQueryData(['UserCurrent'], context.previousUser);
      },
    }
  );

  const deleteWishlist = useMutation(
    async (wishlistId: Wishlist['id']): Promise<any> => {
      await client.request(WishlistDelete, {
        input: { wishlistId },
      });
    },
    {
      onMutate: async (wishlistId: Wishlist['id']) => {
        await queryClient.cancelQueries(['UserCurrent']);
        const previousUser = queryClient.getQueryData(['UserCurrent']);

        queryClient.setQueryData(['UserCurrent'], (old: User) => ({
          ...old,
          wishlists: old?.wishlists.filter((w) => w.id !== wishlistId),
        }));

        return { previousUser };
      },
      onSettled: () => queryClient.invalidateQueries(['UserCurrent']),
      onError: (err, newUser, context) => {
        queryClient.setQueryData(['UserCurrent'], context.previousUser);
      },
    }
  );

  return { updateWishlistExperiences, createWishlist, updateWishlist, deleteWishlist };
};
