import type { SVGProps } from 'react';
import * as React from 'react';
type Props = {
  primaryColor?: string;
  secondaryColor?: string;
  tertiaryColor?: string;
} & SVGProps<SVGSVGElement>;
const SvgMoney = ({
  primaryColor = 'currentColor',
  secondaryColor = 'currentColor',
  tertiaryColor = 'currentColor',
  ...props
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 754 754" {...props}>
    <path
      stroke="currentColor"
      strokeWidth={50}
      d="M44 444c0-125.707 0-188.562 39.052-227.614 39.053-39.053 101.907-39.053 227.615-39.053H444c125.707 0 188.563 0 227.613 39.053 39.054 39.052 39.054 101.907 39.054 227.614 0 125.707 0 188.563-39.054 227.613-39.05 39.054-101.906 39.054-227.613 39.054H310.667c-125.708 0-188.562 0-227.615-39.054C44 632.563 44 569.707 44 444ZM510.667 177.333c0-62.854 0-94.28-19.527-113.807C471.613 44 440.187 44 377.333 44c-62.853 0-94.281 0-113.807 19.526C244 83.052 244 114.48 244 177.333"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={50}
      d="M377.334 555.11c36.82 0 66.666-24.873 66.666-55.553C444 468.874 414.154 444 377.334 444c-36.82 0-66.667-24.873-66.667-55.556 0-30.68 29.847-55.554 66.667-55.554m0 222.22c-36.82 0-66.667-24.873-66.667-55.553m66.667 55.553v22.224m0-244.444v-22.223m0 22.223c36.82 0 66.666 24.874 66.666 55.554"
    />
  </svg>
);
export default SvgMoney;
