import { t, Trans } from '@lingui/macro';
import { Category, Experience, Location, Region } from '@milo/types/index';
import { getDistance } from 'geolib';
import { find, sortBy, throttle } from 'lodash';
import MiniSearch from 'minisearch';
import qs from 'query-string';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { TrackedExperience, trackEvent, useBrowserObject } from '../../hooks';
import { useGeoLocation } from '../../hooks/useGeoLocation';
import { InputRegion2 } from '../Input/InputRegion2';
import { InfoBox } from '../Notifications/InfoBox';
import { Search } from '../Search/Search';
import { SearchQuery } from '../Search/SearchQuery';
import { getVerticalIdWithPrefix, VerticalsFilter, VerticalsIdsArray } from '../Search/VerticalsFilter';
import { Text } from '../Text';

const withDistanceFromUser = (exps: Experience[], location: Location) => {
  if (!location?.lat || !location?.lng) {
    return exps;
  }

  const resWithDistance: Experience[] = [];

  exps.forEach((exp: Experience) => {
    if (!exp?.location?.lat || !exp?.location?.lng) {
      return exp;
    }

    const distance = getDistance(
      { latitude: location.lat, longitude: location.lng },
      { latitude: exp.location.lat, longitude: exp.location.lng }
    );

    resWithDistance.push({
      ...exp,
      distance: Number(Math.round(distance / 1000)),
    });
  });

  return resWithDistance;
};

const withDistanceFromRegion = (exps: Experience[], region: Region) => {
  if (!region?.id) {
    return exps;
  }

  const resWithDistanceFromRegion: Experience[] = [];

  exps.forEach((exp: Experience) => {
    if (!exp?.location?.lat || !exp?.location?.lng || !region?.lat || !region?.lng) {
      return exp;
    }

    const distanceFromRegion = getDistance(
      { latitude: region.lat, longitude: region.lng },
      { latitude: exp.location.lat, longitude: exp.location.lng }
    );

    resWithDistanceFromRegion.push({
      ...exp,
      distanceFromRegion: Number(Math.round(distanceFromRegion / 1000)),
    } as Experience);
  });

  return resWithDistanceFromRegion;
};

const sortedByQueryValue = (exps: Experience[], query: string) => {
  if (!query) {
    return exps;
  }
  const fields = ['name', 'description', 'location.city'];
  let miniSearch = new MiniSearch({
    fields: fields,
    storeFields: ['id'],
    searchOptions: {
      prefix: (term) => term.length > 3,
      fuzzy: (term) => (term.length > 3 ? 0.2 : null),
      boost: {
        [fields[0]]: 9,
        [fields[1]]: 6,
      },
    },
  });

  miniSearch.addAll(exps);
  let results = miniSearch.search(query, { prefix: true });

  return results.map((r) => exps.find((e) => e.id === r.id));
};

const withAppliedVerticalsFilter = (exps: Experience[] = [], selectedVerticals: VerticalsIdsArray = []) => {
  if (selectedVerticals.length === 0) return exps;

  return exps.filter((exp) => {
    const verticalId = exp.vertical_id;
    const ephemeralVerticals = exp.verticals_ephemeral_ids ?? [];

    if (selectedVerticals.includes(verticalId)) {
      return true;
    }

    if (ephemeralVerticals.filter((id) => selectedVerticals.includes(getVerticalIdWithPrefix(id))).length > 0) {
      return true;
    }

    return false;
  });
};

export type BrowsePageProps = {
  experiences: Experience[];
  regions: Region[];
  categories: Category[];
  experiencesLoading?: boolean;
};

// Ugly to use throttle here but don't want to spend time on it since this section is going
// away with the new map
const trackViewExperiencesList = throttle((exps: Experience[] = []) => {
  const first10 = exps.slice(0, 10);
  const formattedFirst10: TrackedExperience[] = first10.map((exp, index) => {
    return {
      index,
      item_category: exp?.categories?.[0],
      item_city: exp?.location?.city ?? 'N/A',
      item_id: exp?.id,
      item_name: exp?.name,
    };
  });

  trackEvent('view_item_list', { ecommerce: { items: formattedFirst10 } });
});

export const BrowsePage: FC<BrowsePageProps> = ({ experiences, regions, experiencesLoading = false }) => {
  const browserObject = useBrowserObject();
  const { isLoading: locationLoading, ...location } = useGeoLocation();
  const [selectedVerticals, setSelectedVerticals] = useState<VerticalsIdsArray>([]);
  const qSearch = qs.parse(window?.location?.search)?.['search'];
  const qRegion = qs.parse(window?.location?.search)?.['region'];
  const [query, setQuery] = useState(qSearch as string);
  const [selectedRegion, setSelectedRegion] = useState<number>(Number(qRegion));
  const currentRegion = (find(regions, (r) => Number(r.id) === Number(selectedRegion)) || {}) as Region;
  const initRef = useRef(false);

  const filteredAndSorted = useMemo(() => {
    if (experiencesLoading) return [];

    const expsWithAppliedCategory = withAppliedVerticalsFilter(experiences, selectedVerticals);

    const expsWithDistanceFromUser = withDistanceFromUser(expsWithAppliedCategory, location);

    if (query?.length > 0) {
      return sortedByQueryValue(expsWithDistanceFromUser, query);
    }

    if (currentRegion.id) {
      const filteredByRegion = expsWithDistanceFromUser.filter((exp) => exp.region.id === currentRegion.id);
      const sortedByUserDistance = sortBy(filteredByRegion, 'distance');
      return sortedByUserDistance;
    }

    const sortedByUserDistance = sortBy(expsWithDistanceFromUser, 'distance');

    return sortedByUserDistance;
  }, [
    experiencesLoading,
    String(query),
    JSON.stringify(selectedVerticals),
    JSON.stringify(currentRegion),
    JSON.stringify(location),
  ]);

  useEffect(() => {
    trackViewExperiencesList(filteredAndSorted);
  }, [String(filteredAndSorted?.map((f) => f?.id))]);

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true;
    } else {
      if (!experiencesLoading) {
        trackEvent('search_category', {
          category_id: selectedVerticals[0],
          keyword: query,
          region_id: selectedRegion,
        });
      }
    }
  }, [String(experiencesLoading), String(query), String(selectedVerticals[0]), String(currentRegion?.id)]);

  return (
    <div className="web-container flex flex-col">
      <h1 className="mb-10 text-center text-3xl font-bold">
        <Trans>Répertoire des expériences M ta région</Trans>
      </h1>
      <div className="mb-6">
        <Text size="h5" className="mb-2 font-semibold">
          <Trans>Sélectionnez les catégories désirées:</Trans>
        </Text>
        <VerticalsFilter onChange={setSelectedVerticals} />
      </div>

      {location?.permission && location?.permission !== 'granted' && (
        <div className="mb-8">
          <InfoBox.Dismissable>
            <p className="font-bold text-raspberry">
              <Trans>
                Vous devez autoriser la géolocalisation de votre navigateur web pour voir les expériences autour de
                vous.
              </Trans>
            </p>
          </InfoBox.Dismissable>
        </div>
      )}

      <div className="mb-10 flex flex-col justify-between gap-4 md:flex-row md:items-end">
        <div className="flex flex-col">
          <Text size="h5" className="mb-2 font-semibold">
            <Trans>Obtenir les résultats pour:</Trans>
          </Text>
          <InputRegion2
            containerClassNames="md:max-w-sm"
            className="appearance-none !border-0 !bg-gray-lighter"
            value={selectedRegion}
            onChange={(e) => {
              const region = Number(e.target.value);
              setSelectedRegion(region);
              browserObject.historyPush(null, { region });
            }}
            placeholder={location?.permission === 'denied' ? t`Toutes les régions` : t`Ma position actuelle`}
          />
        </div>
        <SearchQuery value={query} onChange={setQuery} />
      </div>
      <Search experiences={filteredAndSorted} />
    </div>
  );
};
