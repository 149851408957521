import cn from 'classnames';
import { ReactFC } from '../../types';

const getNodeToRender = (size: Sizes) => {
  switch (size) {
    case '3xl':
    case '2xl':
    case 'xl':
    case 'h1':
      return (props) => <h1 {...props} />;
    case 'h2':
      return (props) => <h2 {...props} />;
    case 'h3':
      return (props) => <h3 {...props} />;
    case 'h4':
      return (props) => <h4 {...props} />;
    case 'h5':
      return (props) => <h5 {...props} />;
    case 'h6':
      return (props) => <h6 {...props} />;
    default:
      return (props) => <p {...props} />;
  }
};

export type TextProps = {
  node?: Sizes;
  size?: Sizes;
  bold?: boolean;
  semibold?: boolean;
  muted?: boolean;
  underline?: boolean;
  lineHeight?: LineHeights;
};

type LineHeights = 'normal' | 'spaced' | 'double';

const lineHeights: { [x in LineHeights]: number } = {
  normal: 1.3,
  spaced: 1.5,
  double: 2,
};

const sizeMatrix = {
  small: 'text-sm',
  p: 'text-base',
  h6: 'text-lg',
  h5: 'text-lg',
  h4: 'text-xl',
  h3: 'text-2xl',
  h2: 'text-3xl',
  h1: 'text-4xl',
  xl: 'text-5xl',
  '2xl': 'text-6xl',
  '3xl': 'text-7xl',
} as const;

type Sizes = keyof typeof sizeMatrix;

export const Text: ReactFC<TextProps> = ({
  muted,
  bold,
  underline,
  semibold,
  className,
  children,
  size = 'p',
  node = 'p',
  lineHeight = 'normal',
  style,
  ...props
}) => {
  const Node = getNodeToRender(node);
  const fontSizeClass = sizeMatrix[size];
  const classes = cn(
    fontSizeClass,
    {
      'text-medium-gray': muted,
      'font-bold': bold,
      'font-semibold': semibold,
      underline,
    },
    className
  );

  return (
    <Node className={classes} style={{ lineHeight: lineHeights[lineHeight], ...style }} {...props}>
      {children}
    </Node>
  );
};
