import { Trans } from '@lingui/macro';
import { convertDateToReadableFormat } from '@milo/helpers';
import { queryClient, useGraphqlClient, UserCurrent } from '@milo/react';
import { Visit } from '@milo/types/index';
import { FC, useEffect, useState } from 'react';
import { ErrorIcon } from '../../icons';
import { Button2 } from '../Button';
import { StepProps } from '../Stepper';

export const ValidationError: FC<StepProps> = ({ state, changeStep }) => {
  const reason = state.errorReason;
  const experience = state.experience;
  const { client } = useGraphqlClient();
  const [visit, setVisit] = useState<Visit>(null);

  useEffect(() => {
    const getVisitObject = async () => {
      await queryClient.cancelQueries(['UserCurrent']);
      const currentUserUpdated = (await client.request(UserCurrent))?.user;
      const visit = currentUserUpdated?.visits_this_year?.filter((v) => v.experience.id === experience.id)?.[0];
      setVisit(visit);
    };

    getVisitObject();
  }, []);

  const minimumTicketsNeeded = 2;
  const freeTicketsOffer = 1;
  const maximumTickets = 5;

  const ComponentToDisplay = () => {
    switch (reason) {
      case 'minimum_not_reached':
        return (
          <>
            <h1 className="mt-3 text-center text-[28px] font-bold leading-9 text-raspberry">
              <Trans>Pas de privilège disponible</Trans>
            </h1>
            <p className="mt-1 text-center leading-[22px]">
              <Trans>
                Vous n'atteignez pas le nombre minimum de billets achetés. Vous devez acheter au moins{' '}
                {minimumTicketsNeeded} billets pour en avoir {freeTicketsOffer} offert, jusqu'à concurrence de{' '}
                {maximumTickets} billets offerts.
              </Trans>
            </p>
            <Button2 variant="primary" className="mt-10" onClick={() => changeStep('amount')}>
              <Trans>Modifier le nombre de billets</Trans>
            </Button2>
          </>
        );
      case 'partner_already_confirmed':
        return (
          <>
            <h1 className="mt-3 flex flex-row text-center text-[28px]  font-bold leading-9">
              <Trans>Visite du {visit ? convertDateToReadableFormat(visit?.date_created) : '...'}</Trans>
            </h1>
            <p className="mt-1 text-center leading-[22px]">
              <Trans>
                Le partenaire <strong>{experience.name}</strong> a déjà confirmé votre visite. Vous avez bel et bien
                obtenu votre réduction. Vous pouvez réclamer votre réduction sans problème.
              </Trans>
            </p>
          </>
        );
      default:
        return (
          <>
            <h1 className="mt-3 text-center text-[28px] font-bold leading-9 text-raspberry">
              <Trans>Petite panne</Trans>
            </h1>
            <p className="mt-1 text-center leading-[22px]">
              <Trans>Quelque chose n'a pas fonctionné.</Trans>
            </p>
            <Button2 variant="primary" className="mt-10" onClick={() => changeStep('savings')}>
              <Trans>Essayer de nouveau</Trans>
            </Button2>
          </>
        );
    }
  };

  return (
    <div className="flex flex-col bg-white px-8 pt-20">
      <div className="flex flex-1 flex-col items-center pb-10">
        <ErrorIcon className="h-16 w-16" />
        <ComponentToDisplay />
      </div>
    </div>
  );
};
