import classNames from 'classnames';
import { FC } from 'react';

export const Skeleton: FC<{ times?: number; className: string }> = ({ times = 1, className }) => (
  <>
    {[...Array.from(Array(times))].map((_, index) => (
      <div
        key={`skeleton-${index}`}
        style={{ backgroundColor: '#A0A1AB' }}
        className={classNames('animate-pulse', className)}
      />
    ))}
  </>
);
