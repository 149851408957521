export const palette = {
  'dark-blue': '#2F4858',
  'deep-blue': '#345078',
  'focus-blue': '#6CBAFB',
  'gray-light': '#d8d8d8',
  'gray-lighter': '#f4f4f4',
  'light-gray': '#d8d8d8',
  'medium-dark': '#646464',
  'medium-gray': '#969696',
  'raspberry-red': '#E62152',
  black: '#000000',
  charcoal: '#2D2C27',
  dark: '#323232',
  error: '#E33E38',
  facebook: '#3B5998',
  fuscia: '#C52F7C',
  green: '#599E41',
  info: '#F6DC41',
  linkedin: '#0077B5',
  medium: '#969696',
  provence: '#FDF7F4',
  purple: '#6E36E3',
  red: '#E62152',
  success: '#1fc31c',
  teal: '#27BAB1',
  transparent: '#00000000',
  twitter: '#1EA1F2',
  violet: '#914592',
  warning: '#EB6215',
  white: '#ffffff',
} as const;

// Taken here: https://github.com/modulz/stitches/issues/839#issue-1021977835
type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HSL = `hsl(${number}, ${number}%, ${number}%)`;
type HSLA = `hsla(${number}, ${number}%, ${number}%, ${number})`;
type HEX = `#${string}`;

export type MiloColors = keyof typeof palette;
export type ColorType = RGB | RGBA | HSL | HSLA | HEX | MiloColors | 'currentColor' | 'transparent';
