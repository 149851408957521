import { ReactFC } from 'src/types';
import { MiloSVGProps } from '..';
import { colorFn, ColorType } from '../../utils';

const SvgComponent: ReactFC<MiloSVGProps> = ({ stroke = 'currentColor' as ColorType, ...props }) => {
  const _stroke = colorFn(stroke);

  return (
    <svg viewBox="-1 -0.5 20 20" fill="none" {...props}>
      <path d="M6 16.25H12" stroke={_stroke} strokeWidth="1.27939" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 11.75V16.25" stroke={_stroke} strokeWidth="1.27939" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12 3.50002L12.75 8.00002C12.75 8.99458 12.3549 9.9484 11.6517 10.6517C10.9484 11.3549 9.99456 11.75 9 11.75C8.00544 11.75 7.05161 11.3549 6.34835 10.6517C5.64509 9.9484 5.25 8.99458 5.25 8.00002L6 3.50002H12Z"
        stroke={_stroke}
        strokeWidth="1.27939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { SvgComponent as PlaisirsGourmands };
