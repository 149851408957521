import { cloneElement, ReactNode } from 'react';
import { Arrow, useHover, useLayer } from 'react-laag';
import { PlacementType } from 'react-laag/dist/PlacementType';
import { ReactFC } from '../../types';

export type TooltipProps = {
  placement?: PlacementType;
  text: ReactNode;
  triggerOffset?: number;
};

export const Tooltip: ReactFC<TooltipProps> = ({ placement = 'top-center', children, triggerOffset = 36, text }) => {
  // We use `useHover()` to determine whether we should show the tooltip.
  // Notice how we're configuring a small delay on enter / leave.
  const [isOver, hoverProps] = useHover();

  // Tell `useLayer()` how we would like to position our tooltip
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    placement,
    arrowOffset: 16,
    triggerOffset, // small gap between wrapped content and the tooltip
  });

  if (!text) {
    return children;
  }

  // when children equals text (string | number), we need to wrap it in an
  // extra span-element in order to attach props
  let trigger;
  if (isReactText(children)) {
    trigger = (
      <span className="tooltip-text-wrapper" {...triggerProps} {...hoverProps}>
        {children}
      </span>
    );
  } else {
    // In case of an react-element, we need to clone it in order to attach our own props
    trigger = cloneElement(children, {
      ...triggerProps,
      ...hoverProps,
    });
  }

  // We're using framer-motion for our enter / exit animations.
  // This is why we need to wrap our actual tooltip inside `<AnimatePresence />`.
  // The only thing left is to describe which styles we would like to animate.
  return (
    <>
      {trigger}
      {renderLayer(
        <>
          {isOver && (
            <div className="rounded-lg bg-charcoal p-4 font-medium text-white" {...layerProps}>
              {text}
              <Arrow {...arrowProps} backgroundColor="#2D2C27" borderColor="#2D2C27" borderWidth={1} size={8} />
            </div>
          )}
        </>
      )}
    </>
  );
};

function isReactText(children) {
  return ['string', 'number'].includes(typeof children);
}
