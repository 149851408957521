import { colorFn, ColorType } from '../utils';

function SvgComponent({ fill = 'currentColor' as ColorType, ...props }) {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M2 8l4.07 4.477a1 1 0 001.61-.18L13.5 2" stroke={_fill} strokeWidth={2.5} strokeLinecap="round" />
    </svg>
  );
}

export { SvgComponent as Visited };
