import { t, Trans } from '@lingui/macro';
import { convertDateToReadableFormat } from '@milo/helpers';
import {
  Button2,
  FlowContainer,
  Link2,
  MiloFull,
  PurchaseDone,
  SubscriptionAlreadyActive,
  useAuthentication,
  useSavedRedirectUrl,
} from '@milo/react';
import { AlreadyUsedFreeVisit } from '.';

export const FreeVisitSuccessPage = () => {
  const { user, isLoading, subscribed, loggedIn } = useAuthentication();
  const { savedRedirectUrl } = useSavedRedirectUrl();

  if (isLoading) return null;

  if (subscribed) {
    return <SubscriptionAlreadyActive title={t`Pas besoin d'obtenir une visite gratuite 🙂`} />;
  }

  if (!loggedIn) {
    location.replace('/free-visit/login');
    return null;
  }

  const pendingFreeVisit = user?.pending_free_visit;
  if (!pendingFreeVisit?.id) {
    return (
      <FlowContainer>
        <div className="text-center text-3xl font-semibold">
          <Trans>Oups, il n'y a rien ici</Trans>
          <br />
          <br />
          👀
        </div>
      </FlowContainer>
    );
  }

  const usedFreeVisit = user?.last_used_free_visit;
  if (usedFreeVisit?.id) {
    return (
      <FlowContainer>
        <AlreadyUsedFreeVisit visit={usedFreeVisit} />
      </FlowContainer>
    );
  }

  return (
    <FlowContainer>
      <MiloFull className="mb-2 w-40" />
      <h1 className="mb-3 text-4xl font-bold">
        <Trans>Et voilà c'est fait!</Trans> 👏
      </h1>
      <h2 className="mb-6 text-lg leading-tight">
        <Trans>
          Votre réduction-découverte est valide jusqu'au{' '}
          {convertDateToReadableFormat(pendingFreeVisit?.valid_until_date)}. Vous pouvez l'utiliser dès maintenant dans
          n'importe quelle de nos expériences M ta région.
        </Trans>
      </h2>

      {savedRedirectUrl && (
        <Link2 className="mb-6 block" href={savedRedirectUrl}>
          <Button2 variant="tertiary" size="sm">
            <Trans>Aller à la dernière expérience visitée</Trans>
          </Button2>
        </Link2>
      )}

      <PurchaseDone showHeaderText={false} showSubHeaderText={false} />
    </FlowContainer>
  );
};
