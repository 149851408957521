import { Trans } from '@lingui/macro';
import { Experience, SavingsTypes } from '@milo/types/index';
import { FC, FormEvent, useState } from 'react';
import { Button2 } from '../../components/Button';
import { InfoBox } from '../../components/Notifications';
import { LightBulbIcon } from '../../icons';
import { InputInvoiceAmount2 } from '../Input/InputInvoiceAmount2';
import { InputText2 } from '../Input/InputText2';
import { StepProps } from '../Stepper';
import { VisitHeader } from './VisitHeader';

export const ValidationAmount: FC<StepProps> = ({ state, setState, changeStep = () => {} }) => {
  const [amount, setAmount] = useState(0);
  const experience: Experience = state!.experience;
  const savingsType = experience.savings_type;
  const disabled = amount < 1;

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    savingsType === SavingsTypes['TwoForOne'] ? setState('twoForOnePurchased', amount) : setState('amount', amount);
    changeStep('savings');
  };

  return (
    <form onSubmit={onSubmit} className="flex flex-col pt-20">
      <div className="flex-1">
        <VisitHeader experience={state?.experience} />
        {savingsType === SavingsTypes['TwoForOne'] ? (
          <TypeSavingsQuantity onChange={setAmount} />
        ) : (
          <TypeSavingsAmount onChange={setAmount} />
        )}
      </div>
      <div className="flex justify-center">
        <Button2 type="submit" block="sm" onClick={onSubmit} disabled={disabled}>
          <Trans>Calcul de votre réduction-découverte</Trans>
        </Button2>
      </div>
    </form>
  );
};

type SavingsTypeProps = {
  onChange?: Function;
};

const TypeSavingsAmount: FC<SavingsTypeProps> = ({ value, onChange = () => {} }) => {
  return (
    <>
      <h1 className="mb-6 text-center text-[28px] font-bold leading-8">
        <Trans>Montant total de la facture, hors taxes</Trans>
      </h1>
      <InputInvoiceAmount2 onChange={onChange} />
      <br />
      <InfoBox.Default id="infobox.experience_validation" className="!mb-6" small icon={<LightBulbIcon />}>
        <p className="leading-5">
          <Trans>Annoncez que vous êtes membre et demandez votre montant de facture sans taxe.</Trans>
        </p>
      </InfoBox.Default>
    </>
  );
};

const TypeSavingsQuantity: FC<SavingsTypeProps> = ({ value, onChange = () => {} }) => {
  return (
    <>
      <h1 className="mb-6 text-center text-[28px] font-bold leading-8">
        <Trans>Nombre de billets achetés</Trans>
      </h1>
      <InputText2
        placeholder="1"
        maxLength={2}
        autoFocus
        onChange={(e) => onChange(e.target.value)}
        value={value}
        className="!mx-auto block w-28 border-none text-center text-3xl font-bold text-dark"
      />
      <InfoBox.Default id="infobox.experience_validation" className="!mb-6" small icon={<LightBulbIcon />}>
        <p className="leading-5">
          <Trans>Annoncez que vous êtes membre et renseignez le nombre de billets payés.</Trans>
        </p>
      </InfoBox.Default>
    </>
  );
};
