import { FC } from 'react';
import { Text } from '../../components/Text';
import { useRegions } from '../../hooks/repositories/useRegions';
import { InputSelect2 } from '../Input/InputSelect2';

export type RegionSelectionProps = {
  value?: number;
  onChange?: (region: number) => void;
};

type RegionShape = {
  key: number;
  label: string;
};

export const RegionSelector: FC<RegionSelectionProps> = ({ value = null, onChange = () => {} }) => {
  const { data: regions, status: regionsStatus } = useRegions();

  if (regionsStatus === 'loading') {
    return <Text>Loading...</Text>;
  }

  const formattedRegions: RegionShape[] = regions?.map((r) => ({ key: r.id, label: r.name })) || [];

  return <InputSelect2 style={{ width: 175 }} items={formattedRegions} noBorder={true} />;
};
