export const ExpandIcon = (props) => (
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22.167 24.5H5.833A2.333 2.333 0 0 1 3.5 22.167V5.833A2.333 2.333 0 0 1 5.833 3.5h16.334A2.333 2.333 0 0 1 24.5 5.833v16.334a2.333 2.333 0 0 1-2.333 2.333ZM8.75 24.5v-21"
      stroke="currentColor"
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 11.666 17.792 14l-2.042 2.333"
      stroke="currentColor"
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
