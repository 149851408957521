import { ReactFC } from 'src/types';
import { MiloSVGProps } from '..';
import { colorFn, ColorType } from '../../utils';

const SvgComponent: ReactFC<MiloSVGProps> = ({ stroke = 'currentColor' as ColorType, ...props }) => {
  const _stroke = colorFn(stroke);

  return (
    <svg viewBox="-2 -2 20 20" fill="none" {...props}>
      <path
        d="M8.66667 3.83333C9.03486 3.83333 9.33333 3.53486 9.33333 3.16667C9.33333 2.79848 9.03486 2.5 8.66667 2.5C8.29848 2.5 8 2.79848 8 3.16667C8 3.53486 8.29848 3.83333 8.66667 3.83333Z"
        stroke={_stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66797 11.8333L6.0013 12.5L6.5013 11.5"
        stroke={_stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.9987 14.5V11.8333L7.33203 9.83334L7.9987 5.83334"
        stroke={_stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66797 8.5V6.5L8.0013 5.83334L10.0013 7.83334L12.0013 8.5"
        stroke={_stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { SvgComponent as Sevader };
