import { MiloSVGProps } from '.';
import { ReactFC } from '../types';
import { colorFn, ColorType } from '../utils';

const SvgComponent: ReactFC<MiloSVGProps> = ({ fill = 'currentColor' as ColorType, ...props }) => {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="0 0 30 30" {...props}>
      <path
        d="M22.2983 18.3106C25.1284 13.6764 24.5538 7.5321 20.5501 3.5215C15.8557 -1.17383 8.21516 -1.17383 3.52078 3.5215C-1.17359 8.21683 -1.17359 15.859 3.52078 20.5482C7.52445 24.5527 13.6675 25.1274 18.3068 22.2967L25.1834 29.1746C26.2836 30.2751 28.0746 30.2751 29.1748 29.1746C30.2751 28.0742 30.2751 26.2829 29.1748 25.1824L22.2983 18.3106ZM18.0623 18.066C14.7372 21.3919 9.33374 21.3919 6.01467 18.066C2.68949 14.7402 2.68949 9.33564 6.01467 6.0159C9.33985 2.69003 14.7433 2.69003 18.0623 6.0159C21.3814 9.33564 21.3814 14.7402 18.0623 18.066Z"
        fill={_fill}
      />
      <path d="M16 11H8V13.5H16V11Z" fill={_fill} stroke={_fill} />
    </svg>
  );
};
export { SvgComponent as ZoomOut };
