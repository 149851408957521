import { colorFn, ColorType } from '../utils';

const SvgComponent = ({
  fill = 'none',
  stroke = 'currentColor' as ColorType,
  enveloppeFoldColor = 'white' as ColorType,
  ...props
}) => {
  const _stroke = colorFn(stroke);
  const _enveloppeFoldColor = colorFn('currentColor');

  return (
    <svg viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M14 2.5H2c-.552 0-1 .492-1 1.1v8.8c0 .607.448 1.1 1 1.1h12c.552 0 1-.492 1-1.1V3.6c0-.608-.448-1.1-1-1.1Z"
        stroke={_stroke}
        strokeWidth={1.6}
      />
      <path d="m1 3 6.25 6.237a1.209 1.209 0 0 0 1.5 0L15 3.5" stroke={_enveloppeFoldColor} strokeWidth={1.6} />
    </svg>
  );
};

export { SvgComponent as Enveloppe };
