import { Distributor } from '@milo/types/index';
import { getGraphqlClient } from '../useGraphqlClient';
import { useQuery } from '../useQuery';

const DistributorFragment = /* GraphQL */ `
  id
  description
  extra_description
  header_text
  image_url
  logo_url
  name
  offer_description
  slug
  website_url
`;

export const getDistributorById: (id: string) => Promise<Distributor> = async (id) => {
  const client = getGraphqlClient();
  const data = await client.request(
    /* GraphQL */ `
      query DistributorById($id: ID!) {
        distributor: DistributorById(id: $id) {
          ${DistributorFragment}
        }
      }
    `,
    { id }
  );
  const dist = data?.distributor as Distributor;
  return dist;
};

export const getDistributorBySlug: (slug: string) => Promise<Distributor> = async (slug) => {
  const client = getGraphqlClient();
  const data = await client.request(
    /* GraphQL */ `
      query DistributorBySlug($slug: String!) {
        distributor: DistributorBySlug(slug: $slug) {
          ${DistributorFragment}
        }
      }
    `,
    { slug }
  );
  const dist = data?.distributor as Distributor;
  return dist;
};

export const getDistributors: () => Promise<Distributor[]> = async () => {
  const client = getGraphqlClient();
  const data = await client.request(/* GraphQL */ `
    query DistributorsList {
      distributors: DistributorsList {
        ${DistributorFragment}
      }
    }
  `);

  const distributors = data?.distributors as Distributor[];
  return distributors;
};

export const useDistributors = () => {
  const response = useQuery<Distributor[]>(['DistributorsList'], async () => await getDistributors(), {
    initialData: [],
  });
  return response;
};
