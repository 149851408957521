import { colorFn, ColorType } from '../utils';

function SvgComponent({ fill = 'currentColor' as ColorType, ...props }) {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8 12a8.8 8.8 0 11-17.6 0 8.8 8.8 0 0117.6 0zm2.2 0c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11zM10.594 7.497a1.41 1.41 0 112.812 0l-.344 5.505a1.064 1.064 0 01-2.124 0l-.344-5.505zM13.2 16.75a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
        fill={_fill}
      />
    </svg>
  );
}

export { SvgComponent as InfoCircle };
