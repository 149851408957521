const SvgComponent = (props) => {
  const fill = props.fill || '#2D2C27';
  return (
    <svg fill="none" viewBox="0 0 24 24" {...props}>
      <rect x={0.75} y={20.985} width={9.127} height={2.265} rx={1.132} fill={fill} stroke={fill} strokeWidth={0.5} />
      <rect x={14.123} y={20.985} width={9.127} height={2.265} rx={1.132} fill={fill} stroke={fill} strokeWidth={0.5} />
      <rect x={14.123} y={0.75} width={9.127} height={2.265} rx={1.132} fill={fill} stroke={fill} strokeWidth={0.5} />
      <rect
        x={21.024}
        y={10.073}
        width={9.324}
        height={2.225}
        rx={1.113}
        transform="rotate(-90 21.024 10.073)"
        fill={fill}
        stroke={fill}
        strokeWidth={0.5}
      />
      <rect
        x={0.75}
        y={10.073}
        width={9.324}
        height={2.225}
        rx={1.113}
        transform="rotate(-90 .75 10.073)"
        fill={fill}
        stroke={fill}
        strokeWidth={0.5}
      />
      <rect
        x={0.75}
        y={23.25}
        width={9.324}
        height={2.225}
        rx={1.113}
        transform="rotate(-90 .75 23.25)"
        fill={fill}
        stroke={fill}
        strokeWidth={0.5}
      />
      <rect
        x={21.024}
        y={23.25}
        width={9.324}
        height={2.225}
        rx={1.113}
        transform="rotate(-90 21.024 23.25)"
        fill={fill}
        stroke={fill}
        strokeWidth={0.5}
      />
      <rect x={0.75} y={0.75} width={9.127} height={2.265} rx={1.132} fill={fill} stroke={fill} strokeWidth={0.5} />
    </svg>
  );
};

export { SvgComponent as Fullscreen };
