import { Country, Province } from '@milo/types/index';
import { UseBaseQueryResult, UseQueryResult } from '@tanstack/react-query';
import { ProvincesList } from '../../graphql-queries';
import { HybridGraphqlClient, useGraphqlClient } from '../useGraphqlClient';
import { useQuery } from '../useQuery';

export const getProvinces: (
  graphqlClient: HybridGraphqlClient,
  countryCode: Country['code']
) => Promise<Province[]> = async (graphqlClient, countryCode) => {
  const data = await graphqlClient.request(ProvincesList, { countryCode });
  return data?.ProvincesList;
};

type useProvincesArgs = Country['code'];

export const useProvinces: (countryCode?: useProvincesArgs) => [Province[], UseBaseQueryResult['status']] = (
  countryCode
) => {
  const { client } = useGraphqlClient();

  const { status, data }: UseQueryResult<Province[]> = useQuery(
    ['ProvincesList', countryCode],
    async () => await getProvinces(client, countryCode),
    { enabled: countryCode !== undefined }
  );

  return [data, status];
};
