import { ReactNode } from 'react';
import { Spinner } from '../Loaders/Spinner';
import { Button2 } from './Button2';

export type IconButtonProps = {
  Icon: ReactNode;
  isLoading?: Boolean;
  children?: ReactNode | string;
};

export const IconButton = ({ Icon, loading = false, ...props }) => {
  return <Button2 {...props}>{(loading && <Spinner />) || Icon}</Button2>;
};
