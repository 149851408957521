import { Trans } from '@lingui/macro';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useAuthentication, useGraphqlClient } from '../../hooks';
import { TrashIcon } from '../../icons';
import { Button2 } from '../Button';
import { Spinner } from '../Loaders';
import { Modal, ModalBody, ModalTitle } from '../Modal';
import { Text } from '../Text';

export const DeleteAccount = () => {
  const { loggedIn } = useAuthentication();
  const { client } = useGraphqlClient();
  const [open, setOpen] = useState(false);

  const {
    mutate: deleteAccount,
    isLoading,
    isError,
  } = useMutation(
    ['DeleteAccount'],
    async () => {
      await client.request(/* GraphQL */ `
        mutation UserDelete {
          UserDelete {
            success
          }
        }
      `);
    },
    {
      onSuccess: () => {
        location.href = '/logout';
      },
    }
  );

  if (!loggedIn) return null;

  return (
    <>
      {open && (
        <Modal open onRequestClose={() => setOpen(false)}>
          <ModalBody>
            <ModalTitle>Supression du compte M ta région</ModalTitle>
            <Text className="mb-4">
              <Trans>Êtes-vous certain de vouloir supprimer votre compte?</Trans>
            </Text>

            <Text className="mb-5 font-bold text-error">
              <Trans>Attention: Cette action est irréversible.</Trans>
            </Text>

            {isError && (
              <Text className="mb-8 font-bold text-error">
                <Trans>Une erreur est survenue, contactez nous pour de l'aide.</Trans>
              </Text>
            )}

            <div className="mx-auto flex items-center justify-center gap-2">
              {isLoading ? (
                <Spinner color="charcoal" className="h-10 w-10" />
              ) : (
                <>
                  <Button2 size="md" variant="secondary" onClick={() => setOpen(false)}>
                    <Trans>Annuler</Trans>
                  </Button2>
                  <Button2 size="md" onClick={() => deleteAccount()}>
                    <Trans>Confirmer</Trans>
                  </Button2>
                </>
              )}
            </div>
          </ModalBody>
        </Modal>
      )}
      <div className="flex justify-center">
        <Button2
          variant="tertiary"
          className="!p-0 !text-error"
          onClick={() => setOpen(true)}
          StartIcon={() => <TrashIcon className="h-4 w-4" />}
        >
          <Trans>Supprimer le compte</Trans>
        </Button2>
      </div>
    </>
  );
};
