import { Guide } from '@milo/types';
import { GuideGet, GuidesList } from '../../graphql-queries';
import { getGraphqlClient } from '../useGraphqlClient';
import { useQuery } from '../useQuery';

export const getGuideById = async (id: number) => {
  const graphqlClient = getGraphqlClient();
  const data = await graphqlClient.request(GuideGet, { id }).catch(() => ({ guide: null }));
  return data.guide as Guide;
};

export const getGuides = async () => {
  const graphqlClient = getGraphqlClient();
  const data = await graphqlClient.request(GuidesList);
  return data?.GuidesList as Guide[];
};

export const useGuides = () => {
  const response = useQuery<Guide[]>(['GuidesList'], async () => await getGuides(), {
    staleTime: Infinity,
  });

  return response;
};
