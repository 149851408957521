'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var luxon = require('luxon');

const convertDateToReadableFormat = (isoDateString, options = {}) => {
  const { locale = "fr", format = "date" } = options;
  const fmt = format === "datetime" ? luxon.DateTime.DATETIME_MED : luxon.DateTime.DATE_FULL;
  return luxon.DateTime.fromISO(isoDateString).setLocale(locale).toLocaleString(fmt);
};
const getIanaTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

const removeAccents = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
const slugify = (str) => removeAccents(str).toLowerCase().trim().replace(/[^a-z0-9 ]/g, "").replace(/\s{2,}/g, " ").replace(/\s/g, "-");

exports.convertDateToReadableFormat = convertDateToReadableFormat;
exports.getIanaTimezone = getIanaTimezone;
exports.removeAccents = removeAccents;
exports.slugify = slugify;
