import { Geolocation } from '@capacitor/geolocation';
import { MiloGeoLocation } from '@milo/types';
import { useQuery } from '@tanstack/react-query';

const getCurrentLocation: () => Promise<MiloGeoLocation> = async () => {
  const location = (await Geolocation.getCurrentPosition({ enableHighAccuracy: true, timeout: 5000 })
    .then(({ coords }) => {
      return {
        lat: coords.latitude,
        lng: coords.longitude,
        permission: 'granted',
      };
    })
    .catch((e) => {
      console.log(e);
      return { lat: null, lng: null, permission: 'denied' };
    })) as MiloGeoLocation;

  return location;
};

export const useGeoLocation: () => MiloGeoLocation & { isLoading: boolean } = () => {
  const { data: location, isLoading } = useQuery(
    ['getLocation'],
    async () => {
      const location = await getCurrentLocation();
      return location;
    },
    { refetchOnWindowFocus: false, refetchOnMount: false, refetchOnReconnect: false, staleTime: Infinity }
  );

  return { ...location, isLoading };
};
