import { ColorType } from 'src/utils/palette';
import { colorFn } from '../utils';

const SvgComponent = ({ fill = 'currentColor' as ColorType, strokeWidth = 2, ...props }) => {
  const _fill = colorFn(fill);

  return (
    <svg viewBox="0 0 17 17" fill="none" {...props}>
      <path
        fill={_fill}
        d="M13.8912 15.8133C13.3242 16.1712 12.5826 16.5113 11.732 16.7619C11.5575 16.8155 11.383 16.6723 11.4267 16.5291C11.5575 16.0817 11.6448 15.6164 11.6448 15.1331C11.6448 12.6453 9.50747 10.5333 8.76581 9.85316C8.61306 9.72781 8.3733 9.72781 8.24233 9.85316C7.50082 10.5154 5.36331 12.6274 5.36331 15.1331C5.36331 15.6879 5.47239 16.2427 5.64688 16.7439C5.69055 16.8871 5.5378 17.0303 5.36331 16.9944C3.90203 16.6723 2.7242 16.1532 2.28804 15.8668C-1.26706 13.3968 -0.0238031 9.51299 1.63369 7.4726C3.46602 5.16394 5.64715 3.19496 5.5381 0.43897C5.51634 0.116868 5.95253 -0.0980052 6.30153 0.0452116C9.07146 1.19069 10.9689 3.67853 11.4705 5.66522C12.212 5.09241 12.5174 4.19751 12.5393 3.41009C12.5393 3.03416 13.1282 2.83728 13.4553 3.12369C15.9417 5.32512 19.7803 12.0369 13.8915 15.8134L13.8912 15.8133Z"
      />
    </svg>
  );
};

export { SvgComponent as Fire };
