import { t, Trans } from '@lingui/macro';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { Loading } from '../../../components/Route/Base';
import { Text } from '../../../components/Text';
import { UserUpdate } from '../../../graphql-queries/UserUpdate';
import { useRegions } from '../../../hooks/repositories/useRegions';
import { useAuthentication } from '../../../hooks/useAuthentication';
import { useGraphqlClient } from '../../../hooks/useGraphqlClient';
import { ReactFC } from '../../../types';
import { Button2 } from '../../Button';
import { Divider } from '../../Divider';
import { Field2, InputSelect2, InputText2 } from '../../Input';
import { Link2 } from '../../Link';

type UpdateProfileMutation = {
  first_name: string;
  region_id: number;
};

export const OnboardingIntroduction: ReactFC = ({}) => {
  const { user, isLoading: userLoading, loggedIn } = useAuthentication();
  const { client } = useGraphqlClient();
  const { data: regions } = useRegions();
  const [firstName, setFirstName] = useState('');
  const [regionId, setRegionId] = useState<number | null>();

  const disabled = !firstName || !regionId;

  const { mutate: updateProfile, isLoading } = useMutation<any, any, UpdateProfileMutation>(
    'UpdateProfile',
    async () => {
      return await client.request(UserUpdate, {
        input: {
          first_name: firstName,
          region_id: regionId,
          onboarding_type: 'new',
          has_passed_onboarding: true,
        },
      });
    },
    {
      onSuccess: () => {
        location.href = '/onboarding/new/success';
      },
    }
  );

  if (userLoading) {
    return <Loading />;
  }

  if (!loggedIn) {
    location.href = '/login';
    return <Loading />;
  }

  // These are the only 2 conditions for passed onboarding for new users
  if (user.first_name && user.region_id) {
    location.href = '/experiences';
    return <Loading />;
  }

  return (
    <div className="mx-auto mb-10 w-11/12 max-w-xl">
      <Text size="h2" className="mb-4 text-2xl font-bold">
        <Trans>À 2 clics de rejoindre la communauté M ta région</Trans>
      </Text>
      <h2 className="mb-4 text-medium-dark">
        <Trans>Pour finaliser la création de votre compte, renseignez ces quelques éléments.</Trans>
      </h2>

      <Field2 label={t`Prénom`}>
        <InputText2 autoFocus={true} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
      </Field2>

      <Field2 label={t`Région de domicile`}>
        <InputSelect2
          placeholder={t`Choisir région`}
          value={regionId}
          items={regions.map((region) => ({ key: region.id, label: region.name, value: region.id }))}
          onValueChange={(e) => setRegionId(Number(e))}
        />
      </Field2>

      <Divider.thick />

      <p className="mb-6 text-sm text-medium-gray">
        <Trans>
          En poursuivant, vous confirmez avoir lu, compris et accepté les{' '}
          <Link2 className="font-semibold underline underline-offset-1" href="/terms">
            conditions d'utilisation
          </Link2>{' '}
          relatives à la creation d'un compte. J'accepte de recevoir les communications de M ta région avec la
          possibilité de se desinscrire en tout temps.
        </Trans>
      </p>

      <Button2
        className="mx-auto w-full max-w-xl"
        block
        variant="primary"
        onClick={updateProfile}
        loading={isLoading}
        disabled={disabled}
      >
        <Trans>Sauvegarder les informations</Trans>
      </Button2>
    </div>
  );
};
