import { Trans } from '@lingui/macro';
import { Button, FlowContainer, Link2, Loading, MiloRoundLogo, useAuthentication } from '@milo/react';

export const MiloFreeLanding = () => {
  const { isLoading } = useAuthentication();

  const Content = () => {
    if (isLoading) {
      return <Loading />;
    }

    return (
      <div className="flex flex-col gap-4">
        <div className="mb-2">
          <p className="mb-2 flex items-center gap-4 text-4xl font-semibold">
            <Trans>M ta région...</Trans>
          </p>
          <h1 className="text-2xl font-semibold">
            <Trans>Gratuit durant tout l'été !</Trans>
          </h1>
        </div>

        <span className="mb-2 flex items-center gap-4 text-6xl">
          🙀
          <MiloRoundLogo />
          💰
        </span>

        <p>
          <Trans>
            Découvrez les meilleures expériences autour de vous partout au Québec. Allant de restaurants exceptionnels
            au saut en parachute, en passant par les meilleures pâtisseries et cafés du coin... Il y en a pour tous les
            goûts! <b>Vous obtiendrez des réductions allant de 15% à 50% sur votre facture</b> lors de votre première
            visite chez chacuns de nos partenaires.
          </Trans>
        </p>

        <p className="mb-3">
          <Trans>
            Aucune attache, aucune carte de crédit requise.{' '}
            <b>Vous pouvez utiliser l'entièreté de la plateforme gratuitement</b>.
          </Trans>
        </p>

        <Link2 href="/milo-gratuit/inscription">
          <Button size="md">
            <Trans>Je veux en profiter!</Trans>
          </Button>
        </Link2>
      </div>
    );
  };

  return (
    <FlowContainer>
      <Content />
    </FlowContainer>
  );
};
