import { Trans } from '@lingui/macro';
import { Experience, SavingsTypes, VisitConfirmType } from '@milo/types/index';
import { useMutation } from '@tanstack/react-query';
import { FC, FormEvent } from 'react';
import { StepProps } from '../../components/Stepper';
import config from '../../config';
import { VisitConfirmMember } from '../../graphql-queries';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import { useGraphqlClient } from '../../hooks/useGraphqlClient';
import { formatCurrency } from '../../utils';
import { Button2, SwipeButton } from '../Button';

export const ValidationSavings: FC<StepProps> = ({ changeStep, setState, state }) => {
  const { user } = useAuthentication();
  const { client } = useGraphqlClient();
  const { platform } = useDeviceInfo();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  const experience: Experience = state.experience;

  const savingsType = experience?.savings_type;

  const savings = (() => {
    if (savingsType === SavingsTypes['Grid']) {
      if (state.amount < experience.savings_matrix.amount1) {
        return 0;
      }

      if (state.amount < experience.savings_matrix.amount2) {
        return experience.savings_matrix.savings1;
      }

      if (state.amount < experience.savings_matrix.amount3) {
        return experience.savings_matrix.savings2;
      }

      return experience.savings_matrix.savings3;
    }

    if (savingsType === SavingsTypes['Percentage']) {
      const percentage = experience.savings_percentage / 100;
      const percentageAmount = state.amount * percentage;
      return Math.round(Math.min(percentageAmount, experience.savings_max));
    }
  })();

  const twoForOneReceived = (() => {
    if (savingsType !== SavingsTypes['TwoForOne']) {
      return null;
    }

    return Math.min(Math.floor(Number(state.twoForOnePurchased / 2)), experience?.two_for_one_max_count);
  })();

  const { mutate: confirmVisit, isLoading: confirmLoading } = useMutation(
    async () => {
      const input = {
        user_id: user?.id,
        experience_id: state?.experience?.id,
        savings,
        two_for_one_purchased: Number(state?.twoForOnePurchased || 0),
        two_for_one_received: Number(twoForOneReceived || 0),
        invoice_total_before_tax: Number(state?.amount || 0),
        discovered_with_milo: state?.discovered_with_milo === 'yes',
        validated_by: VisitConfirmType['Member'],
        platform,
      };

      const response = await client.request(VisitConfirmMember, { input });
      return response?.visit;
    },
    {
      onSuccess: ({ data, success }) => {
        if (success) {
          setState('promo_code', data?.promo_code);
          changeStep('confirmed');
        }
        // happens when partners already confirmed a visit on their dashboard
        // while client tries to confirm it also
        else {
          setState('errorReason', 'partner_already_confirmed');
          changeStep('error');
        }
      },
      onError: () => {
        // TODO: errorReason = 'minimum_not_reached' when the minimum of tickets hasn't been met
        // TODO: any other reason will display a generic error message
        setState('errorReason', 'unknown');
        changeStep('error');
      },
    }
  );

  const formattedSavings = formatCurrency(savings);

  return (
    <form onSubmit={onSubmit} className="relative mt-16 flex flex-col">
      <div className="flex-1 ">
        {/* Receipt */}
        <div
          className="flex flex-col items-center bg-cover bg-bottom bg-no-repeat px-6 pb-20 pt-14"
          style={{
            backgroundImage: `url(https://${config.APP_DOMAIN}/images/receipt-bg.png)`,
            backgroundSize: 'min(400px, 100%) 100%',
          }}
        >
          <div className="text-center">
            <p className="mb-4 max-w-xs text-2xl font-bold">
              <Trans>Votre réduction-découverte M ta région</Trans>
            </p>
            <span className="text-4xl font-bold leading-none text-raspberry">
              {savingsType === 'two_for_one' ? (
                <div className="flex items-center justify-center">
                  <p className="mr-2">{twoForOneReceived}</p>
                  <p className="text-3xl leading-none">
                    <Trans>billets offerts</Trans>
                  </p>
                </div>
              ) : (
                formattedSavings
              )}
            </span>
          </div>
          <p className="mt-3 text-center font-medium leading-[22px] text-medium">
            {savingsType === 'two_for_one' ? (
              <Trans>pour vos {state?.twoForOnePurchased} billets achetés</Trans>
            ) : (
              <Trans>sur votre facture de {formatCurrency(state.amount)}</Trans>
            )}
          </p>
          <Button2
            size="sm"
            variant="secondary"
            className="mt-6 !border-0 !bg-gray-lighter"
            onClick={() => {
              setState('amount', null);
              changeStep('amount');
            }}
          >
            {savingsType === 'two_for_one' ? <Trans>Modifier le nombre</Trans> : <Trans>Modifier le montant</Trans>}
          </Button2>
        </div>
      </div>

      {((savingsType === 'two_for_one' && twoForOneReceived > 0) || (savingsType !== 'two_for_one' && savings > 0)) && (
        <SwipeButton
          isLoading={confirmLoading}
          onSuccess={() => confirmVisit()}
          lockedText={
            <p className="font-bold">
              <Trans>Confirmer l'utilisation</Trans>
            </p>
          }
          unlockedText={
            <p className="font-bold">
              <Trans>Utilisation confirmée</Trans>
            </p>
          }
        />
      )}
    </form>
  );
};
