import { colorFn, ColorType } from '../utils';

export const InstagramIcon = ({ stroke = 'currentColor' as ColorType, ...props }) => {
  const _stroke = colorFn(stroke);

  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
        stroke={_stroke}
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 16V8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5Z"
        stroke={_stroke}
        strokeWidth={2.5}
      />
      <path d="m17.5 6.51.01-.011" stroke={_stroke} strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
