import { t, Trans } from '@lingui/macro';
import { FlowContainer, QuickLogin, SubscriptionAlreadyActive } from '../../components';
import { useAuthentication } from '../../hooks';
import { MiloFull } from '../../logos';

export const FreeVisitLoginPage = () => {
  const { isLoading, loggedIn, subscribed } = useAuthentication();

  if (isLoading) return null;

  if (subscribed) {
    return (
      <FlowContainer>
        <SubscriptionAlreadyActive title={t`Pas besoin d'obtenir une visite gratuite 🙂`} />
      </FlowContainer>
    );
  }

  if (loggedIn) {
    location.href = '/free-visit/profile';
    return null;
  }

  return (
    <FlowContainer>
      <MiloFull className="mx-auto mb-5 w-40" />
      <div className="text-center">
        <h1 className="mb-2 text-3xl font-bold">
          <Trans>Connexion à M ta région</Trans>
        </h1>
        <h2 className="mb-4 text-lg">
          <Trans>Pour profiter de votre essai gratuit, il faudra d'abord vous connecter 🙂</Trans>
        </h2>
      </div>
      <QuickLogin centered onSuccess={() => (location.href = '/free-visit/profile')} />
    </FlowContainer>
  );
};
