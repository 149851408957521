import { createContext, Dispatch, SetStateAction } from 'react';

export const StepperContext = createContext<{
  setStep: Dispatch<SetStateAction<string>>;
  step: string;
  setState: Dispatch<SetStateAction<Record<string, any>>>;
  state?: Record<string, any>;
}>({
  setStep: () => {},
  step: '',
  setState: () => {},
});
