import classNames from 'classnames';
import { ButtonHTMLAttributes, forwardRef, ForwardRefRenderFunction } from 'react';

export type Chip2Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  selected?: boolean;
  onChange?: (selected: boolean) => void;
  theme?: 'primary' | 'secondary' | 'transparent';
  scrollIntoView?: boolean;
};

const ButtonRenderFunction: ForwardRefRenderFunction<HTMLButtonElement, Chip2Props> = (
  { selected, onChange = () => {}, theme, className = '', scrollIntoView = false, ...buttonProps },
  ref
) => {
  const buttonClassNames = classNames(
    'text-sm transition-all whitespace-nowrap leading-[18px] font-semibold py-1 px-2 inline-flex items-center rounded-full !border-2',
    className,
    {
      'border border-charcoal': theme === 'primary',
      'bg-charcoal text-white': theme === 'primary' && selected,
      'bg-white text-raspberry': theme === 'primary' && !selected,

      'border border-white': theme === 'transparent',
      'bg-transparent text-white': theme === 'transparent',

      'border border-charcoal ': theme === undefined,
      'bg-charcoal text-white ': theme === undefined && selected,
      'bg-transparent': theme === undefined && !selected,
    }
  );

  return <button ref={ref} className={buttonClassNames} {...buttonProps} />;
};

export const Chip2 = forwardRef(ButtonRenderFunction);
