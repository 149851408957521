import classNames from 'classnames';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EyeClosedIcon, EyeIcon, ModalCloseIcon } from '../../icons';

export const formatDateWithHypens = (str) => {
  const clean = str.replace(/\-/gm, '');

  if (clean.length > 6) {
    return `${clean.slice(0, 4)}-${clean.slice(4, 6)}-${clean.slice(6, 8)}`;
  }

  if (clean.length > 4) {
    return `${clean.slice(0, 4)}-${clean.slice(4, 6)}`;
  }

  return str;
};

const formatPhoneWithHyphens = (str) => {
  const clean = str.replace(/\-/gm, '');

  if (clean.length > 6) {
    return `${clean.slice(0, 3)}-${clean.slice(3, 6)}-${clean.slice(6, 10)}`;
  }

  if (clean.length > 3) {
    return `${clean.slice(0, 3)}-${clean.slice(3, 99)}`;
  }

  return str;
};

export const baseInputClassNames = ({ error = false, success = false }: InputExtraProps) =>
  classNames(
    'outline-none shadow-input transition placeholder-medium-gray text-medium-dark w-full leading-5 border p-3 rounded',
    {
      'border-error focus-within:border-error': error,
      'border-success focus-within:border-success': success,
      'border-gray-light focus-within:border-medium-dark': !error && !success,
    }
  );

type InputExtraProps = {
  append?: ReactNode;
  clearable?: boolean;
  containerClassName?: string;
  endIcon?: ReactNode;
  error?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>; // backward compatibility
  onChangeText?: (value: string) => void; // backward compatibility
  onClear?: () => void; // backward compatibility
  prepend?: ReactNode; // The diffence between prepend and startIcon is that startIcon doesn't have click events. Clicks pass right through
  startIcon?: ReactNode;
  success?: boolean;
};

export type InputText2Props = InputHTMLAttributes<HTMLInputElement> & InputExtraProps;

const InputTextRenderFunction: React.ForwardRefRenderFunction<HTMLInputElement, InputText2Props> = (
  {
    success = false,
    error = false,
    type: initialType = 'text',
    onChangeText = () => {},
    onChange = () => {},
    onClear = () => {},
    className = '',
    containerClassName = '',
    clearable,
    enterKeyHint = 'enter',
    prepend,
    startIcon,
    append,
    endIcon,
    value: initialValue,
    ...inputProps
  },
  ref
) => {
  const [type, setType] = useState(initialType);
  const [value, setValue] = useState(() => initialValue);

  const clear = () => {
    setValue('');
    onChange && onChange({ target: { value: '' } });
    onClear && onClear();
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const keyboardType = useMemo(() => {
    if (['numeric', 'number', 'date'].includes(type)) {
      return 'numeric';
    }

    if (type === 'phone') {
      return 'tel';
    }

    return 'text';
  }, []);

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    let first = value.slice(0, -1);
    let last = value.slice(-1);

    if (['number', 'numeric', 'phone', 'date'].includes(type)) {
      if (isNaN(Number(last))) {
        last = '';
      }
      last = last.replace(/\s/gm, '');
    }

    value = first + last;

    if (type === 'date') {
      value = formatDateWithHypens(value);
    }

    if (type === 'phone') {
      value = formatPhoneWithHyphens(value);
    }

    if (type === 'email') {
      value = value.toLowerCase().replace(/\s/gm, '');
    }

    setValue(value);
    onChange && onChange(event);
  };

  const inputClassNames = classNames(
    baseInputClassNames({
      error,
      success,
    }),
    {
      'pl-8': !!startIcon || !!prepend,
      'pr-8': !!endIcon || !!append,
    },
    className
  );

  const togglePasswordPlainText = () => {
    setType(type === 'password' ? 'text' : 'password');
  };

  if (initialType === 'password') {
    append = (
      <button onClick={togglePasswordPlainText} type="button" tabIndex={-1}>
        {type === 'password' ? (
          <EyeIcon className="h-5 w-5 text-medium-dark" />
        ) : (
          <EyeClosedIcon className="h-5 w-5 text-medium-dark" />
        )}
      </button>
    );
  }

  return (
    <div className={`relative w-full ${containerClassName}`}>
      {!!startIcon && <span className="absolute bottom-0 left-3 top-0 flex items-center">{startIcon}</span>}
      {!!prepend && <span className="absolute bottom-0 left-3 top-0 flex items-center">{prepend}</span>}

      <input
        type={type}
        value={value}
        className={inputClassNames}
        ref={ref}
        inputMode={keyboardType}
        {...inputProps}
        onChange={_onChange}
      />
      {!endIcon && clearable && (
        <button
          tabIndex={-1}
          className={`absolute bottom-0 right-3 top-0 flex items-center ${
            value ? 'opacity-100' : 'opacity-0'
          } transition-opacity`}
          onClick={clear}
        >
          <ModalCloseIcon fill="currentColor" className="h-3 w-3 text-medium-dark" />
        </button>
      )}
      {append !== undefined && <span className="absolute bottom-0 right-3 top-0 flex items-center">{append}</span>}
      {endIcon !== undefined && (
        <span className="pointer-events-none absolute bottom-0 right-3 top-0 flex items-center">{endIcon}</span>
      )}
    </div>
  );
};

export const InputText2 = forwardRef(InputTextRenderFunction);
