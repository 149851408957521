import { Category } from '@milo/types/index';
import { CategoriesList } from '../../graphql-queries';
import { getGraphqlClient } from '../useGraphqlClient';
import { useQuery } from '../useQuery';

export const getCategories: () => Promise<Category[]> = async () => {
  const graphqlClient = getGraphqlClient();
  const data = await graphqlClient.request(CategoriesList);
  return data?.CategoriesList;
};

export const useCategories = () => {
  const response = useQuery<Category[]>(['CategoriesList'], async () => await getCategories(), {
    initialData: [],
  });

  return response;
};
