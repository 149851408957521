import { useState } from 'react';

export const useHover = () => {
  const [hover, setHover] = useState(false);

  return {
    setHover,
    hover,
    onMouseDown: () => setHover((hov) => !hov),
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
  };
};
