import { ReactFC } from 'src/types';
import { MiloSVGProps } from '..';
import { colorFn, ColorType } from '../../utils';

const SvgComponent: ReactFC<MiloSVGProps> = ({ stroke = 'currentColor' as ColorType, ...props }) => {
  const _stroke = colorFn(stroke);

  return (
    <svg viewBox="-1.5 -1.5 19 19" fill="none" {...props}>
      <path
        d="M2 5.16663V12.5M2 9.8333H14M14 12.5V7.16663C14 6.81301 13.8595 6.47387 13.6095 6.22382C13.3594 5.97378 13.0203 5.8333 12.6667 5.8333H7.33333V9.8333"
        stroke={_stroke}
        strokeWidth="1.3282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66667 7.83332C5.03486 7.83332 5.33333 7.53484 5.33333 7.16665C5.33333 6.79846 5.03486 6.49998 4.66667 6.49998C4.29848 6.49998 4 6.79846 4 7.16665C4 7.53484 4.29848 7.83332 4.66667 7.83332Z"
        stroke={_stroke}
        strokeWidth="1.3282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { SvgComponent as Sejours };
