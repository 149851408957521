import { t, Trans } from '@lingui/macro';
import { FormEvent, ReactNode, useState } from 'react';
import { z } from 'zod';
import { Button, Field2, ImageKitImage, InputText2 } from '../../components';
import { useGraphqlClient, useMutation } from '../../hooks';
import { CheckCircleAlt } from '../../icons';
import { MiloRoundLogo } from '../../logos';
import { ReactFC } from '../../types';
import { LandingLogos, MiloLandingDescription, Testimonials, ThreeReasons } from '../IdeeCadeau';

export const ConcoursBkindPage: ReactFC = () => {
  return (
    <div className="web-container">
      <div className="mx-auto mb-10 mt-5 flex w-fit items-center gap-6 text-3xl font-bold">
        <MiloRoundLogo width={40} />
        <span className="shrink">+</span>
        <img src="/images/home/business-partners/bkind.png" />
      </div>

      <h1 className="mb-12 text-center text-5xl font-bold">
        <Trans>Gagne ton pass M ta région 12 mois!</Trans>
      </h1>

      <div className="mb-20">
        <MiloLandingDescription>
          <Trans>
            Spa, boutiques, restaurants, sorties... pass M ta région te donne des réductions allant de 15 à 50% sur
            toutes les meilleures adresses locales ! Si tu aimes découvrir les plus belles expériences du Québec, tente
            ta chance !
          </Trans>
        </MiloLandingDescription>
      </div>

      <div className="mx-auto mb-20 flex w-fit items-center gap-3">
        <img src="/images/phone-hero.png" className="hidden max-w-md md:block" />
        <ContestForm
          promoName="bkind"
          title={
            <>
              Remplis le formulaire pour participer!
              <p className=" text-sm text-red/75">Tirage le 20 décembre 2022.</p>
            </>
          }
        />
      </div>

      <div className="mb-20 text-center">
        <h2 className="mb-12 text-4xl font-bold">
          <Trans>Gagner un pass M ta région c'est aussi</Trans>
        </h2>
        <div className="mx-auto flex items-center justify-center gap-10">
          <ImageKitImage className="hidden md:block" src="/images/elegant.png" />
          <div className="max-w-2xl">
            <ThreeReasons />
          </div>
        </div>
      </div>

      <LandingLogos />

      <Testimonials />
    </div>
  );
};

const ContestFormSchema = z.object({
  first_name: z.string().min(1).max(255),
  last_name: z.string().min(1).max(255),
  email: z.string().email().min(1).max(255),
  contest_name: z.string().min(1).max(255),
});
type ContestFormValues = z.infer<typeof ContestFormSchema>;

type ContestFormProps = {
  title?: string | ReactNode;
  promoName: string;
};
export const ContestForm: ReactFC<ContestFormProps> = ({
  promoName,
  title = t`Remplissez le formulaire pour participer!`,
}) => {
  const { client } = useGraphqlClient();
  const [error, setError] = useState(false);
  const {
    mutateAsync: sendFormData,
    isLoading: sendFormDataLoading,
    isSuccess,
  } = useMutation(async (formData: ContestFormValues) => {
    return await client.request(
      /* GraphQL */ `
        mutation LandingContestSaveUser($input: LandingContestSaveUserInput!) {
          LandingContestSaveUser(input: $input) {
            success
          }
        }
      `,
      { input: formData }
    );
  });

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = {
      first_name: form.elements['first_name'].value.replace(/\s/, ''),
      last_name: form.elements['last_name'].value.replace(/\s/, ''),
      email: form.elements['email'].value.replace(/\s/, '').toLowerCase(),
      contest_name: form.elements['contest_name'].value,
    };

    const formInputValid = ContestFormSchema.safeParse(formData).success;
    if (!formInputValid) return setError(true);

    await sendFormData(formData);
  };

  return (
    <>
      {isSuccess && (
        <div className="flex animate-fade-in flex-col items-center justify-center gap-3 text-success">
          <CheckCircleAlt className="h-16 w-16" />
          <h2 className="text-center text-2xl font-semibold">
            <Trans>C'est fait, merci de votre participation!</Trans>
          </h2>
        </div>
      )}

      {!isSuccess && (
        <form onSubmit={submitForm} className="mx-auto max-w-lg">
          <h2 className="mb-6 text-2xl font-semibold">{title}</h2>
          <div className="flex gap-2">
            <Field2 className="grow" label={t`Prénom`}>
              <InputText2 placeholder="" type="text" name="first_name" />
            </Field2>
            <Field2 className="grow" label={t`Nom`}>
              <InputText2 placeholder="" type="text" name="last_name" />
            </Field2>
          </div>
          <Field2 label={t`Courriel`}>
            <InputText2 placeholder="" type="email" name="email" />
          </Field2>
          <input type="hidden" name="contest_name" value={promoName} />
          {error && (
            <div className="mb-4 animate-fade-in text-sm text-red">
              <Trans>Svp remplir tous les champs correctement avant de soumettre</Trans>
            </div>
          )}
          <Button type="submit" block loading={sendFormDataLoading}>
            <Trans>Soumettre</Trans>
          </Button>
        </form>
      )}
    </>
  );
};
