import { colorFn, ColorType } from '../utils';

export const TrashIcon = ({ fill = 'currentColor' as ColorType, strokeWidth = 2, ...props }) => {
  const _fill = colorFn(fill);

  return (
    <svg viewBox="-10 -10 480 480" xmlSpace="preserve" {...props}>
      <path
        fill={_fill}
        d="M382.078 57.069h-89.78C289.128 25.075 262.064 0 229.249 0S169.37 25.075 166.2 57.069H76.421c-26.938 0-48.854 21.916-48.854 48.854 0 26.125 20.613 47.524 46.429 48.793V399.5c0 32.533 26.467 59 59 59h192.508c32.533 0 59-26.467 59-59V154.717c25.816-1.269 46.429-22.668 46.429-48.793 0-26.939-21.916-48.855-48.855-48.855zM229.249 30c16.244 0 29.807 11.673 32.76 27.069h-65.52C199.442 41.673 213.005 30 229.249 30zm125.254 369.501c0 15.991-13.009 29-29 29H132.995c-15.991 0-29-13.009-29-29V154.778h250.508v244.723zm27.575-274.723H76.421c-10.396 0-18.854-8.458-18.854-18.854S66.025 87.07 76.421 87.07h305.657c10.396 0 18.854 8.458 18.854 18.854s-8.457 18.854-18.854 18.854z"
      />
      <path
        fill={_fill}
        d="M229.249 392.323c8.284 0 15-6.716 15-15V203.618c0-8.284-6.715-15-15-15-8.284 0-15 6.716-15 15v173.705c0 8.284 6.716 15 15 15zM306.671 392.323c8.284 0 15-6.716 15-15V203.618c0-8.284-6.716-15-15-15s-15 6.716-15 15v173.705c0 8.284 6.716 15 15 15zM151.828 392.323c8.284 0 15-6.716 15-15V203.618c0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15v173.705c0 8.284 6.716 15 15 15z"
      />
    </svg>
  );
};
