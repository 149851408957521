import { Trans } from '@lingui/macro';
import { convertDateToReadableFormat } from '@milo/helpers';
import { Experience } from '@milo/types/index';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { StepProps } from '../../components/Stepper';
import { getUserData, trackEvent, useEffectOnce } from '../../hooks';
import { CheckCircle } from '../../icons';
import { copyToClipboard } from '../../utils';
import { Button2 } from '../Button';

const trackPrivilegeUse = async (exp: Experience) => {
  const user = await getUserData();
  trackEvent('get_privilege', {
    items: [
      {
        item_category: exp?.categories[0],
        item_city: exp?.location?.city ?? '',
        item_id: exp.id,
        quantity: 1,
      },
    ],
    user_id: user?.id,
  });
};

export const ValidationConfirmed: FC<StepProps> = ({ state }) => {
  const now = convertDateToReadableFormat(DateTime.now().toISO());

  useEffectOnce(() => trackPrivilegeUse(state.experience));

  return (
    <div className="flex flex-col pt-20">
      <div className="flex flex-1 flex-col items-center pb-10">
        <CheckCircle className="h-16 w-16 text-success" fill="currentColor" />
        <p>{state.experience.promo_code}</p>
        <h1 className="mt-3 text-center text-[28px] font-bold leading-9">
          <Trans>Privilège utilisé</Trans>
        </h1>
        <h1 className="mt-3 text-center text-[28px] font-bold leading-9 text-raspberry">{state.experience.name}</h1>
        <p className="mt-1 text-center leading-[22px]">
          <Trans>Visite du {now}</Trans>
        </p>
        {state?.promo_code && (
          <Button2 onClick={(e) => copyToClipboard(e, state.promo_code)} className="mt-5 flex-col py-[8px]">
            <span className=" text-lg">
              <Trans>Code promo: {state.promo_code}</Trans>
            </span>
            <small>
              <Trans>(Cliquer pour copier)</Trans>
            </small>
          </Button2>
        )}
      </div>
    </div>
  );
};
