import { Trans } from '@lingui/macro';
import { uniq } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Button2, Link2, LinkButton, Loading, Modal, StepperModal, StepProps } from '../../components';
import { useAuthentication, useLocalStorage } from '../../hooks';
import { ArrowRight } from '../../icons';
import { ReactFC } from '../../types';
import { TutorialContainer, TutorialLink } from './base';

export const TutorialMainPage: ReactFC = () => {
  const { isLoading, isFetching, subscribed, user } = useAuthentication();
  const [pagesVisited, setPagesVisited] = useLocalStorage('member.pagesVisited', []);
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);

  const hasFreeVisit = Boolean(user?.pending_free_visit?.id);

  const privilegeTutorialLocked = !subscribed && !hasFreeVisit;

  useEffect(() => {
    setPagesVisited(uniq([...pagesVisited, 'tutorials']));
  }, []);

  const userLoading = isLoading || isFetching;

  if (userLoading) {
    return <Loading />;
  }

  return (
    <TutorialContainer>
      <div>
        <h1 className="text-3xl font-bold">
          <Trans>Guide de l'utilisateur</Trans>
        </h1>
        <p className="mb-10 leading-5 text-medium-dark">
          <Trans>
            Une incertitude, une piqûre de rappel? Retrouvez ici tout ce qu’il faut savoir pour utiliser de façon
            optimale votre compte M ta région.
          </Trans>
        </p>
        <div className="mb-6 space-y-3 md:mb-10">
          <TutorialLink href="/tutorials/experiences">
            <Trans>Trouver des expériences</Trans>
          </TutorialLink>
          <TutorialLink href="/tutorials/wishlist">
            <Trans>Faire une liste de favoris</Trans>
          </TutorialLink>
          <TutorialLink
            href="/tutorials/validation"
            disabled={privilegeTutorialLocked}
            onClick={(e) => {
              if (privilegeTutorialLocked) {
                e.preventDefault();
                return setShowCreateAccountModal(true);
              }
            }}
          >
            <Trans>Obtenir une réduction-découverte</Trans>
          </TutorialLink>
        </div>
        <div className="flex justify-center">
          <LinkButton
            block="md"
            href="/experiences"
            variant="secondary"
            EndIcon={(props) => <ArrowRight {...props} fill="charcoal" />}
          >
            <Trans>Parcourir les expériences</Trans>
          </LinkButton>
        </div>
      </div>
      {showCreateAccountModal && (
        <Modal open onRequestClose={() => setShowCreateAccountModal(false)}>
          <StepperModal steps={{ info: { Component: BecomeMemberModal } }} initialStep="info" />
        </Modal>
      )}
    </TutorialContainer>
  );
};

export const BecomeMemberModal: FC<StepProps> = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="mb-4 text-center text-[22px] font-semibold leading-6">
        Obtenez un pass M ta région et débloquez vos réductions-découvertes
      </h2>
      <p className="text-center text-[15px] leading-5 text-medium-dark">
        <Trans>
          Être membre, c’est obtenir une réduction-découverte à chaque fois que vous découvrez une expérience
          sélectionnée avec soin par M ta région et approuvée par la communauté.
        </Trans>
      </p>
      <div className="mt-8">
        <Link2 href="/purchase">
          <Button2 variant="primary">
            <Trans>Voir tous les avantages</Trans>
          </Button2>
        </Link2>
      </div>
    </div>
  );
};
