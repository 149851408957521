import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';

export type DividerProps = HTMLAttributes<HTMLDivElement>;

export const thin: FC<DividerProps> = ({ className = '', children }) => {
  const divider = <div className={classNames('my-4 mx-auto h-px flex-1 bg-gray-lighter', className)} />;

  if (children) {
    return (
      <div className="flex items-center justify-center text-gray-light">
        {divider}
        <div className="px-4">{children}</div>
        {divider}
      </div>
    );
  }

  return divider;
};

export const thick: FC<DividerProps> = ({ className = '', children }) => {
  const divider = <div className={classNames('my-6 -mx-6 h-4 flex-1 bg-gray-lighter sm:mx-0', className)} />;

  if (children) {
    return (
      <div className="flex items-center justify-center text-gray-light">
        {divider}
        <div className="px-4">{children}</div>
        {divider}
      </div>
    );
  }

  return divider;
};

export const Divider = {
  thick,
  thin,
};
