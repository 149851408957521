import { useAuthentication } from '../../hooks';
import { ReactFC } from '../../types';
import { Loading } from './Base';

type LoggedInProps = {
  saveRedirect?: boolean;
  redirectPath?: string;
};

export const LoggedIn: ReactFC<LoggedInProps> = ({ children, redirectPath = '/login' }) => {
  const { isLoading, loggedIn } = useAuthentication();

  if (isLoading) {
    return <Loading />;
  }

  if (!loggedIn) {
    location.href = redirectPath;
    return <Loading />;
  }

  return <>{children}</>;
};
