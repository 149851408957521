export const MiloGuides = (props) => {
  return (
    <svg viewBox="0 0 965 104" {...props}>
      <path
        d="M148.6 2.5C143.5 7.2 146.7 16 153.5 16C158.2 16 159.9 15.2 161.6 11.9C163.4 8.3 162.6 4.6 159.3 2C155.9 -0.7 151.8 -0.5 148.6 2.5Z"
        fill="black"
      />
      <path
        d="M731.3 8.9C728.9 13.2 727 17 727 17.4C727 17.7 728.5 18 730.4 18C733.6 18 734.3 17.3 739.4 10C742.5 5.6 745 1.8 745 1.5C745 1.2 742.9 1 740.3 1H735.5L731.3 8.9Z"
        fill="black"
      />
      <path
        d="M31.3 3.0999C10.2 5.5999 -2.50001 25.0999 0.899987 49.9999C3.99999 73.1999 18.4 85.1999 41.7 83.7999C55.4 82.9999 64.8 77.0999 69.9 65.9999C71.9 61.5999 72.5 58.4999 72.8 50.6999L73.2 40.9999H56.1H39V46.9999V52.9999H48C57 52.9999 57 52.9999 57 55.4999C57 59.2999 54.5 63.4999 50.4 66.6999C45.3 70.4999 35.3 71.1999 29.2 68.0999C21 63.8999 17.5 56.3999 17.5 42.9999C17.5 34.7999 17.9 32.6999 20.1 27.8999C23.7 20.2999 29.3 16.7999 37.9 16.7999C45.9 16.7999 51.4 19.9999 54.9 26.6999C56 28.7999 56.8 28.9999 64 28.9999C71.4 28.9999 72 28.7999 72 26.9999C72 23.7999 67.5 15.7999 63.8 12.3999C55.1 4.2999 45.2 1.4999 31.3 3.0999Z"
        fill="black"
      />
      <path
        d="M833 3.30002C829.9 4.80002 829.1 9.90002 831.5 12.4C835.1 15.9 842 13.2 842 8.20002C842 7.10002 840.8 5.20002 839.4 4.10002C836.3 1.70002 836.3 1.60002 833 3.30002Z"
        fill="black"
      />
      <path
        d="M213 19.2V34.5L209.2 30.3C199 18.7 181.2 21.3 174.2 35.4C167.3 49.1 170 70.6 179.7 79C188.3 86.6 202.5 85.3 209.8 76.4L213 72.5V77.8V83H220.5H228V43.5V3.99999H220.5H213V19.2ZM204.5 37.4C214.4 41.6 215.1 63.3 205.6 69.1C201.6 71.5 197.3 71.5 193.3 69C188.7 66.2 186.6 61.7 186.6 54C186.6 45.7 189.1 40.2 194 37.8C198.2 35.6 200 35.6 204.5 37.4Z"
        fill="black"
      />
      <path
        d="M436 43.5V83H440.5H445L445.2 52.9L445.5 22.7L457.9 52.9L470.4 83H474.5H478.6L491 52.8L503.5 22.5L503.8 52.8L504 83H508.5H513V43.5V3.9L507.4 4.2L501.9 4.5L488.7 36.6C481.4 54.2 475.1 68.7 474.5 68.7C474 68.8 468.7 57 462.8 42.7C456.9 28.3 450.9 13.7 449.4 10.2L446.8 4H441.4H436V43.5Z"
        fill="black"
      />
      <path
        d="M557 17.5V25H553H549V29V33H553H557V50.7C557.1 70.9 557.8 76.1 561 79.5C563.6 82.3 572.4 84.6 576.4 83.6C578.6 83.1 579 82.4 579 79.6C579 76.4 578.8 76.2 574.8 75.7C567.4 74.7 567 73.6 567 51.9V33H573H579V29V25H573H567V17.5V10H562H557V17.5Z"
        fill="black"
      />
      <path
        d="M254.2 24.9C247.6 27.2 242.8 31.8 239.2 38.9C237 43.5 236.6 45.8 236.5 52.5C236.5 62.9 238.3 68.9 243.3 74.6C249.5 81.7 253.8 83.4 265 83.5C273.7 83.5 275 83.2 279.8 80.6C285.4 77.5 288.1 74.5 290.5 68.6L292 65H285C278.4 65 277.8 65.2 275.2 68.2C272.8 70.8 271.5 71.4 266.9 71.7C258.6 72.3 253.6 68.5 252.3 60.7L251.7 57H271.9H292V51.2C292 30.7 273.8 18.1 254.2 24.9ZM271.4 37.1C274 39.1 277 43.9 277 46C277 46.6 272.5 47 264.4 47C251.9 47 251.9 47 252.5 44.7C255 36.2 265 32.1 271.4 37.1Z"
        fill="black"
      />
      <path
        d="M313.8 24.3999C298 28.5999 294 45.8999 306.7 54.5999C308.4 55.6999 313.3 57.3999 317.6 58.3999C329.8 61.0999 332.4 62.1999 333.4 65.2999C336.4 73.6999 317.2 75.8999 314 67.4999C313.1 65.0999 312.6 64.9999 305.5 64.9999C297.2 64.9999 296.9 65.2999 299.4 71.2999C305.1 84.8999 331.7 88.4999 344.1 77.2999C350.7 71.2999 351.7 62.2999 346.3 55.9999C343.2 52.1999 339.9 50.7999 327.8 48.0999C317.3 45.6999 314.7 43.8999 315.2 39.2999C315.4 36.9999 316.3 36.1999 319.6 35.0999C323.2 33.7999 324.3 33.7999 327.6 35.0999C330 35.9999 332 37.5999 332.8 39.2999C334 41.8999 334.5 41.9999 341.1 41.9999C347.4 41.9999 348 41.7999 348 39.9999C348 36.3999 344.1 30.0999 340.2 27.5999C333.6 23.1999 323 21.8999 313.8 24.3999Z"
        fill="black"
      />
      <path
        d="M383.274 34.37C370.374 38.87 366.874 54.37 376.474 63.97C384.774 72.27 397.574 70.97 404.174 61.17C410.774 51.37 405.374 37.17 394.074 33.97C389.074 32.57 387.974 32.67 383.274 34.37Z"
        fill="#E62152"
      />
      <path
        d="M82 46.1999C82 70.6999 82.7 74.1999 88.5 79.1999C96.4 86.0999 109.6 85.4999 116.4 77.9999L120 73.9999V78.4999V82.9999H128H136V53.4999V23.9999H128H120V42.9999C120 64.0999 119.4 66.6999 114 69.4999C110.5 71.2999 107 71.3999 103.5 69.7999C98.6 67.5999 98 64.7999 98 43.4999V23.9999H90H82V46.1999Z"
        fill="black"
      />
      <path d="M146 53.5V83H154H162V53.5V24H154H146V53.5Z" fill="black" />
      <path
        d="M604.5 24.9C597.7 26.6 592.1 31 590.1 36.3C588.4 40.7 588.7 41 593.5 41C597.4 41 598.2 40.7 599 38.5C600.5 34.4 606.7 32.2 615.1 32.8C616.6 32.9 619.2 34.3 620.9 35.9C623.6 38.4 624 39.5 624 43.7V48.6L611.8 49.6C592.4 51.3 586.6 55.5 587.2 67.5C587.6 74.7 590.2 78.9 595.7 81.5C604.2 85.6 613.5 84.3 620 78.1L624 74.3V78.7V83H629H634V63.5C634 39.7 633.1 34.8 627.9 29.8C622.7 24.8 613.2 22.8 604.5 24.9ZM624 60.7C624 66.1 621.8 70.2 617.4 73.1C608.1 79.2 594.1 73.6 596.6 64.8C598 59.9 601.7 57.9 610.6 57C625.1 55.6 624 55.3 624 60.7Z"
        fill="black"
      />
      <path
        d="M691 25.6C689.1 26.4 686.4 28.7 685 30.7L682.5 34.4L682.2 29.7L681.9 25H677.4H673V54V83H677.4H681.9L682.2 62.7C682.5 44.8 682.7 42.1 684.5 39.3C686.8 35.3 691.4 33 696.9 33H701V28.5C701 24.1 700.9 24 697.8 24.1C696 24.1 692.9 24.8 691 25.6Z"
        fill="black"
      />
      <path
        d="M722.8 25.2C717.8 26.5 711.4 32.2 708.2 38.4C705.8 43 705.5 44.5 705.5 54C705.5 66.1 707.3 71.1 713.8 77.5C724.7 88.1 746.7 84.9 753.6 71.8C755.7 67.7 755.4 67 750.9 67C747.6 67 746.3 67.6 743.7 70.4C736 78.6 721.8 76.7 716.9 66.9C716 65 715 62 714.6 60.2L714.1 57H735H756V53.5C756 32.8 741.4 20.2 722.8 25.2ZM736 33.5C741 34.9 746 41.6 746 47C746 48.9 745.4 49 730.4 49H714.7L715.3 45.5C716.3 39.1 720.8 34.5 727.5 32.9C730.5 32.2 731.5 32.3 736 33.5Z"
        fill="black"
      />
      <path
        d="M780.9 25.4C772.2 28.5 766.9 35.5 764.8 46.9C763.3 54.9 765.1 65.2 769 71.3C776.5 83.1 793.6 85.4 803.1 75.9L807 72V78.7C807 90 802 95 790.8 95C785.2 95 778.3 92.3 776.7 89.5C776.2 88.5 774.3 88 771 88C765.4 88 765.3 88.8 769.7 94.8C774 100.6 779.1 102.5 790.5 102.5C803.2 102.5 808.8 100.1 813.5 92.5L816.5 87.7L816.8 56.3L817.2 25H812.1H807V29.7V34.4L804 31.2C798.2 24.8 788.9 22.5 780.9 25.4ZM798 34.5C803.9 37.5 807 44.1 807 53.2C806.9 67.2 798.2 75.6 786.5 73C781.7 72 775.6 65.7 774.6 60.9C770.7 41.1 783.2 27 798 34.5Z"
        fill="black"
      />
      <path
        d="M870 25.5001C860.2 28.7001 853.5 38.0001 852.4 50.1001C850.7 68.0001 860.3 82.1001 875.2 83.7001C886.7 84.9001 895.9 80.2001 901.8 70.2001C904.3 65.8001 904.5 64.7001 904.5 54.0001C904.5 41.4001 903.8 39.3001 897.8 32.5001C891.5 25.2001 879.8 22.3001 870 25.5001ZM887.9 35.3001C897.7 41.2001 898.1 66.3001 888.6 72.5001C884.6 75.1001 879 76.1001 874.5 74.9001C866.2 72.7001 862 65.8001 862 54.0001C862 42.5001 866.1 35.4001 874 33.2001C878.5 31.9001 883.6 32.7001 887.9 35.3001Z"
        fill="black"
      />
      <path
        d="M936.7 25.3C934.6 26 931.4 28 929.5 29.9L926 33.3V29.1V25H921H916V54V83H921H926V63.4C926 41.3 926.6 38.6 932.8 34.8C937.5 31.9 944.9 31.8 948.8 34.7C953.7 38.3 954 40 954 62.2V83H959.1H964.2L963.8 60.2C963.6 41.4 963.2 37 961.8 34.5C957 25.7 947.1 22.1 936.7 25.3Z"
        fill="black"
      />
      <path d="M831 54V83H835.5H840V54V25H835.5H831V54Z" fill="black" />
    </svg>
  );
};
