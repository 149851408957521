export const VisitFragment = `
  id
  rating
  savings
  comment
  experience {
    id
    name
    slug
    main_image
    additional_images
  }
  promo_code
  date_created
`;

export const FreeVisitFragment = `
  id
  user_id
  visit_id
  valid_until_date
  visit_date
  created_date
  updated_date
`;
