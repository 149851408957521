import { t, Trans } from '@lingui/macro';
import { Guide } from '@milo/types/index';
import { useEffect, useMemo, useState } from 'react';
import {
  AuthedAction,
  Button2,
  Divider,
  ImageKitImage,
  InputText2,
  Link2,
  Modal,
  ModalBody,
  ResultExperienceCard,
  Share,
  Text,
} from '../../components';
import MiloMap from '../../components/MiloMap';
import { VerticalsFilter } from '../../components/Search/VerticalsFilter';
import { useWishlistMutations } from '../../hooks';
import { ArrowLeft, Heart, MapPinAlt, ShareIcon } from '../../icons';
import { NextBasePageProps, ReactFC } from '../../types';
import { EMAIL_ADDRESS_MEMBERS, formatCurrency } from '../../utils';

export type SingleGuidePageProps = NextBasePageProps & {
  guide: Guide;
};

export const SingleGuidePageComponent: ReactFC<SingleGuidePageProps> = ({ guide = {} as Guide }) => {
  const [selectedVerticals, setSelectedVerticals] = useState([]);
  const [saveGuideModalOpen, setSaveGuideModalOpen] = useState(false);
  const [newListName, setNewListName] = useState(guide.title);
  const { createWishlist } = useWishlistMutations();

  useEffect(() => {
    setNewListName(guide.title);
  }, [guide.title]);

  const experiences = guide.experiences || [];

  const map = useMemo(
    () => <MiloMap experiences={experiences || []} zoomToFitPassedExperiences={true} />,
    [experiences.length]
  );

  if (!guide.id) return null;

  const totalSavingsCents =
    experiences?.reduce((prev, curr) => {
      return prev + Number(curr?.savings_max || 0);
    }, 0) || 0;

  const totalSavings = formatCurrency(totalSavingsCents);

  const openModal = () => {
    setSaveGuideModalOpen(true);
  };

  const closeModal = () => {
    setSaveGuideModalOpen(false);
    createWishlist.reset();
  };

  return (
    <>
      <div className="relative mx-auto min-h-[500px] max-w-7xl overflow-hidden md:rounded-t-3xl">
        <ImageKitImage
          src={'/images/guide/guide-main.png'}
          alt="Montreal Activities"
          className="absolute h-full w-full"
        />
        <div className="web-container relative h-full w-full pt-14 text-white">
          <button className="mb-10" onClick={() => history.back()}>
            <ArrowLeft className="left-5 top-5 h-[32px] w-[32px] rounded-full bg-white/20 p-2" />
          </button>

          <div className="my-10 flex h-full max-w-lg items-center">
            <div>
              <Text size="h3" className="mb-2">
                <Trans>ITINÉRAIRE</Trans>
              </Text>
              <Text size="h1" className="mb-4 font-bold">
                {guide.title}
              </Text>
              {guide?.region?.name && (
                <div className="flex w-fit items-center justify-center rounded-full bg-white/10 px-2 py-1">
                  <MapPinAlt className="h-5 w-5" />
                  <Text size="h4">{guide.region.name}</Text>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 h-10 w-full rounded-t-3xl bg-white"></div>
      </div>
      <div className="web-container">
        <Text size="h1" className="mb-2 font-bold">
          <Trans>Jusqu'à {totalSavings}</Trans>
        </Text>
        <Text size="h3" className="mb-5">
          <Trans>d’économies potentielles avec un pass M ta région actif</Trans>
        </Text>
        <div className="mb-5 flex gap-3">
          <AuthedAction authLevel="logged-in">
            <button className="flex flex-row items-center gap-2 p-0" onClick={openModal}>
              <Heart className="h-6 w-6" stroke="medium-dark" fill="transparent" />
              <Text size="h6">Sauvegarder</Text>
            </button>
          </AuthedAction>
          <Modal open={saveGuideModalOpen} onRequestClose={closeModal}>
            <ModalBody>
              {createWishlist.isSuccess && (
                <div>
                  <Text size="h2" className="mb-4 font-bold">
                    <Trans>Itinéraire sauvegardé!</Trans>
                  </Text>

                  <Text className="mb-4" lineHeight="spaced">
                    <Trans>
                      Vous avez sauvegardé cet itinéraire M ta région dans vos listes de favoris sous le nom{' '}
                      <b>"{newListName}"</b>. Vous pouvez accéder à vos listes à tout moment pour les modifier ou les
                      supprimer.
                    </Trans>
                  </Text>

                  <div className="flex flex-col gap-2">
                    <Button2 variant="secondary" onClick={closeModal}>
                      <Trans>Retourner au guide</Trans>
                    </Button2>

                    <Link2 href="/profile/wishlist" className="flex-grow">
                      <Button2 variant="dark" block>
                        <Trans>Voir mes listes de favoris</Trans>
                      </Button2>
                    </Link2>
                  </div>
                </div>
              )}

              {!createWishlist.isSuccess && (
                <>
                  <Text size="h2" className="mb-4 font-bold">
                    <Trans>Sauvegarder l'itinéraire</Trans>
                  </Text>
                  <Text className="mb-4">
                    <Trans>
                      Choisissez un nom et ajoutez cet itinéraire à vos listes de favoris. Vous pourrez facilement gérer
                      les expériences contenues dans votre nouvelle liste par la suite.
                    </Trans>
                  </Text>
                  <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      await createWishlist.mutateAsync({
                        name: newListName,
                        experiencesIds: experiences.map((e) => e.id),
                      });
                    }}
                  >
                    <InputText2 className="mb-4" value={newListName} onChange={(e) => setNewListName(e.target.value)} />
                    <Button2
                      loading={createWishlist.isLoading}
                      disabled={createWishlist.isLoading}
                      variant="dark"
                      type="submit"
                      className="w-full"
                    >
                      <Trans>Sauvegarder</Trans>
                    </Button2>
                  </form>
                </>
              )}
            </ModalBody>
          </Modal>
          <Share text={guide.meta_description} title={guide.title} url={location.href}>
            <button className="flex flex-row items-center gap-2 p-0">
              <ShareIcon className="h-[24px] w-[24px] text-medium-dark" />
              <Text size="h6">Partager</Text>
            </button>
          </Share>
        </div>

        <div className="mb-10">
          <Text size="h5" lineHeight="spaced">
            {guide.long_description}
          </Text>
        </div>
        <div className="mb-10 h-[450px] overflow-hidden rounded-3xl">{map}</div>

        <div className="mb-5">
          <VerticalsFilter
            limitedVerticals={experiences?.map((exp) => exp?.vertical_id) || []}
            onChange={setSelectedVerticals}
          />
        </div>

        <div className="mb-6 grid grid-cols-2 gap-4 md:grid-cols-4">
          {experiences?.map((exp) => {
            if (!exp) return null;
            if (selectedVerticals.length < 1 || selectedVerticals.includes(exp?.vertical_id)) {
              return <ResultExperienceCard data-testid="guides-page-exp-card" key={exp.id} experience={exp} />;
            }
          })}
        </div>
        <Divider.thin />
        <Text className="mb-10">
          <Trans>
            Vous pensez qu’il manque une expérience à cet itinéraire?{' '}
            <Link2
              className="underline"
              // @check-this
              href={`mailto:${EMAIL_ADDRESS_MEMBERS}}?subject=${encodeURIComponent(
                t`Expérience manquante dans le guide #${guide.id}`
              )}`}
            >
              Écrivez-nous
            </Link2>
          </Trans>
        </Text>
      </div>
    </>
  );
};
