import { Product } from '@milo/types/index';
import { HybridGraphqlClient, useGraphqlClient } from '../useGraphqlClient';
import { useQuery } from '../useQuery';

export const getProducts: (graphqlClient: HybridGraphqlClient) => Promise<Product[]> = async (graphqlClient) => {
  const data = await graphqlClient.request(/* GraphQL */ `
    query ProductsList {
      ProductsList {
        id
        name
        description
        image_url
        price_cents_cad
        base_price
        interval_type
        interval_count
        is_recurring
        special_price_text
        displayed
      }
    }
  `);
  return data?.ProductsList;
};

export const useProducts = () => {
  const { client } = useGraphqlClient();
  const response = useQuery<Product[]>(['ProductsList'], async () => await getProducts(client), {
    staleTime: Infinity,
  });
  return response;
};
