import { colorFn } from '../utils';

function SvgComponent({ fill = colorFn('dark'), ...props }) {
  return (
    <svg viewBox="0 0 16 16" fill="none" {...props}>
      <circle cx={8} cy={8} r={6} stroke={fill} strokeWidth={2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.058 4c-.803 0-1.43.693-1.35 1.493l.25 2.512a1.105 1.105 0 002.2 0l.25-2.512C9.489 4.693 8.862 4 8.059 4zm-.05 8a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
        fill={fill}
      />
    </svg>
  );
}

export { SvgComponent as InfoCircleBold };
