export const FreeVisitModal = () => {
  // Removing the free visit modal for now
  return null;

  // const { subscribed, isLoading, isFetching, user } = useAuthentication();
  // const [hardDismissedTimestamp, setHardDismissedTimestamp] = useLocalStorage('free-visit-modal-hard-dismissed', null);
  // const [open, setOpen] = useState(true);
  // const timeThreshold = 24 * 60 * 60 * 1000 * 7; // 7 days

  // const hideModalFor24h =
  //   hardDismissedTimestamp != null
  //     ? new Date().getTime() - new Date(hardDismissedTimestamp).getTime() <= timeThreshold
  //     : false;
  // if (isLoading || isFetching) {
  //   return null;
  // }

  // const freeVisitModalOpenStateIsTrue = open;
  // const userDoesntHaveAnActiveSubscription = !subscribed;
  // const userDoesntAlreadyHaveAPendingFreeVisit = !Boolean(user?.pending_free_visit?.id);
  // const userAlreadyObtainedAFreeVisit = Boolean(user?.last_used_free_visit?.id);
  // const excludedFromFreeVisitModal = ['/login', '/redeem', '/purchase', '/free-visit'].some((path) =>
  //   window.location.pathname.startsWith(path)
  // );

  // const showModal =
  //   freeVisitModalOpenStateIsTrue &&
  //   !hideModalFor24h &&
  //   userDoesntHaveAnActiveSubscription &&
  //   userDoesntAlreadyHaveAPendingFreeVisit &&
  //   !userAlreadyObtainedAFreeVisit &&
  //   !excludedFromFreeVisitModal;

  // const hardClose = () => {
  //   setHardDismissedTimestamp(new Date());
  //   setOpen(false);
  // };

  // const softClose = () => {
  //   setOpen(false);
  // };

  // return (
  //   <Modal closeOnBackdropClick={false} showCloseButton={false} open={showModal} onRequestClose={hardClose}>
  //     <ModalBody className="flex flex-col space-y-8 justify-center items-center">
  //       <button onClick={softClose} className="absolute -top-2 -right-2 rotate-45 p-5 font-bold text-xl">
  //         +
  //       </button>
  //       <Text className="font-bold" size="h1">
  //         <Trans>Essayez M ta région!</Trans>
  //       </Text>
  //       <Text className="!text-gray text-center" size="h4">
  //         <Trans>
  //           Profitez de votre première réduction M ta région <b>gratuitement</b>.
  //         </Trans>
  //       </Text>
  //       <Link href="/free-visit">
  //         <Button2>
  //           <Trans>Essayer M ta région gratuitement</Trans>
  //         </Button2>
  //       </Link>
  //       <button className="text-medium-gray" onClick={hardClose}>
  //         <Trans>Ne plus afficher</Trans>
  //       </button>
  //     </ModalBody>
  //   </Modal>
  // );
};
