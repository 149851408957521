import classNames from 'classnames';
import { ChangeEventHandler, FormEventHandler, useState } from 'react';

import { t } from '@lingui/macro';
import { ArrowRight, CheckMark, ExclamationTriangle, IconButton, InfoCircle, InputText2 } from '@milo/react';
import { validate as isValidEmail } from 'email-validator';

export const InputSingleFieldForm = ({
  placeholder = '',
  successMessage = t`Opération complétée avec succès!`,
  errorMessage = t`Une erreur s'est produite`,
  validationErrorText = t`Veuillez entrer une adresse courriel valide.`,
  onChange,
  onSubmit,
  initialValue = '',
  onSuccess = () => {},
  onError = () => {},
  disabled = false,
  validationFn = (() => true) as (e: string) => boolean,
  autoFocus = false,
  type = 'text',
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'error' | 'success' | 'loading'>('idle');

  const _disabled =
    submitStatus === 'loading' || disabled || !value || submitStatus === 'success' || validationFn(value) !== true;
  const _formatError = isValidEmail(value) && validationFn(value) !== true ? validationErrorText : '';
  const _isLoading = submitStatus === 'loading';
  const _success = submitStatus === 'success';
  const _error = submitStatus === 'error';

  const _onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      setSubmitStatus('loading');
      await onSubmit(value);
      setSubmitStatus('success');
      await onSuccess?.();
    } catch (error) {
      setSubmitStatus('error');
      await onError?.();
    }
  };

  const _onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const val = e.target.value;
    setValue(val);
    onChange && onChange(val);
  };

  const RenderedIcon = (() => {
    if (_formatError) {
      return <ExclamationTriangle fill="white" className="h-5 w-5 animate-fade-in-fast" />;
    }

    if (_success) {
      return <CheckMark className="h-5 w-5" />;
    }

    return <ArrowRight className="h-5 w-5 animate-fade-in-fast" />;
  })();

  return (
    <>
      <form onSubmit={_onSubmit} className="relative w-full">
        <InputText2
          type={type}
          autoFocus={autoFocus}
          className="!rounded-full !p-3"
          placeholder={placeholder}
          value={value}
          onChange={_onChange}
          {...rest}
        />
        <IconButton
          type="submit"
          loading={_isLoading}
          disabled={_disabled}
          className={classNames('!absolute right-1.5 top-1/2 z-20 flex -translate-y-1/2 rounded-3xl !p-2', {
            'bg-success': submitStatus === 'success',
          })}
          Icon={RenderedIcon}
          data-testid="input-single-field-form-submit-button"
        />
      </form>
      {_formatError && <p className={'animate-fade-in text-center text-lg font-semibold text-error'}>{_formatError}</p>}
      {(_success || _error) && (
        <p className={`${_success && 'text-success'} ${_error && 'text-error'} text-center text-lg font-semibold`}>
          {_success && successMessage}
          {_error && errorMessage}
        </p>
      )}
    </>
  );
};
