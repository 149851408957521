import { ColorType } from 'src/utils/palette';
import { colorFn } from '../utils';

const SvgComponent = ({ fill = 'currentColor' as ColorType, strokeWidth = 2, ...props }) => {
  const _fill = colorFn(fill);

  return (
    <svg viewBox="0 0 13 17" fill="none" {...props}>
      <path
        d="M11.833 6.853c0 2.946-5.333 8-5.333 8s-5.333-5.054-5.333-8a5.333 5.333 0 0 1 10.666 0Z"
        stroke={_fill}
        strokeWidth={1.333}
      />
      <path
        d="M6.5 7.187a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill={_fill}
        stroke={_fill}
        strokeWidth={1}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { SvgComponent as MapPinAlt };
