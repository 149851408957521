import { Trans } from '@lingui/macro';
import { Experience, SavingsTypes } from '@milo/types';
import diacritics from 'diacritics';
import Fuse from 'fuse.js';
import { useMemo, useState } from 'react';
import { ReactFC } from 'src/types';
import { Button2, InputText2, InputToggleGroup, Modal, ModalBody, ModalProps, Text } from '..';
import { useAuthentication } from '../../hooks';
// import { DoubleRangeSlider } from '../Range/doubleRangeSlider';
import {
  getVerticalIdFromEphemeral,
  VerticalId,
  VerticalsFilter,
  verticalsIdsIconMap,
} from '../Search/VerticalsFilter';

export type MapFilterModalProps = ModalProps & {
  experiences: Experience[];
  onApply: (Experiences: Experience[], filters: Filters, activeFiltersCount: number) => void;
  appliedFilters: Filters;
};

type Filters = {
  query: string;
  verticals: VerticalId[];
  savingType: SavingsTypes | null;
  inWishlist: boolean | null;
  visited: boolean | null;
  savingPercentage: number[];
};

const initialVerticals = Array.from({ length: Object.keys(verticalsIdsIconMap).length }, (_, i) => i + 1);

const clearFilterValues: Filters = {
  query: '',
  verticals: initialVerticals,
  savingType: null,
  inWishlist: null,
  visited: null,
  savingPercentage: [0, 100],
};

const calculateSavingPercentageFromMatrix = (exp: Experience) => {
  // the third saving is always the highest percentage
  return (exp.savings_matrix?.['savings3'] / exp.savings_matrix?.['amount3']) * 100;
};

const isBetween = (val, min, max) => val >= min && val <= max;

const filterVisited = (onlyVisited: boolean, visitedId: number, expId: number) =>
  onlyVisited ? visitedId === expId : visitedId != expId;

const filterVerticals = (selectedVerticals, expVertical, expEphemeralVerticals) => {
  const ephemeralVerticals: string[] = selectedVerticals
    .filter((v) => typeof v == 'string')
    .map((v) => getVerticalIdFromEphemeral(v));

  return selectedVerticals?.includes(expVertical) || expEphemeralVerticals?.some((v) => ephemeralVerticals.includes(v));
};

export const MapFilterModal: ReactFC<MapFilterModalProps> = ({
  open,
  onRequestClose,
  experiences,
  onApply,
  appliedFilters,
}) => {
  const { user } = useAuthentication();
  const [resetKey, setResetKey] = useState<number>(0);
  const [filters, setFilters] = useState(appliedFilters ?? clearFilterValues);
  const fuzzy = new Fuse(experiences, {
    isCaseSensitive: false,
    keys: ['name', 'location.city'],
    threshold: 0.2,
    getFn: function () {
      return diacritics.remove(Fuse.config.getFn.apply(this, arguments));
    },
  });

  const internalState = useMemo(() => {
    const expWithQuery = (() => {
      if (!filters.query) return [...experiences];

      const formattedQuery = diacritics.remove(filters.query as string);

      return fuzzy.search(formattedQuery).map(({ item }) => item);
    })();
    const expWithVerticals = expWithQuery.filter((exp) =>
      filterVerticals(filters.verticals, exp.vertical_id, exp.verticals_ephemeral_ids)
    );
    const expInWishList = expWithVerticals.filter((exp) => {
      if (filters.inWishlist == null) return true;
      return exp.in_wishlist == filters.inWishlist;
    });
    const expVisited = expInWishList.filter((exp) => {
      if (filters.visited == null || (filters.visited == false && user?.visited_this_year?.length < 1)) return true;
      return user?.visited_this_year?.some((visitedId) => filterVisited(filters.visited, visitedId, exp.id));
    });
    const expWithSavingType = expVisited.filter((exp) => {
      if (filters.savingType == null) return true;
      return exp.savings_type == filters.savingType;
    });
    const expWithSavingPercentage = expWithSavingType.filter((exp) => {
      if (filters.savingPercentage[0] == 0 && filters.savingPercentage[1] == 100) return true;
      if (exp.savings_percentage)
        return isBetween(exp.savings_percentage, filters.savingPercentage[0], filters.savingPercentage[1]);
      return isBetween(
        calculateSavingPercentageFromMatrix(exp),
        filters.savingPercentage[0],
        filters.savingPercentage[1]
      );
    });
    return expWithSavingPercentage;
  }, [filters]);

  const onClearAll = () => {
    setResetKey((key) => key + 1);
    setFilters(clearFilterValues);
  };
  const compareValue = (val1, val2): boolean => {
    if (Array.isArray(val1) && Array.isArray(val2)) {
      return JSON.stringify(val1.sort()) == JSON.stringify(val2.sort());
    }

    return val1 == val2;
  };
  const countActiveFilters = () => {
    let count = 0;
    Object.keys(filters).forEach((key) => {
      count += compareValue(filters[key], clearFilterValues[key]) ? 0 : 1;
    });
    return count;
  };

  const onApplyInternal = () => {
    onApply?.(internalState, filters, countActiveFilters());
    onRequestClose?.();
  };

  return (
    <Modal closeOnBackdropClick={true} dismissable={true} open={open} onRequestClose={onRequestClose}>
      <ModalBody className="flex cursor-default flex-col items-center justify-center">
        <Text className="mb-5 font-bold" size="h3">
          <Trans>Filtres</Trans>
        </Text>
        {/* header section */}

        {/* filter section */}
        <div className="relative w-full overflow-scroll">
          <div className="mb-10" id="sideScrollerWrapper" key={`verticals${resetKey}`}>
            <Text size="h5" className="mb-3 font-semibold">
              <Trans>Catégories:</Trans>
            </Text>
            <VerticalsFilter
              initialValues={filters.verticals}
              variant="compact"
              onChange={(v) =>
                setFilters((f) => {
                  return { ...f, verticals: v };
                })
              }
            />
          </div>
          <div className="mb-10">
            <Text size="h5" className="mb-3 font-semibold">
              <Trans>Mots clés:</Trans>
            </Text>
            <InputText2
              clearable
              value={filters.query}
              onChange={(e) =>
                setFilters((f) => {
                  return { ...f, query: e.target.value };
                })
              }
            />
          </div>

          {user?.id && (
            <div className="mb-10">
              <Text size="h5" className="mb-3 font-semibold">
                <Trans>Endroits à afficher:</Trans>
              </Text>
              <InputToggleGroup
                key={`visits${resetKey}`}
                value={filters.visited}
                spacing="flex-start"
                onChange={(e) =>
                  setFilters((f) => {
                    return { ...f, visited: e };
                  })
                }
                items={[
                  {
                    value: null,
                    label: (
                      <span className="inline-flex h-8 items-center justify-center px-1 text-[18px] font-bold leading-none">
                        <Trans>Tout</Trans>
                      </span>
                    ),
                  },
                  {
                    value: true,
                    label: (
                      <span className="inline-flex h-8 items-center justify-center px-1 text-[18px] font-bold leading-none">
                        <Trans>Déjà visité</Trans>
                      </span>
                    ),
                  },
                  {
                    value: false,
                    label: (
                      <span className="inline-flex h-8 items-center justify-center px-1 text-[18px] font-bold leading-none">
                        <Trans>Non visité</Trans>
                      </span>
                    ),
                  },
                ]}
              />
            </div>
          )}

          {/* {user?.id && (
            <div className="mb-10">
              <Text size="h5" className="mb-3 font-semibold">
                <Trans>Afficher les favoris?</Trans>
              </Text>
              <InputToggleGroup
                key={`wish${resetKey}`}
                value={filters.inWishlist}
                spacing="flex-start"
                onChange={(e) =>
                  setFilters((f) => {
                    return { ...f, inWishlist: e };
                  })
                }
                items={[
                  {
                    value: null,
                    label: (
                      <span className="inline-flex h-8 items-center justify-center px-1 text-[18px] font-bold leading-none">
                        <Trans>Toutes</Trans>
                      </span>
                    ),
                  },
                  {
                    value: true,
                    label: (
                      <span className="inline-flex h-8 items-center justify-center px-1 text-[18px] font-bold leading-none">
                        <Trans>Oui</Trans>
                      </span>
                    ),
                  },
                  {
                    value: false,
                    label: (
                      <span className="inline-flex h-8 items-center justify-center px-1 text-[18px] font-bold leading-none">
                        <Trans>Non</Trans>
                      </span>
                    ),
                  },
                ]}
              />
            </div>
          )} */}

          {/* <Text size="h5" className="font-semibold mb-2">
            <Trans>Type d'économie:</Trans>
          </Text>
          <InputToggleGroup
            key={`savingType${resetKey}`}
            value={filters.savingType}
            spacing="flex-start"
            onChange={(e) =>
              setFilters((f) => {
                return { ...f, savingType: e };
              })
            }
            items={[
              {
                value: null,
                label: (
                  <span className="inline-flex items-center justify-center text-[18px] leading-none font-bold h-8 px-1">
                    <Trans>Toutes</Trans>
                  </span>
                ),
              },
              {
                value: SavingsTypes['Grid'],
                label: (
                  <span className="inline-flex items-center justify-center text-[18px] leading-none font-bold h-8 px-1">
                    <Trans>Grille</Trans>
                  </span>
                ),
              },
              {
                value: SavingsTypes['TwoForOne'],
                label: (
                  <span className="inline-flex items-center justify-center text-[18px] leading-none font-bold h-8 px-1">
                    <Trans>2 pour 1</Trans>
                  </span>
                ),
              },
              {
                value: SavingsTypes['Percentage'],
                label: (
                  <span className="inline-flex items-center justify-center text-[18px] leading-none font-bold h-8 px-1">
                    %
                  </span>
                ),
              },
            ]}
          />
          <Divider.thin /> */}
          {/* <Text size="h5" className="font-semibold mb-2">
            <Trans>Économie maximale (%):</Trans>
          </Text>
          <DoubleRangeSlider
            key={`DRS${resetKey}`}
            className="mt-6"
            minValue={filters.savingPercentage[0]}
            maxValue={filters.savingPercentage[1]}
            min={0}
            max={100}
            step={5}
            minGap={5}
            withInput
            onChangeMin={(e) =>
              setFilters((f) => {
                return { ...f, savingPercentage: [e, f.savingPercentage[1]] };
              })
            }
            onChangeMax={(e) =>
              setFilters((f) => {
                return { ...f, savingPercentage: [f.savingPercentage[0], e] };
              })
            }
            inputIcon={<div className="font-bold">%</div>}
          /> */}
        </div>

        {/* footer section */}
        <div className="flex w-full flex-col justify-between">
          <Button2 className="mb-2" onClick={onApplyInternal}>
            <Trans>Appliquer</Trans>
            {` (${internalState?.length})`}
          </Button2>
          <button className="underline" onClick={onClearAll}>
            <Trans>Effacer tout</Trans>
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
