import { colorFn, ColorType } from '../utils';

function SvgComponent({ fill = 'currentColor' as ColorType, stroke = 'currentColor' as ColorType, ...props }) {
  const _fill = colorFn(fill);
  const _stroke = colorFn(stroke);

  return (
    <svg viewBox="5 5 22 22" fill="none" {...props}>
      <path
        d="M11 10a1 1 0 0 1 1-1h6.56a1 1 0 0 1 .767.359l1.44 1.725.739-.616-.739.616a1 1 0 0 1 .233.641V22a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V10Z"
        stroke={_stroke}
        strokeWidth={2}
      />
      <rect x={13} y={14} width={6} height={2} rx={1} fill={_stroke} />
      <rect x={13} y={17} width={4} height={2} rx={1} fill={_stroke} />
    </svg>
  );
}
export { SvgComponent as TutorialLinkIcon };
