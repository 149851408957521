import { FC, HTMLAttributes } from 'react';

export const RawHTML: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => {
  return (
    <div className="raw-html">
      <style>{`.raw-html a { text-decoration: underline !important; } `}</style>
      <div dangerouslySetInnerHTML={{ __html: children.toString() }} {...rest} />
    </div>
  );
};
