export const MiloPass = (props) => {
  return (
    <svg viewBox="0 0 237 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M171.304 2.21151C170.045 4.65857 170.045 4.94092 171.117 4.94092C171.817 4.94092 174.94 1.27033 174.94 0.47033C174.94 0.329153 174.381 0.235035 173.635 0.235035C172.609 0.235035 172.19 0.611506 171.304 2.21151Z"
        fill="black"
      />
      <path
        d="M1.20011 1.03512L0.12793 1.22336V12.2822V23.2939H2.45876H4.78958V19.7645V16.2351H7.72643C13.3204 16.2351 16.4437 13.4587 16.4437 8.42336C16.4437 5.64689 15.1851 3.34101 12.9475 2.07042C11.5956 1.31748 3.62417 0.611593 1.20011 1.03512ZM10.57 5.59983C11.549 6.25865 11.7821 6.72924 11.7821 8.47042C11.7821 11.3881 10.57 12.4704 7.26026 12.4704H4.78958V8.42336V4.32924L7.07379 4.56454C8.37906 4.70571 9.9174 5.12924 10.57 5.59983Z"
        fill="black"
      />
      <path
        d="M198.947 1.69409C198.528 3.0588 199.647 4.18821 200.998 3.76468C201.791 3.52939 201.977 3.15291 201.884 2.21174C201.698 0.51762 199.414 0.0940909 198.947 1.69409Z"
        fill="black"
      />
      <path
        d="M88.6995 12.2351V23.2939H89.8649H91.0303L91.1235 14.7292L91.2634 6.16453L94.7596 14.7763C98.0228 22.8704 98.3025 23.341 99.4213 23.1998C100.447 23.0587 100.913 22.2116 103.943 14.8704C105.761 10.3998 107.393 6.58806 107.533 6.44688C107.672 6.30571 107.812 10.0234 107.812 14.7292V23.2939H108.978H110.143V12.2351V1.1763H108.558H106.973L103.337 10.3528C101.286 15.3881 99.5611 19.5292 99.4213 19.5292C99.2814 19.5292 97.4634 15.3881 95.4589 10.3528L91.7295 1.1763H90.2378H88.6995V12.2351Z"
        fill="black"
      />
      <path
        d="M122.73 4.70551C122.73 6.63493 122.636 6.82316 121.564 6.82316C120.632 6.82316 120.399 7.05846 120.399 7.99963C120.399 8.94081 120.632 9.1761 121.564 9.1761H122.73V15.0585C122.73 20.7055 122.776 21.0349 123.895 22.1173C125.34 23.6232 128.79 23.8114 128.79 22.4467C128.79 21.882 128.277 21.4585 127.298 21.1761L125.76 20.7055L125.62 14.9173L125.48 9.1761H127.158C128.603 9.1761 128.79 9.03493 128.79 7.99963C128.79 6.96434 128.603 6.82316 127.158 6.82316C125.527 6.82316 125.527 6.82316 125.527 4.70551C125.527 2.68198 125.48 2.58787 124.128 2.58787C122.776 2.58787 122.73 2.68198 122.73 4.70551Z"
        fill="black"
      />
      <path
        d="M22.2707 6.6823C20.7789 7.10583 19.2406 8.2823 18.4947 9.69406C18.1684 10.3999 17.8421 11.1058 17.8421 11.2941C17.8421 11.8588 21.7113 11.4823 22.3173 10.8235C24.1353 8.98818 27.8647 9.92936 27.5383 12.1411C27.4451 12.9411 27.0256 13.2235 25.7669 13.2705C19.7068 13.6941 17.2361 15.247 17.2361 18.6352C17.2361 20.2823 17.5158 20.8941 18.7278 21.9764C20.7323 23.8117 24.0887 23.8588 26.1865 22.0705L27.6316 20.847V22.0705C27.6316 23.1999 27.7714 23.2941 29.7293 23.2941H31.8271V16.7529C31.8271 10.7764 31.7338 10.1176 30.8015 8.94112C29.1233 6.77641 25.6271 5.83524 22.2707 6.6823ZM27.6316 16.9882C27.6316 19.1999 24.9744 20.847 22.8301 20.047C21.9444 19.6705 21.5714 19.1999 21.5714 18.3999C21.5714 16.8941 22.6902 16.2823 25.4406 16.2352C27.1188 16.2352 27.6316 16.4235 27.6316 16.9882Z"
        fill="black"
      />
      <path
        d="M38.4934 6.68216C37.934 6.77628 36.8152 7.48216 36.0227 8.2351C34.9039 9.2704 34.6242 9.92922 34.6242 11.4351C34.6242 14.2116 35.6964 15.1998 39.9851 16.3763C42.6423 17.1292 43.7611 17.6939 43.8543 18.2586C44.3204 20.5645 40.9641 21.2704 39.0062 19.2939C38.1671 18.4469 37.328 18.1175 36.0227 18.1175C33.9716 18.1175 33.8317 18.4469 34.8573 20.4704C35.8829 22.4469 38.0272 23.4351 41.3836 23.388C44.74 23.388 46.8844 22.4469 47.9099 20.4704C49.4949 17.4116 48.0964 14.8704 44.3671 13.9292C43.2483 13.6469 41.7099 13.2233 40.9641 13.0351C38.0272 12.2822 38.3535 9.64687 41.4302 9.64687C42.5024 9.64687 43.2017 9.92922 43.5746 10.588C44.0407 11.2939 44.6934 11.5292 46.1385 11.5292C48.4693 11.5292 48.6558 10.9175 47.1174 8.94098C45.4392 6.77628 42.3159 5.92922 38.4934 6.68216Z"
        fill="black"
      />
      <path
        d="M53.5039 7.24713C50.8001 8.47066 49.8212 10.9648 50.9866 13.6001C51.5926 14.9177 53.5505 16.0001 56.3009 16.4707C58.3054 16.8471 60.2633 17.8824 60.2633 18.5883C60.2633 19.4354 58.7249 20.4707 57.4197 20.4707C56.5339 20.4707 55.6482 20.0471 54.949 19.2942C54.1099 18.4001 53.4106 18.1177 51.9189 18.1177C49.7745 18.1177 49.7745 18.0707 50.6603 20.1883C52.2918 24.1883 61.1024 24.6589 63.7129 20.8471C65.6242 18.1177 63.946 14.8707 60.2167 13.9295C59.0979 13.6471 57.5595 13.2236 56.8136 13.0354C53.8768 12.2824 54.2031 9.64713 57.2798 9.64713C58.352 9.64713 59.0512 9.92948 59.4242 10.5883C59.8903 11.2942 60.543 11.5295 61.9881 11.5295C64.1791 11.5295 64.3189 11.2001 63.2933 9.2236C61.9881 6.5883 57.0467 5.60007 53.5039 7.24713Z"
        fill="black"
      />
      <path
        d="M73.6974 10.614C72.5319 11.2258 71.8793 11.9788 71.4132 13.3905C70.8071 15.1317 70.8538 15.4611 71.6462 17.1552C73.5109 21.014 78.9184 20.9199 80.5034 17.014C82.368 12.5435 77.9861 8.40228 73.6974 10.614Z"
        fill="#E62152"
      />
      <path
        d="M134.57 7.29387C133.405 7.76446 131.587 9.97622 131.587 10.8703C131.587 11.9527 133.498 11.6233 134.663 10.3527C136.901 7.90563 141.656 9.36446 141.283 12.3762C141.143 13.5998 141.003 13.6468 137.6 13.9292C133.358 14.3056 132.193 14.8703 131.307 17.035C130.421 19.1056 131.074 21.4586 132.799 22.588C134.663 23.8115 137.833 23.6233 139.745 22.1174L141.376 20.8939V22.0703C141.376 23.0586 141.609 23.2939 142.542 23.2939H143.707V16.9409C143.707 10.0233 143.334 8.56446 141.33 7.48211C139.884 6.77622 136.155 6.63505 134.57 7.29387ZM141.376 16.7056C141.376 19.7645 138.02 22.0703 135.223 20.988C133.591 20.3762 133.032 18.4468 134.151 17.1762C135.036 16.188 135.969 15.9056 139.185 15.8115C141.096 15.7645 141.376 15.9056 141.376 16.7056Z"
        fill="black"
      />
      <path
        d="M154.895 15.0116V23.2939H156.293H157.692V17.2234C157.692 11.1998 158.288 10.4412 159.5 9.5C160.199 8.93529 162.12 9 162.82 9C162.82 9 162.82 8.98806 162.82 7.99983C162.82 7.01159 162.82 7.95277 162.82 6.91747C161.934 6.91747 159.199 6.79412 158.5 7.5C157.381 8.62941 157.738 9.08218 157.599 8.04689C157.505 7.341 157.599 7.40995 157.5 6.91747L154.895 6.7763V15.0116Z"
        fill="black"
      />
      <path
        d="M167.481 7.90571C165.104 9.50571 164.032 11.6704 164.032 15.0116C163.985 20.3763 166.502 23.2939 171.164 23.2939C172.516 23.2939 174.194 22.9645 174.94 22.5881C176.199 21.9292 177.737 20.1881 177.737 19.4351C177.737 18.7763 175.033 19.0587 174.8 19.7175C174.474 20.5645 171.723 21.3645 170.138 21.0822C168.553 20.7528 167.108 19.1528 166.735 17.2704L166.456 15.7645H172.376H178.343L178.063 13.6469C177.877 12.4704 177.411 10.8704 177.038 10.0704C175.406 6.87041 170.558 5.78806 167.481 7.90571ZM173.588 9.78806C174.52 10.4939 175.406 12.1881 175.406 13.1292C175.406 13.741 174.707 13.8822 170.978 13.8822C168.181 13.8822 166.549 13.6939 166.549 13.4116C166.549 12.4704 167.947 10.1645 168.74 9.69394C169.859 9.03512 172.702 9.08218 173.588 9.78806Z"
        fill="black"
      />
      <path
        d="M184.496 7.29397C182.072 8.2822 180.534 11.1999 180.534 14.8704C180.534 19.341 182.678 22.3057 186.314 22.6822C188.599 22.9646 191.023 22.2116 191.442 21.1293C191.862 20.0469 192.654 20.3763 192.654 21.5999C192.654 23.1999 191.815 25.2234 190.883 25.9293C189.624 26.9175 185.988 26.7293 184.59 25.6469C183.937 25.1293 182.865 24.7057 182.212 24.7057C181 24.7057 181 24.7528 181.653 26.0704C183.564 29.741 191.163 29.9293 193.959 26.3528C194.938 25.1293 194.985 24.5646 194.985 15.9528V6.82338L192.654 6.87044C192.654 6.5 192.654 7.05867 192.654 8.2822V9.74103L191.34 8.2822C190.035 7.10573 188.972 6.82338 187.387 6.87044C186.314 6.87044 185.009 7.05867 184.496 7.29397ZM190.929 10.5881C191.582 11.341 192.235 12.6116 192.421 13.4116C192.841 15.3881 192.328 17.4116 190.929 19.0587C189.997 20.2351 189.438 20.4704 187.806 20.4704C185.429 20.4704 183.937 19.1057 183.331 16.5175C182.865 14.3057 183.424 11.7175 184.776 10.3057C186.361 8.56456 189.391 8.70573 190.929 10.5881Z"
        fill="black"
      />
      <path
        d="M199.181 15.0116V23.2939H200.579H201.978L201.884 15.1528L201.745 7.05865L200.486 6.91747L199.181 6.7763V15.0116Z"
        fill="black"
      />
      <path
        d="M208.457 7.90575C206.266 9.36457 205.241 11.6705 205.241 14.9646C205.241 19.5763 207.059 22.3999 210.602 23.1999C214.331 23.9999 217.687 22.4469 219.039 19.1999C219.972 16.9881 219.878 12.5646 218.806 10.5881C216.895 6.87046 211.86 5.59987 208.457 7.90575ZM215.683 10.5881C218.433 13.9293 217.501 19.6705 213.958 20.894C210.042 22.3058 206.966 18.494 207.805 13.4116C208.271 10.7763 209.996 9.17634 212.466 9.17634C214.144 9.17634 214.704 9.41163 215.683 10.5881Z"
        fill="black"
      />
      <path
        d="M222.955 15.0587V23.2939H224.354H225.752V17.4116C225.752 11.7645 226 12 226.5 10.5C227.292 9.65294 228.549 9.1763 229.808 9.1763C233.164 9.1763 233.677 10.3057 233.677 17.5998V23.2939H235.122H236.567L236.567 18C236.567 9.27042 234.5 7 230.693 6.82336C229.155 6.82336 228.269 7.10571 227.197 8.04689L225.752 9.27042V8.04689C225.752 7.01159 225.566 6.82336 224.354 6.82336H222.955V15.0587Z"
        fill="black"
      />
    </svg>
  );
};
