function SvgComponent({ ...svgProps }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8 12a8.8 8.8 0 1 1-17.6 0 8.8 8.8 0 0 1 17.6 0Zm2.2 0c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11Zm-9.89 0v.563a.937.937 0 1 1-1.875 0V12c0-1.038.431-1.54 1.474-2.268.567-.397.672-.52.672-.732 0-.698-.338-1.063-1.285-1.063-.644 0-1.019.273-1.203.718a.938.938 0 0 1-1.732-.718c.464-1.119 1.5-1.875 2.935-1.875 2.017 0 3.16 1.236 3.16 2.938 0 1.038-.431 1.54-1.473 2.268-.567.397-.673.52-.673.732Zm-.797 5.625a1.562 1.562 0 1 0 0-3.125 1.562 1.562 0 0 0 0 3.125Z"
        fill="currentColor"
      />
    </svg>
  );
}

export { SvgComponent as QuestionCircle };
