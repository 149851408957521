import { t, Trans } from '@lingui/macro';
import {
  Button2,
  FlowContainer,
  Link2,
  MiloBulletPoints,
  SubscriptionAlreadyActive,
  trackEvent,
  useAuthentication,
  useEffectOnce,
} from '@milo/react';
import config from '../../config';
import { MiloFull } from '../../logos';
import { AlreadyHasFreeVisit, AlreadyUsedFreeVisit } from './Base';

export const FreeVisitLandingPage = () => {
  const { subscribed, isLoading, user } = useAuthentication();

  useEffectOnce(() => trackEvent('free_trial_page_view', { user_id: user?.id }));

  if (isLoading) return null;

  if (subscribed) {
    return (
      <FlowContainer>
        <SubscriptionAlreadyActive title={t`Pas besoin d'obtenir une visite gratuite 🙂`} />
      </FlowContainer>
    );
  }

  const pendingFreeVisit = user?.pending_free_visit;
  if (pendingFreeVisit?.id) {
    return (
      <FlowContainer>
        <AlreadyHasFreeVisit visit={pendingFreeVisit} />
      </FlowContainer>
    );
  }

  const usedFreeVisit = user?.last_used_free_visit;
  if (usedFreeVisit?.id) {
    return (
      <FlowContainer>
        <AlreadyUsedFreeVisit visit={usedFreeVisit} />
      </FlowContainer>
    );
  }

  return (
    <FlowContainer>
      <MiloFull className="mx-auto mb-8 w-40 lg:hidden" />

      <h2 className="mb-10 text-center text-4xl font-bold">
        <Trans>Prêt·es à vivre de nouvelles aventures locales tout en économisant?</Trans>
      </h2>

      <div className="rounded-3xl border-[0.5px] px-6 py-6 shadow-lg">
        <h2 className="text-center text-3xl font-bold">
          <Trans>Le pass M ta région</Trans>
        </h2>

        <img className="mx-auto mb-10" src={`https://${config.APP_DOMAIN}/images/exp-showcase.png`} />

        <div className="mb-10">
          <MiloBulletPoints />
        </div>

        <h2 className="mb-10 text-center text-xl font-semibold text-gray-500">
          <Trans>
            Imaginez économiser des centaines de dollars chaque mois tout en explorant de nouvelles aventures. Alors,
            qu'attendez-vous pour essayer M ta région gratuitement dès maintenant?
          </Trans>
        </h2>

        <div className="mb-8 text-center">
          <Link2 href="/free-visit/profile">
            <Button2 size="md">
              <Trans>Je profite de ma réduction-découverte!</Trans>
            </Button2>
          </Link2>
        </div>
      </div>
    </FlowContainer>
  );
};
