import { t, Trans } from '@lingui/macro';
import {
  AppStoreDownload,
  APP_STORE_LINK,
  GooglePlayDownload,
  GOOGLE_STORE_LINK,
  Link2,
  ReactFC,
  useDeviceInfo,
} from '@milo/react';
import { Platform } from '@milo/types';

type AppDownloadProps = {};

export const AppDownload: ReactFC<AppDownloadProps> = () => {
  const { platform } = useDeviceInfo();

  const IOS = () => (
    <Link2
      // onClickCapture={() => analytics.track('click', { target: 'ios_button' })}
      href={APP_STORE_LINK}
    >
      <AppStoreDownload className="h-14" />
    </Link2>
  );

  const Android = () => (
    <Link2
      // onClickCapture={() => analytics.track('click', { target: 'android_button' })}
      href={GOOGLE_STORE_LINK}
    >
      <GooglePlayDownload className="h-14" />
    </Link2>
  );

  const Rendered = () => {
    if (platform === Platform.Android) {
      return <Android />;
    }

    if (platform === Platform.Ios) {
      return <IOS />;
    }

    return (
      <>
        <IOS />
        <Android />
      </>
    );
  };

  return (
    <div className="flex flex-wrap justify-center gap-2">
      <Rendered />
    </div>
  );
};

export const AppDownloadPage = () => {
  const altImg = t`Écran de téléphone qui présentes l'application M ta région`;

  return (
    <>
      <h1 className="my-20 text-center text-5xl font-bold">
        <Trans>Téléchargement de l'application mobile</Trans>
      </h1>
      <div className="web-container  flex flex-col items-center justify-around lg:flex-row">
        <img alt={altImg} className="mx-auto hidden w-3/5 lg:block" src="/images/phone-hero.png" />

        <div className="flex flex-col items-center gap-2 text-center">
          <h2 className="mb-6 text-4xl font-semibold">
            <Trans>M ta région</Trans>
          </h2>

          <p className="mb-10 text-xl">
            <Trans>
              L’outil idéal pour les explorateurs qui veulent découvrir la culture, le tourisme et le terroir québécois
              à travers près de 400 expériences locales attentivement sélectionnées.
              <br />
              <br />
              Utilisez votre pass M ta région pour obtenir des réductions-découvertes allant de 15% à 50%!
            </Trans>
          </p>

          <AppDownload />
        </div>

        <img alt={altImg} className="mx-auto my-12 block max-w-xs lg:hidden" src="/images/phone-static.png" />
      </div>
    </>
  );
};
