import React, { useCallback, useEffect, useState } from 'react';

type OptionsProp = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | unknown) => void;
  transform?: (val: string) => string;
};

export const useFormState = <T>(_initialState: T) => {
  const [initialState, _setInitialState] = useState<T>(_initialState);
  const [values, setValues] = useState<T>(_initialState);
  const [dirty, setDirtyItems] = useState<Set<keyof T>>(new Set<keyof T>());
  const [touched, setTouchedItems] = useState<Set<keyof T>>(new Set<keyof T>());

  const isDirty = dirty.size > 0;
  const isTouched = touched.size > 0;

  const resetState = () => {
    setDirtyItems(new Set());
    setTouchedItems(new Set());
  };

  const setValue = useCallback(
    (name: keyof T, value: unknown, makeTouched: boolean = true, makeDirty: boolean = true) => {
      setValues((state) => ({
        ...state,
        [name]: value,
      }));

      makeTouched && setTouchedItems((state) => state.add(name));

      if (makeDirty) {
        if (initialState?.[name]) {
          if (typeof value === 'object') {
            if (JSON.stringify(value) === JSON.stringify(initialState[name])) {
              setDirtyItems((state) => {
                state.delete(name);
                return state;
              });
            } else {
              setDirtyItems((state) => state.add(name));
            }
          } else {
            if (value === initialState[name]) {
              setDirtyItems((state) => {
                state.delete(name);
                return state;
              });
            } else {
              setDirtyItems((state) => state.add(name));
            }
          }
        } else {
          if (value === '' || value === false) {
            setDirtyItems((state) => {
              state.delete(name);
              return state;
            });
          } else {
            setDirtyItems((state) => state.add(name));
          }
        }
      }
    },
    [initialState]
  );

  const setInitialState = useCallback((state: T) => {
    _setInitialState(state);
    setValues(state);
    setDirtyItems(new Set());
    setTouchedItems(new Set());
  }, []);

  const clear = useCallback((name: string) => {
    setValues((state) => ({
      ...state,
      [name]: '',
    }));

    setDirtyItems(new Set());
    setTouchedItems(new Set());
  }, []);

  const text = (name: keyof T, options: OptionsProp = {}) => {
    const { onChange, transform = (val: string) => val } = options;

    return {
      type: 'text',
      name,
      get value() {
        return (values?.[name] as string) ?? '';
      },
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(name, transform(event.target.value));
        onChange && onChange(event);
      },
    };
  };

  const select = (name: keyof T, options: OptionsProp = {}) => {
    const { onChange, transform = (val: string) => val } = options;

    return {
      name,
      get value() {
        return (values?.[name] as string) ?? '';
      },
      onChange: (event: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(name, transform(event.target.value));
        onChange && onChange(event);
      },
    };
  };

  const password = (name: keyof T, options: OptionsProp = {}) => {
    const { onChange, transform = (val: string) => val } = options;

    return {
      type: 'password',
      name,
      get value() {
        return values?.[name] ?? '';
      },
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(name, transform(event.target.value));
        onChange && onChange(event);
      },
    };
  };

  const hidden = (name: keyof T, options: OptionsProp = {}) => {
    const { onChange, transform = (val: string) => val } = options;

    return {
      type: 'hidden',
      name,
      get value() {
        return values?.[name] ?? '';
      },
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(name, transform(event.target.value));
        onChange && onChange(event);
      },
    };
  };

  const number = (name: keyof T, options: OptionsProp = {}) => {
    const { onChange } = options;

    return {
      type: 'number',
      name,
      get value() {
        return Number(values?.[name]) || undefined;
      },
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = Number(event.target.value);

        if (isNaN(val)) setValue(name, undefined);
        else setValue(name, val);

        onChange && onChange(event);
      },
    };
  };

  const email = (name: keyof T, options: OptionsProp = {}) => {
    const { onChange } = options;

    return {
      type: 'email',
      name,
      get value() {
        return values?.[name] ?? '';
      },
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(name, event.target.value);
        onChange && onChange(event);
      },
    };
  };

  const radio = (name: keyof T, value: T, options: OptionsProp = {}) => {
    const { onChange } = options;
    return {
      name,
      value,
      get checked() {
        return values?.[name] === value;
      },
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(name, event.target.value);
        onChange && onChange(event);
      },
    };
  };

  const checkbox = (name: keyof T, options: OptionsProp = {}) => {
    const { onChange } = options;

    return {
      name,
      get checked() {
        return !!values?.[name] ?? false;
      },
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(name, event.target.checked);

        onChange && onChange(event);
      },
    };
  };

  const checkboxArray = (name: keyof T, value: string, options: OptionsProp = {}) => {
    const { onChange } = options;

    return {
      name,
      value,
      get checked() {
        return ((values?.[name] as Array<string>) ?? []).includes(value);
      },
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        const copy = Object.assign([] as string[], values?.[name]);

        if (event.target.checked) {
          copy.push(value);
        } else {
          const index = copy.indexOf(value);

          if (index > -1) {
            copy.splice(index, 1);
          }
        }

        setValue(name, copy);

        onChange && onChange(event);
      },
    };
  };

  // This one is always going to return the raw event values
  // of a component so you can use it with wathever you want.
  const raw = (name: keyof T, options: OptionsProp = {}) => {
    const { onChange } = options;
    return {
      name,
      get value() {
        return values?.[name];
      },
      onChange: (value: unknown) => {
        setValue(name, value);
        onChange && onChange(value);
      },
    };
  };

  return [
    values,
    {
      // Input types methods
      text,
      password,
      hidden,
      number,
      email,
      radio,
      checkbox,
      checkboxArray,
      select,
      raw,

      // Direct access methods
      setValue,
      clear,
      setInitialState,
      dirty,
      touched,

      resetState,

      // Form states
      isDirty,
      isTouched,
    },
  ] as const;
};
