import { t } from '@lingui/macro';
import classNames from 'classnames';
import { FC, SelectHTMLAttributes } from 'react';
import { Chevron } from '../../icons/Chevron';

type InputSelectItem = {
  label: string;
  value: any;
};

type SelectExtraProps = {
  error?: boolean;
  success?: boolean;
  showPlaceholder?: boolean;
  items?: InputSelectItem[];
  onValueChange?: (e: any) => any;
  containerClassNames?: string;
};

export const baseSelectClassNames = ({ error = false, success = false }: Pick<SelectExtraProps, 'error' | 'success'>) =>
  classNames(
    'outline-none disabled:bg-white shadow-input bg-white transition disabled:shadow-none placeholder-medium-gray text-medium-dark w-full leading-5 border px-3 p-2 rounded disabled:border-gray-lighter appearance-none',
    {
      'border-error focus-within:border-error': error,
      'border-success focus-within:border-success': success,
      'border-gray-light focus-within:border-medium-dark': !error && !success,
    }
  );

export type InputSelect2Props = SelectHTMLAttributes<HTMLSelectElement> & SelectExtraProps;

export const InputSelect2: FC<InputSelect2Props> = ({
  className = '',
  error = false,
  success = false,
  items = [],
  placeholder,
  showPlaceholder = true,
  onValueChange,
  containerClassNames = '',
  onChange = () => {},
  ...rest
}) => {
  const selectClassName = classNames(className, baseSelectClassNames({ error, success }));

  return (
    <div className={`relative w-full ${containerClassNames}`}>
      <select
        className={selectClassName}
        onChange={(e) => (onValueChange ? onValueChange(e.target.value) : onChange(e))}
        {...rest}
      >
        {showPlaceholder && <option value="">{placeholder || t`Choose Option...`}</option>}
        {items.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      <Chevron className="pointer-events-none absolute right-3 top-2 h-3 w-3 translate-y-1/2 rotate-90" />
    </div>
  );
};
