import { colorFn, ColorType } from '../utils';

function SvgComponent({ fill = 'currentColor' as ColorType, strokeWidth = 2, ...props }) {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 8.5L4.20142 11.7014C4.62589 12.1259 5.32607 12.0834 5.69603 11.6106L14 1"
        stroke={_fill}
        strokeWidth={strokeWidth || '2'}
        strokeLinecap="round"
      />
    </svg>
  );
}

export { SvgComponent as CheckMark };
