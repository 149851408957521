import { ArrowLeft, ArrowRight } from '@milo/react/src/icons';
import cn from 'classnames';
import { debounce } from 'lodash';
import { Children, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { useDeviceInfo } from '../../hooks';
import { ReactFC } from '../../types';
import { zIndexFn } from '../../utils';

type ButtonsPositions = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';

export type SideScrollerProps = HTMLAttributes<HTMLDivElement> & {
  itemsGap?: number;
  buttonsPosition?: ButtonsPositions;
  width?: number;
  containerAlignment?: 'center' | 'left' | 'right';
};

export const SideScroller: ReactFC<SideScrollerProps> = ({
  className,
  children,
  itemsGap = 1,
  containerAlignment = 'center',
  buttonsPosition = 'top-right',
  width,
}) => {
  const { isMouseInput } = useDeviceInfo();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const baseContainerRef = useRef<HTMLDivElement>(null);
  const elementsWrapperRef = useRef<HTMLDivElement>(null);
  const [containerSmallerThanContent, setContainerSmallerThanContent] = useState(false);

  const gap = itemsGap * 12;
  const bottom = buttonsPosition.includes('top') ? 'calc(100% + 3px)' : 'initial';
  const top = buttonsPosition.includes('bottom') ? 'calc(100% + 3px)' : 'initial';
  const left = buttonsPosition.includes('center') ? '50%' : 'initial';
  const right = buttonsPosition.includes('right') ? '0' : 'initial';
  const transform = buttonsPosition.includes('center') ? 'translate(-50%,0)' : 'initial';
  const containerWidth = width;

  const scrollLeft = () => {
    scrollContainerRef.current?.scrollBy({
      left: -elementsWrapperRef?.current?.childNodes?.[0]?.offsetWidth,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    scrollContainerRef.current?.scrollBy({
      left: elementsWrapperRef?.current?.childNodes?.[0]?.offsetWidth,
      behavior: 'smooth',
    });
  };

  const resizeHandler = debounce((wrapperWidth) => {
    const containerIsSmallerThanCotnent =
      scrollContainerRef.current?.scrollWidth > baseContainerRef.current?.clientWidth;
    setContainerSmallerThanContent(containerIsSmallerThanCotnent);
  }, 250);

  useEffect(() => {
    const sideScrollerWrapper = document.getElementById('sideScrollerWrapper');
    window.addEventListener('resize', () => resizeHandler(sideScrollerWrapper?.clientWidth));
    resizeHandler(sideScrollerWrapper?.clientWidth);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [Children.count(children)]);

  return (
    <div className={cn('relative w-full', className)} ref={baseContainerRef}>
      {isMouseInput && containerSmallerThanContent && (
        // {isMouseInput && windowSmallerThanContainer && (
        <div
          className="absolute flex animate-fade-in gap-2 text-white"
          style={{ bottom, top, left, right, transform, zIndex: zIndexFn('navigation', -1) }}
        >
          <button onClick={scrollLeft} className="h-7 w-7 cursor-pointer rounded-full bg-charcoal/70 p-2">
            <ArrowLeft className="w-full" />
          </button>
          <button onClick={scrollRight} className="h-7 w-7 cursor-pointer rounded-full bg-charcoal/70 p-2">
            <ArrowRight className="w-full" />
          </button>
        </div>
      )}

      <div className="side-scroll" ref={scrollContainerRef}>
        <div
          onClick={(e) =>
            (e.target as HTMLDivElement).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
          }
          ref={elementsWrapperRef}
          className={cn('flex w-fit', {
            'mx-auto': containerAlignment === 'center',
            'ml-auto': containerAlignment === 'right',
            'mr-auto': containerAlignment === 'left',
          })}
          style={{ gap, width: containerWidth }}
        >
          {Children.map(children, (child, i) => (
            <span className="shrink-0" key={`slide-${i}`}>
              {child}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
