import { useState } from 'react';
import { Chevron } from '../../icons';
import { ReactFC } from '../../types';

export const Accordion: ReactFC<{ heading: string }> = ({ heading, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <details className="rounded-lg border shadow-md">
      <summary
        className="relative cursor-pointer list-none p-4 text-xl font-semibold"
        onClick={() => setOpen((o) => !o)}
      >
        {heading}
        <Chevron
          className={`absolute right-3 top-1/2 w-6 -translate-y-1/2 text-gray-400 transition-all duration-300 
          ${open ? '-rotate-90' : 'rotate-90'}`}
        />
      </summary>

      {open && <div className="animate-fade-in-top p-4 pt-0">{children}</div>}
    </details>
  );
};
