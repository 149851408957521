import { Trans } from '@lingui/macro';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { StepperProps } from '.';
import { useIsomorphicLayoutEffect } from '../../hooks';
import { Button2 } from '../Button';
import { StepperContext } from './StepperContext';

export type BasicStepperProps = StepperProps & {
  showLogo?: boolean;
};

export const BasicStepper: FC<BasicStepperProps> = (props) => {
  const { initialState, initialStep, navigate, steps = {}, stepsCount } = props;
  const [currentStep, setCurrentStep] = useState(initialStep);
  const DisplayedComponent = useMemo(() => steps[currentStep]?.Component, [currentStep]);
  const stepsHistory = useRef<string[]>([currentStep]);
  const [stepperState, setStepperState] = useState();

  useEffect(() => {
    setStepperState((state: any) => ({ ...state, ...initialState }));
  }, [initialState]);

  const set = (key, value) => {
    setStepperState((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const setStateObject = (obj) => {
    setStepperState((state) => ({
      ...state,
      ...obj,
    }));
  };

  const changeStep = (step: string) => {
    stepsHistory.current.push(step);
    setCurrentStep(step);
  };

  const back = () => {
    setCurrentStep(stepsHistory.current.slice(-2)[0]);
    stepsHistory.current.pop();
  };

  const displayBack = steps[currentStep]?.displayBackButton;

  useIsomorphicLayoutEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
    });
  }, [currentStep]);

  // Necessary when we change a step links to itself with new query params
  useEffect(() => {
    setCurrentStep(initialStep);
  }, [initialStep]);

  return (
    <div data-component={BasicStepper.name} style={{ minHeight: 'calc(100vh - 80px)' }} className="animate-fade-in">
      <div className="ml-auto mr-auto max-w-[700px]">
        <StepperContext.Provider
          value={{ setState: setStepperState, setStep: setCurrentStep, step: currentStep, state: stepperState }}
        >
          {DisplayedComponent && (
            <DisplayedComponent
              {...{
                back,
                changeStep,
                currentStep,
                navigate,
                setState: set,
                setStateObject,
                state: stepperState,
              }}
            />
          )}
          {displayBack && (
            <Button2 onClick={back} variant="secondary">
              <Trans>Retour</Trans>
            </Button2>
          )}
        </StepperContext.Provider>
      </div>
    </div>
  );
};
