import React, { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIsomorphicLayoutEffect } from '../../hooks/useIsomorphicLayoutEffect';
import { MiloRoundLogo } from '../../logos';
import { ReactFC } from '../../types';
import { ModalContext } from '../Modal';
import { StepperContext } from './StepperContext';

export type StepProps = {
  back?: () => void;
  changeStep: (x: string) => void;
  currentStep: string;
  setState: (key: string, value: any) => void;
  state: Record<string, any>;
  navigate: (any) => any;
};

export type StepperSteps = Record<
  string,
  {
    Component: ReactFC<StepProps>;
    title?: string;
    displayBackButton?: boolean;
    displayCloseButton?: boolean;
    displaySteps?: boolean;
    showHeaderLogo?: boolean;
    fullWidth?: boolean;
  }
>;

export type StepperProps = {
  currentStep?: number;
  initialState?: Record<string, any>;
  initialStep?: string;
  steps: StepperSteps;
  stepsCount?: number;
  fitContent?: boolean;
  hasMinHeight?: boolean;
  title?: ReactNode;
};

// TODO - Use react lazy to load the right router depending on the context...
// Using that for now...
const navigate = (url) => {
  window.location = url;
};

export const StepperModal: FC<StepperProps> = ({
  initialStep,
  steps = {},
  initialState = {},
  fitContent = false,
  title,
}) => {
  const container = useRef();
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [stepperState, setStepperState] = useState(initialState);

  useEffect(() => {
    setCurrentStep(initialStep);
  }, [initialStep]);

  const stepsHistory = useRef<string[]>([currentStep]);
  const { setOnBack: setModalOnBack, setTitle, setShowCloseBtn, onClose } = useContext(ModalContext);

  const displayBack = steps[currentStep]?.displayBackButton;
  const displayCloseButton = steps[currentStep]?.displayCloseButton ?? true;
  const DisplayedComponent = steps[currentStep]?.Component;
  const stepTitle = steps[currentStep]?.title ?? title;

  const set = (key, value) => {
    setStepperState((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const changeStep = (step: string) => {
    stepsHistory.current.push(step);
    setCurrentStep(step);
  };

  const back = useCallback(() => {
    setCurrentStep(stepsHistory.current.slice(-2)[0]);
    stepsHistory.current.pop();
  }, []);

  // const showHeaderLogo = steps[currentStep]?.showHeaderLogo;
  const showHeaderLogo = false;

  useEffect(() => {
    if (setModalOnBack) {
      setModalOnBack(displayBack ? () => back : undefined);
    }
  }, [displayBack, back]);

  useEffect(() => {
    setTitle(stepTitle);
  }, [stepTitle, setTitle]);

  useEffect(() => {
    setShowCloseBtn(displayCloseButton);
  }, [setShowCloseBtn, displayCloseButton]);

  useIsomorphicLayoutEffect(() => {
    container?.current?.scrollTo(0);
  }, [currentStep]);

  return (
    <div className="minw relative w-screen max-w-2xl animate-fade-in rounded-t-xl bg-white p-8 sm:rounded-b-xl">
      {showHeaderLogo && <MiloRoundLogo width="40" className="m-auto mb-5" />}
      <StepperContext.Provider
        value={{ setState: setStepperState, setStep: setCurrentStep, step: currentStep, state: stepperState }}
      >
        {DisplayedComponent && (
          <DisplayedComponent
            {...{
              close: onClose,
              steps,
              back,
              changeStep,
              currentStep,
              navigate,
              setState: set,
              state: stepperState,
            }}
          />
        )}
      </StepperContext.Provider>
    </div>
  );
};
