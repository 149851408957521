import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Fullscreen, Pause, Play } from '../../icons';
import { ReactFC, ReactHTMLElementProps } from '../../types';

type VideoProps = ReactHTMLElementProps<HTMLVideoElement> & {
  showPlayIcon?: boolean;
  showPauseIcon?: boolean;
  Icon?: ReactFC;
  src: string;
  poster?: string;
  onStatusChange?: (status: VideoStatus) => void;
};

export type VideoStatus = 'playing' | 'paused' | 'stopped';

const VideoRenderFunction: ReactFC<VideoProps> = (
  { onStatusChange = () => {}, showPlayIcon = true, showPauseIcon = true, src = '', poster = '', className, ...props },
  ref
) => {
  const [status, setStatus] = useState<VideoStatus>('stopped');
  const videoRef = useRef<HTMLVideoElement>(null);

  useImperativeHandle(ref, () => ({
    play: () => {
      videoRef.current.play();
    },
    pause: () => {
      videoRef.current.pause();
    },
    requestFullscreen: (e) => {
      openFullscreen();
    },
  }));

  const openFullscreen = () => {
    videoRef?.current?.requestFullscreen?.() ||
      videoRef.current?.mozRequestFullScreen?.() ||
      videoRef.current?.webkitRequestFullscreen?.() ||
      videoRef.current?.msRequestFullscreen?.();
  };

  const togglePlayPause = () => {
    if (!document.fullscreenElement) {
      videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause();
    }
  };

  const changeStatus = (status: VideoStatus) => {
    setStatus(() => {
      onStatusChange(status);
      return status;
    });
  };

  return (
    <div className={`${className} group relative`}>
      <video
        controls={false}
        ref={videoRef}
        onPlay={() => changeStatus('playing')}
        onPause={() => changeStatus('paused')}
        onEnded={() => changeStatus('stopped')}
        onClick={togglePlayPause}
        src={src}
        poster={poster}
        className={`w-full`}
        {...props}
      />
      {showPlayIcon && (
        <div
          onClick={togglePlayPause}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
        >
          {status !== 'playing' && <Play fill="white" className="h-16 w-16" />}
        </div>
      )}
      {showPauseIcon && (
        <div
          onClick={togglePlayPause}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
        >
          {status === 'playing' && (
            <Pause
              fill="white"
              className="h-16 w-16 opacity-0 transition-opacity duration-500 group-hover:opacity-100"
            />
          )}
        </div>
      )}
      <Fullscreen
        onClick={openFullscreen}
        fill="white"
        className="absolute bottom-2 right-3 h-6 w-6 cursor-pointer opacity-0 transition-opacity duration-500 group-hover:opacity-100"
      />
    </div>
  );
};

export const Video = forwardRef(VideoRenderFunction);
