import { colorFn, ColorType } from '../utils';

function SvgComponent({ fill = 'currentColor' as ColorType, ...props }) {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill={_fill}
        d="M0.331276 8.75995L8.39276 15.8307C8.86203 16.2168 9.57986 16.2168 10.0217 15.8307C10.4634 15.4446 10.4634 14.7931 10.0217 14.4069L2.76081 8.06015L10.0217 1.7134C10.4634 1.30321 10.4634 0.675747 10.0217 0.289576C9.57994 -0.0965254 8.86213 -0.0965254 8.39276 0.289576L0.331276 7.33617C-0.110434 7.74636 -0.110434 8.37378 0.331276 8.75995Z"
      />
    </svg>
  );
}

export { SvgComponent as BasicArrowLeft };
