import { ImageKitImage } from '@milo/react';
import { Experience, User } from '@milo/types';
import { FC } from 'react';

type HomeTestimonialProps = {
  experience?: Pick<Experience, 'main_image' | 'name' | 'location' | 'ratings'>;
  user?: Pick<User, 'first_name' | 'last_name' | 'city' | 'province'> & { avatar?: string };
};

export const HomeTestimonial: FC<HomeTestimonialProps> = ({ experience, user, children }) => {
  const src = experience?.main_image || user?.avatar;

  return (
    <div
      className="flex flex-col justify-between items-center max-h-[425px] h-screen p-5 rounded-3xl shrink-0 w-80 my-5"
      style={{ boxShadow: 'rgb(0 0 0 / 12%) 0px 0px 16px' }}>
      {src && <ImageKitImage src={src} width={96} height={96} />}
      <p>“ {children} ”</p>
      <div className="flex flex-col items-center">
        <div className="mb-2">
          <p className="text-center font-bold">
            {user ? `${user.first_name} ${user.last_name}` : experience ? experience.name : 'Inconnu'}
          </p>
          {user && (
            <p className="text-center font-bold">
              {user.city}, {user.province}
            </p>
          )}
        </div>
        {!!experience?.ratings && (
          <div className="flex items-center">
            <div className="flex items-center gap-2">
              <p className="py-1 px-2.5 bg-gray-lighter rounded-lg">
                <span className="text-sm font-bold leading-none">{experience?.ratings.great}</span>
              </p>
              😍
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
