import { Trans } from '@lingui/macro';
import { TutorialLayout, TutorialStep } from './base';

export const ExperiencesTutorialPage = () => {
  return (
    <TutorialLayout title={<Trans>Trouver des expériences</Trans>}>
      <TutorialStep>
        <p className="mb-5 leading-[22px] text-dark">
          <Trans>
            Utilisez les catégories et filtrer par région pour trouver facilement et rapidement les expériences qui vous
            intéressent.
          </Trans>
        </p>
        <div className="overflow-hidden">
          <video autoPlay loop muted playsInline className="relative -top-1 -bottom-5 mx-auto w-full">
            <source type="video/mp4" src="/images/tutorials/ill-trouver-une-experience.mp4" />
          </video>
        </div>
      </TutorialStep>
    </TutorialLayout>
  );
};
