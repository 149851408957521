'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var PurchaseMode = /* @__PURE__ */ ((PurchaseMode2) => {
  PurchaseMode2["Self"] = "self";
  PurchaseMode2["Offer"] = "offer";
  return PurchaseMode2;
})(PurchaseMode || {});
var SavingsTypes = /* @__PURE__ */ ((SavingsTypes2) => {
  SavingsTypes2["Grid"] = "grid";
  SavingsTypes2["Percentage"] = "percentage";
  SavingsTypes2["TwoForOne"] = "two_for_one";
  return SavingsTypes2;
})(SavingsTypes || {});
var ExperienceStatus = /* @__PURE__ */ ((ExperienceStatus2) => {
  ExperienceStatus2["Quit"] = "quit";
  ExperienceStatus2["Prospect"] = "prospect";
  ExperienceStatus2["QualifiedProspect"] = "qualified_prospect";
  ExperienceStatus2["InCreation"] = "in_creation";
  ExperienceStatus2["Online"] = "online";
  ExperienceStatus2["DeclinedProspect"] = "declined_prospect";
  ExperienceStatus2["Declined"] = "declined";
  return ExperienceStatus2;
})(ExperienceStatus || {});
var ParentEntityType = /* @__PURE__ */ ((ParentEntityType2) => {
  ParentEntityType2["Experience"] = "experience";
  ParentEntityType2["Group"] = "group";
  ParentEntityType2["User"] = "user";
  return ParentEntityType2;
})(ParentEntityType || {});
var MailingListIds = /* @__PURE__ */ ((MailingListIds2) => {
  MailingListIds2["All"] = "all";
  MailingListIds2["Discoveries"] = "discoveries";
  MailingListIds2["Escapades"] = "escapades";
  MailingListIds2["Promotions"] = "promotions";
  return MailingListIds2;
})(MailingListIds || {});
var PartnerType = /* @__PURE__ */ ((PartnerType2) => {
  PartnerType2["Prospect"] = "prospect";
  PartnerType2["Legacy"] = "legacy";
  return PartnerType2;
})(PartnerType || {});
var ProductType = /* @__PURE__ */ ((ProductType2) => {
  ProductType2["Recurring"] = "recurring";
  ProductType2["Prepaid"] = "prepaid";
  return ProductType2;
})(ProductType || {});
var ProductInterval = /* @__PURE__ */ ((ProductInterval2) => {
  ProductInterval2["Day"] = "day";
  ProductInterval2["Week"] = "week";
  ProductInterval2["Month"] = "month";
  ProductInterval2["Year"] = "year";
  return ProductInterval2;
})(ProductInterval || {});
var ProductId = /* @__PURE__ */ ((ProductId2) => {
  ProductId2["TwoMonthNonRecurring"] = "two_month_non_recurring";
  ProductId2["ThreeMonthNonRecurring"] = "three_month_non_recurring";
  ProductId2["SixMonthNonRecurring"] = "six_month_non_recurring";
  ProductId2["TwelveMonthNonRecurring"] = "twelve_month_non_recurring";
  ProductId2["MiloPassOneMonth"] = "milo_pass_one_month";
  ProductId2["MiloPassOneWeek"] = "milo_pass_one_week";
  ProductId2["OneMonthRecurring"] = "one_month_recurring";
  ProductId2["MiloPassOneYear"] = "milo_pass_one_year";
  return ProductId2;
})(ProductId || {});
var SubscriptionPlan = /* @__PURE__ */ ((SubscriptionPlan2) => {
  SubscriptionPlan2["Monthly"] = "monthly";
  return SubscriptionPlan2;
})(SubscriptionPlan || {});
var SubscriptionType = /* @__PURE__ */ ((SubscriptionType2) => {
  SubscriptionType2["Member"] = "member";
  return SubscriptionType2;
})(SubscriptionType || {});
var SubscriptionStatus = /* @__PURE__ */ ((SubscriptionStatus2) => {
  SubscriptionStatus2["Active"] = "active";
  SubscriptionStatus2["Inactive"] = "inactive";
  return SubscriptionStatus2;
})(SubscriptionStatus || {});
var PromotionalPartnerStatus = /* @__PURE__ */ ((PromotionalPartnerStatus2) => {
  PromotionalPartnerStatus2["Active"] = "active";
  PromotionalPartnerStatus2["Inactive"] = "inactive";
  return PromotionalPartnerStatus2;
})(PromotionalPartnerStatus || {});
var PromotionalPartnerIds = /* @__PURE__ */ ((PromotionalPartnerIds2) => {
  PromotionalPartnerIds2["Caa"] = "caa";
  return PromotionalPartnerIds2;
})(PromotionalPartnerIds || {});
var UserStatus = /* @__PURE__ */ ((UserStatus2) => {
  UserStatus2["NonUser"] = "non_user";
  UserStatus2["User"] = "user";
  UserStatus2["Member"] = "member";
  return UserStatus2;
})(UserStatus || {});
var Gender = /* @__PURE__ */ ((Gender2) => {
  Gender2["F"] = "f";
  Gender2["M"] = "m";
  Gender2["O"] = "o";
  return Gender2;
})(Gender || {});
var VisitConfirmType = /* @__PURE__ */ ((VisitConfirmType2) => {
  VisitConfirmType2["Partner"] = "partner";
  VisitConfirmType2["Member"] = "member";
  return VisitConfirmType2;
})(VisitConfirmType || {});
var Platform = /* @__PURE__ */ ((Platform2) => {
  Platform2["Android"] = "android";
  Platform2["Ios"] = "ios";
  Platform2["Web"] = "web";
  return Platform2;
})(Platform || {});

var card_status = /* @__PURE__ */ ((card_status2) => {
  card_status2["active"] = "active";
  card_status2["inactive"] = "inactive";
  return card_status2;
})(card_status || {});

const Statuses = {
  quit: "quit",
  qualified_prospect: "qualified_prospect",
  prospect: "prospect",
  online: "online",
  declined: "declined",
  declined_prospect: "declined_prospect",
  in_creation: "in_creation"
};

exports.ExperienceStatus = ExperienceStatus;
exports.Gender = Gender;
exports.MailingListIds = MailingListIds;
exports.ParentEntityType = ParentEntityType;
exports.PartnerType = PartnerType;
exports.Platform = Platform;
exports.ProductId = ProductId;
exports.ProductInterval = ProductInterval;
exports.ProductType = ProductType;
exports.PromotionalPartnerIds = PromotionalPartnerIds;
exports.PromotionalPartnerStatus = PromotionalPartnerStatus;
exports.PurchaseMode = PurchaseMode;
exports.SavingsTypes = SavingsTypes;
exports.Statuses = Statuses;
exports.SubscriptionPlan = SubscriptionPlan;
exports.SubscriptionStatus = SubscriptionStatus;
exports.SubscriptionType = SubscriptionType;
exports.UserStatus = UserStatus;
exports.VisitConfirmType = VisitConfirmType;
exports.card_status = card_status;
