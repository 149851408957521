import classNames from 'classnames';
import { FC, TextareaHTMLAttributes, useState } from 'react';
import { baseInputClassNames } from '.';

type TextAreaExtraProps = {
  error?: boolean;
  success?: boolean;
};

type InputTextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & TextAreaExtraProps;

export const InputTextArea2: FC<InputTextAreaProps> = ({
  maxLength,
  error = false,
  success = false,
  className = '',
  onChange,
  ...textAreaProps
}) => {
  const [totalCharacters, setTotalCharacters] = useState(0);

  const textAreaClassNames = classNames(baseInputClassNames({ error, success }), 'min-h-[150px]');

  return (
    <div className="relative">
      <textarea
        className={textAreaClassNames}
        onChange={(e) => {
          setTotalCharacters(e.target.value.length);
          onChange && onChange(e);
        }}
        {...textAreaProps}
      />

      {!!maxLength && (
        <span className="absolute bottom-3 right-3 text-xs text-medium-gray">
          {totalCharacters} / {maxLength}
        </span>
      )}
    </div>
  );
};
