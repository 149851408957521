import { Category } from '@milo/types/index';
import { useState } from 'react';
import { useVerticals, useVerticalsEphemeral } from '../../hooks';
import { CreativiteLocale, PlaisirsGourmands, Sejours, Sevader } from '../../icons';
import { ReactFC } from '../../types';
import { colorFn, ColorType } from '../../utils';
import { Skeleton } from '../Skeleton';
import { SideScroller } from '../Slider';

export const verticalsIdsColorMap: { [x: number]: ColorType } = {
  1: 'warning',
  2: 'teal',
  3: 'green',
  4: 'purple',
};

export const verticalsEphemeralColorMap: { [x: number]: ColorType } = {
  1: '#E72053',
};

export const verticalsIdsIconMap: { [x: number]: ReactFC } = {
  1: PlaisirsGourmands,
  2: Sejours,
  3: Sevader,
  4: CreativiteLocale,
};

export const verticalsIdsNameMap: { [x: number]: string } = {
  1: 'Plaisirs gourmands',
  2: 'Séjours',
  3: "S'évader",
  4: 'Créativité locale',
};

export const verticalsEphemeralPrefix: string = 'ephemeral-';

export type VerticalId = number | string;
export type VerticalsIdsArray = VerticalId[];

export const getVerticalIdWithPrefix = (id: number) => `${verticalsEphemeralPrefix}${id}`;
export const getVerticalIdFromEphemeral = (id: string) => Number(id.replace(verticalsEphemeralPrefix, ''));

export type VerticalsFiltersProps = {
  initialValues?: VerticalsIdsArray;
  onChange?: (verticals: VerticalsIdsArray) => void;
  limitedVerticals?: VerticalsIdsArray;
  categories?: Category[];
  variant?: 'default' | 'compact';
};

const VerticalsInjectedStyles = () => (
  <style>
    {`
    .verticals-filter svg {
      width: 100%;
      height: 100%;
      fill: none;
    }

    .verticals-filter svg * {
      stroke: currentColor;
      fill: currentColor;
    }
  `}
  </style>
);

export const VerticalsFilter: ReactFC<VerticalsFiltersProps> = ({
  limitedVerticals = [],
  onChange = () => {},
  initialValues = [],
  variant = 'default',
}) => {
  const { data: verticals = [], isFetched: verticalsFetched, isLoading: verticalsLoading } = useVerticals();
  const {
    data: verticalsEphemeral = [],
    isFetched: ephemeralFetched,
    isLoading: ephemeralLoading,
  } = useVerticalsEphemeral();
  const [selectedVerticals, setSelectedVerticals] = useState<VerticalsIdsArray>(initialValues);
  const isLoading = !verticalsFetched || !ephemeralFetched || verticalsLoading || ephemeralLoading;

  const containerClassName = `flex gap-2 side-scroll`;

  const displayedVerticals = verticals.filter((v) => {
    if (limitedVerticals.length < 1) {
      return v;
    }

    return limitedVerticals.includes(v.id);
  });

  const selectVerticals = (vertical: VerticalId | 'none' | 'all') => {
    // Select all
    if (vertical === 'all') {
      const newVerticals = displayedVerticals.map((v) => v.id);
      setSelectedVerticals(newVerticals);
      onChange(newVerticals);
      return;
    }

    // Remove all
    if (vertical === 'none') {
      setSelectedVerticals([]);
      onChange([]);
      return;
    }

    // Remove selected
    if (selectedVerticals?.includes(vertical)) {
      const newVerticals = selectedVerticals.filter((v) => v !== vertical);
      setSelectedVerticals(newVerticals);
      onChange(newVerticals);
      return;
    }

    // Add selected vertical
    const newVerticals = [...selectedVerticals, vertical];
    setSelectedVerticals(newVerticals);
    onChange(newVerticals);
  };

  if (variant === 'compact') {
    if (isLoading) {
      return (
        <div className={containerClassName}>
          <Skeleton className="h-12 w-12 shrink-0 grow-0 rounded-full" times={5} />
        </div>
      );
    }

    return (
      <div className="verticals-filter flex gap-2">
        <VerticalsInjectedStyles />
        {verticalsEphemeral.map((ephemeral) => {
          const ephemeralId = getVerticalIdWithPrefix(ephemeral.id);
          const selected = selectedVerticals?.includes(ephemeralId);
          const ephemeralColor = ephemeral.hex_color;
          const SVG = (props) => {
            return <div dangerouslySetInnerHTML={{ __html: ephemeral.svg }} {...props}></div>;
          };

          return (
            <div
              key={ephemeralId}
              className="flex animate-pulse cursor-pointer flex-col items-center justify-center rounded-full border-[3px] p-2 text-center transition-all duration-300"
              onClick={() => selectVerticals(ephemeralId)}
              style={{
                background: selected ? ephemeralColor : null,
                color: selected ? colorFn('white') : ephemeralColor,
                borderColor: selected ? 'none' : ephemeralColor,
              }}
            >
              <SVG className="h-9 w-9" />
            </div>
          );
        })}

        {displayedVerticals.map((v) => {
          const selected = selectedVerticals.includes(v.id);
          const verticalColor = colorFn(verticalsIdsColorMap[v.id]);
          const VerticalIcon = verticalsIdsIconMap[v.id];
          const SVG = (props) => (
            <div {...props}>
              <VerticalIcon />
            </div>
          );

          return (
            <div
              key={v.id}
              className="flex cursor-pointer flex-col items-center justify-center rounded-full border-[3px] p-2 text-center transition-all duration-300"
              onClick={() => selectVerticals(v.id)}
              style={{
                background: selected ? verticalColor : null,
                color: selected ? colorFn('white') : verticalColor,
                borderColor: selected ? 'transparent' : verticalColor,
              }}
            >
              <SVG className="h-9 w-9" />
            </div>
          );
        })}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={containerClassName}>
        <Skeleton className="h-10 w-36 shrink-0 grow-0 rounded-full" times={4} />
      </div>
    );
  }

  return (
    <div className="verticals-filter relative">
      <VerticalsInjectedStyles />
      <SideScroller buttonsPosition="top-right" containerAlignment="left">
        {verticalsEphemeral.map((ephemeral) => {
          const ephemeralId = getVerticalIdWithPrefix(ephemeral.id);
          const selected = selectedVerticals?.includes(ephemeralId);
          const ephemeralColor = ephemeral.hex_color;

          const SVG = (props) => {
            return <div dangerouslySetInnerHTML={{ __html: ephemeral.svg }} {...props}></div>;
          };

          return (
            <div
              key={ephemeralId}
              className="flex w-[200px] shrink-0 animate-pulse cursor-pointer justify-center rounded-full border-2 py-2 font-semibold outline-red transition-all duration-300"
              onClick={() => selectVerticals(ephemeralId)}
              style={{
                background: selected ? ephemeralColor : null,
                color: selected ? colorFn('white') : ephemeralColor,
                borderColor: selected ? 'transparent' : ephemeralColor,
                boxShadow: `0 0 5px 4px ${colorFn(ephemeralColor as ColorType, 0.5)}`,
              }}
            >
              <SVG className="mr-2 h-6 w-6" />
              {ephemeral.name}
            </div>
          );
        })}
        {displayedVerticals.map((vert) => {
          const selected = selectedVerticals?.includes(vert.id);
          const verticalColor = colorFn(verticalsIdsColorMap[vert.id]);
          const SVG = (props) => {
            const Icon = verticalsIdsIconMap[vert.id];
            return (
              <div {...props}>
                <Icon />
              </div>
            );
          };

          return (
            <div
              key={`vertical-${vert.id}`}
              className="flex w-[200px] shrink-0 cursor-pointer justify-center rounded-full border-2 py-2 font-semibold transition-all duration-300"
              onClick={() => selectVerticals(vert.id)}
              style={{
                background: selected ? verticalColor : null,
                color: selected ? colorFn('white') : verticalColor,
                borderColor: selected ? 'transparent' : verticalColor,
              }}
            >
              <SVG className="mr-2 h-6 w-6" /> {vert.name}
            </div>
          );
        })}
      </SideScroller>
    </div>
  );
};
