import { t, Trans } from '@lingui/macro';
import { MiloFull } from '../../logos';
import { ReactFC } from '../../types';
import { Button2 } from '../Button';
import { Link2 } from '../Link';

export const SubscriptionAlreadyActive: ReactFC<{ title?: string; text?: string }> = ({
  title = t`Désolé!`,
  text = t`Vous avez déjà un pass M ta région actif. Vous pouvez toujours offrir un autre produit M ta région en cadeau à vos proches!`,
}) => {
  return (
    <div>
      <MiloFull className="mb-5 w-32" />
      <div className="max-w-lg animate-fade-in">
        <div className="mb-8">
          <h1 className="mb-2 text-2xl font-bold">{title}</h1>
          <p>{text}</p>
        </div>
        <div className="flex flex-wrap gap-2">
          <Link2 href="/purchase">
            <Button2 size="md">
              <Trans>Voir les produits M ta région</Trans>
            </Button2>
          </Link2>
          <Link2 href="/experiences">
            <Button2 size="md" variant="secondary">
              <Trans>Voir les expériences</Trans>
            </Button2>
          </Link2>
        </div>
      </div>
    </div>
  );
};
