import config from '@milo/react/src/config';

type UseGooglePlacesApiParams = {
  onLoad?: () => void;
};

const GOOGLE_MAPS_API_SCRIPT_ID = 'google-maps-api-script-tag';

export const useGooglePlacesApi: (params?: UseGooglePlacesApiParams) => void = ({ onLoad = () => {} }) => {
  const src = `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_MAPS_JAVASCRIPT_API_KEY}&libraries=places`;
  const existingScript = document.getElementById(GOOGLE_MAPS_API_SCRIPT_ID);

  if (!existingScript) {
    const script = document.createElement('script');
    script.id = GOOGLE_MAPS_API_SCRIPT_ID;
    script.defer = true;
    script.src = src;
    script.onload = onLoad;
    document.body.appendChild(script);
  }
};
