import { useEffect, useState } from 'react';
import { ExpireObject, getExpireDateStringFromExpireObject } from '../utils';

export const deleteFromLocalStorage = (id: string) => {
  return localStorage.removeItem(id);
};

export const writeToLocalStorage = (key: string, value: number | string | object | boolean, expire?: ExpireObject) => {
  const expiryDate = getExpireDateStringFromExpireObject(expire);

  const valueStored = {
    value,
    expire: expiryDate,
  };

  const persistedValue = JSON.stringify(valueStored);
  localStorage.setItem(key, persistedValue);
  return valueStored.value;
};

export const readFromLocalStorage = (key: string) => {
  const value = localStorage.getItem(key);

  try {
    const parsedValue = JSON.parse(value);

    if (parsedValue?.value) {
      if (parsedValue?.expire) {
        const now = new Date().toUTCString();
        if (parsedValue.expire > now) {
          return parsedValue.value;
        }
        return null;
      }

      return parsedValue.value;
    }
    return parsedValue;
  } catch (error) {
    return value;
  }
};

export const useLocalStorage: (key: string, defaultValue?: any, expire?: ExpireObject) => [any, Function, Function] = (
  key,
  defaultValue = null,
  expire = null
) => {
  const [value, setValue] = useState(() => {
    const valueFromStorage = readFromLocalStorage(key);
    return valueFromStorage || defaultValue;
  });

  useEffect(() => {
    if (value) writeToLocalStorage(key, value, expire);
  }, [key, value]);

  return [value, setValue, () => deleteFromLocalStorage(key)];
};
