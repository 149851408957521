import { t } from '@lingui/macro';
import {
  AddGuideToWishlistModal,
  convertCentsIntegerToClosestFiveDollarString,
  Heart,
  ImageKitImage,
  Link2,
  MapPinAlt,
  trackEvent,
  useAuthentication,
} from '@milo/react';
import { Guide } from '@milo/types';
import { useMemo, useState } from 'react';

export const GuideCard = ({ guide }: { guide: Guide }) => {
  const { user } = useAuthentication();
  const { id, slug, title, main_image_url, savings, region, long_description, experiences } = guide || {};
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const inWishlist = false;

  const price = useMemo(() => {
    if (experiences) {
      return experiences.reduce((acc, exp) => {
        return acc + exp.savings_max;
      }, 0);
    }
    return 0;
  }, [experiences]);

  const randomImages = useMemo(() => {
    let images = [];
    if (main_image_url && main_image_url !== '') {
      images.push(main_image_url);
    }
    if (experiences) {
      experiences.forEach((exp) => {
        if (exp.main_image && exp.main_image !== '') {
          images.push(exp.main_image);
        } else if (exp.thumbnail_image && exp.thumbnail_image !== '') {
          images.push(exp.thumbnail_image);
        }
      });
    }

    for (let i = images.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [images[i], images[j]] = [images[j], images[i]];
    }

    return images;
  }, [id]);

  return (
    <Link2
      onClickCapture={(e) => trackEvent('guide_click', { guide_id: guide.id, user_id: user?.id })}
      href={`/guides/${slug}`}
      className="mx-2 mb-4 flex h-full w-full shrink-0 flex-col items-center rounded-xl shadow-lg"
      popup
    >
      <div className="relative">
        <div className="absolute bottom-0 left-0 right-0 top-0 rounded-t-xl bg-gradient-to-b from-black/50" />
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-start justify-between">
          <p className="p-4 text-xl font-bold text-white">
            -{' '}
            {savings
              ? convertCentsIntegerToClosestFiveDollarString(savings)
              : convertCentsIntegerToClosestFiveDollarString(price)}
          </p>
          <AddGuideToWishlistModal guide={guide}>
            <div className="p-4">
              <Heart
                className="h-[24px] w-[24px]"
                fill={inWishlist ? 'red' : 'transparent'}
                stroke={inWishlist ? 'red' : 'white'}
              />
            </div>
          </AddGuideToWishlistModal>
        </div>

        <ImageKitImage
          className="min-h-[200px] w-full rounded-t-xl"
          imageFileName={
            randomImages[currentImageIndex] ||
            '/wp-content/uploads/2022/10/311586969_5616748038371749_7515329476885463724_n.jpeg'
          }
          transforms="w-300,h-200,q-50"
        />
      </div>

      <div className=" bottom-0 flex w-full flex-col gap-1 overflow-hidden rounded-b-xl bg-white p-3">
        <h3 className="truncate text-lg font-bold leading-snug">{title || '?'}</h3>
        <p className="text-sm capitalize text-black">
          <MapPinAlt className="mr-1 inline-block h-4 w-4" />
          {t`À`} {region?.name || '?'}
        </p>
        <p className="box-border h-[50px] w-[236px] overflow-hidden text-ellipsis text-xs capitalize text-medium-gray">
          {long_description || ''}
        </p>
      </div>
    </Link2>
  );
};
