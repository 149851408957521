import { Trans } from '@lingui/macro';
import { Button, Link2, Loading, ReactFC, Text, useAuthentication } from '@milo/react';

export const OnboardingSuccess: ReactFC = () => {
  const { isLoading } = useAuthentication();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="relative flex flex-col items-center justify-center">
      <Text size="h1" className="mb-4 font-bold">
        <Trans>Bienvenue sur M ta région 🎉</Trans>
      </Text>
      <div className="mb-6 w-11/12">
        <Text className="mb-2">
          <Trans>Naviguez à travers nos expériences, rêvez et voyez combien vous pourriez économiser.</Trans>
        </Text>
        <Text className="mb-2">
          <Trans>
            Pour en profiter, explorez nos différentes formules de pass M ta région et{' '}
            <b> économisez à chaque découverte</b>.
          </Trans>
        </Text>
      </div>

      <Link2 href="/experiences">
        <Button className="w-full">
          <Trans>Voir les expériences</Trans>
        </Button>
      </Link2>
    </div>
  );
};
