export const HomeIcon = (props) => (
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.833 24.5H8.167A4.667 4.667 0 0 1 3.5 19.833v-7.34c0-1.633.852-3.146 2.248-3.992l5.833-3.535a4.667 4.667 0 0 1 4.838 0L22.252 8.5a4.667 4.667 0 0 1 2.248 3.991v7.341a4.667 4.667 0 0 1-4.667 4.667ZM10.5 19.833h7"
      stroke="currentColor"
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
