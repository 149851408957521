import { FeatureFlagConditions } from '@milo/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useGraphqlClient } from './useGraphqlClient';

export const useFeatureFlag = (
  id: string,
  conditions: FeatureFlagConditions = {},
  options: UseQueryOptions<boolean> = { enabled: true }
) => {
  const { client } = useGraphqlClient();

  const useQueryData = useQuery<boolean>(
    [`feature-flags-${id}`],
    async () => {
      const response = await client.request(
        /* GraphQL */ `
          query FeatureFlagActive($input: FeatureFlagInput) {
            FeatureFlagActive(input: $input)
          }
        `,
        {
          input: {
            id,
            conditions,
          },
        }
      );

      return response?.FeatureFlagActive as boolean;
    },
    {
      staleTime: Infinity,
      ...options,
    }
  );

  return useQueryData;
};
