import { MiloSVGProps } from '.';
import { ReactFC } from '../types';
import { colorFn, ColorType } from '../utils';

const SvgComponent: ReactFC<MiloSVGProps> = ({ fill = 'currentColor' as ColorType, ...props }) => {
  const _fill = colorFn(fill);
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.000000 64.000000" {...props}>
      <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill={_fill} stroke="none">
        <path
          d="M417 510 c-14 -11 -29 -29 -32 -40 -6 -19 -16 -20 -165 -20 -125 0
-159 -3 -163 -14 -12 -30 16 -36 169 -36 146 0 153 -1 159 -21 8 -25 61 -59
93 -59 69 0 123 72 102 136 -23 69 -108 97 -163 54z m97 -46 c24 -24 20 -61
-9 -80 -23 -15 -27 -15 -50 0 -42 28 -24 96 25 96 10 0 26 -7 34 -16z"
        />
        <path
          d="M104 301 c-89 -54 -47 -191 57 -191 40 0 84 28 94 60 6 19 16 20 165
20 125 0 159 3 163 14 12 30 -16 36 -169 36 -146 0 -153 1 -159 21 -8 26 -62
59 -95 59 -14 0 -39 -9 -56 -19z m84 -47 c39 -27 19 -94 -28 -94 -47 0 -67 67
-28 94 12 9 25 16 28 16 3 0 16 -7 28 -16z"
        />
      </g>
    </svg>
  );
};
export { SvgComponent as Filter };
