import { VerticalEphemeral } from '@milo/types';
import { getGraphqlClient } from '../useGraphqlClient';
import { useQuery } from '../useQuery';

export const getVerticalsEphemeral: () => Promise<VerticalEphemeral[]> = async () => {
  const client = getGraphqlClient();
  const { VerticalsEphemeralList = [] } = await client.request(`
    query VerticalsEphemeralList {
      VerticalsEphemeralList {
        id
        hex_color
        name
        svg
        starts_at
        ends_at
      }
    }
  `);

  return VerticalsEphemeralList;
};

export const useVerticalsEphemeral = () => {
  const response = useQuery<VerticalEphemeral[]>(
    ['VerticalsEphemeralList'],
    async () => {
      return await getVerticalsEphemeral();
    },
    {
      initialData: [],
    }
  );

  return response;
};
