import { useEffect } from 'react';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import { ReactFC } from '../../../types';
import { datepickerStyles } from './styles';

const initializeDatepicker = () => {
  const datepickerStylesId = 'datepicker-styles';
  const existingDatepickerStyles = document.getElementById(datepickerStylesId);

  if (!existingDatepickerStyles) {
    const newDatepickerStyles = document.createElement('style');
    newDatepickerStyles.innerText = datepickerStyles;
    newDatepickerStyles.id = datepickerStylesId;
    document.body.append(newDatepickerStyles);
  }
};

type InputDatePickerProps = {
  minDate?: Date;
  maxDate?: Date;
  name: string;
  value: Date;
  onChange: (e: { target: { value: Date; name: string } }) => void;
};

export const InputDatePicker: ReactFC<InputDatePickerProps> = ({ onChange, name, ...props }) => {
  useEffect(() => {
    initializeDatepicker();
  }, []);

  const handleOnChange = (e: Date) => {
    onChange && onChange({ target: { value: e, name } });
  };

  return <DatePicker locale="fr" onChange={handleOnChange} {...props} />;
};
