import { Trans } from '@lingui/macro';
import {
  AppDownload,
  Button2,
  FlowContainer,
  isNativeApp,
  Link2,
  Route,
  VALIDATION_VIDEO_URL,
  YoutubeEmbed,
} from '@milo/react';

export const MiloFreeSucces = () => {
  const nativeApp = isNativeApp();

  return (
    <Route.LoggedIn redirectPath="/milo-gratuit">
      <FlowContainer showHomeLink={false}>
        <div className="flex flex-col gap-12">
          <h1 className="-mb-7 text-3xl font-semibold">
            <Trans>Votre accès gratuit est actif!</Trans> 🎉👏
            <br />
          </h1>

          <p>
            <Trans>
              Commencez dès maintenant à découvrir les expériences autour de vous et à profiter d'aubaines incroyables!
            </Trans>
          </p>

          <div className="flex items-center justify-center">
            <Link2 href="/map" className="animate-hearbeat">
              <Button2>
                <Trans>Voir la carte des expériences</Trans>
              </Button2>
            </Link2>
          </div>

          <div className="text-center">
            <h2 className="mb-4 text-2xl font-semibold">
              <Trans>Voyez comment obtenir les réductions</Trans>
            </h2>
            <div className="h-72 overflow-hidden rounded-xl  shadow-xl">
              <YoutubeEmbed src={VALIDATION_VIDEO_URL} />
            </div>
          </div>
          {!nativeApp && (
            <div className="text-center">
              <h2 className="mb-4 text-2xl font-semibold">
                <Trans>Téléchargez notre application mobile</Trans>
              </h2>
              <AppDownload />
            </div>
          )}
        </div>
      </FlowContainer>
    </Route.LoggedIn>
  );
};
