import { Promotion } from '@milo/types';
import { HybridGraphqlClient, useGraphqlClient } from '../useGraphqlClient';
import { useQuery } from '../useQuery';

export const getPromotionActive = async (graphqlClient: HybridGraphqlClient) => {
  const data = await graphqlClient.request(/* GraphQL */ `
    query PromotionActive {
      promotion: PromotionActive {
        name
        description
        discounts {
          promo_code
          applicable_product_id
          discounted_amount
        }
      }
    }
  `);
  const promotion = data?.promotion as Partial<Promotion>;
  return promotion;
};

export const usePromotionActive = () => {
  const { client } = useGraphqlClient();
  const response = useQuery(['PromotionActive'], async () => await getPromotionActive(client), {
    staleTime: Infinity,
  });

  const isActive = (response?.data?.discounts || [])?.length > 0;

  return { ...response, isActive };
};
