import { t } from '@lingui/macro';
import { InputHTMLAttributes } from 'react';
import { ReactFC } from '../../types';
import { InputText2 } from './InputText2';

type InputExtraProps = {
  errorText?: string;
};

export type InputAgeProps = InputHTMLAttributes<HTMLInputElement> & InputExtraProps;

export const InputAge: ReactFC<InputAgeProps> = (props) => {
  return <InputText2 maxLength={2} type="numeric" placeholder={t`Votre âge`} {...props} />;
};
