import { Experience, PointOfInterest } from '@milo/types/index';
import React from 'react';
import { Loading } from '../Route';

// Do this to only load the map when the component is rendered in the browser because the leaflet library is not compatible with SSR
const MiloMap = React.lazy(() => import('./MiloMap'));

export type MiloMapProps = {
  activeFiltersCount?: number;
  className?: string;
  experiences?: Experience[];
  pointsOfInterest?: PointOfInterest[];
  includeCurrentPositionInBounds?: boolean;
  onClickFilter?: () => void;
  rounded?: boolean;
  zoomToFitPassedExperiences?: boolean;
};

export const MiloMapLoader = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <Loading inline={true} text="Chargement de la carte..." />
    </div>
  );
};

export default function Map(props: MiloMapProps) {
  return (
    <React.Suspense fallback={<MiloMapLoader />}>
      <MiloMap {...props} />
    </React.Suspense>
  );
}
