import { Trans } from '@lingui/macro';
import { useState } from 'react';
import { useAuthentication, useSessionStorage } from '../../hooks';
import { ReactFC } from '../../types';
import { Button2 } from '../Button';
import { Link2 } from '../Link';
import { Modal, ModalBody } from '../Modal';
import { Text } from '../Text';

export const POST_PROFILE_COMPLETION_REDIRECT_PATH = 'post-profile-completion-redirect-path';
export const POST_LOGIN_REDIRECT_PATH = 'post-login-redirect-path';
export const POST_LOGOUT_REDIRECT_PATH = 'post-logout-redirect-path';

// This component is shown when the user is not logged in
const LoginOnly: ReactFC = () => {
  const [, setRedirectUrl] = useSessionStorage(POST_LOGIN_REDIRECT_PATH);

  return (
    <>
      <Text className="mb-6" lineHeight="spaced">
        <Trans>Vous devez être connecté pour accéder à cette fonctionnalité.</Trans>
      </Text>

      <Link2 href="/login" onClickCapture={() => setRedirectUrl(location.pathname + location.search)}>
        <Button2 block>
          <Trans>Se connecter</Trans>
        </Button2>
      </Link2>
    </>
  );
};

// This component is shown when the user is not logged in and the user should go through onboarding first
const OnboardingOnly: ReactFC = () => {
  const [, setRedirectUrl] = useSessionStorage(POST_PROFILE_COMPLETION_REDIRECT_PATH);

  return (
    <>
      <Text className="mb-6" lineHeight="spaced">
        <Trans>Vous devez avoir complété vos informations de profil pour accéder à cette fonctionnalité.</Trans>
      </Text>

      <Link2 href="/onboarding/profile" onClickCapture={() => setRedirectUrl(location.pathname)}>
        <Button2 block>
          <Trans>Compléter mon profil</Trans>
        </Button2>
      </Link2>
    </>
  );
};

// This component is shown when the user hasn't given his demographic informations
const LoginPlusOnboarding: ReactFC = () => {
  const [, setPostProfileRedirectUrl] = useSessionStorage(POST_PROFILE_COMPLETION_REDIRECT_PATH);
  const [, setPostLoginRedirectUrl] = useSessionStorage(POST_LOGIN_REDIRECT_PATH);

  return (
    <>
      <Text className="mb-6" lineHeight="spaced">
        <Trans>
          Vous devez être connecté et avoir complété vos informations de profil pour accéder à cette fonctionnalité.
        </Trans>
      </Text>

      <Link2
        href="/login"
        onClickCapture={() => {
          setPostLoginRedirectUrl('/onboarding/profile');
          setPostProfileRedirectUrl(location.pathname);
        }}
      >
        <Button2 block>
          <Trans>Se connecter</Trans>
        </Button2>
      </Link2>
    </>
  );
};

type AuthedActionProps = {
  authLevel: 'logged-in' | 'profile-info-complete';
};

export const AuthedAction: ReactFC<AuthedActionProps> = ({ children, authLevel = 'logged-in' }) => {
  const { loggedIn, user } = useAuthentication();
  const [open, setOpen] = useState(false);

  const onlyNeedsLogin = authLevel === 'logged-in' && !loggedIn;
  // const needsToCompleteOnboarding = loggedIn && !user?.has_passed_onboarding;
  const needsLoginPlusOnboarding = authLevel === 'profile-info-complete' && (!loggedIn || !user.has_passed_onboarding);

  const captureClickToShowModal = onlyNeedsLogin || needsLoginPlusOnboarding; //|| needsToCompleteOnboarding;

  const showModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };

  if (open) {
    const ModalContent = () => {
      if (onlyNeedsLogin) return <LoginOnly />;
      // if (needsToCompleteOnboarding) return <OnboardingOnly />;
      if (needsLoginPlusOnboarding) return <LoginPlusOnboarding />;
      return null;
    };

    return (
      <>
        <Modal open={open} onRequestClose={() => setOpen(false)}>
          <ModalBody>
            <Text size="h2" className="mb-4 font-semibold">
              <Trans>Connexion requise</Trans>
            </Text>
            <ModalContent />
          </ModalBody>
        </Modal>
        {children}
      </>
    );
  }

  if (captureClickToShowModal) {
    return <span onClickCapture={showModal}>{children}</span>;
  }

  return <>{children}</>;
};
