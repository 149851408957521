import { Trans } from '@lingui/macro';
import { TutorialLayout, TutorialStep } from './base';

export const WishListTutorialPage = () => {
  return (
    <TutorialLayout title={<Trans>Faire une liste de favoris</Trans>}>
      <TutorialStep>
        <p className="leading-[22px] text-dark">
          <Trans>
            Ajouter les expériences qui vous font envie à votre liste de favoris en cliquant sur l'icône au haut à
            droite et accumulez les économies potentielles!
          </Trans>
        </p>
        <video autoPlay loop muted playsInline className="mx-auto mt-6 w-full">
          <source type="video/mp4" src="/images/tutorials/ill-liste-de-souhaits.mp4" />
        </video>
      </TutorialStep>
    </TutorialLayout>
  );
};
