import { Trans } from '@lingui/macro';
import cn from 'classnames';
import React, { Children, HTMLAttributes, MouseEvent, ReactNode, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { StepNumber } from '../../components';
import { Link, Link2, LinkProps } from '../../components/Link';
import { useIsomorphicLayoutEffect } from '../../hooks';
import { ArrowLeft, Lock, TutorialLinkIcon } from '../../icons';
import { ReactFC } from '../../types';

export const TutorialLink: ReactFC<LinkProps> = ({ disabled = false, children, href, ...rest }) => {
  return (
    <Link2 className="flex w-full items-center rounded-xl border border-gray-light px-4 py-3" href={href} {...rest}>
      <TutorialLinkIcon
        className={cn('mr-3 h-8 w-8', {
          'stroke-medium-gray': disabled,
          'stroke-charcoal': !disabled,
        })}
      />

      <p
        className={`flex-1 text-center text-[15px] leading-5 text-medium-dark ${
          disabled ? 'text-medium-gray' : 'text-charcoal'
        }`}
      >
        {children}
      </p>

      {disabled && <Lock className="ml-3 h-4 w-4" fill="medium-gray" />}
    </Link2>
  );
};

type TutorialStepProps = Omit<HTMLAttributes<HTMLElement>, 'title'> & {
  title?: ReactNode;
};

export const TutorialStep: ReactFC<TutorialStepProps> = ({ title, ...divProps }) => {
  return <div {...divProps} />;
};

type TutorialLayoutProps = {
  title: ReactNode;
  backLabel?: ReactNode;
};

export const TutorialLayout: ReactFC<TutorialLayoutProps> = ({ title, backLabel, children }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const maxSteps = Children.count(children);

  const navigationLinks = Children.map(children, (child) => {
    if (React.isValidElement(child) && (child as React.ReactElement).type === TutorialStep) {
      return child.props['title'] as ReactNode;
    }

    throw new Error('Every child component of TutorialLayout must be TutorialStep');
  });

  return (
    <div className="mx-auto w-full max-w-7xl">
      <div className="sticky left-0 right-0 top-0 bg-white pb-3 md:hidden">
        <div className="flex items-center">
          <div className="flex h-16 w-16 items-center justify-center">
            <Link href="/tutorials">
              <ArrowLeft className="mr-3 h-4 w-4 text-charcoal" fill="currentColor" />
            </Link>
          </div>
          <div className="flex-1">
            <h1 className="text-center text-2xl font-bold">{title}</h1>
          </div>
          <div className="h-16 w-16"></div>
        </div>
        {maxSteps > 1 && (
          <div className="px-6">
            <TutorialNavigation title={title} selectedIndex={currentStep}>
              {navigationLinks}
            </TutorialNavigation>
          </div>
        )}
      </div>
      <div className="web-container mb-24 grid grid-cols-1 md:mt-16 md:grid-cols-3">
        <div className="relative hidden w-96 px-6 md:block">
          <div className="sticky left-0 right-0 top-0">
            <Link className="my-2 mb-16 inline-flex items-center font-medium leading-8" href="/tutorials">
              <ArrowLeft className="mr-3 h-4 w-4 text-charcoal" fill="currentColor" />
              {backLabel ?? <Trans>Retour au Guide de l'utilisateur</Trans>}
            </Link>
            {maxSteps > 1 && (
              <TutorialNavigation title={title} selectedIndex={currentStep}>
                {navigationLinks}
              </TutorialNavigation>
            )}
          </div>
        </div>
        <div className="mx-auto max-w-md flex-1 px-6 md:col-span-2 lg:col-span-1">
          <h1 className="hidden text-center text-[40px] font-bold leading-[56px] md:block">{title}</h1>
          <div className="mt-10 space-y-16 md:mt-16">
            {Children.map(children, (child, index) => (
              <InView
                threshold={1}
                as="div"
                onChange={(inView) => inView && setCurrentStep(index)}
                id={`step-${index + 1}`}
              >
                {maxSteps > 1 && (
                  <div className="mb-4 flex justify-center">
                    <StepNumber>{index + 1}</StepNumber>
                  </div>
                )}
                {child}
              </InView>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

type TutorialNavigationProps = {
  selectedIndex?: number;
  title?: ReactNode;
};

export const TutorialNavigation: ReactFC<TutorialNavigationProps> = ({ selectedIndex = 0, title, children }) => {
  const smoothScrollTo = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.document.getElementById(e.currentTarget.href.split('#')[1])?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const scrollingContainerRef = useRef<HTMLOListElement>(null);
  const stepsRef = useRef<(HTMLLIElement | null)[]>([]);
  const stepsCount = Children.count(children);

  useIsomorphicLayoutEffect(() => {
    scrollingContainerRef.current?.scroll({
      left: (stepsRef.current[selectedIndex]?.offsetLeft ?? 0) - 24,
      behavior: 'smooth',
    });
  }, [selectedIndex]);

  return (
    <nav className="">
      {title !== undefined && <h3 className="mb-7 hidden py-4 text-xl font-medium leading-6 md:block">{title}</h3>}
      <div className="mb-1 flex items-center space-x-9 md:hidden">
        <span className="text-sm font-semibold uppercase leading-[22px] text-medium-gray">
          <Trans>
            Étape {selectedIndex + 1} / {stepsCount}
          </Trans>
        </span>
        <div className="h-1 flex-1 rounded-full bg-gray-light">
          <div
            className="h-1 min-w-[12px] rounded-full bg-raspberry transition-all"
            style={{ width: `${((selectedIndex + 1) / stepsCount) * 100}%` }}
          ></div>
        </div>
      </div>
      <ol
        className="side-scroll -mx-6 flex flex-nowrap space-x-6 overflow-x-auto scroll-smooth px-6 md:mx-0 md:flex-col md:space-x-0 md:space-y-6 md:overflow-x-visible md:px-0"
        ref={scrollingContainerRef}
      >
        {Children.map(children, (child, index) => (
          <li
            ref={(element) => (stepsRef.current[index] = element)}
            className={`${
              selectedIndex === index
                ? 'text-charcoal md:border-l-2 md:border-charcoal md:pl-2.5 md:leading-8'
                : 'text-medium-gray md:leading-[22px]'
            } font-medium leading-8 transition-all duration-100`}
          >
            <a href={`#step-${index + 1}`} onClick={smoothScrollTo} className="whitespace-nowrap md:whitespace-normal">
              {index + 1}. {child}
            </a>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export const TutorialContainer: ReactFC = (props) => {
  return <div className="mx-auto w-11/12 max-w-lg pb-20 text-center" {...props} />;
};
