import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { Platform } from '@milo/types/index';
import { primaryInput } from 'detect-it';

export const getPlatform: () => Platform = () => {
  return Capacitor.getPlatform() as Platform;
};

export const isMousePrimaryInput: () => boolean = () => primaryInput === 'mouse';

export const isNativeApp: () => boolean = () => {
  const platform = getPlatform();

  if ([Platform['Android'], Platform['Ios']].includes(platform)) {
    return true;
  }

  return false;
};

export const isConnectedToNetwork: () => Promise<boolean> = async () => {
  return await Network.getStatus().then((status) => status.connected);
};

const getCssVariableValue: (varName: string) => any = (varName) => {
  return getComputedStyle(document.documentElement).getPropertyValue(varName);
};

type DeviceSafeAreas = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export const getDeviceSafeAreas: () => DeviceSafeAreas = () => {
  const top = getCssVariableValue('--safe-area-top');
  const right = getCssVariableValue('--safe-area-right');
  const bottom = getCssVariableValue('--safe-area-bottom');
  const left = getCssVariableValue('--safe-area-left');

  return {
    top: parseInt(top || '0px'),
    right: parseInt(right || '0px'),
    bottom: parseInt(bottom || '0px'),
    left: parseInt(left || '0px'),
  };
};

export const useDeviceInfo = () => {
  const safeAreas = getDeviceSafeAreas();
  const platform = getPlatform();
  const isMobileApp = isNativeApp();
  const isMouseInput = isMousePrimaryInput();
  const connected = isConnectedToNetwork();
  return { platform, isMobileApp, safeAreas, isMouseInput, connected };
};
