import { User } from '@milo/types/index';
import { useMutation } from '@tanstack/react-query';
import { UserUpdate } from '../../graphql-queries/UserUpdate';
import { useGraphqlClient } from '../../hooks/useGraphqlClient';

type UpdateProfileProps = {
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
};

export const useUpdateProfile = ({
  onSuccess = () => {},
  onError = () => {},
  onSettled = () => {},
}: UpdateProfileProps) => {
  const { client } = useGraphqlClient();

  const mutation = useMutation(
    async (input: Partial<User>) => {
      return await client.request(UserUpdate, { input });
    },
    {
      onSuccess,
      onError,
      onSettled,
    }
  );

  return mutation;
};
