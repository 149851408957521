import { Country } from '@milo/types/index';
import { UseBaseQueryResult, UseQueryResult } from '@tanstack/react-query';
import { CountriesList } from '../../graphql-queries';
import { HybridGraphqlClient, useGraphqlClient } from '../useGraphqlClient';
import { useQuery } from '../useQuery';

export const getCountries: (graphqlClient: HybridGraphqlClient) => Promise<Country[]> = async (graphqlClient) => {
  const data = await graphqlClient.request(CountriesList);
  return data?.CountriesList;
};

export const useCountries: () => [Country[], UseBaseQueryResult['status']] = () => {
  const { client } = useGraphqlClient();
  const { data, status }: UseQueryResult<Country[]> = useQuery(
    ['CountriesList'],
    async () => await getCountries(client)
  );
  return [data, status];
};
