import { Browser } from '@capacitor/browser';
import { UserCurrentType } from '@milo/types/index';
import cn from 'classnames';
import { omit } from 'lodash';
import queryString from 'query-string';
import React, { AnchorHTMLAttributes, forwardRef, ForwardRefRenderFunction, HTMLProps, useMemo } from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import config from '../../config';
import { trackEvent, useAuthentication } from '../../hooks';
import { isNativeApp } from '../../hooks/useDeviceInfo';
import { ReactFC } from '../../types';

// Doing that to prevent loading the next link on mobile and get an error
// const NextLink = React.lazy(() => import('next/link'));

const trackLinkClick = async (href: string, user: UserCurrentType) => {
  if (['/support', '/faq', '/contact'].includes(href)) {
    trackEvent('contact', { user_id: user?.id });
  }
};

export type Link2Props = {
  disabled?: boolean;
  href: string;
  popup?: boolean;
  raw?: boolean;
  underline?: boolean;
  muted?: boolean;
} & HTMLProps<HTMLLinkElement & HTMLAnchorElement>;

export const Link2: ReactFC<Link2Props> = ({
  className = '',
  disabled = false,
  href,
  popup = false,
  raw = false,
  underline = false,
  muted = false,
  onClick = (e) => {},
  ...props
}) => {
  if (!href) throw new Error('Component Link2: href is required');

  const hrefIsPhoneNumber = href.startsWith('tel:');
  const hrefIsWebsite = href.startsWith('http');
  const hrefShouldForcePopup = ['faq', 'terms', 'blog', 'support'].some((item) => href.includes(item));
  const hrefIsExternal = popup || hrefShouldForcePopup || hrefIsWebsite;
  const hrefIsExternalForApp = ['purchase'].some((item) => href.includes(item)) || hrefIsExternal;
  const { user } = useAuthentication();

  const allProps: Link2Props = {
    ...props,
    href: disabled ? null : href,
    className: cn('contents', className, {
      'text-medium': muted,
      'font-semibold underline underline-offset-2': underline,
    }),
    target: hrefIsExternal ? '_blank' : '_self',
    onClick: (e) => {
      trackLinkClick(href, user);
      onClick?.(e);
    },
  };

  // Sometimes we might just want to render a raw "a" tag
  if (raw) {
    return <a {...allProps} />;
  }

  // External links for mobile app
  if (hrefIsExternalForApp && isNativeApp()) {
    const computedHref = hrefIsWebsite ? allProps.href : `https://${config.APP_DOMAIN}${allProps.href}`;

    return (
      <button
        {...allProps}
        onClick={(e) => {
          e.preventDefault();
          Browser.open({ presentationStyle: 'popover', url: computedHref });
        }}
      />
    );
  }

  if (hrefIsPhoneNumber || hrefIsExternal) {
    return <a {...omit(allProps, 'href')} href={allProps.href} />;
  }

  if (isNativeApp()) {
    return <ReactRouterDomLink to={allProps.href} {...omit(allProps, 'href')} />;
  }

  return (
    <React.Suspense fallback={<></>}>
      {/* Remove for next 13 for now */}
      {/* <NextLink href={allProps.href} passHref> */}
      <a {...allProps} />
      {/* </NextLink> */}
    </React.Suspense>
  );
};

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string;
  params?: Record<string, string>;
  popup?: boolean;
  disabled?: boolean;
  onPress?: (e: any) => void;
};

const LinkRenderFunction: ForwardRefRenderFunction<HTMLAnchorElement, LinkProps> = (
  { href = '', params = {}, popup = false, disabled = false, onPress, ...aProps },
  ref
) => {
  const isPhone = href.includes('tel:');
  const isMailto = href.includes('mailto:');
  const isWebsite = href.startsWith('http');
  const forcePopup = ['faq', 'terms', 'blog'].includes(href);
  const external = popup || forcePopup || isWebsite || isPhone || isMailto;

  const stringifiedParams = queryString.stringify(params);
  const query = stringifiedParams.length > 0 ? '?' + stringifiedParams : '';

  const computedHref = useMemo(() => (isPhone || isWebsite || isMailto ? href : `${href}${query}`), []);

  const renderedProps = {
    ...aProps,
    onClick: onPress ?? aProps.onClick, // backwards compatibility
    href: computedHref,
    target: external ? '_blank' : '',
    rel: external ? 'noopener noreferrer' : '',
  };

  // About the most we can do to "disable" <a> links.
  if (disabled) {
    renderedProps.style.cursor = 'not-allowed';
    renderedProps.href = '#';
    renderedProps.onClick = (e) => {
      e.preventDefault();
    };
  }

  return <a ref={ref} role="link" {...renderedProps} />;
};

export const Link = forwardRef(LinkRenderFunction);
