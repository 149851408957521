import { colorFn, ColorType } from '../utils';

export const LogoutIcon = ({ fill = 'currentColor' as ColorType, ...props }) => {
  const _fill = colorFn(fill);

  return (
    <svg viewBox="-5 -80 500 500" fill="none" {...props}>
      <path
        d="M318.713.106c-32.723 0-64.571 9.208-92.103 26.628a173.02 173.02 0 0 0-62.445 69.196l26.865 13.353c24.272-48.838 73.198-79.176 127.683-79.176 78.575 0 142.5 63.925 142.5 142.5s-63.925 142.5-142.5 142.5c-54.484 0-103.41-30.338-127.683-79.176l-26.865 13.353a173.02 173.02 0 0 0 62.445 69.196c27.531 17.42 59.38 26.628 92.103 26.628 95.117 0 172.5-77.383 172.5-172.5S413.83.106 318.713.106Z"
        fill={_fill}
      />
      <path
        d="M318.713 187.606v-30H57.426l34.393-34.393L70.606 102 0 172.607l70.606 70.606L91.819 222l-34.394-34.394h261.288Z"
        fill={_fill}
      />
    </svg>
  );
};
