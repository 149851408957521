import { IMAGEKIT_BASE_URL } from '@milo/constants';
import pathNormalize from 'path-normalize';
import { useMemo, useState } from 'react';
import { ImageKitImage } from '../Image/ImageKitImage';
import { Modal } from '../Modal';
import { Slider } from '../Slider';

type Image = {
  imageFileName: string;
  imageFileId?: string;
  imageAltText?: string;
};

export type ImageGalleryProps = {
  items: Image[];
  originalHeight?: number;
  originalWidth?: number;
  thumbnailWidth?: number;
  thumbnailHeight?: number;
  thumbnailClassName?: string;
  containerClassName?: string;
};

export const ImageGallery = (props: ImageGalleryProps) => {
  const {
    items,
    thumbnailWidth = 64,
    thumbnailHeight = thumbnailWidth,
    thumbnailClassName = '',
    containerClassName = '',
  } = props;

  const [galleryOpen, setGalleryOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(0);

  const paths = items.map((item) => {
    const original = 'https://' + pathNormalize(`${IMAGEKIT_BASE_URL}/${item.imageFileName}`);
    return original;
  });

  const thumbs = paths.map((p) => `${p}/tr:q-50,w-${thumbnailWidth},h-${thumbnailHeight}`);

  const fullSizeImages = useMemo(() => {
    return paths.map((p) => {
      const src = `${p}/tr:q-70`;
      return <ImageKitImage key={src} src={src} className="mx-auto h-full animate-fade-in rounded-3xl" />;
    });
  }, [paths]);

  return (
    <div className="side-scroll flex gap-3" data-testid="image-gallery-thumbnails">
      {thumbs.map((t, index) => (
        <button
          className="h-16 w-16 shrink-0 cursor-pointer"
          key={t}
          onClick={() => {
            setStartIndex(index);
            setGalleryOpen(true);
          }}
        >
          <ImageKitImage src={t} className="h-full w-full rounded-lg" />
        </button>
      ))}

      <Modal
        open={galleryOpen}
        onRequestClose={() => setGalleryOpen(false)}
        stickToBottom={false}
        closeOnBackdropClick={true}
      >
        <Slider
          className="w-full"
          navigation
          pagination
          paginationClickable
          initialSlideIndex={startIndex}
          slides={fullSizeImages}
        />
      </Modal>
    </div>
  );
};
