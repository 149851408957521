import { colorFn, ColorType } from '../utils';

function SvgComponent({ fill = 'currentColor' as ColorType, ...props }) {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="0 0 299 248.17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M284.5 107.686H59.116l77.209-77.214c6.764-6.76 6.764-17.726 0-24.485-6.764-6.764-17.73-6.764-24.484 0L5.073 112.757c-6.764 6.76-6.764 17.727 0 24.485l106.768 106.775a17.252 17.252 0 0 0 12.242 5.072c4.43 0 8.861-1.689 12.242-5.072 6.764-6.76 6.764-17.726 0-24.484l-77.209-77.218H284.5c9.562 0 14.5-6.753 14.5-16.315 0-9.562-4.937-18.314-14.5-18.314Z"
        fill={_fill}
      />
    </svg>
  );
}
export { SvgComponent as ArrowLeft };
