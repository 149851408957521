import { t } from '@lingui/macro';
import { FC, useEffect, useState } from 'react';
import { InputSelect2 as InputSelect, InputSelect2Props as InputSelectProps } from '.';
import { useCountries } from '../../hooks';

export const InputCountry2: FC<InputSelectProps> = (props) => {
  const [countries] = useCountries();
  const [countriesOptions, setCountriesOptions] = useState([]);

  useEffect(() => {
    if (countries) {
      const mappedCountriesOptions = countries
        .map((c) => {
          return { key: c.code, label: c.name, value: c.code };
        })
        .filter((opt) => opt.value === 'CA');

      setCountriesOptions(mappedCountriesOptions);
    }
  }, [countries]);

  return <InputSelect placeholder={t`Choisir pays`} items={countriesOptions} {...props} />;
};
