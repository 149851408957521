import { IMAGEKIT_BASE_URL } from '@milo/constants';
import pathNormalize from 'path-normalize';
import { forwardRef, ForwardRefRenderFunction, ImgHTMLAttributes, useState } from 'react';
import { Spinner } from '../Loaders';

export type ImageKitImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  alt?: string;
  imageFileName?: string;
  src?: string;
  transforms?: string; // See transforms for syntax: https://docs.imagekit.io/features/image-transformations
};

export const getImageKitUrl = (imagefilePath: string, transforms: string = '') => {
  const imgUrl = 'https://' + pathNormalize(`${IMAGEKIT_BASE_URL}/${imagefilePath}`);
  return addTransformsToImageKitUrl(imgUrl, transforms);
};

export const addTransformsToImageKitUrl = (url: string, transforms: string) => {
  const tr = transforms ? `/tr:${transforms}` : '';
  return `${url}${tr}`;
};

const ImageKitImageRenderFunction: ForwardRefRenderFunction<HTMLImageElement, ImageKitImageProps> = (
  { imageFileName, transforms = '', src, alt = '', ...rest },
  ref
) => {
  const [loaded, setLoaded] = useState(false);

  const imgSource = (() => {
    if (imageFileName) return getImageKitUrl(imageFileName, transforms);
    return addTransformsToImageKitUrl(src, transforms);
  })();

  const classes = `flex items-center justify-center object-cover object-center block ${rest.className}`;

  if (!loaded) {
    return (
      <div className={classes}>
        <img loading="lazy" className="!invisible !h-0 !w-0" src={imgSource} onLoad={() => setLoaded(true)} />
        <Spinner width={45} thickness={3} color={'gray-light'} />
      </div>
    );
  }

  return <img alt={alt} ref={ref} loading="lazy" src={imgSource} {...rest} className={classes} />;
};

export const ImageKitImage = forwardRef(ImageKitImageRenderFunction);
