import { useAuthentication } from '../../hooks/useAuthentication';
import { Loading } from './Base';

export const LoggedOut = ({ children }) => {
  const { isLoading, loggedIn } = useAuthentication();

  if (isLoading) {
    return <Loading />;
  }

  if (loggedIn) {
    location.href = '/experiences';
    return <Loading />;
  }

  return <>{children}</>;
};
