import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { getIanaTimezone } from '@milo/helpers';
import { GraphQLClient } from 'graphql-request';
import config from '../config';
import { isNativeApp } from '../hooks/useDeviceInfo';

export type BaseClientRequestOptionsType = { locale?: 'fr' | 'en' };
export const BaseClientRequestOptions: BaseClientRequestOptionsType = { locale: 'fr' };

type GraphqlClientOptions = BaseClientRequestOptionsType & {};
const defaultGraphqlClientOptions: GraphqlClientOptions = {
  ...BaseClientRequestOptions,
};

class MobileGraphqlClient {
  apiUrl: string;
  headers: { [x: string]: any };

  constructor(url, options = { headers: {} }) {
    this.apiUrl = url;
    this.headers = {
      ...options.headers,
    };
  }

  async request(query: string, variables?: { [x: string]: any }): Promise<HttpResponse> {
    const data = { query, variables };

    const options = {
      url: `${this.apiUrl}`,
      headers: this.headers,
      data,
    };

    const response = (await CapacitorHttp.request({ ...options, method: 'POST' })).data;

    if ((response?.errors ?? []).length > 0) {
      throw response.errors[0];
    }

    return response.data;
  }
}

export type HybridGraphqlClient = GraphQLClient | MobileGraphqlClient;

const newRequest = new Proxy(GraphQLClient.prototype.request, {
  apply: (target, thisArg, args) => {
    const [document] = args;

    // Extract the query or mutation name and pass id to the client for easily identifying
    // the backend query that is called while debugging or checking network requests
    const queryName: string =
      document
        .replace(/\s/gm, '')
        .replace(/(mutation|query)/gm, '')
        .match(/^([a-zA-Z]+)/gm)?.[0] || '';

    const newUrl = new URL(thisArg.url);
    thisArg.url = `${newUrl.origin}${newUrl.pathname}?query=${queryName}`;

    return target.call(thisArg, ...args);
  },
});

GraphQLClient.prototype.request = newRequest;

const getApiDomain = () => {
  // If mobile or server side return the config value
  if (isNativeApp() || typeof window === 'undefined') {
    return config.API_DOMAIN;
  }

  // Otherwise use the hostname value and let NextJS redirect from it's
  // server to the graphql API so that the frontend url is flexible and doesn't get CORS
  return window.location.hostname;
};

let graphqlClient = null;

export const getGraphqlClient: (options?: GraphqlClientOptions) => HybridGraphqlClient = (
  options = BaseClientRequestOptions
) => {
  if (graphqlClient) return graphqlClient;

  const apiDomain = getApiDomain();
  const apiUrl = `https://${apiDomain}/api/graphql`;
  const headers = {
    'x-locale': options.locale,
    'x-timezone-iana': getIanaTimezone(),
    'Content-Type': 'application/json',
  };

  // Using 2 different graphql clients that support the same API between
  // web and mobile to be able to use the same hook everywhere
  const GQLClient = isNativeApp() ? MobileGraphqlClient : GraphQLClient;

  return new GQLClient(apiUrl, { headers });
};

export const useGraphqlClient: (options?: GraphqlClientOptions) => {
  client: HybridGraphqlClient;
} = (options = defaultGraphqlClientOptions) => {
  if (!graphqlClient) {
    graphqlClient = getGraphqlClient(options);
  }

  return { client: graphqlClient };
};
