import { t } from '@lingui/macro';
import { Experience } from '@milo/types/index';
import { FC } from 'react';
import { useAuthentication } from '../../hooks';
import { StepperModal } from '../Stepper';
import { ValidationAmount } from './ValidationAmount';
import { ValidationConfirmed } from './ValidationConfirmed';
import { ValidationDiscovery } from './ValidationDiscovery';
import { ValidationError } from './ValidationError';
import { ValidationSavings } from './ValidationSavings';

type ValidationBottomSheetProps = {
  experience: Experience;
  onClose?: () => void;
};

const steps = {
  // info: {
  //   Component: ValidationInfo,
  //   displayBackButton: false,
  //   displayCloseButton: true,
  //   fullWidth: true,
  // },
  discovery: {
    Component: ValidationDiscovery,
    displayBackButton: false,
    displayCloseButton: true,
    fullWidth: true,
  },
  amount: {
    Component: ValidationAmount,
    displayBackButton: false,
    displayCloseButton: true,
    fullWidth: true,
  },
  savings: {
    Component: ValidationSavings,
    displayBackButton: true,
    fullWidth: true,
  },
  confirmed: {
    Component: ValidationConfirmed,
    fullWidth: true,
    displayBackButton: false,
    displayCloseButton: true,
  },
  error: {
    Component: ValidationError,
    fullWidth: true,
    displayBackButton: false,
    displayCloseButton: true,
  },
  //   rating: {
  //     Component: ValidationRating,
  //     displayBackButton: true,
  //     displayCloseButton: false,
  //     fullWidth: true,
  //   },
};

export const ValidationBottomSheet: FC<ValidationBottomSheetProps> = ({ experience, onClose = () => {} }) => {
  const { user } = useAuthentication();

  return (
    <StepperModal
      title={t`Membre: ${user?.member_code}`}
      steps={steps}
      initialStep="discovery"
      initialState={{ experience, visit: null, onClose }}
    />
  );
};
