export const ErrorIcon = (props) => (
  <svg viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={30.5} cy={30.285} r={30} fill="#E33E38" fillOpacity={0.1} />
    <path
      d="M43.411 39.285H18.588a.333.333 0 0 1-.292-.494l12.412-22.567a.333.333 0 0 1 .584 0l12.411 22.567a.333.333 0 0 1-.292.494Z"
      stroke="#fff"
      strokeWidth={2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 24.285a1.84 1.84 0 0 0-1.834 1.993l.39 4.678a1.45 1.45 0 0 0 2.888 0l.39-4.678A1.84 1.84 0 0 0 31 24.285Zm-.067 12a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333Z"
      fill="#FFFFFF"
    />
    <path
      d="M43.411 39.285H18.588a.333.333 0 0 1-.292-.494l12.412-22.567a.333.333 0 0 1 .584 0l12.411 22.567a.333.333 0 0 1-.292.494Z"
      stroke="#E33E38"
      strokeWidth={2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 24.285a1.84 1.84 0 0 0-1.834 1.993l.39 4.678a1.45 1.45 0 0 0 2.888 0l.39-4.678A1.84 1.84 0 0 0 31 24.285Zm-.067 12a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333Z"
      fill="#E33E38"
    />
  </svg>
);
