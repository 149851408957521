import { createContext, useState } from 'react';
import { useIsomorphicLayoutEffect } from '../../hooks';
import { ReactFC } from '../../types';
import { breakpointFromWidth, breakPoints } from '../../utils';

const ThemeContext = createContext<any>({});

const getDevice = (width: number, height: number) => {
  if (width < 500) {
    return 'mobile';
  }

  if (width >= 500 && width < 1000) {
    return 'tablet';
  }

  return 'desktop';
};

export const ThemeProvider: ReactFC = ({ children }) => {
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [ready, setReady] = useState(false);

  const device = getDevice(width, height);
  const breakpoint = breakpointFromWidth(width);

  useIsomorphicLayoutEffect(() => {
    setWidth(window.innerWidth);

    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });

    return window.removeEventListener('resize', window);
  });

  const inBreakpoint = (breakpoint: keyof typeof breakPoints) => {
    return width >= breakPoints[breakpoint];
  };

  const lessThanBreakpoint = (breakpoint: keyof typeof breakPoints) => {
    return width < breakPoints[breakpoint];
  };

  const lessThanWindowSize = (size: number) => {
    return width < size;
  };

  return (
    <ThemeContext.Provider
      value={{
        height,
        width,
        device,
        breakpoint,
        inBreakpoint,
        lessThanBreakpoint,
        ready,
        lessThanWindowSize,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
