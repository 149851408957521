import { t } from '@lingui/macro';

import { FC } from 'react';
import { InputSelect2, InputSelect2Props as InputSelectProps } from '.';

export const InputGender2: FC<InputSelectProps> = (props) => {
  return (
    <InputSelect2
      placeholder={t`Choisir genre...`}
      items={[
        { label: t`Homme`, value: 'm' },
        { label: t`Femme`, value: 'f' },
        { label: t`Autre`, value: 'o' },
      ]}
      {...props}
      data-testid="input-gender"
    />
  );
};
