import { t, Trans } from '@lingui/macro';
import {
  FlowContainer,
  MiloFull,
  SubscriptionAlreadyActive,
  Text,
  UpdateUserInformationForm,
  useAuthentication,
  useGraphqlClient,
} from '@milo/react';
import { AlreadyHasFreeVisit, AlreadyUsedFreeVisit } from '.';

const freeVisitSuccessUrl = '/free-visit/success';

export const FreeVisitProfilePage = () => {
  const { user, isLoading, subscribed, loggedIn } = useAuthentication();
  const { client } = useGraphqlClient();

  if (isLoading) return null;

  if (subscribed) {
    return (
      <FlowContainer>
        <SubscriptionAlreadyActive title={t`Pas besoin d'obtenir une visite gratuite 🙂`} />
      </FlowContainer>
    );
  }

  if (!loggedIn) {
    location.replace('/free-visit/login');
    return null;
  }

  const pendingFreeVisit = user?.pending_free_visit;
  if (pendingFreeVisit?.id) {
    return (
      <FlowContainer>
        <AlreadyHasFreeVisit visit={pendingFreeVisit} />
      </FlowContainer>
    );
  }

  const usedFreeVisit = user?.last_used_free_visit;
  if (usedFreeVisit?.id) {
    return (
      <FlowContainer>
        <AlreadyUsedFreeVisit visit={usedFreeVisit} />
      </FlowContainer>
    );
  }

  const freeVisitCreate = async () => {
    await client.request(/* Graphql */ `
      mutation FreeVisitCreate {
        FreeVisitCreate {
          success
        }
      }
    `);
  };

  const handleSuccess = async () => {
    await freeVisitCreate();
    location.href = freeVisitSuccessUrl;
  };

  return (
    <FlowContainer>
      <MiloFull className="mb-5 w-40" />
      <div className="mb-8">
        <Text size="h1" className="mb-2 font-bold">
          <Trans>Complétez votre profil M ta région</Trans>
        </Text>
      </div>
      <UpdateUserInformationForm onSuccess={handleSuccess} showHeaderText={false} />
    </FlowContainer>
  );
};
