import cn from 'classnames';
import { ReactFC } from '../../types';
import { colorFn, ColorType } from '../../utils';

export type SpinnerProps = React.HTMLAttributes<HTMLDivElement> & {
  color?: ColorType;
  thickness?: number;
  width?: number;
};

export const Spinner: ReactFC<SpinnerProps> = ({ className, color = 'currentColor', thickness = 3, width = 25 }) => {
  const computedStyles = {
    borderWidth: thickness,
    borderColor: colorFn(color as ColorType, 0.1),
    borderTopColor: colorFn('gray-light', 0.5),
  };

  return <div className={cn(className, 'h-5 w-5 animate-spin rounded-full')} style={computedStyles} />;
};
