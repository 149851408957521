import { colorFn, ColorType } from '../utils';

export const LinkIcon = ({ fill = 'currentColor' as ColorType, ...props }) => {
  const _fill = colorFn(fill);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-30 -30 340 340" xmlSpace="preserve" {...props}>
      <path
        fill={_fill}
        d="m265.117 22.764-9.877-8.737c-23.003-20.398-58.227-18.283-78.618 4.726l-28.267 31.89c-6.38 7.199-5.717 18.251 1.479 24.637l2.653 2.354c7.221 6.402 18.239 5.741 24.646-1.481l28.265-31.889c6.305-7.107 17.227-7.761 24.338-1.466l9.865 8.752c7.113 6.303 7.783 17.223 1.469 24.334l-61.808 69.726a17.214 17.214 0 0 1-20.816 3.875 18.86 18.86 0 0 0-22.787 4.245l-.482.544a18.76 18.76 0 0 0-4.439 15.943 18.772 18.772 0 0 0 9.825 13.313 55.643 55.643 0 0 0 25.814 6.358 55.625 55.625 0 0 0 41.67-18.775l61.804-69.718c20.368-22.978 18.248-58.247-4.734-78.631z"
      />
      <path
        fill={_fill}
        d="m133.998 208.581-2.659-2.356c-7.204-6.383-18.259-5.712-24.64 1.489L78.445 239.6c-6.308 7.105-17.222 7.764-24.327 1.473l-9.879-8.764c-7.115-6.301-7.783-17.212-1.467-24.325l61.806-69.721c5.124-5.787 13.555-7.442 20.504-4.028 7.986 3.924 17.683 2.016 23.595-4.656l.222-.25a18.496 18.496 0 0 0 4.386-15.614 18.496 18.496 0 0 0-9.533-13.119c-22.828-12.22-50.769-7.22-67.947 12.165l-61.81 69.707v.001c-20.371 22.978-18.252 58.246 4.726 78.622l9.877 8.749c10.583 9.383 23.77 13.992 36.913 13.992 15.394 0 30.724-6.327 41.718-18.724l28.258-31.886c6.393-7.219 5.737-18.235-1.489-24.641z"
      />
    </svg>
  );
};
