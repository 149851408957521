function SvgComponent({ fill = 'currentColor', ...props }) {
  return (
    <svg viewBox="0 0 73 74" fill="none" {...props}>
      <path
        d="M33.875 56.599c-.6-.1-1.1.1-1.4.5l-8.2 8.2c-1.6 1.6-3.6 2.8-5.8 3.2-3.7.7-7.3-.4-9.9-3-2.1-2.1-3.2-4.8-3.2-7.7 0-2.9 1.1-5.7 3.2-7.7l14.2-14.2c2.7-2.7 6.5-3.8 10.3-2.9 1.1.3 2.2.7 3.1 1.3 1.3.8 2.4 1.8 3.2 3 .7 1 2 1.2 2.9.4.1 0 .1-.1.1-.1.8-.6 1-1.8.4-2.6-1.1-1.7-2.7-3.2-4.5-4.3-1.2-.7-2.4-1.3-3.8-1.6-5.2-1.3-10.4.2-14 3.8l-15.2 15c-5.7 5.7-5.7 14.9 0 20.5 2.7 2.7 6.4 4.3 10.3 4.3 3.9 0 7.5-1.5 10.3-4.3l9.1-9.1c.9-.9.3-2.6-1.1-2.7Z"
        fill={fill}
        stroke={fill}
      />
      <path
        d="M46.075 45.201c-.6-.7-1.6-.9-2.5-.4-2.4 1.4-5.3 1.8-8.2 1.1-1.2-.3-2.3-.8-3.3-1.5-1.1-.8-2.1-1.7-2.9-2.8-.7-1-2-1.1-2.9-.4-.1 0-.1.1-.2.1-.7.6-.9 1.8-.4 2.6s1.1 1.5 1.8 2.2c2.7 2.7 6.4 4.3 10.3 4.3 2.8 0 5.5-.8 7.8-2.3 1-.6 1.2-1.9.5-2.9ZM54.375 21.7c-.2 1 .4 2.1 1.4 2.3l.3.1c1.1.3 2.3-.4 2.5-1.5 1.9-8.9.3-15.1-4.9-18.5-.4-.3-1.7-1-2.1-1.3-2.1-1.1-4.5-1.8-6.9-1.8-5.3 0-10.4 1.5-13.8 7.6l-5.8 10.9c-.8 1.4.4 3.2 2.1 2.9h.2c.7-.1 1.3-.5 1.6-1.1l5.5-10.2c1.9-3.5 5.6-5.7 9.6-5.7 1.8 0 3.6.5 5.3 1.3 3.8 2.3 6.9 5.7 5 15ZM62.275 32.2l4.9-.2c1.1 0 1.9-.9 1.8-2-.1-1.1-.9-1.9-2-1.8l-4.9.2c-1.1 0-1.9.9-1.8 2 .1 1.1.9 1.9 2 1.8ZM71.175 41.699l-10.1-4.1c-1-.4-2.1.1-2.5 1.1-.4 1 .1 2.1 1.1 2.5l10.1 4.1c1 .4 2.1-.1 2.5-1.1.3-1-.1-2.1-1.1-2.5ZM57.675 45c-.7-.8-1.9-.8-2.7-.1-.8.7-.8 1.9-.1 2.7l4.5 4.7c.7.8 1.9.8 2.7.1.8-.7.8-1.9.1-2.7l-4.5-4.7Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
export { SvgComponent as BrokenLink };
