import { colorFn, ColorType } from '../utils';

function SvgComponent({ fill = 'currentColor' as ColorType, ...props }) {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="-0.5 -1 17 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5V4a2 2 0 1 0-4 0v1h4ZM4 4v1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2V4a4 4 0 0 0-8 0Zm6 3h3v6H3V7h7ZM8 8a1 1 0 0 0-1 1v2a1 1 0 1 0 2 0V9a1 1 0 0 0-1-1Z"
        fill={_fill}
      />
    </svg>
  );
}
export { SvgComponent as Lock };
