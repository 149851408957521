const SvgComponent = (props) => (
  <svg viewBox="0 0 68 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M27.455 30.09v14.668l8.663-8.74M59.455 7.425l-50.667 20 18.667 2.667 32-22.667Zm0 0-6.667 40-25.333-17.333 32-22.667Zm0 0-32 22.667 32-22.667Z"
      stroke="currentColor"
      strokeWidth={2.954}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.73 42.078a1.477 1.477 0 1 0-1.755-2.375c-2.657 1.964-6.605 2.51-10.722 2.51a1.477 1.477 0 0 0 0 2.955c4.203 0 9.002-.52 12.478-3.09Zm22.826 4.781a1.477 1.477 0 0 0-1.806-2.337c-4.288 3.314-8.191 5.424-12.474 6.764-4.298 1.345-9.05 1.936-15.07 2.129a1.477 1.477 0 1 0 .094 2.952c6.15-.196 11.2-.805 15.858-2.262 4.675-1.463 8.886-3.759 13.398-7.245Z"
      fill="currentColor"
    />
  </svg>
);
export { SvgComponent as PaperAirplaneIcon };
