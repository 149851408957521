import { CapacitorHttp } from '@capacitor/core';
import config from '../config';
import { isNativeApp } from './useDeviceInfo';

type ApiURL = `https://${string}`;

type HttpRequestOptions = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD';
  url?: `https://${string}`;
  path?: `/${string}`;
  headers?: { [x: string]: any };
  data?: { [x: string]: any };
};

type HttpResponse<ResponseType> = {
  data: ResponseType;
  error: any;
  statusCode: number;
};

class HttpClient {
  apiUrl: ApiURL = `https://${config.APP_DOMAIN}`;

  request: <ResponseType>(options: HttpRequestOptions) => Promise<HttpResponse<ResponseType>> = async (options) => {
    const params = {
      headers: {
        'x-locale': 'fr',
        'Content-Type': 'application/json',
        ...(options.headers ?? {}),
      },
      method: options.method,
      url: options.path ? `${this.apiUrl}${options.path}` : options.url,
    };

    if (!['GET', 'HEAD'].includes(options.method)) {
      params['data'] = options.data ?? {};
    }

    if (isNativeApp()) {
      const nativeResponse = await CapacitorHttp.request({
        ...params,
      });

      return {
        data: nativeResponse.data,
        error: null,
        statusCode: nativeResponse.status,
      };
    }

    const webResponse = await fetch(params.url, {
      body: JSON.stringify(params.data),
      method: params.method,
      headers: params.headers,
    });

    return {
      data: await webResponse.json(),
      error: null,
      statusCode: webResponse.status,
    };
  };
}

let httpClient: HttpClient;

export const createHttpClient: () => HttpClient = () => {
  if (!httpClient) {
    httpClient = new HttpClient();
  }
  return httpClient;
};

export const useHttpClient: () => HttpClient = () => {
  return createHttpClient();
};
