import React from 'react';
import { MiloFull } from '../../logos';
import { ImageKitImage } from '../Image';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('🚀 ~ file: ErrorBoundary.tsx:19 ~ ErrorBoundary ~ componentDidCatch ~ errorInfo:', errorInfo);
    console.log('🚀 ~ file: ErrorBoundary.tsx:19 ~ ErrorBoundary ~ componentDidCatch ~ error:', error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex h-screen w-screen items-center justify-center text-center text-xl font-semibold">
          <div className="flex flex-col gap-2">
            <div className="mb-12">
              <ImageKitImage className="mx-auto mb-5 w-12" imageFileName="/web/error.gif?error" />
              <h1>Une erreur est survenue</h1>
              <h1>Nous travaillons présentement à la résoudre le plus rapidement possible</h1>
            </div>
            <MiloFull className="mt-auto h-12" />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
