import { Trans } from '@lingui/macro';
import { Experience } from '@milo/types/index';
import { FC, useMemo } from 'react';
import { Skeleton } from '../Skeleton';
import { ResultExperienceCard } from './ResultExperienceCard';

export type SearchProps = {
  experiences?: Experience[];
};

const SearchResultsContainer = ({ isEmpty = false, ...props }) => {
  if (isEmpty) {
    return <div className="py-4" {...props} />;
  }

  return (
    <div
      className="grid animate-fade-in-top grid-cols-2 gap-5 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6"
      data-testid="exp-search-results"
      {...props}
    />
  );
};

export const Search: FC<SearchProps> = ({ experiences }) => {
  const experiencesLoading = !experiences;
  const resultsEmpty = experiences?.length < 1 && !experiencesLoading;

  const content = useMemo(() => {
    if (experiencesLoading) {
      return <Skeleton className="h-48 rounded-2xl" times={1000} />;
    }

    if (resultsEmpty) {
      return (
        <>
          <p className="mb-2 animate-fade-in text-center text-2xl font-bold">
            <Trans>Que recherchez vous?</Trans>
          </p>
          <p className="mx-auto max-w-lg animate-fade-in text-center font-bold">
            <Trans>
              Ajustez les filtres et paramètres de recherche pour obtenir des résultats spécifiques à vos goûts.
            </Trans>
          </p>
        </>
      );
    }

    return experiences?.map((exp) => <ResultExperienceCard key={exp.id} experience={exp} />);
  }, [experiences]);

  return <SearchResultsContainer isEmpty={resultsEmpty}>{content}</SearchResultsContainer>;
};
