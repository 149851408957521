export const MiloMap = (props) => {
  return (
    <svg viewBox="0 0 675 85" fill="none" {...props}>
      <path
        d="M479.6 7L475.9 14H478.9C481.4 14 482.5 13 486.4 7.5C488.9 3.9 491 0.7 491 0.5C491 0.2 489.3 -2.08616e-07 487.2 -2.08616e-07C483.5 -2.08616e-07 483.3 0.2 479.6 7Z"
        fill="black"
      />
      <path
        d="M560.2 3.2C557.4 6 559.5 11 563.5 11C566.2 11 568 9.2 568 6.5C568 3.8 566.2 2 563.5 2C562.3 2 560.9 2.5 560.2 3.2Z"
        fill="black"
      />
      <path
        d="M0 34.5V66H6H12L12.1 44.2V22.5L15.9 32C18 37.2 21.9 47 24.5 53.7L29.2 66H33.4H37.5L45.4 45.6C49.7 34.4 53.6 24.6 54.1 23.9C54.6 23.1 55 31.7 55 44.2V66H61H67V34.5V3H59.1H51.3L47.7 12.2C37 40 33.8 48 33.1 47.6C32.7 47.3 28.6 37.2 23.9 25.1L15.5 3H7.8H0V34.5Z"
        fill="black"
      />
      <path
        d="M243 34.5V66H246.5H250V42C250 28.8 250.3 18 250.8 18C251.2 18 254.4 25.1 257.9 33.8C272 68.4 270.8 66 274.5 66H277.7L287.6 41.9L297.5 17.9L297.8 41.9L298 66H302H306V34.5V3.00002H301.2H296.4L285.5 29.7L274.6 56.3L272.8 52.4C271.9 50.3 266.9 38.3 261.8 25.8L252.5 3.00002H247.8H243V34.5Z"
        fill="black"
      />
      <path
        d="M341 13.4001V20.0001H337.5C334.3 20.0001 334 20.2001 334 23.0001C334 25.8001 334.3 26.0001 337.5 26.0001H341V41.5001C341 62.1001 342.3 65.4001 351 66.0001C357.4 66.5001 358 66.2001 358 63.0001C358 60.3001 357.7 60.0001 355.1 60.0001C349.5 60.0001 349 58.5001 349 41.4001V26.0001H353.5C357.8 26.0001 358 25.9001 358 23.0001C358 20.1001 357.8 20.0001 353.5 20.0001H349.1L348.8 13.7001L348.5 7.50013L344.8 7.20013L341 6.90013V13.4001Z"
        fill="black"
      />
      <path
        d="M201.5 27.4C191.3 31.8 189.3 44.6 197.5 51.9C203.9 57.5 212.2 56.9 217.9 50.5C223.2 44.4 222.9 36.2 217.2 30.7C212.4 26.1 207 25 201.5 27.4Z"
        fill="#E62152"
      />
      <path
        d="M86 20.2001C81.7 22.4001 77 27.8001 77 30.6001C77 31.6001 78.6 32.0001 82.6 32.0001C87 32.0001 88.5 31.6001 89.8 29.9001C90.8 28.7001 93.2 27.7001 95.5 27.3001C100.7 26.7001 105 29.7001 105 34.0001C105 37.2001 104.6 37.4001 92.2 38.4001C80.9 39.4001 75 44.3001 75 52.7001C75 65.5001 92.5 71.4001 101.9 61.8001L105 58.6001V62.3001V66.0001H111.6H118.2L117.8 47.7001C117.5 31.6001 117.3 29.1001 115.5 26.0001C112.5 20.8001 106.2 18.0001 97.5 18.0001C92.1 18.0001 89.3 18.5001 86 20.2001ZM105 48.1001C105 53.0001 100.3 57.0001 94.6 57.0001C91.1 57.0001 89.8 56.5001 88.6 54.8001C84.5 49.0001 89.2 45.6001 101.8 45.1001C104.7 45.0001 105 45.2001 105 48.1001Z"
        fill="black"
      />
      <path
        d="M127 50.0001V82.1001L133.3 81.8001L139.5 81.5001L139.8 70.2001L140.1 58.9001L143 61.9001C149.8 68.6001 160.8 68.3001 167.3 61.1001C171.9 56.0001 173.5 51.1001 173.4 42.0001C173.3 26.4001 166.9 18.0001 155 18.0001C149.2 18.0001 145.1 19.8001 142.2 23.5001L140.1 26.1001L139.8 22.3001L139.5 18.5001L133.3 18.2001L127 17.9001V50.0001ZM155 29.3001C160.3 31.9001 162.6 42.2001 159.6 49.4001C156.1 57.9001 145.2 58.3001 141 50.2001C139.6 47.4001 139.5 38.5001 140.9 34.9001C143 29.1001 149.5 26.5001 155 29.3001Z"
        fill="black"
      />
      <path
        d="M376.5 20.1002C373.5 20.9002 367 26.4002 367 28.0002C367 28.5002 366.7 29.5002 366.4 30.4002C365.9 31.7002 366.6 32.0002 369.8 32.0002C372.9 32.0002 374 31.6002 374.5 30.1002C375 28.3002 378.7 26.3002 382 25.9002C389.4 25.1002 394 28.5002 394 34.8002V38.7002L385.3 39.2002C371.3 40.1002 365.9 43.2002 364.6 51.1002C362.3 65.0002 382.4 72.5002 391.7 61.2002L393.9 58.5002L394 62.2002C394 66.0002 394 66.0002 398.1 66.0002H402.2L401.8 47.7002C401.5 31.7002 401.3 29.1002 399.5 26.2002C395.8 20.1002 385.6 17.4002 376.5 20.1002ZM393 54.0002C388.6 62.3002 372 61.6002 372 53.1002C372 47.5002 376.1 45.5002 389 44.7002L393.5 44.5002L393.8 48.2002C394 50.3002 393.6 52.9002 393 54.0002Z"
        fill="black"
      />
      <path
        d="M446.3 20.9C444.6 22 442.7 23.7 442.1 24.7C441.2 26.2 441.1 26 441 23.2C441 20.1 440.8 20 437 20H433V43V66H437H441V50C441 39.8 441.4 33.2 442.2 31.5C443.7 28.2 447.8 26 452.5 26C455.8 26 456 25.8 456 22.5C456 19.2 455.8 19 452.8 19C451 19 448.1 19.9 446.3 20.9Z"
        fill="black"
      />
      <path
        d="M472 20.3001C466.7 22.8001 464 25.4001 461.5 30.3001C459.5 34.4001 459 36.9001 459 43.0001C459 56.8001 465.5 65.0001 477.6 66.5001C482.5 67.1001 483.8 66.8001 488.8 64.3001C493.9 61.8001 499 56.5001 499 53.9001C499 53.4001 497.5 53.0001 495.7 53.0001C493.4 53.0001 491.7 53.9001 489.7 56.0001C486.3 59.5001 483.4 60.4001 477.8 59.7001C472.9 59.0001 468.4 55.0001 467 50.1001C466.5 48.1001 466 46.2001 466 45.7001C466 45.3001 473.7 45.0001 483.1 45.0001H500.1L499.5 40.2001C498.1 29.8001 494.1 23.3001 487.2 20.5001C483.1 18.7001 475.5 18.7001 472 20.3001ZM486.1 27.7001C488.6 29.2001 492 35.1001 492 38.0001C492 38.6001 487 39.0001 479.5 39.0001C467.6 39.0001 467 38.9001 467 37.0001C467 34.1001 469.6 29.4001 472.2 27.6001C475.2 25.5001 482.5 25.6001 486.1 27.7001Z"
        fill="black"
      />
      <path
        d="M518.5 20.4001C511.1 23.7001 506.8 31.7001 506.8 42.2001C506.8 55.3001 513.5 63.9001 524.4 64.8001C530.4 65.3001 535.1 63.4001 538.7 59.1001L540.9 56.5001L541 61.8001C541 70.4001 538.5 73.5001 529.9 75.5001C526.4 76.4001 519.7 74.5001 517.5 72.0001C515.1 69.3001 507.7 69.1001 508.7 71.7001C513.1 84.1001 538 85.7001 545.7 74.1001C547.9 70.8001 548 70.0001 548 45.4001V20.0001H544.5C541.1 20.0001 541 20.1001 541 23.7001V27.4001L537.8 24.3001C532.3 19.0001 525 17.5001 518.5 20.4001ZM535.7 28.2001C540.7 32.3001 542.4 43.4001 539.1 50.7001C536.6 56.0001 533.3 58.2001 527.5 58.2001C521.7 58.2001 518.4 56.0001 515.9 50.7001C512.6 43.4001 514.3 32.3001 519.3 28.1001C523.1 25.0001 532 25.1001 535.7 28.2001Z"
        fill="black"
      />
      <path
        d="M588.2 21.1002C580.5 25.0002 576.7 32.3002 576.8 43.7002C576.9 61.8002 592.5 71.8002 608.2 63.9002C619.6 58.1002 622.7 37.4002 613.7 26.5002C607.7 19.2002 596.8 16.9002 588.2 21.1002ZM606.2 28.7002C609.4 31.5002 611 36.3002 611 43.1002C611 53.9002 606.1 60.0002 597.3 60.0002C586.7 60.0002 580.5 45.4002 585.9 33.3002C589.5 25.6002 600 23.1002 606.2 28.7002Z"
        fill="black"
      />
      <path
        d="M642.3 20.5C640.5 21.2 638.5 22.8 637.8 23.9C636 26.8 635 26.5 635 23C635 20.2 634.7 20 631.5 20H628V43V66H631.4H634.8L635.2 50.2C635.4 37.8 635.9 33.8 637.2 31.4C641.1 24.3 653.8 23.8 656.9 30.7C657.6 32.1 658 39.6 658 49.5V66H662.1H666.2L665.8 47.7C665.5 31.7 665.3 29.1 663.5 26.1C659.7 19.7 650 17.1 642.3 20.5Z"
        fill="black"
      />
      <path d="M560 43V66H563.5H567V43V20H563.5H560V43Z" fill="black" />
    </svg>
  );
};
