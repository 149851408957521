import { ColorType } from '../utils';

export type MiloSVGProps = {
  fill?: ColorType;
  stroke?: ColorType;
};

export * from '../icons-new';
export * from './AppStoreDownload';
export * from './ArrowDown';
export * from './ArrowLeft';
export * from './ArrowRight';
export * from './ArrowUp';
export * from './BasicArrowLeft';
export * from './BasicArrowRight';
export * from './BookIcon';
export * from './BrokenCloud';
export * from './BrokenLink';
export * from './CellPhone';
export * from './ChartIcon';
export * from './CheckCircle';
export * from './CheckCircleAlt';
export * from './CheckCircleValidation';
export * from './CheckMark';
export * from './Chevron';
export * from './CogIcon';
export * from './CollapseIcon';
export * from './Contract';
export * from './CrossIcon';
export * from './CurrentPosition';
export * from './Disconnected';
export * from './Discount';
export * from './DocumentIcon';
export * from './DownloadIcon';
export * from './EditIcon';
export * from './Email';
export * from './emojis/BackPack';
export * from './emojis/Luggage';
export * from './emojis/Package';
export * from './EmptyInbox';
export * from './Enveloppe';
export * from './ErrorIcon';
export * from './ExclamationTriangle';
export * from './ExpandIcon';
export * from './EyeClosedIcon';
export * from './EyeIcon';
export * from './FacebookIcon';
export * from './Filter';
export * from './Fire';
export * from './Fullscreen';
export * from './Gift';
export * from './Globe';
export * from './GooglePlayDownload';
export * from './Heart';
export * from './HomeIcon';
export * from './InfoCircle';
export * from './InfoCircleBold';
export * from './InstagramIcon';
export * from './LightBulbIcon';
export * from './LinkedInIcon';
export * from './LinkIcon';
export * from './Lock';
export * from './LoginIcon';
export * from './LogoutIcon';
export * from './MagnifyingGlass';
export * from './Map';
export * from './MapAlt';
export * from './MapPinAlt';
export * from './MapPinAlt2';
export * from './Message';
export * from './Modal';
export * from './PaperAirplaneIcon';
export * from './PartnerIcon';
export * from './Pause';
export * from './Play';
export * from './PlusIcon';
export * from './QuestionCircle';
export * from './ShareIcon';
export * from './Star';
export * from './TrashIcon';
export * from './TutorialLinkIcon';
export * from './TwitterIcon';
export * from './User';
export * from './verticals';
export * from './Visited';
export * from './WavingHand';
export * from './YoutubeIcon';
export * from './ZoomIn';
export * from './ZoomOut';
