export const CogIcon = (props) => (
  <svg viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 18a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"
      stroke="currentColor"
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m22.893 12.628-1.28-3.092L23.334 7.5l-2.333-2.333-2.025 1.73-3.158-1.299-.726-2.764h-2.28l-.737 2.8-3.086 1.301-1.988-1.768L4.667 7.5l1.696 2.087-1.261 3.1-2.768.647v2.333l2.801.765 1.3 3.085L4.668 21.5l2.334 2.334 2.09-1.703 3.04 1.25.703 2.786h2.333l.706-2.785 3.092-1.28C19.48 22.47 21 23.834 21 23.834l2.333-2.334-1.732-2.042 1.282-3.093 2.783-.725v-2.306l-2.774-.706Z"
      stroke="currentColor"
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
