import classNames from 'classnames';
import { ButtonHTMLAttributes, SVGProps } from 'react';
import { ReactFC } from '../../types';
import { Spinner } from '../Loaders/Spinner';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'teal' | 'dark' | 'header-login' | 'muted';

export type ExtraButtonProps = {
  loading?: boolean;
  disabled?: boolean;
  variant?: ButtonVariant;
  StartIcon?: any;
  EndIcon?: any;
  block?: boolean | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  onPress?: () => void; // backwards compatibility
  size?: 'sm' | 'md';
};

export const baseButtonClassNames = ({ variant = 'primary', size, block = false }: ExtraButtonProps) =>
  classNames(
    'relative border select-none leading-none items-center text-center justify-center rounded-2xl font-semibold',
    {
      'bg-raspberry text-white border-transparent shadow-lg': variant === 'primary',
      'bg-white text-charcoal shadow-lg': variant === 'secondary',
      'bg-transparent text-charcoal underline border-transparent': variant === 'tertiary',
      'bg-teal text-white border-transparent shadow-lg': variant === 'teal',
      'bg-charcoal text-white border-transparent shadow-lg': variant === 'dark',
      'bg-transparent border-none text-medium': variant === 'muted',

      'py-2 px-4 sm:py-3 sm:px-6 bg-transparent border border-white text-white':
        variant === 'header-login' && size === undefined,

      // padding size
      'py-4 px-8': variant !== 'header-login' && size === undefined,
      'py-2 px-4': size === 'sm',
      'py-3 px-6': size === 'md',
      '!px-0': variant === 'tertiary',

      'w-full flex': block === true,
      'inline-flex': block === false,
      'w-full flex sm:w-auto sm:inline-flex': block === 'sm',
      'w-full flex md:w-auto md:inline-flex': block === 'md',
      'w-full flex lg:w-auto lg:inline-flex': block === 'lg',
      'w-full flex xl:w-auto xl:inline-flex': block === 'xl',
      'w-full flex 2xl:w-auto 2xl:inline-flex': block === '2xl',
    }
  );

export type Button2Props = ButtonHTMLAttributes<HTMLButtonElement> & ExtraButtonProps;

export const Button2: ReactFC<Button2Props> = ({
  children,
  className = '',
  variant = 'primary',
  loading = false,
  disabled,
  StartIcon,
  EndIcon,
  block,
  onPress,
  size,
  type = 'button',
  ...buttonProps
}) => {
  const buttonClassName = classNames(baseButtonClassNames({ variant, loading, block, size, disabled }), className);

  const TrailingIcon: ReactFC<SVGProps<SVGElement>> = (props) => {
    if (loading) return <Spinner />;
    if (EndIcon) return <EndIcon {...props} />;
    return null;
  };

  return (
    <button
      type={type}
      className={buttonClassName}
      disabled={disabled || loading}
      onClick={onPress ?? buttonProps.onClick}
      {...buttonProps}
    >
      {disabled && <div className="absolute h-full w-full cursor-not-allowed rounded-2xl bg-white opacity-20" />}

      {StartIcon !== undefined && !loading && (
        <span className="mr-2">
          <StartIcon fill="currentColor" width="18" />
        </span>
      )}
      <>{children}</>
      {(EndIcon || loading) && (
        <span className="ml-2">
          <TrailingIcon fill="currentColor" width="18" />
        </span>
      )}
    </button>
  );
};
