import { colorFn, ColorType } from '../utils';

function SvgComponent({ fill = 'currentColor' as ColorType, ...props }) {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="0 0 22 21" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9726 9.90114C17.9726 14.0828 14.5827 17.4726 10.4011 17.4726C6.21953 17.4726 2.82967 14.0828 2.82967 9.90114C2.82967 5.71953 6.21953 2.32967 10.4011 2.32967C14.5827 2.32967 17.9726 5.71953 17.9726 9.90114ZM16.9409 17.3352C15.1967 18.8709 12.9077 19.8023 10.4011 19.8023C4.93288 19.8023 0.5 15.3694 0.5 9.90114C0.5 4.43289 4.93288 0 10.4011 0C15.8694 0 20.3022 4.43289 20.3022 9.90114C20.3022 11.9603 19.6737 13.8726 18.5979 15.4567L21.4765 18.3353C21.9647 18.8235 21.9647 19.6149 21.4765 20.1031C20.9884 20.5912 20.1969 20.5912 19.7088 20.1031L16.9409 17.3352Z"
        fill={_fill}
      />
    </svg>
  );
}

export { SvgComponent as MagnifyingGlass };
