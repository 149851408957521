import { colorFn, ColorType } from '../utils';

const SvgComponent = ({ fill = 'currentColor' as ColorType, strokeWidth = 2, ...props }) => {
  const _stroke = colorFn(fill);

  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8M16 6l-4-4-4 4M12 2v13"
        stroke={_stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { SvgComponent as ShareIcon };
