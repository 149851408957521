import { FC, HTMLAttributes } from 'react';

export type StepNumberProps = HTMLAttributes<HTMLSpanElement>;

export const StepNumber: FC<StepNumberProps> = ({ className = '', ...spanProps }) => {
  return (
    <span
      className={`${className} inline-flex h-10 w-10 items-center justify-center rounded-full border border-charcoal bg-white font-bold leading-5`}
      {...spanProps}
    />
  );
};
