import { uniqueId } from 'lodash';
import { ReactNode, useState } from 'react';
import { colorFn } from '../../utils';

type LabeledItem = {
  label?: string | ReactNode;
  value: number | string;
  spacing?: 'space-around' | 'space-between';
};

type InputToggleGroup = {
  items: LabeledItem[];
};

export const InputToggleGroup = ({ items, onChange, spacing = 'space-around', value = null }) => {
  const [selected, setSelected] = useState(value);

  // Trying out react native way of styling
  const s = {
    button: {
      paddingVertical: 5,
      paddingHorizontal: 10,
      borderRadius: 6,
      borderWidth: 2,
      borderColor: colorFn('gray-lighter'),
    },
    button_not_last: {
      marginRight: 10,
    },
    button_active: {
      borderColor: colorFn('medium-dark', 0.7),
      backgroundColor: colorFn('gray-lighter'),
    },
  };

  const onValueChange = async (val) => {
    setSelected(val);
    onChange && (await onChange(val));
  };

  return (
    // style={{ flexDirection: 'row', alignItems: 'center', justifyContent: spacing }}
    <div className="flex items-center justify-around">
      {items.map((i) => {
        const active = selected === i.value;
        return (
          <div
            key={`InputToggleItem-${uniqueId()}`}
            className={`mr-2 cursor-pointer rounded-lg border-2 border-gray-lighter px-3 py-2 last:mr-0 ${
              active && 'border-medium-dark/70 bg-gray-lighter'
            }`}
            // style={[s.button, s.button_not_last, selected === i.value ? s.button_active : {}]}
            onClick={() => onValueChange(i.value)}
          >
            <p>{i.label}</p>
          </div>
        );
      })}
    </div>
  );
};
