import { t, Trans } from '@lingui/macro';
import { DISTRIBUTOR_REFERRAL_COOKIE_KEY, DISTRIBUTOR_REFERRAL_KEEP_DAYS, VALIDATION_VIDEO_URL } from '@milo/constants';
import { Distributor } from '@milo/types';
import { PurchaseWidget } from '@milo/web/components/Purchase/PurchaseDetails';
import React, { useEffect } from 'react';
import { ImageKitImage, YoutubeEmbed } from '../../components';
import { MiloFull } from '../../logos';
import { ReactFC } from '../../types';
import { setCookie } from '../../utils';
import { AppDownload } from '../AppDownload';
const MiloMap = React.lazy(() => import('../../components/MiloMap'));

type DistributorPageComponentProps = {
  distributor: Distributor;
};

export const DistributorPage: ReactFC<DistributorPageComponentProps> = ({ distributor }) => {
  useEffect(() => {
    if (distributor.id) {
      setCookie(DISTRIBUTOR_REFERRAL_COOKIE_KEY, distributor.id, { expires: { days: DISTRIBUTOR_REFERRAL_KEEP_DAYS } });
    }
  }, [distributor.id]);

  return (
    <>
      <div className="web-container">
        <h1 className="mx-auto mb-10 max-w-xl text-center text-4xl font-bold">
          {distributor?.header_text || (
            <Trans>
              Offre exclusive <br /> Réservée aux clients de {distributor?.name}
            </Trans>
          )}
        </h1>

        <div className="mx-auto mb-12 w-fit gap-4 overflow-hidden rounded-xl">
          <ImageKitImage transforms="h-200" src={distributor.logo_url} />
        </div>

        {(distributor.offer_description && (
          <h2 className="mx-auto mb-10 max-w-2xl text-center text-2xl font-semibold">
            {distributor.offer_description}
          </h2>
        )) ||
          null}

        {(distributor.extra_description && (
          <p className="mx-auto mb-20 w-fit max-w-5xl text-xl">{distributor.extra_description}</p>
        )) ||
          null}

        <div className="mb-36 rounded-3xl bg-gray-50 py-20 shadow-lg">
          <div>
            <PurchaseWidget purchaseText={t`J'achète un pass M ta région!`} showOffer={false} />
          </div>
          <p className="mx-auto w-10/12 text-center text-lg font-semibold text-gray-700">
            <Trans>
              Choisissez bien votre période de validité! Vous pouvez profiter de généreuses économies de façon illimitée
              tant que votre pass M ta région est actif. Faites le calcul!
            </Trans>
          </p>
        </div>

        <div className="mx-auto max-w-5xl text-justify">
          <div className="mb-36">
            <h2 className="mb-10 text-center text-4xl font-bold">
              <Trans>L’application M ta région, comment ça marche?</Trans>
            </h2>

            <ol className="mx-auto w-fit text-2xl">
              {[
                t`Achetez un pass M ta région.`,
                t`Téléchargez l'application M ta région.`,
                t`Découvrez toutes les activités plaisantes autour de vous.`,
                t`Visitez ces endroits et présentez un pass M ta région au moment de payer.`,
                t`Appréciez les expériences de qualité et économisez de 15% à 50% sur tout!`,
              ].map((item, index) => (
                <li key={index} className="mb-2">
                  <span className="font-bold">{index + 1}.</span> {item}
                </li>
              ))}
            </ol>
          </div>
        </div>

        <div className="mb-36">
          <h2 className="mb-12 text-center text-3xl font-bold">
            <Trans>
              Jetez un oeil à notre carte interactive pour découvrir les activités et les endroits près de chez vous!
            </Trans>
          </h2>
          <div className="mx-auto h-[60vw] max-h-[550px] max-w-[95vw] overflow-hidden rounded-3xl border shadow-lg">
            <MiloMap />
          </div>
        </div>

        <div className="mb-14">
          <h2 className="mb-12 text-center text-3xl font-bold">
            <Trans>
              Vous ne savez pas par où commencer?
              <br />
              Laissez-nous vous guider!
            </Trans>
          </h2>
          <div className="mb-28 h-[60vw] max-h-[600px] overflow-hidden rounded-3xl shadow-2xl">
            <YoutubeEmbed src={VALIDATION_VIDEO_URL} />
          </div>
          <div className="mx-auto text-center">
            <p className="mx-auto mb-10 !block text-3xl font-semibold">
              <Trans>Téléchargez l'application M ta région pour obtenir une meilleure expérience!</Trans>
            </p>
            <div className="mb-8">
              <AppDownload />
            </div>
            <MiloFull className="mx-auto mb-8 w-40" />
          </div>
        </div>
      </div>
    </>
  );
};
