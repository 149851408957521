import { t, Trans } from '@lingui/macro';
import { Experience, SavingsTypes } from '@milo/types/index';
import { DateTime } from 'luxon';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ReactFC } from '../../types';

// Do this to only load the map when the component is rendered in the browser because the leaflet library is not compatible with SSR
const MiloMap = React.lazy(() => import('@milo/react/src/components/MiloMap'));

import { convertDateToReadableFormat } from '@milo/helpers';
import {
  AddWishlistModal,
  AuthedAction,
  Button2,
  ExperienceSavingsGrid,
  ImageGallery,
  ImageKitImage,
  Link2,
  LinkButton,
  Loading,
  Modal,
  ModalBody,
  RawHTML,
  ResultExperienceCard,
  SideScroller,
  Spinner,
  Text,
  ValidationBottomSheet,
  VerticalLabel,
} from '../../components';
import {
  isNativeApp,
  trackEvent,
  useAuthentication,
  useDeviceInfo,
  useEffectOnce,
  useExperiences,
  useFeatureFlag,
  useSavedRedirectUrl,
} from '../../hooks';
import {
  ArrowLeft,
  CheckCircle,
  DiscountIcon,
  FacebookIcon,
  Globe,
  Heart,
  InfoCircle,
  InstagramIcon,
  QuestionCircle,
} from '../../icons';
import { formatCurrency, formatPercent, zIndexFn } from '../../utils';

const privilegeButtonClasses = 'mb-3 animate-fade-in block';

type ExperiencePageProps = { experience: Experience };

export const ExperiencesByRegion = ({ region_id }: { region_id: number }) => {
  const { data } = useExperiences();

  const experiences = [...data].filter((experience) => experience?.region?.id === region_id).slice(0, 40);

  return (
    <div className="flex flex-col">
      <div className="mb-5 flex items-center">
        <h2 className="flex items-center text-3xl font-semibold">
          <Trans>Dans la même région</Trans>
        </h2>
      </div>

      <SideScroller>
        {experiences.map((experience) => (
          <div key={experience.id.toString()} className="flex w-48">
            <ResultExperienceCard experience={experience} />
          </div>
        ))}
      </SideScroller>
    </div>
  );
};

const Conditions = () => {
  const [showConditions, setShowConditions] = useState(false);

  return (
    <>
      <Button2
        StartIcon={QuestionCircle}
        className="mt-2 !py-[6px]"
        variant="tertiary"
        size="sm"
        onClick={() => setShowConditions(true)}
      >
        <Trans>Conditions d'utilisation</Trans>
      </Button2>
      <Modal open={showConditions} onRequestClose={() => setShowConditions(false)}>
        <ModalBody className="animate-fade-in-top">
          <h2 className="mb-4 text-3xl font-semibold">
            <Trans>Conditions d'utilisation</Trans>
          </h2>

          <p className="text-medium-dark">
            <Trans>
              Les réductions-découvertes sont appliqués sous forme de réduction calculée sur le montant avant taxes et
              sur le prix régulier. Non cumulable à toute autre offre en cours et non applicable sur les forfaits.{' '}
              <br />
              <br />
              La facture doit être acquittée par le membre, une pièce d'identité peut être demandée lors de la
              vérification de la preuve du pass M ta région. Le membre a droit à une seule réduction-découverte par
              expérience par année du pass M ta région. Le réduction-découverte M ta région peut être réclamé une fois
              par période de douze mois chez un même partenaire.
              <br />
              <br />
              M ta région peut modifier ou retirer cette expérience sans préavis.
              <br />
              <br />
            </Trans>
          </p>
        </ModalBody>
      </Modal>
    </>
  );
};

const Restrictions: ReactFC<{ text: string }> = ({ text = '' }) => {
  const [showRestrictions, setShowRestrictions] = useState(false);

  return (
    <>
      <Button2 StartIcon={InfoCircle} size="sm" variant="tertiary" onClick={() => setShowRestrictions(true)}>
        <Trans>Conditions d'application</Trans>
      </Button2>
      <Modal open={showRestrictions} onRequestClose={() => setShowRestrictions(false)}>
        <ModalBody className="animate-fade-in-top">
          <h2 className="mb-4 text-3xl font-semibold">
            <Trans>Conditions d'application</Trans>
          </h2>

          <RawHTML className="text-lg text-inherit">{text}</RawHTML>
        </ModalBody>
      </Modal>
    </>
  );
};

const PromoCodeNotice: ReactFC<{ text: string }> = ({ text = '' }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button2 StartIcon={DiscountIcon} size="sm" variant="tertiary" onClick={() => setShow(true)}>
        <Trans>Utilisation d'un code promo</Trans>
      </Button2>
      <Modal open={show} onRequestClose={() => setShow(false)}>
        <ModalBody className="animate-fade-in-top">
          <h2 className="mb-4 text-3xl font-semibold">
            <Trans>Utilisation d'un code promo</Trans>
          </h2>

          <RawHTML className="text-lg text-inherit">{text}</RawHTML>
        </ModalBody>
      </Modal>
    </>
  );
};

export const ExperiencePage: FC<ExperiencePageProps> = ({ experience }) => {
  const { user, isLoading, refetch: refreshUser, subscribed, isFetching } = useAuthentication();
  const [showValidation, setShowValidation] = useState(false);
  const { data: juneFreeActive, isLoading: juneFreeLoading } = useFeatureFlag('june-free');
  const showLoading = isLoading || isFetching || juneFreeLoading;
  const { saveRedirectUrl } = useSavedRedirectUrl();
  const { safeAreas } = useDeviceInfo();

  // Keep url in memory
  useEffect(() => {
    saveRedirectUrl();
  });

  useEffectOnce(() => {
    if (experience?.id) {
      trackEvent('view_item', {
        ecommerce: {
          items: [
            {
              index: 0,
              item_category: experience.categories[0],
              item_city: experience.location.city,
              item_id: experience.id,
              item_name: experience.name,
            },
          ],
        },
      });
    }
  });

  const additionalImagesNames = experience?.additional_images || [];

  const memoedAditionnalImages = useMemo(() => {
    return additionalImagesNames.map((image, index) => ({
      imageFileName: image,
      className: 'cursor-pointer rounded-lg mr-2',
    }));
  }, [experience?.additional_images]);

  const renewalDate = user?.visits_this_year?.filter((visit) => visit?.experience?.id === experience?.id)[0]
    ?.date_created;
  const isInWishlist = user?.wishlists?.some((wishlist) =>
    wishlist?.experiences?.some((exp) => exp?.id === experience?.id)
  );
  const visitThisYear = user?.visits_this_year?.filter((v) => v?.experience?.id === experience?.id)[0];
  const isVisited = Boolean(visitThisYear?.id);

  const PrivilegeButton = useMemo(() => {
    if (showLoading) {
      return (
        <div className={privilegeButtonClasses}>
          <Spinner color="charcoal" />
        </div>
      );
    }

    if (isVisited) {
      return (
        <div className={`${privilegeButtonClasses} `}>
          <span className="inline-flex items-center">
            <Text size="h3" className="font-bold">
              {visitThisYear?.promo_code ? t`Code promo obtenu ${visitThisYear?.promo_code}` : t`Privilège obtenu`}{' '}
            </Text>
            <CheckCircle className="ml-2 inline-flex h-5 w-5 text-success" fill="currentColor" />
          </span>
          <p>
            <Trans>
              À nouveau disponible à partir du{' '}
              <b>{convertDateToReadableFormat(DateTime.fromISO(renewalDate).plus({ year: 1 }).toISO())}</b>
            </Trans>
          </p>
        </div>
      );
    }

    const pendingFreeVisit = user?.pending_free_visit;
    if (subscribed || !!pendingFreeVisit?.id || juneFreeActive) {
      return (
        <AuthedAction authLevel="logged-in">
          <Button2
            data-testid="exp-page-get-privilege-button-sub-or-free-visit"
            size="md"
            onClick={() => setShowValidation(true)}
            className={privilegeButtonClasses}
          >
            <Trans>Obtenir la réduction-découverte</Trans>
          </Button2>
        </AuthedAction>
      );
    }

    return (
      <div className={privilegeButtonClasses}>
        <p className="mb-2">
          <b>
            <Trans>Pour bénéficier de la réduction-découverte</Trans>
          </b>
        </p>
        <LinkButton
          data-testid="exp-page-get-milopass"
          onClickCapture={() =>
            trackEvent('cta_go_to_purchase', {
              path: location.pathname,
              user_id: user?.id,
            })
          }
          href="/purchase"
          size="md"
        >
          <Trans>Obtenez un pass M ta région</Trans>
        </LinkButton>
      </div>
    );
  }, [isVisited, user?.last_used_free_visit?.id, user?.pending_free_visit?.id, subscribed, showLoading]);

  const daysOfTheWeek = [
    { day: 'monday', label: t`Lundi` },
    { day: 'tuesday', label: t`Mardi` },
    { day: 'wednesday', label: t`Mercredi` },
    { day: 'thursday', label: t`Jeudi` },
    { day: 'friday', label: t`Vendredi` },
    { day: 'saturday', label: t`Samedi` },
    { day: 'sunday', label: t`Dimanche` },
  ];

  if (!experience?.id) {
    return <Loading />;
  }

  return (
    <div className=" mb-20">
      <div className={`${isNativeApp() ? '' : 'mx-auto w-11/12 max-w-7xl'}`}>
        <div className="relative mb-10">
          <div className={`relative overflow-hidden ${isNativeApp() ? 'rounded-b-3xl' : 'rounded-3xl'}`}>
            {!showValidation && (
              <>
                <div className="absolute left-0 right-0 top-0 bg-gradient-to-b from-charcoal to-transparent px-6 py-8"></div>
                <div
                  className="absolute left-0 right-0 flex animate-fade-in items-center justify-between px-6 py-8"
                  style={{ top: safeAreas.top / 2 }}
                >
                  <button
                    onClick={() => history.back()}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white/85 focus:outline-none "
                  >
                    <ArrowLeft className="h-4 w-4 text-dark" fill="currentColor" />
                  </button>
                  <AddWishlistModal experience={experience}>
                    <Heart
                      className="h-9 w-9"
                      fill={isInWishlist ? 'red' : 'transparent'}
                      stroke={isInWishlist ? 'red' : 'white'}
                    />
                  </AddWishlistModal>
                </div>
              </>
            )}

            <ImageKitImage
              imageFileName={experience.main_image}
              className="h-[100vw] max-h-[600px] min-h-[300px]  w-full"
            />
          </div>
        </div>

        <div className={`${isNativeApp() ? 'mx-auto w-11/12' : ''}`}>
          <div className="mb-4 flex flex-col space-y-4">
            <h1 className="-mb-3 text-4xl font-bold">{experience?.name}</h1>
            <p className="text-lg text-gray-400">
              {experience?.location.address} - {experience?.location.city}
            </p>
            <VerticalLabel className="text-xl font-bold" vertical_id={experience?.vertical_id} />
          </div>

          <div className="mb-8">
            <ImageGallery items={memoedAditionnalImages} />
          </div>

          <div className="mb-20 flex flex-col  gap-14 md:flex-row md:gap-5">
            <div className="md:w-1/2">
              <h2 className="mb-4 text-3xl font-semibold">
                <Trans>Réduction-Découverte</Trans>
              </h2>
              <div className="relative w-full rounded-xl bg-gray-50 p-7 shadow-lg">
                <div className="mb-5">
                  {experience.savings_type === SavingsTypes['Percentage'] && (
                    <>
                      <div className="flex items-center justify-between">
                        <h2 className="mb-1 text-2xl font-semibold">
                          <Trans>Obtenez {formatPercent((experience.savings_percentage / 100) * 1)} de réduction</Trans>
                        </h2>
                      </div>

                      <Text size="h5" className="mb-4">
                        <Trans>Jusqu'à concurrence de {formatCurrency(Math.round(experience.savings_max))}.</Trans>
                      </Text>
                    </>
                  )}

                  {experience.savings_type === SavingsTypes['Grid'] && (
                    <>
                      <h2 className="mb-4 text-2xl font-semibold">
                        <Trans>Économies variables</Trans>
                      </h2>
                      <ExperienceSavingsGrid experience={experience} />
                    </>
                  )}

                  {experience.savings_type === SavingsTypes['TwoForOne'] && (
                    <>
                      <h2 className="mb-4 text-2xl font-semibold">
                        <Trans>Offre 2 pour 1</Trans>
                      </h2>
                      <p className="mb-2 text-xl">
                        <Trans>
                          1 acheté = 1 offert
                          <br />
                          Jusqu'à concurrence de {experience?.two_for_one_max_count} billets offerts.
                        </Trans>
                      </p>
                    </>
                  )}
                </div>

                <div className="mb-2">{PrivilegeButton}</div>

                <div className="mb-2">
                  <Conditions />
                </div>

                {experience.restrictions && (
                  <div className="mb-2">
                    <Restrictions text={experience.restrictions} />
                  </div>
                )}

                {experience.promo_code_instructions && (
                  <div className="mb-2">
                    <PromoCodeNotice text={experience.promo_code_instructions} />
                  </div>
                )}
              </div>
            </div>

            <div className="md:w-1/2">
              <h2 className="mb-4 text-3xl font-semibold">
                <Trans>Description</Trans>
              </h2>
              <div className="rounded-2xl bg-gray-50 p-7 shadow-lg">
                <RawHTML className="text-lg">{experience.description}</RawHTML>
              </div>
            </div>
          </div>

          <div className="mb-20">
            <h2 className="mb-4 text-3xl font-semibold">
              <Trans>Cette expérience sur la carte</Trans>
            </h2>

            <div className="relative flex flex-col gap-6">
              <div className="h-[50vw] max-h-[550px] w-full overflow-hidden rounded-3xl border-2 shadow-lg">
                <MiloMap
                  pointsOfInterest={[]}
                  experiences={[experience]}
                  includeCurrentPositionInBounds={true}
                  zoomToFitPassedExperiences={true}
                />
              </div>

              <section
                className="left-2 top-2 flex flex-col gap-4 rounded-3xl bg-white/70 p-5 md:absolute"
                style={{ zIndex: zIndexFn('navigation', -1) }}
              >
                <div className="flex-1 space-y-5">
                  {experience?.company_phone && (
                    <p className="leading-6">
                      <Link2
                        onClickCapture={() => trackEvent('exp_phone_number_click', { user_id: user?.id })}
                        href={`tel:${experience?.company_phone}`}
                      >
                        📞 &nbsp;
                        <span className="font-semibold underline underline-offset-2">{experience?.company_phone}</span>
                      </Link2>
                    </p>
                  )}

                  <div>
                    <p className="leading-6 text-medium-dark">{experience.location.address}</p>
                    <p className="leading-6 text-medium-dark">
                      {experience?.location?.city}
                      <br />
                      {experience?.location?.province?.name}, {experience?.location?.country?.name}
                      <br />
                      {experience?.location?.postal_code}
                    </p>
                  </div>

                  <div className="flex items-center">
                    {experience?.website && (
                      <Link2 popup className="mr-4" href={'http://' + experience.website}>
                        <Globe className="h-6 w-6" />
                      </Link2>
                    )}
                    {experience?.facebook && (
                      <Link2 popup className="mr-4" href={`https://www.facebook.com/${experience.facebook}`}>
                        <FacebookIcon className="h-6 w-6 text-charcoal" stroke="currentColor" />
                      </Link2>
                    )}
                    {experience?.instagram && (
                      <Link2 popup className="mr-4" href={`https://www.instagram.com/${experience.instagram}`}>
                        <InstagramIcon className="h-6 w-6 text-charcoal" stroke="currentColor" />
                      </Link2>
                    )}
                  </div>
                </div>

                {/* {!!experience?.opening_hours.length && (
                  <div className="flex flex-1 flex-col gap-5">
                    <p className="font-semibold leading-6 text-medium-dark underline underline-offset-2">
                      <Trans>Heures d'ouverture</Trans>
                    </p>
                    <div className="flex flex-col">
                      {daysOfTheWeek.map((openingHour) => {
                        const openingHourForDay = experience?.opening_hours?.find(
                          (openingHour) => openingHour.day.toLowerCase() === openingHour.day.toLowerCase()
                        );

                        return (
                          <div className="flex gap-2">
                            <p className="font-semibold leading-6 text-medium-dark">{openingHour.label}</p>
                            <p className="leading-6 text-medium-dark">
                              {openingHourForDay ? openingHourForDay.open : t`fermé`}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )} */}
              </section>
            </div>
          </div>

          {/* {config.APP_STAGE !== 'prd' && (
            <section className="mb-20">
              <h2 className="mb-4 text-3xl font-semibold">
                <Trans>Mot du propriétaire</Trans>
              </h2>
              <QuestionsSection experience={experience} />
            </section>
          )} */}
        </div>

        <Modal
          open={showValidation}
          onRequestClose={() => {
            setShowValidation(false);
            refreshUser();
          }}
        >
          <ValidationBottomSheet experience={experience} />
        </Modal>
      </div>
      <div className="mx-5 sm:px-20">
        <ExperiencesByRegion region_id={experience?.region?.id || 0} />
      </div>
    </div>
  );
};
