import { useEffect, useRef } from 'react';

export const useEffectOnce = (fn: Function) => {
  const init = useRef(false);
  useEffect(() => {
    if (!init.current) {
      init.current = true;
      fn?.();
    }
  }, []);
};
