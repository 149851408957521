import { ProductId, PurchaseMode } from '@milo/types';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { VerticalId } from 'src/components/Search/VerticalsFilter';
import config from '../config';
import { getPlatform } from './useDeviceInfo';

// These represent the different environment IDs in google tag manager
const GTM_ENVS = {
  dev: {
    auth: 'cV_EjVLleA7Q7-QAUdfXsQ',
    preview: 'env-79',
  },
  stg: {
    auth: 'aBer31nATiJ0uQBQ_HiKrQ',
    preview: 'env-80',
  },
  prd: {
    auth: 'kpGBhgx-WYL8ljMtYHVUlg',
    preview: 'env-1',
  },
};

export const DATALAYER_NAME = 'MiloDataLayer';

export const initializeEventTracker = () => {
  TagManager.initialize({
    gtmId: config?.GOOGLE_TAG_MANAGER_ID,
    dataLayerName: DATALAYER_NAME,
    preview: GTM_ENVS[config.APP_STAGE].preview,
    auth: GTM_ENVS[config.APP_STAGE].auth,
  });
};

export const useEventTracking = () => {
  useEffect(() => {
    initializeEventTracker();
  }, []);
};

// The shape of the object is supposed to be important so we follow GA's shape
export type TrackedExperience = {
  item_name: string;
  item_id: number;
  item_category: number; // ID of category
  item_city: string;
  index: number; // Index of the item in the array
};

type PurchaseEvents = {
  cta_go_to_purchase: CTAClickPayload;
  cta_purchase_click: {
    user_id?: number;
    purchase_mode: PurchaseMode;
    path: string;
  };

  // User lands on the purchase page
  purchase_page_view: {
    user_id?: number;
  };

  // When user lands on the checkout page
  begin_checkout: {
    ecommerce: {
      user_id: number;
      items: {
        item_id: ProductId;
        purchase_mode: PurchaseMode;
        quantity: 1;
      }[];
    };
  };

  // When a product is purchased
  purchase: {
    ecommerce: {
      currency: string;
      value: number; // purchase amount in cents
      tax: number; // tax amount in cents
      transaction_id: string; // stripe charge id
      discount?: number; // discount amount in cents
      coupon?: string;
      user_email: string;
      items: {
        item_id: string; // Product ID from DB
        quantity: 1;
      }[];
    };
  };
};

type GuidesEvents = {
  cta_go_to_guides: CTAClickPayload;
  guide_click: {
    user_id?: number;
    guide_id: number;
  };
};

type AuthenticationEvents = {
  // On page load we send the user data for identification
  user_data: {
    user_id?: string | number;
  };

  // When a user signs up
  sign_up: {
    user_id?: number;
    method: 'email' | 'google';
  };

  // When a user logs in
  login: {
    method: 'email' | 'google';
    user_id?: number;
  };
};

type ExperienceBrowseEvents = {
  // When user changes a category
  search_category: {
    category_id: VerticalId;
    keyword?: string; // if empty => undefined
    region_id?: number; // if empty => undefined
  };

  // View the experiences page
  view_item_list: {
    ecommerce: {
      items: TrackedExperience[];
    };
  };
};

type SingleExperienceEvents = {
  view_item: {
    ecommerce: {
      items: TrackedExperience[];
    };
  };

  exp_phone_number_click: {
    user_id?: number;
  };
};

type FreeVisitEvents = {
  cta_free_trial_click: CTAClickPayload;
  free_trial_page_view: {
    user_id?: number;
  };
  free_trial_start: {
    user_id?: number;
  };
};

type WishlistEvents = {
  add_to_wishlist: {
    user_id: number;
    ecommerce: {
      items: TrackedExperience[];
    };
  };
};

type BannerEvents = {
  banner_click: {
    user_id?: number;
    type: 'app_download' | 'promotion';
  };
};

type ContactEvents = {
  contact: {
    user_id?: number;
  };

  subscribe_newsletter: {
    user_email: string;
  };
};

type MilopassEvents = {
  get_privilege: {
    user_id: number; // ID of the user getting the privilege
    items: {
      item_id: number; // Experience ID
      item_category: number; // Category ID of the experience
      item_city: string; // Name of the city
      quantity: 1;
    }[];
  };
};

type AppDownloadEvents = {
  app_download_click: {
    user_id?: number;
    platform: 'ios' | 'android';
  };
};

type TrackedEvents = AppDownloadEvents &
  AuthenticationEvents &
  BannerEvents &
  ContactEvents &
  ExperienceBrowseEvents &
  FreeVisitEvents &
  GuidesEvents &
  MilopassEvents &
  PurchaseEvents &
  SingleExperienceEvents &
  WishlistEvents;

type CTAClickPayload = {
  path: string;
  user_id: number | undefined;
};

export const trackEvent = <Event extends keyof TrackedEvents, Payload extends TrackedEvents[Event]>(
  event: Event,
  data: Payload
) => {
  console.log('TRACKING ON HOLD');

  // const datalayer = window?.[DATALAYER_NAME] || [];
  // datalayer.push({ ecommerce: null });
  // datalayer.push({ event, platform: getPlatform(), ...data });
};
