export const RegionsList = /* GraphQL */ `
  query RegionsList {
    RegionsList {
      id
      slug
      name
      lat
      lng
    }
  }
`;
