import { useRef } from 'react';
import { useAuthentication } from './useAuthentication';

export const useSavings: () => number | string = () => {
  const { user } = useAuthentication();
  const previous = useRef<string>('0');

  if (!user?.wishlist) {
    return previous.current;
  }

  const savings = user?.wishlist?.reduce((a, b) => {
    return a + b.savings_max;
  }, 0);

  const val = (savings / 100).toFixed();
  previous.current = val;

  return val;
};
