import { DistributorDiscount } from '@milo/types';
import { getGraphqlClient } from '../useGraphqlClient';
import { useQuery } from '../useQuery';

export const getDistributorDiscountsActive = async () => {
  const graphqlClient = getGraphqlClient();
  const data = await graphqlClient.request(/* GraphQL */ `
    query DistributorDiscountsActive {
      discounts: DistributorDiscountsActive {
        applicable_product_id
        discounted_amount
      }
    }
  `);

  const discounts = data?.discounts as DistributorDiscount[];

  return discounts;
};

export const useDistributorDiscountsActive = () => {
  const response = useQuery(['DistributorDiscountsActive'], async () => await getDistributorDiscountsActive(), {
    staleTime: Infinity,
  });

  const isActive = (response?.data || [])?.length > 0;

  return { ...response, isActive };
};
