import cn from 'classnames';
import { FC, HTMLAttributes, ReactNode, useEffect, useState } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { InfoCircleBold, ModalCloseIcon } from '../../icons';

export type BaseInfoBoxProps = Omit<HTMLAttributes<HTMLDivElement>, 'id'> & {
  id?: string;
  open?: boolean;
  showIcon?: boolean;
  small?: boolean;
  dismissable?: boolean;
  icon?: ReactNode;
  variant?: 'success' | 'info' | 'normal' | 'warning' | 'error';
  noBackground?: boolean;
};

const BaseInfoBox: FC<BaseInfoBoxProps> = ({
  children,
  className = '',
  dismissable = false,
  icon,
  id,
  noBackground = false,
  open: initiallyShowing = true,
  showIcon = false,
  small = false,
  variant = 'normal',
  ...divProps
}) => {
  const [showing, setShowing] = useState(initiallyShowing);
  const [isDismissedByUser, setIsDismissedByUser] = useLocalStorage(id, false);

  useEffect(() => {
    setShowing(initiallyShowing);
  }, [initiallyShowing]);

  const close = () => {
    setShowing(false);

    // Only do it when you have an id otherwise as soon as you dismiss any box on the site that doesn't have an id
    // all the other boxes that also don't have an id will not show up because the local storage will have an undefined
    // key set to true
    if (id) {
      setIsDismissedByUser(true);
    }
  };

  if (!showing || isDismissedByUser) {
    return null;
  }

  const containerClassName = cn(
    'relative overflow-hidden mb-5 rounded-2xl border-2 font-semibold shadow-md',
    className,
    {
      // Background color
      'bg-warning/5 border-warning/20 text-warning/80 shadow-warning/20': variant === 'warning',
      'bg-error/5 border-error/20 text-error/80 shadow-error/20': variant === 'error',
      'bg-success/5 border-success/20 text-success/80 shadow-success/20': variant === 'success',
      'bg-focus-blue/5 border-focus-blue/20 text-focus-blue/80 shadow-focus-blue/20': variant === 'info',
      'border border-gray-100': variant === 'normal',

      'p-3': small,
      'py-6 px-8': !small,
    }
  );

  return (
    <div className={`${containerClassName}`} {...divProps} style={{ background: noBackground ? 'none' : null }}>
      <div className="flex items-center">
        {showIcon && <InfoCircleBold className="mr-1 mt-1 h-3.5 w-3.5" />}
        {icon !== undefined && <span className="mr-2 h-10 w-10 p-1">{icon}</span>}
        <div className="flex-1">{children}</div>
        {dismissable && (
          <button className="absolute top-2 right-2 rounded-full p-2" onClick={close}>
            <ModalCloseIcon className="h-3 w-3" fill="white" />
          </button>
        )}
      </div>
    </div>
  );
};

export const InfoBox = {
  Default: (props: BaseInfoBoxProps) => <BaseInfoBox {...props} />,
  Dismissable: (props: BaseInfoBoxProps) => <BaseInfoBox dismissable {...props} />,
};
