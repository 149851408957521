import { useVerticals } from '../../hooks';
import { ReactFC } from '../../types';
import { colorFn } from '../../utils';
import { verticalsIdsColorMap } from '../Search/VerticalsFilter';

export const VerticalLabel: ReactFC<{ vertical_id: number; [key: string]: any }> = ({
  vertical_id,
  className = '',
  ...props
}) => {
  const { data: verticals, isLoading } = useVerticals();

  if (isLoading) return null;

  const vertical = verticals.find((v) => {
    return v.id === vertical_id;
  });
  const color = colorFn(verticalsIdsColorMap?.[vertical_id]);
  const name = vertical?.name || '';

  return (
    <div
      style={{ backgroundColor: color }}
      className={`${className} flex w-fit items-center gap-3 rounded-full px-3 py-1 text-sm font-semibold capitalize text-white`}
    >
      <p className="w-max">{name}</p>
    </div>
  );
};
