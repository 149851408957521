import { t, Trans } from '@lingui/macro';
import { PartnerIcon } from '../../icons';
import { MiloRoundLogo } from '../../logos';
import { ReactFC } from '../../types';
import { Button2, LoginButton } from '../Button';
import { Link2 } from '../Link';
import { Route } from '../Route';
import { Text } from '../Text';

type LoginOptionsProps = {
  error?: boolean;
  showEmailOption?: boolean;
  showGoogleOption?: boolean;
  showPartnerOption?: boolean;
};

export const LoginOptions: ReactFC<LoginOptionsProps> = ({
  error = false,
  showEmailOption = true,
  showGoogleOption = true,
  showPartnerOption = true,
}) => {
  return (
    <Route.LoggedOut>
      <div className="flex flex-col items-center space-y-4">
        <div className="mb-4 text-center">
          <MiloRoundLogo className="mx-auto mb-4" />
          <Text size="h2" className="font-bold">
            <Trans>Bienvenue chez M ta région</Trans>
          </Text>
          <p>
            <Trans>Le plaisir de découvrir et d'économiser</Trans>
          </p>
        </div>

        {showEmailOption && <LoginButton loginType="email" />}

        {showGoogleOption && <LoginButton loginType="google" />}

        {showPartnerOption && (
          <>
            <div className="relative !mb-6 !mt-10 h-[1px] w-full max-w-md border-[1px] border-gray-light">
              <p className="absolute -top-[28px] left-1/2 -translate-x-1/2 bg-white p-4 text-medium">
                <Trans>OU</Trans>
              </p>
            </div>

            <Link2 href="/partner/login">
              <Button2 variant="dark" StartIcon={PartnerIcon}>
                <Trans>Connexion Partenaire</Trans>
              </Button2>
            </Link2>
          </>
        )}

        {error && <ErrorContactSupport message={t`Une erreur de connexion est survenue.`} />}
      </div>
    </Route.LoggedOut>
  );
};

const ErrorContactSupport: ReactFC<{ message?: string }> = ({ message = 'Une erreur est survenue.' }) => {
  return (
    <Text className="rounded-lg border border-raspberry bg-raspberry/5 p-3 text-center font-bold text-raspberry-red">
      <Trans>
        {message}{' '}
        <Link2 href="/support" underline>
          Contactez nous
        </Link2>{' '}
        pour recevoir de l'aide.
      </Trans>
    </Text>
  );
};
