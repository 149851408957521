import { ProductInterval } from '@milo/types';
import qs from 'query-string';

/**
 * Get the portion of a string before the last occurrence of a given value.
 *
 * https://github.com/laravel/framework/blob/8.x/src/Illuminate/Support/Str.php#L126
 */
export function beforeLast(subject: string, search: string): string {
  if (search === '') {
    return search;
  }

  const position = subject.lastIndexOf(search);

  if (position === -1) {
    return subject;
  }

  return subject.substring(0, position);
}

export function formatPercent(value: number, options = { decimals: 0 }) {
  const intPercent = value * 100;

  if (intPercent % 1 === 0) {
    return `${intPercent}%`;
  }

  if (options.decimals > 0) {
    return `${intPercent.toFixed(options.decimals)}%`;
  }

  return `${Math.round(intPercent)}%`;
}

type FormatCurrencyOptions = {
  isCents?: boolean;
  decimals?: number;
};

export const formatCurrency: (value: number, options?: FormatCurrencyOptions) => string = (
  value: number,
  options = {}
) => {
  // Avoid NaN display
  if (typeof value !== 'number') return '0';
  const { decimals = 0, isCents = true } = options;
  if (isCents) value = value / 100;
  const retval = value.toFixed(decimals);
  return `${retval}$`;
};

export const isValidPhoneNumber: (str: string) => boolean = (str) => {
  return /^\d\d\d-\d\d\d-\d\d\d\d$/.test(str);
};

export const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('');

export const ID = () => {
  return '_' + Math.random().toString(36).substring(2, 9);
};

export const objectToQueryString = (obj: Record<string, any> = {}) => {
  return qs.stringify(obj, { skipNull: true, skipEmptyString: true });
};

export const getProductIntervalString = (productInterval: ProductInterval, intervalCount: number) => {
  const intervalType =
    productInterval === ProductInterval['Week']
      ? 'semaine'
      : productInterval === ProductInterval['Month']
      ? 'mois'
      : productInterval === ProductInterval['Year']
      ? 'an'
      : 'jour';

  const plural = (() => {
    if (productInterval === ProductInterval['Month']) return '';
    if (Number(intervalCount) > 1) return 's';
    return '';
  })();

  const start = (() => {
    if (intervalCount === 1) {
      return 'par';
    }

    return 'pour';
  })();

  const middle = (() => {
    if (intervalCount === 1) {
      return '';
    }

    return intervalCount;
  })();

  const end = `${intervalType}${plural}`;

  return `${start} ${middle} ${end}`;
};
