import { Trans } from '@lingui/macro';
import { useWishlistMutations } from '@milo/react';
import { Guide } from '@milo/types/index';
import { useEffect, useState } from 'react';
import { AuthedAction, Button2, InputText2, Link2, Modal, ModalBody, Text } from '../../components';

type AddGuideToWishlistModalProps = {
  guide: Guide;
  children: React.ReactNode;
};

export const AddGuideToWishlistModal = ({ guide = {} as Guide, children }: AddGuideToWishlistModalProps) => {
  const [saveGuideModalOpen, setSaveGuideModalOpen] = useState(false);
  const [newListName, setNewListName] = useState(guide.title);
  const { createWishlist } = useWishlistMutations();

  useEffect(() => {
    setNewListName(guide.title);
  }, [guide.title]);

  const openModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setSaveGuideModalOpen(true);
  };

  const closeModal = () => {
    setSaveGuideModalOpen(false);
    createWishlist.reset();
  };

  return (
    <>
      <AuthedAction authLevel="logged-in">
        <button onClick={openModal}>{children}</button>
      </AuthedAction>

      <Modal open={saveGuideModalOpen} onRequestClose={closeModal}>
        <ModalBody>
          {createWishlist.isSuccess && (
            <div>
              <Text size="h2" className="mb-4 font-bold">
                <Trans>Itinéraire sauvegardé!</Trans>
              </Text>

              <Text className="mb-4" lineHeight="spaced">
                <Trans>
                  Vous avez sauvegardé cet itinéraire M ta région dans vos listes de favoris sous le nom{' '}
                  <b>"{newListName}"</b>. Vous pouvez accéder à vos listes à tout moment pour les modifier ou les
                  supprimer.
                </Trans>
              </Text>

              <div className="flex flex-col gap-2">
                <Button2 variant="secondary" onClick={closeModal}>
                  <Trans>Retourner au guide</Trans>
                </Button2>

                <Link2 href="/profile/wishlist" className="flex-grow">
                  <Button2 variant="dark" block>
                    <Trans>Voir mes listes de favoris</Trans>
                  </Button2>
                </Link2>
              </div>
            </div>
          )}

          {!createWishlist.isSuccess && (
            <>
              <Text size="h2" className="mb-4 font-bold">
                <Trans>Sauvegarder l'itinéraire</Trans>
              </Text>
              <Text className="mb-4">
                <Trans>
                  Choisissez un nom et ajoutez cet itinéraire à vos listes de favoris. Vous pourrez facilement gérer les
                  expériences contenues dans votre nouvelle liste par la suite.
                </Trans>
              </Text>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  await createWishlist.mutateAsync({
                    name: newListName,
                    experiencesIds: (guide.experiences || []).map((e) => e.id),
                  });
                }}
              >
                <InputText2 className="mb-4" value={newListName} onChange={(e) => setNewListName(e.target.value)} />
                <Button2
                  loading={createWishlist.isLoading}
                  disabled={createWishlist.isLoading}
                  variant="dark"
                  type="submit"
                  className="w-full"
                >
                  <Trans>Sauvegarder</Trans>
                </Button2>
              </form>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
