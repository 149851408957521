import { t } from '@lingui/macro';
import { useAuthentication, useSessionStorage } from '../../hooks';
import { POST_LOGIN_REDIRECT_PATH } from '../AuthedAction';
import { Loading } from '../Route';

export const LoginSuccess = () => {
  const { user, loggedIn, isFetching, isLoading } = useAuthentication();
  const [postLoginRedirectUrl, , deletePostLoginRedirectPath] = useSessionStorage(POST_LOGIN_REDIRECT_PATH);

  const fetching = isFetching || isLoading;

  if (loggedIn) {
    const redirectUrl = (() => {
      if (postLoginRedirectUrl) return postLoginRedirectUrl;
      if (user?.has_pending_prepaid) return '/onboarding/prepaid';
      if (!user.has_passed_onboarding) return '/onboarding/new';
      return '/experiences';
    })();

    deletePostLoginRedirectPath();
    location.href = redirectUrl;
  }

  if (!loggedIn && !fetching) {
    location.href = '/login';
  }

  return (
    <div className="w-5 overflow-hidden">
      <Loading text={t`Connexion réussie, juste un instant...`} />
    </div>
  );
};
