function SvgComponent(props) {
  return (
    <svg viewBox="0 0 20 21" fill="none" {...props}>
      <path
        d="M17.923 6.356l-2-4a.667.667 0 00-.59-.356H4.667a.667.667 0 00-.597.37l-2 4a.69.69 0 00-.07.296v2a2 2 0 001.333 1.877V16a2 2 0 002 2h9.334a2 2 0 002-2v-5.457A2 2 0 0018 8.666v-2a.693.693 0 000-.166.635.635 0 00-.077-.144zM16.257 6H13.23l-.443-2.667h2.133L16.257 6zm-4.82-2.667L11.88 6H8.12l.443-2.667h2.874zm-6.357 0h2.133L6.77 6H3.747L5.08 3.333zm3.587 13.333V14a.666.666 0 01.666-.667h1.334a.667.667 0 01.666.667v2.666H8.667zm6 0h-2V14a2 2 0 00-2-2H9.333a2 2 0 00-2 2v2.666h-2A.667.667 0 014.667 16v-5.334h10.666V16a.667.667 0 01-.666.666zm2-8a.667.667 0 01-.667.667H4a.667.667 0 01-.667-.667V7.333h13.334v1.333z"
        fill="currentColor"
      />
    </svg>
  );
}

export { SvgComponent as PartnerIcon };
