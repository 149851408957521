import { Trans } from '@lingui/macro';
import { useState } from 'react';
import { Text } from '../Text';

const MAX_SAVINGS_AMOUNT = 999999;

const normalizeCentsAmount = (centsAmount) => {
  return centsAmount > MAX_SAVINGS_AMOUNT ? MAX_SAVINGS_AMOUNT : centsAmount;
};

const transformTextValToCentsInt = (textAmount) => {
  return Number(textAmount.replace(/\./gm, ''));
};

export const InputInvoiceAmount2 = ({ onChange }) => {
  const [amount, setAmount] = useState('0');
  const centsAmount = transformTextValToCentsInt(amount);
  const normalizedCentsAmount = normalizeCentsAmount(centsAmount);
  const showError = centsAmount > MAX_SAVINGS_AMOUNT;

  const _onChange = (e) => {
    const val = e.target.value;
    const intVal = transformTextValToCentsInt(val);
    const amount = normalizeCentsAmount(Number(intVal));
    setAmount(val);
    onChange && onChange(amount);
  };

  const formattedValue = Number(normalizedCentsAmount / 100).toFixed(2);

  return (
    <>
      <div className="relative mx-auto mb-4 max-w-xs rounded-xl border-4 border-gray-lighter">
        <input
          onChange={_onChange}
          className={
            'inline-flex h-24 w-full pl-4 text-4xl font-bold leading-none placeholder-gray-light !outline-none'
          }
          inputMode="decimal"
          type="number"
          value={formattedValue}
          autoFocus
        />
        <span className="absolute right-0 top-1/2 flex h-full -translate-y-1/2 items-center bg-gray-lighter px-6 text-3xl font-bold">
          $
        </span>
      </div>
      <Text
        className={`text-center !text-lg font-bold !text-error ${showError && '!opacity-100'} opacity-0 transition`}
      >
        <Trans>Le montant maximum est {(MAX_SAVINGS_AMOUNT / 100).toFixed(2)}$</Trans>
      </Text>
    </>
  );
};
