export const UserGetOnboardingData = /* GraphQL */ `
  query UserGetOnboardingData {
    UserGetOnboardingData {
      cents_credits
      email
      first_name
      free_days
      has_passed_onboarding
      last_name
      payment_method {
        id
        brand
        last4
        expiry_year
      }
      reactivated_at
      stripe_customer_id
      user_source
    }
  }
`;
