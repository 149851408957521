import { colorFn, ColorType } from '../utils';

export const DocumentIcon = ({ fill = 'currentColor' as ColorType, ...props }) => {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.666 24.967V3.034a.7.7 0 0 1 .7-.7h13.593a.7.7 0 0 1 .495.205l3.674 3.673a.7.7 0 0 1 .205.495v18.26a.7.7 0 0 1-.7.7H5.366a.7.7 0 0 1-.7-.7Z"
        stroke={_fill}
        strokeWidth={1.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.666 6.3V2.746a.412.412 0 0 1 .704-.292l3.842 3.842A.412.412 0 0 1 22.92 7h-3.554a.7.7 0 0 1-.7-.7Z"
        fill={_fill}
        stroke={_fill}
        strokeWidth={1.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.334 11.666h9.333M9.334 21h9.333M9.334 16.334h4.667"
        stroke={_fill}
        strokeWidth={1.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
