import { Trans } from '@lingui/macro';
import { Experience } from '@milo/types/index';
import { FC } from 'react';
import { ArrowRight } from '../../icons';
import { formatCurrency } from '../../utils';

type ExperienceSavingsGridProps = {
  experience: Experience;
  highlightedIndex?: number;
};

const Pill = ({ contrast = 'light', ...divProps }) => {
  const textColor = contrast === 'dark' ? 'text-white' : 'text-charcoal';
  const bgColor = contrast === 'dark' ? 'bg-charcoal' : contrast === 'white' ? 'bg-white' : 'bg-gray-lighter';

  return <div className={`${textColor} ${bgColor} rounded-lg py-1 px-2 text-center font-bold`} {...divProps} />;
};

export const ExperienceSavingsGrid: FC<ExperienceSavingsGridProps> = ({ experience }) => {
  if (experience?.current_contract_version === 0) {
    return (
      <p>
        <Trans>
          {experience?.savings_percentage}% de réduction, jusqu'à concurrence de{' '}
          <span className="text-error">{formatCurrency(experience?.savings_max)}</span> d'économies.
        </Trans>
      </p>
    );
  }

  return (
    <>
      <div className="grid max-w-sm grid-cols-5 items-center gap-3 focus-within:outline-none">
        <div className="col-span-2 justify-self-start">
          <p className="font-semibold">
            <Trans>Achetez</Trans>
            <ArrowRight className="ml-2 inline-flex w-3" fill="currentColor" />
          </p>
        </div>
        <Pill contrast="white">{formatCurrency(experience.savings_matrix.amount1)}</Pill>
        <Pill contrast="white">{formatCurrency(experience.savings_matrix.amount2)}</Pill>
        <Pill contrast="white">{formatCurrency(experience.savings_matrix.amount3)}</Pill>
        <div className="col-span-2 justify-self-start">
          <p className="font-semibold">
            <Trans>Recevez</Trans>
            <ArrowRight className="ml-2 inline-flex w-3" fill="currentColor" />
          </p>
        </div>
        <Pill contrast="dark">{formatCurrency(experience.savings_matrix.savings1)}</Pill>
        <Pill contrast="dark">{formatCurrency(experience.savings_matrix.savings2)}</Pill>
        <Pill contrast="dark">{formatCurrency(experience.savings_matrix.savings3)}</Pill>
      </div>
    </>
  );
};
