import { Experience } from '@milo/types/index';
import { ExperienceGet, ExperienceList } from '../../graphql-queries';
import { getGraphqlClient } from '../useGraphqlClient';
import { useQuery } from '../useQuery';

export const getExperienceById: (id: number) => Promise<Experience> = async (id) => {
  const client = getGraphqlClient();
  const data = await client.request(ExperienceGet, { id });
  const exp = data?.experience as Experience;
  return exp;
};

export const getExperiences: () => Promise<Experience[]> = async () => {
  const client = getGraphqlClient();
  const data = await client.request(ExperienceList);
  const exps = data?.ExperienceList as Experience[];
  return exps;
};

export const useExperiences = () => {
  const response = useQuery<Experience[]>(['ExperienceList'], async () => await getExperiences(), {
    initialData: [],
  });

  return response;
};
