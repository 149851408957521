import { Trans } from '@lingui/macro';
import { GettingStartedBlock, LinkButton, Loading, StepProps } from '../../../components';
import { useAuthentication } from '../../../hooks';
import { ArrowRight } from '../../../icons';
import { ReactFC } from '../../../types';

export type OnboardingGiftActivatedProps = StepProps & {};

const RedArrow = (props) => <ArrowRight fill="raspberry-red" width="20" className="mr-2" {...props} />;
const Row = (props) => <div className="mt-4 flex flex-row items-center" {...props} />;
const RowText = (props) => <p {...props} />;

export const OnboardingGiftActivated: ReactFC<OnboardingGiftActivatedProps> = () => {
  const { user, isLoading } = useAuthentication();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="animate-fade-in p-4">
      <div className="mx-auto max-w-xl">
        <p className="mb-4 text-3xl font-bold">
          🍾🎉 <Trans>Bravo {user?.first_name}, vous êtes officiellement membre!</Trans>
        </p>
        <p className="mb-2 text-lg">
          <Trans>
            Partez à la découverte de M ta région, et profitez sans attendre de tous les avantages de votre pass M ta
            région.
          </Trans>
        </p>
      </div>

      <div className="my-10">
        <GettingStartedBlock />
      </div>

      <div className="mb-6">
        <Row>
          <RedArrow />
          <RowText>
            <Trans>Trouver des expériences</Trans>
          </RowText>
        </Row>
        <Row>
          <RedArrow />
          <RowText>
            <Trans>Créer des listes de favoris</Trans>
          </RowText>
        </Row>
        <Row>
          <RedArrow />
          <RowText>
            <Trans>Obtenir une réduction-découverte</Trans>
          </RowText>
        </Row>
      </div>

      <div className="flex flex-wrap gap-2">
        <LinkButton href="/tutorials" variant="secondary">
          <Trans>Voir le guide de l'utilisateur</Trans>
        </LinkButton>

        <LinkButton href="/experiences">
          <Trans>Parcourir les expériences</Trans>
        </LinkButton>
      </div>
    </div>
  );
};
