import { t, Trans } from '@lingui/macro';
import { Accordion, EmptyInbox, InvoiceItem, Text, useAuthentication, useProducts } from '@milo/react';
import { ReactFC } from 'src/types';

export const Invoices: ReactFC = () => {
  const { user } = useAuthentication();
  const { data: products = [] } = useProducts();
  const invoices = user?.invoices;
  return (
    <Accordion heading={t`Historique d'achats (${invoices?.length})`}>
      {invoices?.length > 0 ? (
        invoices?.map((invoice) => (
          <InvoiceItem
            key={invoice.id}
            id={invoice.id}
            name={products?.find((p) => p?.id === invoice.name)?.name}
            amount={invoice.amount}
            date_created={invoice.date_created}
            offered_as_gift={invoice.offered_as_gift}
          />
        ))
      ) : (
        <div className="flex flex-col items-center justify-center gap-2 text-center text-medium">
          <EmptyInbox className=" h-16 w-16" />
          <Text>
            <Trans>Votre historique est vide pour le moment.</Trans>
          </Text>
        </div>
      )}
    </Accordion>
  );
};
