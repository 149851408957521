import { useLocalStorage } from './useLocalStorage';

export const useSavedRedirectUrl = () => {
  const [value, saveFn, deleteFn] = useLocalStorage('saved_redirect_url');

  const saveRedirectUrl = () => {
    const currentUrl = location.href.replace(location.origin, '');
    saveFn(currentUrl);
  };

  const restoreSavedRedirectUrl = () => {
    location.href = value;
  };

  return {
    restoreSavedRedirectUrl,
    savedRedirectUrl: value,
    saveRedirectUrl,
    deleteRedirectUrl: deleteFn,
  };
};
