import { t } from '@lingui/macro';

import sortBy from 'lodash/sortBy';
import { useEffect, useState } from 'react';
import { useRegions } from '../../hooks/repositories/useRegions';
import { ReactFC } from '../../types';
import { InputSelect2, InputSelect2Props } from './InputSelect2';

export const InputRegion2: ReactFC<InputSelect2Props> = (props) => {
  const { data: regions = [] } = useRegions();
  const [regionsOptions, setRegionsOptions] = useState([]);

  useEffect(() => {
    if (regions.length > 0) {
      const mappedRegionsOptions = regions.map((r) => {
        return { key: r.name, label: `${r.name}${r.id < 100 ? ` (${r.id})` : ''}`, value: Number(r.id) };
      });

      const sorted = sortBy(mappedRegionsOptions, 'key');
      const other = sorted.find((r) => r.value === 99999);
      sorted.splice(sorted.indexOf(other), 1);
      sorted.push(other);
      setRegionsOptions(sorted);
    }
  }, [regions.length]);

  return (
    <InputSelect2 placeholder={t`Choisir Région...`} items={regionsOptions} {...props} data-testid="input-region" />
  );
};
