import { throttle } from 'lodash';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { ArrowLeft, ArrowRight } from '../../icons';
import { ReactFC } from '../../types';

type Slider2Props = {
  slides: ReactNode[];
  initialSlideIndex?: number;
  spaceBetween?: number;
  navigation?: boolean;
  scrollbar?: boolean;
  pagination?: boolean;
  paginationClickable?: boolean;
  keyboardControlled?: boolean;
};

const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const Slider: ReactFC<Slider2Props> = ({ slides, initialSlideIndex = 0 }) => {
  const sliderIdRef = useRef(Math.random().toString(36).substr(2, 9));
  const slidesCount = slides.length;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(initialSlideIndex);

  const sliderId = `slider-${sliderIdRef.current}`;

  const scrollToSlide = (slideIndex: number | 'last', behavior: ScrollBehavior = 'smooth') => {
    const index = slideIndex === 'last' ? slidesCount - 1 : slideIndex;
    const slides = document.querySelectorAll(`#${sliderId} .slider-slide`);
    const slide = slides[index];
    setCurrentSlideIndex(index);
    slide.scrollIntoView({ behavior, block: 'center', inline: 'center' });
  };

  useEffect(() => {
    scrollToSlide(initialSlideIndex, 'auto');
    const sliderContainer = document.querySelector(`#${sliderId}`);
    const containerScrollHandler = throttle((e) => {
      const slides = document.querySelectorAll(`#${sliderId} .slider-slide`);
      const slideInView = Array.from(slides).find((slide, i) => isInViewport(slide));
      const inViewIndex = Array.from(slides).indexOf(slideInView);
      if (inViewIndex === -1) return;

      setCurrentSlideIndex(inViewIndex);
    }, 100);
    sliderContainer.addEventListener('scroll', containerScrollHandler);

    return () => {
      sliderContainer.removeEventListener('scroll', containerScrollHandler);
    };
  }, []);

  return (
    <div
      id={sliderId}
      className="slider no-scrollbar relative flex w-screen snap-x snap-mandatory items-center overflow-x-scroll whitespace-nowrap"
      data-testid={'slider'}
    >
      {slides.map((slide, i) => (
        <div data-index={i} className="slider-slide w-full shrink-0 snap-center" key={`slide-${i}`}>
          <div className="mx-auto h-[80vh] rounded-3xl">{slide}</div>
        </div>
      ))}

      <div className="fixed bottom-5 left-1/2 flex w-fit -translate-x-1/2 items-center gap-3">
        {currentSlideIndex > 0 && (
          <button
            className="animate-fade-in"
            data-testid="slider-button-left"
            onClick={() => scrollToSlide(currentSlideIndex - 1 >= 0 ? currentSlideIndex - 1 : 0)}
          >
            <ArrowLeft
              data-testid="slider-previous-button"
              className="h-12 w-12 cursor-pointer rounded-full bg-black/10 p-3 text-white"
            />
          </button>
        )}

        <div className="flex gap-2">
          {slides.map((_, i) => {
            return (
              <button
                data-testid={'slider-pagination-button'}
                onClick={() => scrollToSlide(i)}
                className={`block h-4 w-4 cursor-pointer rounded-full transition-colors duration-200 ${
                  currentSlideIndex === i ? 'bg-gray-50' : 'bg-gray-50/30'
                }`}
              />
            );
          })}
        </div>

        {currentSlideIndex + 1 < slidesCount && (
          <button
            data-testid="slider-button-right"
            className="animate-fade-in"
            onClick={() =>
              scrollToSlide(currentSlideIndex + 1 <= slidesCount - 1 ? currentSlideIndex + 1 : slidesCount - 1)
            }
          >
            <ArrowRight className=" h-12 w-12 cursor-pointer rounded-full bg-black/10 p-3 text-white" />
          </button>
        )}
      </div>
    </div>
  );
};
