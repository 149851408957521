export type ExpireObject = {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

export const getExpireDateStringFromExpireObject = (expire: ExpireObject) => {
  const { days = 0, hours = 0, minutes = 0, seconds = 0 } = expire ?? {};

  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) return null;

  let date = new Date();

  const daysInMs = days * 24 * 60 * 60 * 1000;
  const hoursInMs = hours * 60 * 60 * 1000;
  const minutesInMs = minutes * 60 * 1000;
  const secondsInMs = seconds * 1000;
  const totalMs = daysInMs + hoursInMs + minutesInMs + secondsInMs;

  date.setTime(date.getTime() + totalMs);
  const dateString = date.toUTCString();
  return dateString;
};

export const setCookie = (name: string, value: any, { expires: { days = 0, hours = 0, minutes = 0, seconds = 0 } }) => {
  const dateString = getExpireDateStringFromExpireObject({ days, hours, minutes, seconds });
  const cookieExpire = '; expires=' + dateString;

  const val = (() => {
    if (typeof value === 'string') return value;
    return JSON.stringify(value);
  })();

  const cookie = name + '=' + val + cookieExpire + '; path=/';
  document.cookie = cookie;
};

export const getCookie = (name) => {
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split('=');
    if (cookie[0] === name) {
      return decodeURIComponent(cookie[1]);
    }
  }
  return null; // Cookie not found
};

export const deleteCookie = (name: string) => {
  document.cookie = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
