import { Region } from '@milo/types/index';
import { useQuery } from '@tanstack/react-query';
import { RegionsList } from '../../graphql-queries';
import { getGraphqlClient } from '../useGraphqlClient';

export const getRegions: () => Promise<Region[]> = async () => {
  const graphqlClient = getGraphqlClient();
  const data = await graphqlClient.request(RegionsList);
  return data?.RegionsList as Region[];
};

export const useRegions = () => {
  return useQuery(['RegionsList'], async () => await getRegions(), {
    initialData: [],
  });
};
