import { t, Trans } from '@lingui/macro';
import { useState } from 'react';
import { useAuthentication, useFeatureFlag, useLocalStorage } from '../../hooks';
import { ReactFC } from '../../types';
import { Button } from '../Button';
import { Link2 } from '../Link';
import { Modal, ModalBody } from '../Modal';

export const promoSlug = 'black-friday-2023';
const promoText = t`Découvrez M ta région et plongez dans les couleurs de l'automne québécois! Nous couvrons 19 régions, offrant
une variété d'expériences inoubliables. De la gastronomie aux aventures en plein air, économisez avec des
réductions allant jusqu'à 50% chez nos 450 partenaires grâce à votre pass M ta région.`;
const promoTitle = t`Vendredi Fou: 70% de réduction sur le pass M ta région annuel!`;

export const PromotionPopup: ReactFC = () => {
  const [closed, setClosed] = useState(false);
  const [isDismissed, setIsDismissed] = useLocalStorage(`${promoSlug}-dismissed`, false, { minutes: 30 });
  const userDismissedBefore = Boolean(isDismissed);
  const { data: featureActive, isLoading: featureLoading } = useFeatureFlag(promoSlug);
  const { subscribed, isLoading: userLoading } = useAuthentication();

  const urlIsMiloFreePage = window.location.pathname.includes('/milo-gratuit');
  const urlIsPurchasePage = window.location.pathname.includes('/purchase');

  const loading = featureLoading || userLoading;

  if (loading || userDismissedBefore || !featureActive || urlIsMiloFreePage || urlIsPurchasePage || subscribed) {
    return null;
  }

  const dismiss = () => {
    setIsDismissed(true);
    setClosed(true);
  };

  const modalClosed = userDismissedBefore || closed;

  return (
    <Modal open={!modalClosed} onRequestClose={dismiss}>
      <ModalBody>
        <div className="flex flex-col items-center justify-center gap-7">
          <p className="text-5xl">🛍️</p>
          <p className="text-2xl font-semibold">{promoTitle}</p>
          <p>{promoText}</p>
          <Link2 href={`/purchase?utm_campaign=${promoSlug}&utm_source=promo-popup`}>
            <Button className="w-full">
              <Trans>Je veux en profiter</Trans>
            </Button>
          </Link2>
        </div>
      </ModalBody>
    </Modal>
  );
};
