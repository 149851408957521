const SvgComponent = (props) => (
  <svg fill="none" {...props} viewBox="0 0 15 16">
    <path
      d="M0 1.584A1 1 0 0 1 1.43.68l12.764 6.078a1 1 0 0 1 .033 1.788L1.464 15.233A1 1 0 0 1 0 14.347V1.584Z"
      fill={props.fill || '#2D2C27'}
    />
  </svg>
);

export { SvgComponent as Play };
