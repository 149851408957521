import { colorFn, ColorType } from '../utils';

function SvgComponent({ fill = 'currentColor' as ColorType, ...props }) {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="0 0 22 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8 11a8.8 8.8 0 11-2.917-6.545l-5.997 7.663-3.002-3.002a1.25 1.25 0 10-1.768 1.768l3.202 3.201a2.25 2.25 0 003.362-.204l5.837-7.458A8.76 8.76 0 0119.8 11zm.146-6.402A10.95 10.95 0 0122 11c0 6.075-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0c2.773 0 5.306 1.026 7.241 2.72l.775-.99a1.25 1.25 0 011.968 1.54l-1.038 1.328z"
        fill={_fill}
      />
    </svg>
  );
}

export { SvgComponent as CheckCircleAlt };
