import { Trans } from '@lingui/macro';
import { FlowContainer, Loading, Route, UpdateUserInformationForm, useAuthentication } from '@milo/react';

export const MiloFreeInformations = () => {
  const { user, isLoading } = useAuthentication();

  if (isLoading) return <Loading />;

  if (user?.has_passed_onboarding) {
    location.href = '/milo-gratuit/succes';
    return <Loading />;
  }

  return (
    <Route.LoggedIn redirectPath="/milo-gratuit">
      <FlowContainer>
        <div className="mb-6">
          <h1 className="mb-2 text-3xl font-semibold">
            <Trans>Bienvenue chez M ta région!</Trans> <br />
          </h1>
          <p>
            <Trans>
              Confirmez vos informations pour pouvoir utiliser notre plateforme entièrement gratuitement pendant tout le
              mois!
            </Trans>
          </p>
        </div>
        <UpdateUserInformationForm
          showHeaderText={false}
          onSuccess={() => (location.href = '/milo-gratuit/succes')}
          showTerms={false}
        />
      </FlowContainer>
    </Route.LoggedIn>
  );
};
