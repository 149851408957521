import { Trans } from '@lingui/macro';
import { InfoBox } from '@milo/react';
import { ReactFC } from '../../types';
import { TutorialLayout, TutorialStep } from './base';

export const ValidationTutorialPage: ReactFC = () => {
  return (
    <TutorialLayout title={<Trans>Obtenir une réduction-découverte</Trans>}>
      <TutorialStep title={<Trans>Sélectionnez une expérience</Trans>}>
        <p className="mb-6 leading-[22px] text-dark">
          <Trans>
            <strong className="font-semibold">Sélectionnez l’expérience</strong> pour laquelle vous souhaitez obtenir
            une réduction-découverte.
          </Trans>
        </p>
        <video autoPlay loop muted playsInline className="mx-auto mt-6 w-full">
          <source type="video/mp4" src="/images/tutorials/ill-obtain privilege-step1.mp4" />
        </video>
      </TutorialStep>
      <TutorialStep title={<Trans>Annoncez que vous êtes membre</Trans>}>
        <p className="leading-[22px] text-dark">
          <Trans>
            Maintenant, le timing est important! Au moment de réclamer la facture,{' '}
            <strong className="font-semibold">annoncez que vous êtes membre M ta région</strong>, puis cliquez sur{' '}
            <strong className="font-semibold">Obtenir ma réduction-découverte</strong>.
          </Trans>
        </p>

        <img src="/images/tutorials/ill-obtain privilege-step2.png" className="mx-auto mt-6 w-full" />

        <InfoBox.Default showIcon small className="mt-6">
          <p>
            <Trans>
              Bon à savoir:{' '}
              <strong className="font-semibold">Votre numéro de membre est votre preuve d’abonnement.</strong>{' '}
              Trouvez-le dans la section “Compte et Abonnement”.
            </Trans>
          </p>
        </InfoBox.Default>
      </TutorialStep>
      <TutorialStep title={<Trans>Découvert avec M ta région?</Trans>}>
        <p className="leading-[22px] text-dark">
          <Trans>Indiquez-nous si M ta région vous a aidé à découvrir cette expérience.</Trans>
        </p>

        <img src="/images/tutorials/ill-obtain privilege-step3.png" className="mx-auto mt-6 w-full" />
      </TutorialStep>
      <TutorialStep title={<Trans>Indiquez votre facture totale</Trans>}>
        <p className="leading-[22px] text-dark">
          <Trans>
            Demandez le montant de votre facture avant taxes, indiquez-le et cliquez sur{' '}
            <strong className="font-semibold">Calcul de votre réduction-découverte</strong>.
          </Trans>
        </p>
        <video autoPlay loop muted playsInline className="mx-auto mt-6 w-full">
          <source type="video/mp4" src="/images/tutorials/ill-obtain privilege-step4.mp4" />
        </video>
      </TutorialStep>
      <TutorialStep title={<Trans>Validez votre réduction-découverte</Trans>}>
        <p className="leading-[22px] text-dark">
          <Trans>
            Confirmez avec le commerçant le montant de votre réduction-découverte et glissez votre doigt sur l’écran
            pour valider l’utilisation.
          </Trans>
        </p>
        <video autoPlay loop muted playsInline className="mx-auto mt-6 w-full">
          <source type="video/mp4" src="/images/tutorials/ill-obtain privilege-step5.mp4" />
        </video>
      </TutorialStep>
    </TutorialLayout>
  );
};
