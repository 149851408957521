import { cloneElement, createContext, FC, HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react';
import { FieldLabel2 as FieldLabel } from '.';
import { ID } from '../../utils';

export const FieldContext = createContext<{
  setErrorText: (errorText: ReactNode) => void;
  setSuccessText: (successText: ReactNode) => void;
}>({
  setErrorText: () => {},
  setSuccessText: () => {},
});

const StyledHelpText: FC<HTMLAttributes<HTMLParagraphElement>> = ({ className = '', ...pProps }) => (
  <p className={`mt-1 text-xs leading-4 ${className}`} {...pProps} />
);

export type FieldProps = HTMLAttributes<HTMLDivElement> & {
  label?: ReactNode;
  helpText?: ReactNode;
  errorText?: ReactNode;
  successText?: ReactNode;
  children?: JSX.Element;
};

export const Field2: FC<FieldProps> = ({
  label,
  helpText,
  errorText: errorTextProp,
  successText: successTextProp,
  children,
  className = '',
  ...divProps
}) => {
  const [errorText, setErrorText] = useState(errorTextProp);
  const [successText, setSuccessText] = useState(successTextProp);

  useEffect(() => {
    setErrorText(errorTextProp);
  }, [errorTextProp]);

  useEffect(() => {
    setSuccessText(successTextProp);
  }, [successTextProp]);

  // So that the label is clikable
  const id = useMemo(() => ID(), []);

  const renderedText = (() => {
    if (errorText) {
      return <StyledHelpText className="text-error">{errorText}</StyledHelpText>;
    }

    if (successText) {
      return <StyledHelpText className="text-success">{successText}</StyledHelpText>;
    }

    if (helpText) {
      return <StyledHelpText className="text-medium-dark">{helpText}</StyledHelpText>;
    }

    return null;
  })();

  return (
    <FieldContext.Provider value={{ setErrorText, setSuccessText }}>
      <div className={`mb-6 last:mb-0 ${className}`} {...divProps}>
        {label !== undefined && <FieldLabel htmlFor={id}>{label}</FieldLabel>}
        {cloneElement(children, { id, error: errorText !== undefined, success: successText !== undefined })}
        {renderedText}
      </div>
    </FieldContext.Provider>
  );
};
