import { rgba } from 'polished';
import { ColorType, palette } from './palette';

/**
 * Based on taildwind's default breakpoints
 * @link https://tailwindcss.com/docs/breakpoints
 */
export const breakPoints = {
  xs: 500,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1440,
};

export const containerWidth = (breakpoint: keyof typeof breakPoints) => breakPoints[breakpoint];

/**
 * Pass a width, and it returns its 2-letter breakpoint
 */
export const breakpointFromWidth = (width: number) =>
  Object.entries(breakPoints).reduce(
    (breakpoint, [currentBreakpoint, currentBreakpointMaxWidth]) =>
      currentBreakpointMaxWidth <= width ? currentBreakpoint : breakpoint,
    'xs'
  );

export const makeVariants = (v) => {
  return ({ variant }) => v[variant];
};

export const colorFn: (color: ColorType, alpha?: number) => string = (color, alpha = 1) => {
  if (!color || color === 'transparent') {
    return null;
  }

  if (color in palette) {
    color = palette[color];
  }

  if (color === 'currentColor') {
    return 'currentColor' as ColorType;
  }

  return rgba(color as string, alpha);
};

const zIndexes = {
  navigation: 1000,
  modal: 2000,
  'modal-content': 3000,
  toast: 4000,
};

/**
 * Takes the desired z-index hierarchy and adds a positive or negative modifier to the depth
 * @param type One of the pre-defined z-index hierarchy
 * @param modifier How many levels you want to add or remove to it (signed int)
 * @returns The processed z-index hierarchy
 */
export const zIndexFn = (type: keyof typeof zIndexes, modifier: number = 0) => {
  if (type) {
    return zIndexes[type] + modifier;
  }

  throw Error('You need to pass a type to zIndex function.');
};

// This is just a utility function to allow for tailwind autocompletion outside of components className attribute
export const tw = (string) => string[0];

export const statsPalette: ColorType[] = ['#005f8b', '#7465b0', '#d55ca2', '#ff6a66', '#ffa600'];
