import { t } from '@lingui/macro';

import { LooseObject } from '@milo/types/index';
import classNames from 'classnames';
import { ReactFC } from 'src/types';
import { EmailIcon } from '../../icons';
import { FacebookRound, GoogleG } from '../../logos';
import { formatGetParams } from '../../utils/url';
import { Button2, Button2Props } from './Button2';

export type LoginButtonProps = {
  loginType?: 'facebook' | 'google' | 'email' | 'partner';
  getParams?: LooseObject;
} & Button2Props;

export const LoginButton: ReactFC<LoginButtonProps> = (props) => {
  const { children, loginType, getParams = {}, className, ...rest } = props;
  const formattedGetParams = formatGetParams(getParams);

  const computed: {
    IconComponent?: ReactFC<{ className?: string }>;
    buttonVariant?: Button2Props['variant'];
    text?: string;
    href?: string;
    classes?: string;
  } = (() => {
    if (loginType === 'facebook') {
      return {
        IconComponent: FacebookRound,
        text: t`Continuer avec Facebook`,
        href: `api/auth/facebook?${formattedGetParams}`,
        buttonVariant: 'secondary',
        classes: classNames('', className),
      };
    }

    if (loginType === 'google') {
      return {
        IconComponent: GoogleG,
        text: t`Continuer avec Google`,
        href: `/api/auth/google?${formattedGetParams}`,
        buttonVariant: 'secondary',
        classes: classNames('', className),
      };
    }

    if (loginType === 'email') {
      return {
        IconComponent: EmailIcon,
        text: t`Connexion par courriel`,
        href: `/login/email`,
        buttonVariant: 'secondary',
        classes: classNames('', className),
        'data-testid': 'email-login-button',
      };
    }

    return null;
  })();

  const { IconComponent, buttonVariant, text, href, classes, ...restComputed } = computed || {};

  return (
    <a href={href || ''}>
      <Button2
        className={classNames(classes, 'flex items-center !border-gray-light')}
        variant={buttonVariant}
        {...restComputed}
        {...rest}
      >
        {IconComponent && <IconComponent className="mr-3 w-5" />}
        {text}
      </Button2>
    </a>
  );
};
