import { t, Trans } from '@lingui/macro';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect } from 'react';
import { useBrowserObject } from '../../hooks';
import { InputText2 } from '../Input/InputText2';
import { Text } from '../Text';

export type SearchQueryProps = {
  value?: string;
  onChange?: (region: string) => void;
};

export const SearchQuery: FC<SearchQueryProps> = ({ value = '', onChange = () => {} }) => {
  const browserObject = useBrowserObject();

  useEffect(() => {
    onChange(browserObject?.query?.search);
  }, [history.state]);

  const handleChange = useCallback(
    debounce((e) => {
      const val = e.target.value;
      onChange?.(val);
      browserObject.historyPush(null, { search: val });
    }, 500),
    []
  );

  return (
    <div className="relative w-full md:max-w-xs">
      <Text size="h5" className="mb-2 font-semibold">
        <Trans>Mots clés:</Trans>
      </Text>
      <InputText2
        name="search-query"
        className="h-10"
        value={value}
        onChange={handleChange}
        placeholder={t`ex: Boutique, Restaurant, ...`}
      />
    </div>
  );
};
