import { PartnerUser } from '@milo/types/index';
import { useQuery } from '@tanstack/react-query';
import { useGraphqlClient } from './useGraphqlClient';

type usePartnerAuthenticationRetVal = {
  partnerUser: PartnerUser;
  loggedIn: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  isSuccess: boolean;
};
export const usePartnerAuthentication: () => usePartnerAuthenticationRetVal = () => {
  const { client } = useGraphqlClient();

  const response = useQuery(
    ['PartnerCurrent'],
    async () => {
      const data = await client.request(/* GraphQL */ `
        query PartnerCurrent {
          PartnerCurrent {
            id
            is_admin
            has_admin_password
            experiences {
              id
              additional_images
              company_phone
              gross_sales
              location {
                city
              }
              main_image
              name
              slug
              terms_accepted
              visits
              wishlist_total
              savings_code
              categories
              categories_names
              savings_percentage
              savings_max
              status
              savings_matrix {
                amount1
                amount2
                amount3
                savings1
                savings2
                savings3
              }
              savings_type
              reservation_conditions
              ratings {
                bad
                good
                great
              }
              two_for_one_max_count
              current_contract_version
              current_contract {
                signed_date
                signed_contact {
                  first_name
                  last_name
                }
              }
              region {
                name
              }
              partnerships {
                id
                accept_terms_date
                is_current
                onboarding_code
                partner_user_accepted {
                  first_name
                  last_name
                }
                contract {
                  version_date
                  version
                  link
                }
              }
            }
          }
        }
      `);

      return data;
    },
    { retry: false }
  );

  const partnerUser = response?.data?.PartnerCurrent;

  return {
    partnerUser,
    loggedIn: !!partnerUser?.id,
    ...response,
  };
};
