import { LooseObject } from '@milo/types/index';
import qs from 'query-string';
import { useEffect, useMemo, useState } from 'react';

type MiloBrowserObject = {
  query: LooseObject;
  historyPush: (path: string, params?: LooseObject) => void;
};

const getUrlPath = () => {
  return window.location.pathname;
};

const getUrlQueryStringObject = () => {
  return qs.parse(window.location.search);
};

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}

export const useBrowserObject: () => MiloBrowserObject = () => {
  const forceUpdate = useForceUpdate();
  const query = getUrlQueryStringObject();

  useEffect(() => {
    window.addEventListener('popstate', forceUpdate);
    return () => window.removeEventListener('popstate', forceUpdate);
  }, []);

  const historyPush = useMemo(
    () =>
      (path = null, queryStringObject: LooseObject) => {
        const existing = getUrlQueryStringObject();
        const newQueryString = qs.stringify({ ...existing, ...queryStringObject });

        if (!path) {
          path = getUrlPath();
        }

      history.pushState({query: newQueryString}, null, `${path}?${newQueryString}`);

        forceUpdate();
      },
    []
  );

  return { query, historyPush };
};
