import { colorFn, ColorType } from '../utils';

function SvgComponent({ fill = 'currentColor' as ColorType, ...props }) {
  const _fill = colorFn(fill);

  return (
    <svg viewBox="0 0 12 12" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.501 6.001L4.304 8.493a1.291 1.291 0 000 1.663.95.95 0 001.464 0l2.93-3.324a1.291 1.291 0 000-1.662l-2.93-3.325a.951.951 0 00-1.465 0 1.292 1.292 0 000 1.663l2.198 2.493z"
        fill={_fill}
      />
    </svg>
  );
}

export { SvgComponent as Chevron };
