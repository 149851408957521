import { useEffect } from 'react';

type ScrollHijackProps = {
  onEscapeKeyPress?: () => void;
  isOpen: boolean;
};

// This is the effect that a modal uses to prevent scrolling when open and closes on escape key pressed
export const useScrollHijack = ({ onEscapeKeyPress, isOpen }: ScrollHijackProps) => {
  const handleEscapeKeyPress = (e) => {
    if (onEscapeKeyPress && e.key === 'Escape') onEscapeKeyPress();
  };

  const openEffects = () => {
    document.querySelector('body').style.overflow = document.querySelector('html').style.overflow = 'hidden';

    if (onEscapeKeyPress) {
      // Handle escape to close modal
      document.addEventListener('keydown', handleEscapeKeyPress);
    }
  };

  const closeEffects = () => {
    document.querySelector('body').style.overflow = document.querySelector('html').style.overflow = '';

    if (onEscapeKeyPress) {
      // Remove escape to close modal
      document.removeEventListener('keydown', handleEscapeKeyPress);
    }
  };

  useEffect(() => {
    isOpen && openEffects();
    return closeEffects;
  }, [isOpen]);
};
