import { InfoCircle } from '@milo/react';
import { ReactFC } from '../../types';

type InfoTooltipProps = {
  position?: 'top' | 'bottom';
};

export const InfoTooltip: ReactFC<InfoTooltipProps> = ({ children, position = 'top', className = '' }) => {
  const childrenWrapper = (
    <span className="whitespace-no-wrap relative z-10 w-max max-w-[200px] rounded-xl bg-charcoal p-3 text-center leading-none text-white shadow-lg">
      {children}
    </span>
  );

  return (
    <span className={`group relative flex h-[25px] flex-col items-center ${className}`}>
      <InfoCircle className="h-[25px] w-[25px] cursor-pointer" />

      {position === 'top' && (
        <div className="absolute bottom-7 hidden flex-col items-center group-hover:flex">
          {childrenWrapper}
          <div className="-mt-2 h-3 w-3 rotate-45 bg-charcoal"></div>
        </div>
      )}

      {position === 'bottom' && (
        <div className="absolute -top-7 hidden flex-col items-center group-hover:flex">
          <div className="-mb-2 h-3 w-3 rotate-45 bg-charcoal"></div>
          {childrenWrapper}
        </div>
      )}
    </span>
  );
};
