import { ReactFC } from 'src/types';
import { MiloSVGProps } from '.';
import { colorFn, ColorType } from '../utils';

const SvgComponent: ReactFC<MiloSVGProps & { accent?: ColorType }> = ({
  accent = 'currentColor' as ColorType,
  stroke = 'currentColor' as ColorType,
  ...props
}) => {
  const _stroke = colorFn(stroke);
  const _accent = colorFn(accent);

  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path
        fill={_stroke}
        d="M405.658 507.939a14.813 14.813 0 0 1-9.35-3.316c-3.96-3.215-96.992-79.684-96.992-163.607 0-58.637 47.705-106.342 106.342-106.342S512 282.379 512 341.016c0 8.195-6.644 14.837-14.837 14.837s-14.837-6.643-14.837-14.837c0-42.274-34.393-76.667-76.667-76.667s-76.667 34.393-76.667 76.667c0 55.852 54.302 111.692 76.62 132.281 13.919-12.954 40.35-39.78 58.275-71.678 4.015-7.146 13.06-9.68 20.204-5.666 7.143 4.015 9.68 13.06 5.665 20.204-28.008 49.839-72.796 86.867-74.691 88.419a14.815 14.815 0 0 1-9.407 3.363z"
      />
      <circle fill={_accent} cx={405.652} cy={341.021} r={37.286} />
      <path
        fill={_stroke}
        d="M405.658 393.132c-28.737 0-52.118-23.379-52.118-52.116 0-28.738 23.381-52.118 52.118-52.118s52.118 23.379 52.118 52.118c0 28.736-23.381 52.116-52.118 52.116zm0-74.56c-12.376 0-22.443 10.069-22.443 22.443s10.067 22.441 22.443 22.441 22.443-10.067 22.443-22.441c0-12.375-10.067-22.443-22.443-22.443z"
      />
      <path
        fill={_stroke}
        d="M151.995 494.36a54.242 54.242 0 0 1-19.057-3.472L35.09 454.195C14.103 446.324 0 425.975 0 403.557V124.299a54.091 54.091 0 0 1 23.277-44.451 54.095 54.095 0 0 1 49.794-6.187l70.287 26.358c7.84 2.941 16.518 1.696 23.214-3.328l109.044-81.785c14.837-11.129 34.068-13.888 51.438-7.373l97.848 36.693c20.989 7.873 35.092 28.222 35.092 50.638v84.553c0 8.195-6.644 14.837-14.837 14.837s-14.837-6.643-14.837-14.837V94.865c0-10.116-6.365-19.3-15.839-22.852L316.634 35.32c-7.839-2.941-16.517-1.694-23.214 3.327l-109.044 81.786c-14.842 11.131-34.069 13.888-51.441 7.373l-70.283-26.358c-7.601-2.852-15.796-1.835-22.473 2.792-6.675 4.626-10.505 11.938-10.505 20.06v279.256c0 10.118 6.364 19.3 15.836 22.852l97.848 36.693c7.84 2.941 16.518 1.696 23.214-3.328l109.044-81.785c14.837-11.129 34.068-13.888 51.438-7.373 7.674 2.877 11.561 11.429 8.684 19.103-2.875 7.671-11.429 11.56-19.102 8.683-7.84-2.938-16.518-1.696-23.214 3.327l-109.044 81.786c-9.534 7.151-20.883 10.846-32.383 10.846z"
      />
      <path
        fill={_accent}
        d="M306.344 18.984a39.216 39.216 0 0 0-21.826 7.794l-109.044 81.785a39.232 39.232 0 0 1-26.163 7.745V479.39a39.23 39.23 0 0 0 26.163-7.745l109.044-81.785a39.221 39.221 0 0 1 21.826-7.794V18.984z"
      />
      <path
        fill={_stroke}
        d="M151.939 494.315c-1.203 0-2.418-.04-3.616-.12-7.794-.521-13.849-6.994-13.849-14.805V116.307a14.836 14.836 0 0 1 15.826-14.805 24.23 24.23 0 0 0 16.274-4.81l109.044-81.785c8.73-6.548 19.13-10.264 30.074-10.747a14.863 14.863 0 0 1 10.911 4.103 14.843 14.843 0 0 1 4.579 10.72v363.082c0 7.941-6.251 14.474-14.184 14.822-4.951.218-9.644 1.892-13.573 4.84L184.38 483.513c-9.288 6.967-20.809 10.802-32.441 10.802zm12.211-364.493v331.556a24.304 24.304 0 0 0 2.424-1.604l109.044-81.785a53.808 53.808 0 0 1 15.889-8.218V40.083l-107.128 80.348c-6 4.502-12.935 7.695-20.229 9.391z"
      />
    </svg>
  );
};

export { SvgComponent as Map };
