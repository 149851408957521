import { ReactFC } from '../../types';
import { VideoStatus } from './Video';

type YoutubeEmbedProps = {
  src: string;
  onStatusChange?: (status: VideoStatus) => void;
};

export const YoutubeEmbed: ReactFC<YoutubeEmbedProps> = ({ onStatusChange = () => {}, ...props }) => {
  return (
    <iframe
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="h-full w-full"
      {...props}
    />
  );
};
