import cn from 'classnames';
import { ColorType } from '../utils/palette';
import { ArrowLeft } from './ArrowLeft';

function SvgComponent({ fill = 'currentColor' as ColorType, className = '', ...props }) {
  const classes = cn('rotate-180', className);
  return <ArrowLeft fill={fill} className={classes} {...props} />;
}

export { SvgComponent as ArrowRight };
