const SvgComponent = ({ primaryColor = 'currentColor', ...props }) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.653 3.794A5.753 5.753 0 0 0 4.06 8.19c-.147-.021-.294-.042-.463-.042A3.595 3.595 0 0 0 0 11.744a3.608 3.608 0 0 0 3.596 3.596h1.052l5.489-11.524c-.147 0-.316-.022-.484-.022ZM15.731 6.865c-.315 0-.63.042-.925.105a5.809 5.809 0 0 0-1.094-1.493l1.556-3.26A1.046 1.046 0 0 0 14.785.83a1.046 1.046 0 0 0-1.388.484L5.573 17.78a1.046 1.046 0 0 0 .484 1.388c.147.063.294.105.442.105.378 0 .757-.21.946-.589l1.577-3.302h6.73A4.25 4.25 0 0 0 20 11.134a4.272 4.272 0 0 0-4.269-4.269Z"
      fill={primaryColor}
    />
  </svg>
);

export { SvgComponent as BrokenCloud };
