import { useState } from 'react';

export const useScrollTo: (
  containerRef: React.MutableRefObject<any>
) => [{ onLayout: (e: any) => void }, () => void, { x?: number; y?: number }] = (containerRef) => {
  const [x, setX] = useState();
  const [y, setY] = useState();

  const triggerScroll = () => {
    containerRef.current?.scrollTo({
      y,
      x,
      animated: true,
    });
  };

  const elementProps = {
    onLayout: (e) => {
      setY(e.nativeEvent.layout.y);
      setX(e.nativeEvent.layout.x);
    },
  };

  return [elementProps, triggerScroll, { x, y }];
};
