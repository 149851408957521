import { Trans } from '@lingui/macro';
import { ArrowLeft } from '../../icons';
import { MiloRoundLogo } from '../../logos';
import { ReactFC } from '../../types';
import { Button2 } from '../Button';
import { Link2 } from '../Link';
import { Route } from '../Route';
import { QuickLogin } from './LoginCode';

export const LoginEmail: ReactFC = () => {
  return (
    <Route.LoggedOut>
      <div className="mx-auto flex w-[95%] max-w-lg flex-col justify-center space-y-4">
        <MiloRoundLogo className="mx-auto" />
        <h1 className="text-center text-2xl font-bold">
          <Trans>Connexion par courriel</Trans>
        </h1>

        <QuickLogin centered onSuccess={() => (location.href = '/login/success')} />

        <p className="text-center text-sm opacity-50">
          <Trans>
            * Si vous n'avez pas de profil M ta région, il sera créé automatiquement à partir du courriel fourni.
          </Trans>
        </p>

        <Link2 href="/login" className="mx-auto !block w-fit">
          <Button2 variant="muted" StartIcon={ArrowLeft}>
            <Trans>Retour</Trans>
          </Button2>
        </Link2>
      </div>
    </Route.LoggedOut>
  );
};
