import { Trans } from '@lingui/macro';
import { Experience } from '@milo/types/index';
import { FC } from 'react';
// import { Divider } from '../Divider';

const Heading = ({ className = '', ...h2Props }) => (
  <h2 className={`mb-4 text-center text-[22px] font-semibold leading-7 ${className} `} {...h2Props} />
);

export const ExperienceTermsOfUse: FC<{ experience: Experience }> = ({ experience }) => {
  // const ReservationCondition = () => {
  //   if (!experience?.reservation_conditions) {
  //     return null;
  //   }

  //   return (
  //     <div>
  //       <Heading className="text-medium-dark">
  //         <Trans>Réserver et profiter de votre réduction-découverte</Trans>
  //       </Heading>
  //       <p className="mb-2 text-medium-dark">{experience?.reservation_conditions}</p>
  //       <Divider.thin />
  //     </div>
  //   );
  // };

  // const Restrictions = () => {
  //   if (!experience?.restrictions) {
  //     return null;
  //   }

  //   return (
  //     <>
  //       <Heading className="text-medium-dark">
  //         <Trans>Exceptions</Trans>
  //       </Heading>
  //       <p className="mb-2 text-medium-dark">{experience?.restrictions}</p>
  //       <Divider.thin />
  //     </>
  //   );
  // };

  return (
    <>
      <Heading>Conditions d'utilisation</Heading>
      {/* <Restrictions /> */}
      {/* <ReservationCondition /> */}
      <p className="mb-6 text-medium-dark">
        <Trans>
          Les réductions-découvertes sont appliqués sous forme de réduction calculée sur le montant avant taxes et sur
          le prix régulier. Non cumulable à toute autre offre en cours et non applicable sur les forfaits. <br />
          <br /> La facture doit être acquittée par le membre, une pièce d'identité peut être demandée lors de la
          vérification de la preuve du pass M ta région. Le membre a droit à une seule réduction-découverte par
          expérience par année du pass M ta région. Le réduction-découverte M ta région peut être réclamé une fois par
          période de douze mois chez un même partenaire.
        </Trans>
      </p>

      <p className="text-medium-dark">
        <Trans>Milo peut modifier ou retirer cette expérience sans préavis.</Trans>
      </p>
    </>
  );
};
