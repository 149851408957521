import { Trans } from '@lingui/macro';
import { Category } from '@milo/types/index';
import { FC, useEffect, useMemo, useState } from 'react';
import { Chip2 } from '../../components/Chip/Chip2';
import { useBrowserObject } from '../../hooks';

const CategoryChip = ({ className, ...props }) => {
  return <Chip2 {...props} className={`${className} mr-2 mb-2`} />;
};

export type CategoryFiltersProps = {
  categories?: Category[];
  values?: number[];
  onChange?: (categories: number[]) => void;
};

type CategoryShape = {
  key: number;
  label: string;
};

export const CategoriesFilter: FC<CategoryFiltersProps> = ({ categories, values = [], onChange = () => {} }) => {
  const browserObject = useBrowserObject();
  const [selected, setSelected] = useState(values);

  const formattedCategories: CategoryShape[] = useMemo(() => {
    const cats = categories.map((c) => ({ key: c.id, label: c.name }));
    // Boire et Manger, Boutiques, Activités sportives & de détente, Terroir, Culture et divertissement, Hébergement
    const desiredCategoryOrder = [2, 4, 1, 3, 5, 6];
    cats.sort((a, b) => {
      const aIndex = desiredCategoryOrder.indexOf(a.key);
      const bIndex = desiredCategoryOrder.indexOf(b.key);
      return aIndex < bIndex ? -1 : aIndex > bIndex ? 1 : 0;
    });

    return cats;
  }, [categories]);

  useEffect(() => {
    if (typeof browserObject?.query?.categories === 'string') {
      const cats = [Number(browserObject?.query?.categories)];
      setSelected(cats);
      onChange(cats);
    } else {
      const cats = (browserObject?.query?.categories || []).map((c) => Number(c));
      setSelected(cats);
      onChange(cats);
    }
  }, [browserObject?.query?.categories]);

  const handleChange = useMemo(
    () => (value: number | 'all') => {
      setSelected((s) => {
        const newArray = value === 'all' ? [] : [value];
        onChange(newArray);
        browserObject.historyPush(null, { categories: newArray });
        return newArray;
      });
    },
    []
  );

  const largerScreenClassNames = `md:block md:w-full md:px-0 md:flex-wrap lg:text-center md:left-0`;

  return (
    <div
      className={`side-scroll ios-cut-x-scrollbar relative -left-6 flex w-screen overflow-x-scroll px-6 ${largerScreenClassNames}`}
    >
      <CategoryChip selected={selected.length === 0} onClick={() => handleChange('all')}>
        <Trans>Tout</Trans>
      </CategoryChip>
      {formattedCategories.map((category) => (
        <CategoryChip
          key={category.key}
          selected={selected.includes(category.key)}
          onClick={() => handleChange(category.key)}
        >
          {category.label}
        </CategoryChip>
      ))}
    </div>
  );
};
