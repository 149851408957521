export const ExperienceFragment = `
    id
    additional_images
    categories
    company_phone
    current_contract_version
    description
    facebook
    google_maps_url
    group {
      id
      name
    }
    in_wishlist
    instagram
    location {
      address
      city
      country {
        code
        name
      }
      province {
        code
        name
      }
      postal_code
      lat
      lng
    }
    main_image
    thumbnail_image
    name
    region {
      id
      slug
      name
    }
    reservation_conditions
    restrictions
    promo_code_instructions
    savings_matrix {
      amount1
      amount2
      amount3
      savings1
      savings2
      savings3
    }
    savings_max
    savings_percentage
    savings_type
    slug
    two_for_one_max_count
    vertical_id
    verticals_ephemeral_ids
    visited_this_year
    visits
    website
`;
