import { t } from '@lingui/macro';
import { ArrowRight } from '../../icons';
import { ReactFC } from '../../types';

const BulletPoint: ReactFC = ({ children }) => {
  return (
    <div className="flex gap-2 text-left font-medium">
      <ArrowRight className="mt-1 h-5 self-start" fill="raspberry-red" />
      <div className="w-full">{children}</div>
    </div>
  );
};

type BulletPointsProps = {
  items: string[];
  size?: 'small' | 'normal' | 'large';
};
export const BulletPoints: ReactFC<BulletPointsProps> = ({ items, size = 'normal' }) => {
  const sz = (() => {
    if (size === 'small') return 'md';
    if (size === 'normal') return 'xl';
    if (size === 'large') return '2xl';
  })();

  const textSize = `text-${sz}`;

  return (
    <div>
      <div className={`space-y-3 ${textSize}`}>
        {items.map((item, index) => {
          return <BulletPoint key={item + index}>{item}</BulletPoint>;
        })}
      </div>
    </div>
  );
};

export const MiloBulletPoints: ReactFC = () => {
  const bullets = [
    t`Accédez à des réductions allant jusqu'à 50% à la découverte d’une nouvelle expérience M ta région`,
    t`Obtenez la réduction directement sur votre facture finale en présentant un pass M ta région actif directement depuis votre téléphone`,
    t`Économisez des centaines de dollars chaque mois`,
  ];

  return <BulletPoints items={bullets} />;
};
