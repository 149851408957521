import config from '@milo/config/config-public.json';
import { Experience } from '@milo/types';
import { useState } from 'react';
import { useAuthentication } from '../../hooks';
import { Heart, MapPinAlt } from '../../icons';
import { ReactFC } from '../../types';
import { convertCentsIntegerToClosestFiveDollarString } from '../../utils';
import { AddWishlistModal } from '../AddWishlistModal';
import { ImageKitImage } from '../Image';
import { Link2 } from '../Link';
import { VerticalLabel } from './VerticalLabel';

type ExperienceCardProps = { experience: Experience; showLikeButton?: boolean; clickable?: boolean };

export const ExperienceCard: ReactFC<ExperienceCardProps> = ({
  experience,
  showLikeButton = true,
  clickable = true,
}) => {
  const { user } = useAuthentication();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const displayedImage = experience?.thumbnail_image || experience?.main_image;
  const inWishlist = user?.wishlists?.some((wishlist) =>
    wishlist?.experiences?.some((exp) => exp?.id === experience.id)
  );

  const additionalImagesNames = experience?.additional_images || [
    `https://${config.APP_DOMAIN}/wp-content/uploads/2022/10/311586969_5616748038371749_7515329476885463724_n.jpeg`,
  ];

  // const handleClickArrowImage = (e: Event, step: number) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   const newIndex = currentImageIndex + step;
  //   setCurrentImageIndex(newIndex >= additionalImagesNames.length ? 0 : newIndex);
  // };

  const Wrapper = clickable ? Link2 : (props) => <div {...props} />;

  return (
    <Wrapper href={`/experiences/${experience.slug}`} popup>
      <div className="relative mx-2 mb-4 flex min-h-[250px] w-[260px] shrink-0 flex-col items-center rounded-xl shadow-lg">
        <div className="absolute top-0 flex w-full flex-row items-center justify-between rounded-t-xl bg-gradient-to-b from-medium-dark">
          <p className="p-4 text-2xl font-bold text-white">
            -{experience?.savings_max ? convertCentsIntegerToClosestFiveDollarString(experience?.savings_max) : ' 0 $'}
          </p>
          {showLikeButton && (
            <AddWishlistModal experience={experience}>
              <button className="p-4">
                <Heart
                  className="h-[24px] w-[24px]"
                  fill={inWishlist ? 'red' : 'transparent'}
                  stroke={inWishlist ? 'red' : 'white'}
                />
              </button>
            </AddWishlistModal>
          )}
        </div>
        {/* <div className="absolute top-[90px] left-0 flex w-full justify-between px-1">
          <BasicArrowLeft onClick={(e: Event) => handleClickArrowImage(e, 1)} className="z-50 h-4 w-4 text-white" />
          <BasicArrowRight onClick={(e: Event) => handleClickArrowImage(e, -1)} className="z-50 h-4 w-4 text-white" />
        </div> */}
        <ImageKitImage
          imageFileName={additionalImagesNames[currentImageIndex] || displayedImage}
          className="max-h-[180px] w-full rounded-t-xl object-cover"
        />
        <div className="absolute bottom-0 min-h-[80px] w-full rounded-b-xl bg-white p-4">
          <div className="absolute -top-1/4 flex gap-2">
            <VerticalLabel vertical_id={experience.vertical_id} />
          </div>
          <h3 className="mb-2 truncate text-lg font-bold leading-snug">{experience.name || '?'}</h3>
          <p className="text-sm capitalize text-black">
            <MapPinAlt className="mr-1 inline-block h-4 w-4" />
            {(() => {
              if (experience.distance === undefined || experience.distance === null)
                return experience.region ? experience.region.name : '';
              if (experience.distance < 1) return ` < 1 km`;
              return ` à ${experience.distance} km`;
            })()}
          </p>
        </div>
      </div>
    </Wrapper>
  );
};
