import { t, Trans } from '@lingui/macro';
import { useState } from 'react';
import { ReactFC } from 'src/types';
import { useWishlistMutations } from '../../hooks';
import { InputText2 } from '../Input';
import { Spinner } from '../Loaders';
import { Text } from '../Text';

type NewWishlistFormProps = {
  onCreate?: (name: string) => void;
  onError?: Function;
  onSettled?: Function;
  onSuccess?: Function;
};

export const NewWishlistForm: ReactFC<NewWishlistFormProps> = ({
  onCreate = () => {},
  onSuccess = () => {},
  onError = () => {},
  onSettled = () => {},
}) => {
  const { createWishlist } = useWishlistMutations();
  const [newWishlistName, setNewWishlistName] = useState('');

  const handleCreate = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    onCreate(newWishlistName);

    try {
      await createWishlist.mutateAsync({ name: newWishlistName });
      setNewWishlistName('');
      onSuccess();
    } catch (error) {
      onError(error);
    } finally {
      onSettled();
    }
  };

  const submitDisabled = newWishlistName.length < 1;

  return (
    <form className="relative mb-5 w-full items-center justify-between gap-5 border-b-gray-light">
      <Text size="h3" className="mb-1 font-semibold">
        <Trans>Créer une liste</Trans>
      </Text>

      <div className="relative">
        <InputText2
          className="!rounded-full"
          placeholder={t`ex: vacances d'été`}
          value={newWishlistName}
          onChange={(e) => setNewWishlistName(e.target.value)}
        />
        <button
          onClick={handleCreate}
          disabled={submitDisabled}
          className="absolute top-0 right-0 z-50 h-full px-4 text-lg font-bold disabled:opacity-50"
          type="submit"
        >
          {(createWishlist.isLoading && <Spinner />) || <Trans>Créer</Trans>}
        </button>
      </div>
    </form>
  );
};
