import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import {
  FC,
  MouseEvent,
  ReactNode,
  TouchEvent as TouchDragEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIsomorphicLayoutEffect } from '../../hooks';
import { ArrowRight } from '../../icons';
import { Spinner } from '../Loaders';

export type SwipeButtonProps = {
  lockedText: ReactNode;
  unlockedText: ReactNode;
  icon?: ReactNode;
  isLoading?: Boolean;
  onSuccess?: () => void;
  variant?: 'primary';
  className?: string;
};

export const SwipeButton: FC<SwipeButtonProps> = ({
  isLoading = false,
  lockedText,
  unlockedText,
  icon,
  onSuccess = () => {},
  variant = 'primary',
}) => {
  const startingLeft = 48;

  const [isDragging, setIsDragging] = useState(false);
  const [sliderLeft, setSliderLeft] = useState(0);
  const [unlocked, setUnlocked] = useState(false);

  const container = useRef<HTMLDivElement>();
  const containerWidth = useRef(0);
  const startX = useRef(0);

  // Start
  const onDragStart = (e: MouseEvent) => {
    if (unlocked) return;

    setIsDragging(true);
    startX.current = e.clientX;
  };

  const onTouchStart = (e: TouchDragEvent) => {
    if (unlocked) return;

    setIsDragging(true);
    startX.current = e.touches[0].clientX;
  };

  // During
  const onDrag = useCallback(
    (e: DragEvent) => {
      if (unlocked || !isDragging) return;
      setSliderLeft(Math.min(Math.max(0, e.clientX - startX.current), containerWidth.current));
    },
    [unlocked, isDragging]
  );

  const onTouchMove = useCallback(
    (e: TouchEvent) => {
      if (unlocked || !isDragging) return;
      e.preventDefault();
      setSliderLeft(Math.min(Math.max(0, e.touches[0].clientX - startX.current), containerWidth.current));
    },
    [unlocked, isDragging]
  );

  // Stop
  const onStopDrag = useCallback(() => {
    if (unlocked || !isDragging) return;

    setIsDragging(false);

    if (sliderLeft > containerWidth.current * 0.9) {
      setSliderLeft(containerWidth.current);
      setUnlocked(true);
    } else {
      setSliderLeft(0);
    }
  }, [unlocked, isDragging, sliderLeft]);

  useIsomorphicLayoutEffect(() => {
    const isTouchDevice = 'ontouchstart' in document.documentElement;

    if (isTouchDevice) {
      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onStopDrag);
    } else {
      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', onStopDrag);
    }

    containerWidth.current = container.current.clientWidth - startingLeft;

    return () => {
      if (isTouchDevice) {
        document.removeEventListener('touchmove', onTouchMove);
        document.removeEventListener('touchend', onStopDrag);
      } else {
        document.removeEventListener('mousemove', onDrag);
        document.removeEventListener('mouseup', onStopDrag);
      }
    };
  }, [onTouchMove, onStopDrag, onDrag]);

  useEffect(() => {
    if (unlocked) {
      onSuccess();
    }
  }, [unlocked]);

  const componentClassNames = classNames('text-white relative w-full h-[52px] border-2 rounded-full select-none', {
    'border-raspberry': variant === 'primary',
  });

  const containerClassNames = classNames('float-left w-full h-full bg-white rounded-full relative overflow-hidden', {
    'cursor-default transition-[left]': unlocked,
    'w-full': !unlocked,
    'bg-raspberry': variant === 'primary',
  });

  const sliderClassNames = classNames(
    'touch-none float-left w-full absolute h-full top-0 left-12 rounded-full z-[100] cursor-pointer ',
    {
      'bg-raspberry': variant === 'primary',
      'animate-slide-bounce-right': !isDragging && !unlocked,
    }
  );

  return (
    <div className={componentClassNames}>
      <div className={containerClassNames} ref={container}>
        <div
          className={sliderClassNames}
          style={{ left: sliderLeft + startingLeft, marginLeft: `-100%` }}
          onMouseDown={onDragStart}
          onTouchStart={onTouchStart}
        >
          {!unlocked && (
            <span className="absolute right-4 top-1/2 -translate-y-1/2">
              {icon || <ArrowRight className="h-4 w-4 text-white" fill="currentColor" />}
            </span>
          )}
        </div>
        <div className="absolute inset-0 flex items-center justify-center">{unlocked ? unlockedText : lockedText}</div>
      </div>
      {isLoading && (
        <div className="pointer-events-none absolute top-0 right-0 left-0 bottom-0 z-modal flex items-center justify-between p-5">
          <span className="h-0 w-0" />
          <p className="font-bold">
            <Trans>Confirmation en cours</Trans>
          </p>
          <Spinner className="h-5 w-5" />
        </div>
      )}
    </div>
  );
};
