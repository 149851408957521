'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const ROOT_FONT_SIZE = 16;
const MEMBERSHIP_3_MONTH_PRICE_CENTS = 2700;
const MEMBERSHIP_MONTHLY_PRICE_CENTS = 900;
const MEMBERSHIP_YEARLY_PRICE_CENTS = 9e3;
const MILO_SELECTION_CRITERION_PDF = "https://miloguide.nyc3.digitaloceanspaces.com/prd/files/milo-criteres-selection-2022-11-20.pdf";
const MILO_COMMERCIAL_PRESENTATION_PDF = "https://miloguide.nyc3.digitaloceanspaces.com/prd/files/milo-presentation-commerciale.pdf";
const APP_STORE_LINK = "https://apps.apple.com/ca/app/milo/id1395492825";
const GOOGLE_STORE_LINK = "https://play.google.com/store/apps/details?id=com.miloguide.app2022";
const VALIDATION_VIDEO_URL = "https://www.youtube.com/embed/Ngj9aIKf5gY";
const PRESENTATION_VIDEO_URL = "https://www.youtube.com/embed/hmcYa0yStbI";
const PRESENTATION_VIDEO_POSTER_URL = "https://miloguide.nyc3.digitaloceanspaces.com/prd/videos/milo-presentation-2022-10-26_poster.jpeg";
const IMAGEKIT_BASE_URL = "ik.imagekit.io/milo/prd/images-new";
const trackedUTMs = ["utm_source", "utm_content", "utm_campaign"];
const DISTRIBUTOR_REFERRAL_COOKIE_KEY = "distributor-referral-id";
const DISTRIBUTOR_REFERRAL_KEEP_DAYS = 30;
const PURCHASE_OFFER_DISPLAY_QUERY_KEY = "displayOffer";
const EXPERIENCE_MARKER_CLASS_PREFIX = "experience-marker";
const MAP_DEFAULT_CENTER = [45.565902, -73.575469];
const MAP_DEFAULT_ZOOM = 5;
const MAP_ID = "milo-map";
const MAP_LAST_CLICKED_EXPERIENCE_LS_KEY = "last-clicked-experience-on-map";
const MAP_MAX_ZOOM = 18;
const MAP_MIN_ZOOM = 0;
const MAP_SAVED_CENTER_LS_KEY = "last-center-on-map";
const MAP_SAVED_BOUNDS_LS_KEY = "last-bounds-on-map";
const MAP_SAVED_ZOOM_LS_KEY = "last-zoom-on-map";
const MAP_TILE_LAYER_URL = "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png";
const REDEEM_CODES_TRANSACTION_STATUSES = ["cancelled", "paid", "pending", "refunded", "unpaid"];
const REGION_OTHER_ID = 99999;
const PUSH_NOTIFICATIONS_TOKEN_KEY = "push_notification_token";
const EMAIL_ADDRESS_PARTNERS = "partenaire@mtaregion.com";
const EMAIL_ADDRESS_MEMBERS = "membre@mtaregion.com";
const EMAIL_ADDRESS_CODE = "code@mtaregion.com";
const SOCIAL_FACEBOOK_URL = "https://www.facebook.com/miloguide/";
const SOCIAL_INSTAGRAM_URL = "https://www.instagram.com/miloguide/";
const SOCIAL_LINKEDIN_URL = "https://www.linkedin.com/company/miloguide/";

exports.APP_STORE_LINK = APP_STORE_LINK;
exports.DISTRIBUTOR_REFERRAL_COOKIE_KEY = DISTRIBUTOR_REFERRAL_COOKIE_KEY;
exports.DISTRIBUTOR_REFERRAL_KEEP_DAYS = DISTRIBUTOR_REFERRAL_KEEP_DAYS;
exports.EMAIL_ADDRESS_CODE = EMAIL_ADDRESS_CODE;
exports.EMAIL_ADDRESS_MEMBERS = EMAIL_ADDRESS_MEMBERS;
exports.EMAIL_ADDRESS_PARTNERS = EMAIL_ADDRESS_PARTNERS;
exports.EXPERIENCE_MARKER_CLASS_PREFIX = EXPERIENCE_MARKER_CLASS_PREFIX;
exports.GOOGLE_STORE_LINK = GOOGLE_STORE_LINK;
exports.IMAGEKIT_BASE_URL = IMAGEKIT_BASE_URL;
exports.MAP_DEFAULT_CENTER = MAP_DEFAULT_CENTER;
exports.MAP_DEFAULT_ZOOM = MAP_DEFAULT_ZOOM;
exports.MAP_ID = MAP_ID;
exports.MAP_LAST_CLICKED_EXPERIENCE_LS_KEY = MAP_LAST_CLICKED_EXPERIENCE_LS_KEY;
exports.MAP_MAX_ZOOM = MAP_MAX_ZOOM;
exports.MAP_MIN_ZOOM = MAP_MIN_ZOOM;
exports.MAP_SAVED_BOUNDS_LS_KEY = MAP_SAVED_BOUNDS_LS_KEY;
exports.MAP_SAVED_CENTER_LS_KEY = MAP_SAVED_CENTER_LS_KEY;
exports.MAP_SAVED_ZOOM_LS_KEY = MAP_SAVED_ZOOM_LS_KEY;
exports.MAP_TILE_LAYER_URL = MAP_TILE_LAYER_URL;
exports.MEMBERSHIP_3_MONTH_PRICE_CENTS = MEMBERSHIP_3_MONTH_PRICE_CENTS;
exports.MEMBERSHIP_MONTHLY_PRICE_CENTS = MEMBERSHIP_MONTHLY_PRICE_CENTS;
exports.MEMBERSHIP_YEARLY_PRICE_CENTS = MEMBERSHIP_YEARLY_PRICE_CENTS;
exports.MILO_COMMERCIAL_PRESENTATION_PDF = MILO_COMMERCIAL_PRESENTATION_PDF;
exports.MILO_SELECTION_CRITERION_PDF = MILO_SELECTION_CRITERION_PDF;
exports.PRESENTATION_VIDEO_POSTER_URL = PRESENTATION_VIDEO_POSTER_URL;
exports.PRESENTATION_VIDEO_URL = PRESENTATION_VIDEO_URL;
exports.PURCHASE_OFFER_DISPLAY_QUERY_KEY = PURCHASE_OFFER_DISPLAY_QUERY_KEY;
exports.PUSH_NOTIFICATIONS_TOKEN_KEY = PUSH_NOTIFICATIONS_TOKEN_KEY;
exports.REDEEM_CODES_TRANSACTION_STATUSES = REDEEM_CODES_TRANSACTION_STATUSES;
exports.REGION_OTHER_ID = REGION_OTHER_ID;
exports.ROOT_FONT_SIZE = ROOT_FONT_SIZE;
exports.SOCIAL_FACEBOOK_URL = SOCIAL_FACEBOOK_URL;
exports.SOCIAL_INSTAGRAM_URL = SOCIAL_INSTAGRAM_URL;
exports.SOCIAL_LINKEDIN_URL = SOCIAL_LINKEDIN_URL;
exports.VALIDATION_VIDEO_URL = VALIDATION_VIDEO_URL;
exports.trackedUTMs = trackedUTMs;
