import { PointOfInterest } from '@milo/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HybridGraphqlClient, useGraphqlClient } from '../useGraphqlClient';

const PointOfInterestQuery = /* GraphQL */ `
  query PointOfInterestList {
    PointOfInterestList {
      id
      name
      description
      city
      lat
      lng
      link
      type
      partner {
        id
        name
        description
        logo_url
        website_url
      }
      primary_tag {
        id
        name
      }
      secondary_tags {
        id
        name
      }
    }
  }
`;

export const getPointsOfInterest = async (graphqlClient: HybridGraphqlClient) => {
  const data = await graphqlClient.request(PointOfInterestQuery);
  return data?.PointOfInterestList as PointOfInterest[];
};

type UsePointsOfInterestArgs = {
  options?: UseQueryOptions<PointOfInterest[]>;
};

export const usePointsOfInterest = (args: UsePointsOfInterestArgs) => {
  const { client } = useGraphqlClient();
  const options = {
    staleTime: Infinity,
    ...args.options,
  };

  const response = useQuery<PointOfInterest[]>(
    ['PointsOfInterests'],
    async () => await getPointsOfInterest(client),
    options
  );

  return response;
};
