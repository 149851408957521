const SvgComponent = (props) => (
  <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m3.607 6.297-.94.94 4.626 4.627a1 1 0 0 0 1.414 0l4.626-4.627-.94-.94-3.726 3.72V3.904a.667.667 0 0 0-1.334 0v6.113l-3.726-3.72ZM4 12.904a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H4Z"
      fill="currentColor"
    />
  </svg>
);

export { SvgComponent as DownloadIcon };
