import { PromotionalPartnerIds, PromotionalPartnerStatus, UserCurrentType, UserStatus } from '@milo/types/index';
import { useQuery } from '@tanstack/react-query';
import { POST_LOGOUT_REDIRECT_PATH, queryClient } from '../components';
import { UserCurrent, UserLogout } from '../graphql-queries';
import { deleteFromSessionStorage, readFromSessionStorage } from '../hooks/useSessionStorage';
import { analytics, getCookie } from '../utils';
import { trackEvent } from './useEventTracking';
import { useFeatureFlag } from './useFeatureFlag';
import { getGraphqlClient } from './useGraphqlClient';

export const getUserData: () => Promise<UserCurrentType> = async () => {
  const client = getGraphqlClient();
  const { user } = await client.request(UserCurrent);

  const returnedUser = user ?? {};

  if (!returnedUser.status) {
    returnedUser.status = UserStatus['NonUser'];
  }

  if (!returnedUser?.id) {
    return {
      ...returnedUser,
      wishlist: getCookie('milo-wishlist') ? JSON.parse(getCookie('milo-wishlist')) : [],
    };
  }

  trackEvent('user_data', { user_id: user?.id });

  return returnedUser;
};

const logout = async () => {
  const postLogoutUrl = readFromSessionStorage(POST_LOGOUT_REDIRECT_PATH);
  const client = getGraphqlClient();

  await client.request(UserLogout);
  await analytics.reset();
  await queryClient.invalidateQueries(['UserCurrent']);

  if (postLogoutUrl) {
    deleteFromSessionStorage(POST_LOGOUT_REDIRECT_PATH);
    location.href = postLogoutUrl;
  } else {
    location.href = '/login';
  }
};

export const useAuthentication = () => {
  const {
    data: user,
    isLoading,
    ...queryObserverData
  } = useQuery(
    ['UserCurrent'],
    async () => {
      const user = await getUserData();

      if (user?.id && user?.email) {
        analytics.event('identify', { email: user.email, userId: user.id });
      }

      return user;
    },
    { staleTime: Infinity }
  );

  const { data: juneFreeActive } = useFeatureFlag('june-free', {}, { staleTime: Infinity });

  const isCAAMember = user?.promotional_partners_memberships?.some(
    (p) => p.partner_id === PromotionalPartnerIds['Caa'] && p.membership_status === PromotionalPartnerStatus['Active']
  );

  const subscribed = (() => {
    // If connected and the summer promotion is still active...
    if (juneFreeActive && !!user?.id) return true;

    // ... or they actually has the Milo subscription
    return user?.status === UserStatus['Member'];
  })();

  return {
    isCAAMember,
    isLoading,
    loggedIn: Boolean(user?.id),
    logout,
    subscribed,
    user,
    ...queryObserverData,
  };
};
