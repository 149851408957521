import { ColorType } from '../utils';

function SvgComponent({ fill = 'currentColor' as ColorType, ...props }) {
  return (
    <svg viewBox="0 0 22 23" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3.47 21.912c-.759.42-1.655-.26-1.457-1.103l.843-3.602a.5.5 0 0 0-.487-.614H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v12.593a2 2 0 0 1-2 2h-6.638a1 1 0 0 0-.483.125L3.47 21.912Zm-1.557-8.065a1 1 0 0 0 1 1h1.484a1 1 0 0 1 .958 1.286L4.5 19l8.01-4.046a1 1 0 0 1 .45-.107h6.127a1 1 0 0 0 1-1v-11.1a1 1 0 0 0-1-1H2.913a1 1 0 0 0-1 1v11.1Z" />
      <path d="M6.87 7H6a1 1 0 0 0-1 1v.745a1 1 0 0 0 1 1h.87a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM11.652 7h-.87a1 1 0 0 0-1 1v.745a1 1 0 0 0 1 1h.87a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM16.435 7h-.87a1 1 0 0 0-1 1v.745a1 1 0 0 0 1 1h.87a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1Z" />
    </svg>
  );
}

export { SvgComponent as MessageIcon };
