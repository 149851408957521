export const PlusIcon = (props) => (
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0a1 1 0 0 0-1 1v4H1a1 1 0 1 0 0 2h4v4a1 1 0 1 0 2 0V7h4a1 1 0 1 0 0-2H7V1a1 1 0 0 0-1-1Z"
      fill="currentColor"
    />
  </svg>
);
