import { colorFn } from '../utils';

function SvgComponent({ fill = colorFn('charcoal'), ...props }) {
  return (
    <svg viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M10 0a10 10 0 100 20 10 10 0 000-20zm8.003 9.032h-3.826c-.054-1.735-.29-4.49-1.148-6.503a8.084 8.084 0 014.974 6.503zM10 18.064c-1.155 0-2.106-3.1-2.242-7.096h4.484c-.136 3.996-1.087 7.096-2.242 7.096zM7.758 9.032C7.894 5.035 8.845 1.935 10 1.935s2.107 3.1 2.242 7.097H7.758zM6.971 2.53c-.858 2.013-1.094 4.768-1.148 6.503H1.997A8.084 8.084 0 016.97 2.53zm-4.974 8.439h3.826c.054 1.735.29 4.49 1.148 6.503a8.084 8.084 0 01-4.974-6.503zm11.032 6.503c.858-2.013 1.094-4.771 1.148-6.503h3.826a8.084 8.084 0 01-4.974 6.503z"
        fill={fill}
      />
    </svg>
  );
}

export { SvgComponent as Globe };
