import { Trans } from '@lingui/macro';

import { Address } from '@milo/types/index';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { useGooglePlacesApi } from '../../hooks';
import { ArrowLeft } from '../../icons';
import { ReactFC } from '../../types';
import { Button2 } from '../Button';
import { InputText2 } from './InputText2';

// TODO -- Add other supported countries' codes as we go
enum SupportedCountries {
  Canada = 'canada',
}
const parseGoogleAddressObject: (addressData) => Address = (addressData) => {
  if (addressData?.description.toLowerCase()?.includes(SupportedCountries['Canada'])) {
    return {
      city: addressData?.terms?.[0]?.value,
      province: addressData?.terms?.[1]?.value,
      postal_code: addressData?.terms?.[2]?.value,
      country: 'CA',
    };
  }

  return null;
};

const miloAddressObjectToString: (obj: Address) => string = (obj) => {
  const street_number = obj?.street_number || '';
  const route = obj?.route || '';
  const city = obj?.city || '';
  const postal_code = obj?.postal_code || '';
  const province = obj?.province || '';
  const country = obj?.country || '';

  const addressString = `${street_number} ${route}, ${city} ${postal_code}, ${province} ${country}`
    .trim()
    .replace(/^\,\s/, '')
    .replace(/\,$/, '')
    .trim();

  return addressString;
};

export const InputGooglePlaces: ReactFC<{ initialValue?: Address; onChange: (address: Address) => void }> = ({
  initialValue,
  onChange,
}) => {
  const [editing, setEditing] = useState(false);
  const [focused, setFocused] = useState(false);
  const [addressValue, setAddressValue] = useState<Address | null>(null);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setAddressValue(initialValue);
  }, [JSON.stringify(initialValue)]);

  const displaySelectedAddress = !editing && addressValue;

  const {
    init: useAutocompleteInit,
    suggestions: { data },
    setValue,
    value,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: true,
    debounce: 300,
  });

  useEffect(() => {
    // Load google places
    useGooglePlacesApi({ onLoad: useAutocompleteInit });
  });

  const results = data.filter((d) => d?.types?.includes('postal_code'));
  const addrString = miloAddressObjectToString(addressValue);

  const selectAddress = (selected) => {
    return () => {
      const structuredAddress = parseGoogleAddressObject(selected);
      setEditing(false);
      setAddressValue(structuredAddress);
      onChange && onChange(structuredAddress);
    };
  };

  const ResultBox = useMemo(() => {
    const Container = (props) => {
      return (
        <div
          {...props}
          className="relative -top-1 z-0 max-h-32 animate-fade-in space-y-2 overflow-y-scroll rounded border-[1px] border-gray-lighter bg-white p-3 shadow-sm"
          data-testid="input-google-places_option"
        />
      );
    };

    if (!focused) {
      return null;
    }

    if (results.length > 0) {
      return (
        <Container>
          {results.map((r) => (
            <div
              key={r.place_id}
              onMouseDown={selectAddress(r)}
              className="cursor-pointer rounded p-2 transition-all ease-in hover:bg-gray-lighter"
            >
              {r.description}
            </div>
          ))}
        </Container>
      );
    }

    return (
      <Container>
        <p className="text-md mb-2 text-medium-dark">
          <Trans>Entrez votre code postal et sélectionnez une des suggestions.</Trans>
        </p>
        <p className="text-sm text-medium-gray">
          *{' '}
          <Trans>
            Si vous ne trouvez pas votre code postal exact, tentez d'utiliser seulement les{' '}
            <b>3 premiers caractères </b>
            et sélectionnez simplement votre ville.
          </Trans>
        </p>
      </Container>
    );
  }, [results.length, focused]);

  if (displaySelectedAddress) {
    return (
      <div className="flex items-center justify-between">
        <p className="text-medium-gray">{addrString}</p>
        <Button2
          className="!p-0"
          onClick={() => {
            setEditing(true);
            clearSuggestions();
            setTimeout(() => inputRef.current.focus(), 100);
          }}
          variant="tertiary"
        >
          <Trans>Éditer</Trans>
        </Button2>
      </div>
    );
  }

  return (
    <div>
      <InputText2
        value={value}
        ref={inputRef}
        startIcon={
          (addressValue && (
            <ArrowLeft
              onClick={() => setEditing(false)}
              className="h-4 w-4 cursor-pointer text-medium-dark"
              fill="currentColor"
            />
          )) ||
          null
        }
        autoComplete="off"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        clearable
        className="z-50"
        onChange={(e) => setValue(e.target.value)}
        onClear={() => clearSuggestions()}
        data-testid="input-google-places"
      />
      {ResultBox}
    </div>
  );
};
