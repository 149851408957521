import { ReactFC } from 'src/types';
import { MiloSVGProps } from '.';
import { colorFn, ColorType } from '../utils';

const SvgComponent: ReactFC<MiloSVGProps> = ({
  stroke = 'currentColor' as ColorType,
  fill = 'currentColor' as ColorType,
  ...props
}) => {
  const _fill = colorFn(fill);
  const _stroke = colorFn(stroke);

  return (
    <svg viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6 3.5h4L12 5l2-1.5 2-.5h1.5l2 1L21 5.5l.5 2.5v3L17 16.5l-3.5 4h-2L4 13l-1.5-2.5V8l1-2.5 2.5-2Z"
        fill={_fill}
      />
      <path
        d="M22 8.862a5.95 5.95 0 0 1-1.654 4.13c-2.441 2.531-4.809 5.17-7.34 7.608-.581.55-1.502.53-2.057-.045l-7.295-7.562c-2.205-2.286-2.205-5.976 0-8.261a5.58 5.58 0 0 1 8.08 0l.266.274.265-.274A5.612 5.612 0 0 1 16.305 3c1.52 0 2.973.624 4.04 1.732A5.95 5.95 0 0 1 22 8.862Z"
        stroke={_stroke}
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </svg>
  );
};
export { SvgComponent as Heart };
