import { ReactFC } from 'src/types';
import { MiloSVGProps } from '..';
import { colorFn, ColorType } from '../../utils';

const SvgComponent: ReactFC<MiloSVGProps> = ({ stroke = 'currentColor' as ColorType, ...props }) => {
  const _stroke = colorFn(stroke);

  return (
    <svg viewBox="-2 -2 20 20" fill="none" {...props}>
      <path
        d="M8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 7.21207 2.15519 6.43185 2.45672 5.7039C2.75825 4.97595 3.20021 4.31451 3.75736 3.75736C4.31451 3.20021 4.97595 2.75825 5.7039 2.45672C6.43185 2.15519 7.21207 2 8 2C9.5913 2 11.1174 2.5619 12.2426 3.5621C13.3679 4.56229 14 5.91885 14 7.33333C14 8.04058 13.6839 8.71885 13.1213 9.21895C12.5587 9.71905 11.7956 10 11 10H9.33333C9.03591 9.99521 8.74542 10.09 8.50808 10.2693C8.27074 10.4487 8.10018 10.7022 8.02354 10.9896C7.94689 11.277 7.96856 11.5818 8.0851 11.8555C8.20163 12.1292 8.40635 12.3561 8.66667 12.5C8.79978 12.6228 8.89106 12.7843 8.92769 12.9616C8.96433 13.139 8.94447 13.3234 8.87091 13.4889C8.79735 13.6544 8.67378 13.7927 8.51758 13.8844C8.36137 13.9761 8.18037 14.0165 8 14"
        stroke={_stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.0013 7.33334C5.1854 7.33334 5.33464 7.1841 5.33464 7.00001C5.33464 6.81591 5.1854 6.66667 5.0013 6.66667C4.81721 6.66667 4.66797 6.81591 4.66797 7.00001C4.66797 7.1841 4.81721 7.33334 5.0013 7.33334Z"
        fill="black"
        stroke={_stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.0013 5.33334C8.1854 5.33334 8.33464 5.1841 8.33464 5.00001C8.33464 4.81591 8.1854 4.66667 8.0013 4.66667C7.81721 4.66667 7.66797 4.81591 7.66797 5.00001C7.66797 5.1841 7.81721 5.33334 8.0013 5.33334Z"
        fill="black"
        stroke={_stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0013 7.33334C11.1854 7.33334 11.3346 7.1841 11.3346 7.00001C11.3346 6.81591 11.1854 6.66667 11.0013 6.66667C10.8172 6.66667 10.668 6.81591 10.668 7.00001C10.668 7.1841 10.8172 7.33334 11.0013 7.33334Z"
        fill="black"
        stroke={_stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { SvgComponent as CreativiteLocale };
