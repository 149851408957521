export const MiloMag = (props) => {
  return (
    <svg viewBox="0 0 895 84" {...props}>
      <path
        d="M280.5 2.50019C277.5 5.40019 277.3 8.00019 279.8 11.1002C283.6 15.6002 292 12.7002 292 6.80019C292 4.30019 287.6 0.000187485 285 0.000187485C283.9 0.000187485 281.8 1.10019 280.5 2.50019Z"
        fill="black"
      />
      <path
        d="M705.8 4.2C704 7 700 14.7 700 15.5C700 15.8 701.2 16 702.8 16C705 15.9 706.4 14.7 710.3 9L715.1 2H711.2C708.2 2 706.9 2.5 705.8 4.2Z"
        fill="black"
      />
      <path
        d="M783.2 5.2C780.3 8.1 782.5 13 786.7 13C789.9 13 792.5 8.8 791 6.1C789.8 3.7 785.2 3.2 783.2 5.2Z"
        fill="black"
      />
      <path
        d="M0 36.5V68H6H12L12.1 46.2V24.5L15.9 34C18 39.2 21.9 49 24.5 55.7L29.2 68H33.4H37.5L45.4 47.6C49.7 36.4 53.6 26.6 54.1 25.9C54.6 25.1 55 33.7 55 46.2V68H61H67V36.5V5H59.1H51.3L47.7 14.2C37 42 33.8 50 33.1 49.6C32.7 49.3 28.6 39.2 23.9 27.1L15.5 5H7.8H0V36.5Z"
        fill="black"
      />
      <path
        d="M467 36.4V68H470.5H474V43.7C474.1 15.2 473 14.9 486.1 47.3L494.5 68L498 67.8L501.4 67.5L511 44.3C516.2 31.6 520.8 20.8 521.3 20.4C521.7 20 522 30.5 522 43.8V68H525.5H529V36.4V4.9L524.3 5.2L519.7 5.5L509.1 31.2C503.3 45.4 498.3 57 497.9 57C497.6 57 492.5 45.4 486.7 31.2L476.2 5.5L471.6 5.2L467 4.9V36.4Z"
        fill="black"
      />
      <path
        d="M564.7 9.70019C564.3 10.0002 564 13.0002 564 16.2002V22.0002H560.5C557.3 22.0002 557 22.2002 557 25.0002C557 27.8002 557.3 28.0002 560.5 28.0002H564V42.7002C564 50.9002 564.5 59.2002 565.1 61.2002C566.2 65.3002 569.4 67.6002 574.5 68.1002C580.3 68.5002 581 68.2002 581 65.0002C581 62.4002 580.6 62.0002 578.2 62.0002C572.7 62.0002 572 60.0002 572 43.2002V28.0002H576.5C580.8 28.0002 581 27.9002 581 25.0002C581 22.1002 580.8 22.0002 576.5 22.0002H572V15.5002V9.00019H568.7C566.8 9.00019 565 9.30019 564.7 9.70019Z"
        fill="black"
      />
      <path
        d="M427.082 31.2342C416.882 35.6342 414.882 48.4342 423.082 55.7342C429.482 61.3342 437.782 60.7342 443.482 54.3342C448.782 48.2342 448.482 40.0342 442.782 34.5342C437.982 29.9342 432.582 28.8342 427.082 31.2342Z"
        fill="#E72152"
      />
      <path
        d="M86 22.2004C81.7 24.4004 77 29.8004 77 32.6004C77 33.6004 78.6 34.0004 82.6 34.0004C87 34.0004 88.5 33.6004 89.8 31.9004C90.8 30.7004 93.2 29.7004 95.5 29.3004C100.7 28.7004 105 31.7004 105 36.0004C105 39.2004 104.6 39.4004 92.2 40.4004C80.9 41.4004 75 46.3004 75 54.7004C75 67.5004 92.5 73.4004 101.9 63.8004L105 60.6004V64.3004V68.0004H111.6H118.2L117.8 49.7004C117.5 33.6004 117.3 31.1004 115.5 28.0004C112.5 22.8004 106.2 20.0004 97.5 20.0004C92.1 20.0004 89.3 20.5004 86 22.2004ZM105 50.1004C105 55.0004 100.3 59.0004 94.6 59.0004C91.1 59.0004 89.8 58.5004 88.6 56.8004C84.5 51.0004 89.2 47.6004 101.8 47.1004C104.7 47.0004 105 47.2004 105 50.1004Z"
        fill="black"
      />
      <path
        d="M135.4 21.9002C126 27.7002 122.3 41.8002 127.1 54.4002C129.5 61.1002 133.3 64.5002 139.8 65.9002C147.2 67.4002 152.8 65.7002 157.2 60.5002C158.9 58.6002 159 58.7002 159 64.3002C159 69.1002 158.6 70.5002 156.5 72.5002C153 76.0002 145.4 76.2002 140.7 72.9002C138.5 71.3002 136.7 71.0002 132 71.2002L126 71.5002L127.9 75.0002C129.1 77.3002 131.5 79.4002 135 81.2002C142.6 85.2002 154.2 85.2002 162.1 81.2002C171.3 76.5002 171.5 75.9002 171.5 46.0002V20.5002H165.5H159.5L159 24.5002C158.5 28.4002 158.4 28.4002 157.2 26.2002C153.8 20.2002 142 17.9002 135.4 21.9002ZM154.2 32.0002C157.9 34.8002 159.3 39.2002 158.8 45.7002C158.4 50.5002 157.8 51.8002 155.1 54.2002C152.9 56.1002 150.9 57.0002 148.5 57.0002C146.1 57.0002 144.1 56.1002 141.9 54.2002C139.2 51.8002 138.6 50.5002 138.2 45.7002C137.5 36.4002 141.6 30.0002 148.5 30.0002C150.3 30.0002 152.9 30.9002 154.2 32.0002Z"
        fill="black"
      />
      <path
        d="M190 22.2003C186.7 23.9003 184.9 25.7003 183.2 29.0003L181 33.5003L186.5 33.8003C191.3 34.1003 192.5 33.7003 195.2 31.4003C197.8 29.3003 199.1 28.9003 202.2 29.3003C207.3 30.0003 209 31.5003 209 35.5003C209 39.2003 207.5 40.0003 199.9 40.0003C193.6 40.0003 185.8 42.5003 183.2 45.3003C180 48.7003 178.8 53.6003 180 58.1003C182.8 68.5003 197 71.9003 206 64.4003L209 61.8003V64.9003V68.0003H215.5H222V49.6003C222 32.3003 221.9 31.1003 219.8 28.0003C215.6 21.8003 212.8 20.5003 203.2 20.2003C195.7 19.9003 193.9 20.2003 190 22.2003ZM209 50.5003C209 55.8003 205.4 59.0003 199.6 59.0003C194.6 59.0003 192 57.1003 192 53.6003C192 49.6003 196.5 47.6003 206.3 47.1003C208.8 47.0003 209 47.3003 209 50.5003Z"
        fill="black"
      />
      <path
        d="M231 25V30H242.5C248.8 30 254 30.3 254 30.6C254 31 248.6 38 242 46.2C232 58.7 230 61.8 230 64.6V68H250.1H270.1L269.8 63.2L269.5 58.5L258.2 58L246.8 57.5L257.9 43.1C268.1 29.8 269 28.4 269 24.4V20H250H231V25Z"
        fill="black"
      />
      <path d="M279 44V68H285H291V44V20H285H279V44Z" fill="black" />
      <path
        d="M301 44V68H307.5H314V51.9V35.8L316.9 32.9C319 30.8 320.8 30 323.2 30C330.6 30.1 332 33.7 332 53.1V68H338.6H345.2L344.8 49.7C344.4 29.3 343.4 25.7 337.5 22C332.8 19.1 323.8 19.2 319.3 22.2C317.5 23.5 315.7 25.4 315.3 26.5C314.7 28.1 314.4 27.6 314 24.5L313.5 20.5L307.3 20.2L301 19.9V44Z"
        fill="black"
      />
      <path
        d="M364.4 21.9C348 29.3 348.1 59.7 364.5 66.6C369.6 68.7 376.6 69.3 381.9 68C387.7 66.6 394.3 61.4 395.8 57L396.9 54H391.3C386.9 54 385.2 54.5 382.8 56.5C378.4 60.2 371.9 60 367.9 56.1C366 54.1 365 52.1 365 50.1V47H381H397V42.7C397 33.2 391.9 24.6 384.3 21.5C379.3 19.4 369.4 19.6 364.4 21.9ZM379.9 30.5C382.2 31.6 385 36.2 385 38.6C385 38.8 380.5 39 375 39C368.7 39 365 38.6 365 38C365 36 368 31.1 369.7 30.1C372.3 28.6 376.7 28.8 379.9 30.5Z"
        fill="black"
      />
      <path
        d="M598.4 22.9002C594.2 24.8002 590 29.6002 590 32.4002C590 35.3002 595.8 34.4002 599 31.0002C601.7 28.1002 604 27.5002 610 28.0002C614.1 28.3002 615.9 29.8002 617.1 33.9002C618.9 40.1002 618.3 40.6002 608.1 41.3002C593.6 42.2002 588 46.1002 588 55.1002C588 67.7002 604.1 73.3002 614.1 64.3002L618 60.8002V64.4002C618 67.8002 618.1 68.0002 621.5 68.0002H625V49.7002C625 33.2002 624.8 31.2002 623 28.2002C619 21.6002 606.9 19.0002 598.4 22.9002ZM617.7 51.2002C616.9 57.2002 612.6 61.1002 606.2 61.8002C600.5 62.3002 597.3 60.9002 595.9 57.3002C594.5 53.7002 596.7 49.8002 601 48.3002C602.9 47.6002 607.6 47.1002 611.4 47.1002L618.3 47.0002L617.7 51.2002Z"
        fill="black"
      />
      <path
        d="M670.2 22.4C668.9 23.1 667 25 665.9 26.6L664 29.5V25.7C664 22.1 663.9 22 660.5 22H657V45V68H660.4H663.9L664.2 51C664.5 34.2 664.5 34 667.1 31.4C668.9 29.6 671.2 28.6 674.4 28.3C678.9 27.8 679 27.6 679 24.4C679 21.2 678.8 21 675.8 21C674 21.1 671.5 21.6 670.2 22.4Z"
        fill="black"
      />
      <path
        d="M694.3 22.9003C678.9 29.9003 678.2 58.4003 693.3 66.1003C699.2 69.2003 708 69.2003 713.8 66.2003C717.8 64.0003 722 59.0003 722 56.2003C722 54.4003 715.4 54.6003 714.6 56.5003C713.7 59.1003 707.7 62.0003 703.5 62.0003C696.3 62.0003 690 55.9003 690 49.0003C690 47.1003 690.6 47.0003 706.6 47.0003H723.3L722.7 41.2003C722.2 35.1003 719.5 28.7003 716.3 25.8003C711 20.9003 701.4 19.7003 694.3 22.9003ZM709.8 29.6003C712.4 31.4003 715 36.1003 715 39.0003C715 40.9003 714.4 41.0003 702.5 41.0003C694.6 41.0003 690 40.6003 690 40.0003C690 37.5003 693 32.2003 695.5 30.2003C698.9 27.5003 706.4 27.2003 709.8 29.6003Z"
        fill="black"
      />
      <path
        d="M740.8 23C733.9 26.7 730 34.2 730 43.8C730 51 731.6 56.1 735.2 60.5C742.1 68.8 755.9 69.3 761.8 61.4L763.9 58.5L764 64.8C764 70.6 763.7 71.5 761 74C755.9 78.8 744.8 78.8 740.5 74C739.4 72.8 737.4 72 735.3 72C731.4 72 731.1 73.5 734.2 77.5C741.2 86.4 761.6 86.1 768.3 76.9C771.4 72.7 772 67.5 772 44.2V22H768C764.1 22 764 22 764 25.7L763.9 29.5L761.7 26.9C756.8 21 747.8 19.3 740.8 23ZM759.2 30.7C766.2 36.9 765.5 52.7 757.9 58.3C752.7 62.1 743.5 60.2 740.1 54.6C736.3 48.3 736.6 37.8 740.8 32.2C744.6 27.2 754.4 26.4 759.2 30.7Z"
        fill="black"
      />
      <path
        d="M812.4 22.9003C802.5 27.4003 797.5 40.5003 801 53.1003C804.9 67.1003 821.4 73.1003 833.5 64.9003C843.9 57.9003 845.3 36.3003 836 27.0003C830.1 21.1003 820.2 19.4003 812.4 22.9003ZM828.9 29.9003C834 32.7003 836.5 44.7003 833.6 52.5003C831.2 58.6003 827 62.0003 821.7 62.0003C816.4 62.0003 811.8 59.2003 809.7 54.7003C807.6 50.4003 807.5 40.2003 809.4 35.7003C812.7 27.9003 820.7 25.5003 828.9 29.9003Z"
        fill="black"
      />
      <path
        d="M866.5 22.1C865.4 22.7 863.3 24.4 861.8 25.8L859 28.4V25.2C859 22.1 858.8 22 855 22H851V45V68H855H859V52.5C859 35.8 859.7 32.7 864.2 29.6C865.4 28.7 868.4 28 870.8 28C880.5 28 882 31.3 882 52.5V68H885.5H889V50.2C889 30.8 888.3 27.7 882.8 23.6C879.5 21.3 869.6 20.3 866.5 22.1Z"
        fill="black"
      />
      <path d="M783 45V68H786.5H790V45V22H786.5H783V45Z" fill="black" />
    </svg>
  );
};
