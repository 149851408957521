import { t, Trans } from '@lingui/macro';
import {
  InputSingleFieldForm,
  MiloFull,
  NewsletterAddVisitor,
  ReactFC,
  trackEvent,
  useGraphqlClient,
} from '@milo/react';
import { validate as isValidEmail } from 'email-validator';
import { useState } from 'react';

export type NewsletterFormProps = {
  containerClasses?: string;
  colorScheme?: 'light' | 'dark';
};

export const NewsletterForm: ReactFC<NewsletterFormProps> = ({ colorScheme = 'dark', containerClasses = '' }) => {
  const { client } = useGraphqlClient();
  const [email, setEmail] = useState('');
  const primaryTextColor = colorScheme === 'dark' ? 'text-white' : 'text-charcoal';

  const addEmailToNewslettersList = async () => {
    await client.request(NewsletterAddVisitor, { email });
    trackEvent('subscribe_newsletter', { user_email: email });
  };

  const submitNewsletterEmail = async () => {
    await addEmailToNewslettersList();
  };

  return (
    <div className={`mx-auto my-14 w-11/12 max-w-xl animate-fade-in ${containerClasses}`}>
      <MiloFull className="mx-auto mb-5 w-40" />
      <h1 className={`mb-2 text-center text-3xl font-bold ${primaryTextColor}`}>
        <Trans>Recevez nos inspirations</Trans>
      </h1>
      <p className={`mb-8 text-center ${primaryTextColor}`}>
        <Trans>On vous envoie le meilleur à découvrir près de chez vous et partout au Québec.</Trans>
      </p>
      <InputSingleFieldForm
        successMessage={t`Merci c'est enregistré`}
        placeholder={t`Entrez votre courriel`}
        onSubmit={submitNewsletterEmail}
        validationFn={isValidEmail}
        onChange={setEmail}
      />
    </div>
  );
};
