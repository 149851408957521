import { Trans } from '@lingui/macro';
import { FlowContainer, QuickLogin } from '@milo/react';

export const MiloFreeInscription = () => {
  return (
    <FlowContainer>
      <div className="flex flex-col gap-4">
        <h1>
          <p className="text-3xl font-bold">
            <Trans>Connexion</Trans>{' '}
          </p>
          <p className="font-bold text-gray-600">
            <Trans>Étape 1/2</Trans>
          </p>
        </h1>

        <p>
          <Trans>Si vous n'avez pas de compte, il sera créé automatiquement à partir de votre courriel.</Trans>
        </p>

        <QuickLogin onSuccess={() => (location.href = '/milo-gratuit/informations')} />

        <small className="text-gray-400">
          <Trans>
            En 2 étapes simples, récupérez votre droit d'aller visiter nos partenaires et obtenez plein de réductions,
            <b>entièrement gratuitement durant tout l'été !</b>
          </Trans>
        </small>
      </div>
    </FlowContainer>
  );
};
