import { t, Trans } from '@lingui/macro';
import { Button2, CrossIcon, Link2, ReactFC, SideScroller } from '@milo/react';

export const IdeeCadeau: ReactFC = () => {
  return (
    <div className="web-container">
      {/* Title + Call to action */}
      <div className="mb-10 mt-10 flex flex-col justify-around text-center md:flex-row">
        <div className="mb-6 flex flex-row justify-center md:mb-0 md:flex-col">
          <h1 className="max-w-[500px] text-5xl font-bold">
            <Trans>Une idée cadeau originale et locale!</Trans>
          </h1>
        </div>
        <div className="mt-5 flex flex-row justify-center md:m-0 md:flex-col ">
          <Link2 href={`/purchase`}>
            <Button2 className=" text-xl !font-bold md:!text-xl">
              <Trans>J'offre un pass M ta région</Trans>
            </Button2>
          </Link2>
        </div>
      </div>

      <div className="mb-16">
        <MiloLandingDescription>
          <Trans>
            Pour les fêtes, il n'est pas simple de trouver le cadeau idéal. Avec M ta région, vous n’avez plus de soucis
            à vous faire! Notre catalogue vous propose une sélection des meilleures expériences du Québec. Restaurants,
            activités culturelles ou en plein air.... plus besoin de chercher, il y en a pour tous les goûts!
          </Trans>
        </MiloLandingDescription>
      </div>

      <LandingLogos />

      {/* 3 reasons */}
      <div className="mb-16 flex flex-col justify-center gap-16 md:flex-row">
        <img
          className="hidden h-[400px] rounded-3xl object-cover object-center lg:flex"
          src="/images/idee-cadeau/image-stvalentin.png"
          alt="Une idée cadeau originale et locale"
        />
        <div className="flex max-w-xl flex-col">
          <div className="flex text-4xl font-bold text-raspberry">
            <Trans>3 raisons d'offrir le pass M ta région</Trans>
          </div>
          <ThreeReasons />
        </div>
      </div>

      {/* Call to action */}
      <div className="mb-16 flex flex-row justify-center">
        <Link2 href={`/purchase`}>
          <Button2 className="text-xl !font-bold md:!text-xl">
            <Trans>J'offre un pass M ta région</Trans>
          </Button2>
        </Link2>
      </div>

      <Testimonials />
    </div>
  );
};

export const ThreeReasons: ReactFC = () => {
  return (
    <div className="text-center text-xl md:text-left">
      <div className="mb-6">
        <div className="mb-2 text-2xl font-bold">
          <Trans>Soutenir les commerçants locaux</Trans>
        </div>
        <div>
          <Trans>Il est important pour nous d'encourager la découverte d'expériences locales.</Trans>
        </div>
      </div>
      <div className="mb-6">
        <div className="mb-2 text-2xl font-bold">
          <Trans>Gagner du temps</Trans>
        </div>
        <div>
          <Trans>
            Plus besoin de passer des heures à chercher les meilleures adresses, l'équipe M ta région les a sélectionné
            avec des critères très précis.
          </Trans>
        </div>
      </div>
      <div className="mb-6">
        <div className="mb-2 text-2xl font-bold">
          <Trans>Découvrir en économisant</Trans>
        </div>
        <div>
          <Trans>
            Nous proposons des réductions sur nos expériences allant de 15% à 50% tout en faisant découvrir votre
            région!
          </Trans>
        </div>
      </div>
    </div>
  );
};

export const MiloLandingDescription: ReactFC = ({ children }) => {
  return (
    <div className="flex flex-col justify-around text-center md:flex-row">
      <h1 className="text-2xl">{children}</h1>
    </div>
  );
};

export const LandingLogos: ReactFC = () => {
  return (
    <div className="mb-16 flex flex-wrap justify-around gap-4 text-center text-3xl font-semibold">
      {/* Picto 1 */}
      <div className="flex flex-none basis-1/5 flex-col items-center gap-4">
        <div className="flex h-24 w-24 items-center justify-center rounded-full bg-warning">
          <svg width="50" viewBox="0 0 109 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.1667 14.9083H54.5L49.9583 59.6331H22.7083L18.1667 14.9083Z"
              stroke="white"
              strokeWidth="8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M31.7917 89.4496H40.875V104.358H31.7917V89.4496Z"
              stroke="white"
              strokeWidth="8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M90.8335 14.9083V74.5413H68.1252C68.0207 56.2489 68.9609 37.7378 90.8335 14.9083Z"
              stroke="white"
              strokeWidth="8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M90.8333 74.5413V104.358H86.2916V89.4495"
              stroke="white"
              strokeWidth="8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M36.3333 59.6329V89.4494"
              stroke="white"
              strokeWidth="8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <Trans>Restaurants</Trans>
      </div>

      {/* Picto 2 */}
      <div className="flex flex-none basis-1/5 flex-col items-center gap-4 text-3xl">
        <div className="flex h-24  w-24 items-center justify-center rounded-full bg-teal">
          <svg width="55" viewBox="0 0 129 148" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M69.875 30.8333C72.8435 30.8333 75.25 28.0724 75.25 24.6667C75.25 21.2609 72.8435 18.5 69.875 18.5C66.9065 18.5 64.5 21.2609 64.5 24.6667C64.5 28.0724 66.9065 30.8333 69.875 30.8333Z"
              stroke="white"
              strokeWidth="11"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.5 104.833L48.375 111L52.4062 101.75"
              stroke="white"
              strokeWidth="11"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M80.625 129.5V104.833L59.125 86.3334L64.5 49.3334"
              stroke="white"
              strokeWidth="11"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M37.625 74V55.5L64.5 49.3334L80.625 67.8334L96.75 74"
              stroke="white"
              strokeWidth="11"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <Trans>Activités</Trans>
      </div>

      {/* Picto 3 */}
      <div className="mb-16 flex flex-none basis-1/5 flex-col items-center gap-4 text-3xl">
        <div className="flex h-24 w-24 items-center justify-center rounded-full bg-purple">
          <svg width="50" viewBox="0 0 108 104" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 91H94.5" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M13.5 30.3333V34.6667C13.5 38.1145 14.9223 41.4211 17.4541 43.8591C19.9858 46.297 23.4196 47.6667 27 47.6667C30.5804 47.6667 34.0142 46.297 36.5459 43.8591C39.0777 41.4211 40.5 38.1145 40.5 34.6667V30.3333M40.5 34.6667C40.5 38.1145 41.9223 41.4211 44.4541 43.8591C46.9858 46.297 50.4196 47.6667 54 47.6667C57.5804 47.6667 61.0142 46.297 63.5459 43.8591C66.0777 41.4211 67.5 38.1145 67.5 34.6667V30.3333M67.5 34.6667C67.5 38.1145 68.9223 41.4211 71.4541 43.8591C73.9858 46.297 77.4196 47.6667 81 47.6667C84.5804 47.6667 88.0142 46.297 90.5459 43.8591C93.0777 41.4211 94.5 38.1145 94.5 34.6667V30.3333H13.5L22.5 13H85.5L94.5 30.3333"
              stroke="white"
              strokeWidth="8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.5 91.0005V47.0172"
              stroke="white"
              strokeWidth="8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M85.5 91.0005V47.0172"
              stroke="white"
              strokeWidth="8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M40.5 91V73.6667C40.5 71.3681 41.4482 69.1637 43.136 67.5384C44.8239 65.9131 47.1131 65 49.5 65H58.5C60.8869 65 63.1761 65.9131 64.864 67.5384C66.5518 69.1637 67.5 71.3681 67.5 73.6667V91"
              stroke="white"
              strokeWidth="8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <Trans>Boutiques</Trans>
      </div>

      {/* Picto 4 */}
      <div className="flex flex-none basis-1/5 flex-col items-center gap-4 text-3xl">
        <div className="flex h-24 w-24 items-center justify-center rounded-full bg-medium-gray">
          <div className="rotate-45">
            <CrossIcon fill="white" width={30} height={30} />
          </div>
        </div>
        <Trans>Et bien plus</Trans>
      </div>
    </div>
  );
};

export const Testimonials: ReactFC = () => {
  return (
    <>
      <h3 className="mb-5 text-4xl font-semibold">
        <Trans>Témoignages</Trans>
      </h3>

      <SideScroller>
        <Testimonial
          text={t`Grâce à notre pass M ta région “Le grand voyageur” et ses réductions, nous pouvons nous permettre de faire plus de sorties et essayer de nouvelles expériences avec les enfants. Ils sont ravis et nous aussi!`}
          author={t`Thierry et Marianne`}
          authorImage="/images/idee-cadeau/testimonial-1.jpg"
          city={t`Sherbrooke`}
        />
        <Testimonial
          text={t`Pour le Noël d'entreprise, j'ai offert un pass explorateur à une de mes collègues de travail que je ne connais pas beaucoup. Etant donné qu'il y en a pour tous les goûts, j'étais sûre qu'elle trouverait une expérience faite pour elle!`}
          author={t`Christine`}
          authorImage="/images/idee-cadeau/testimonial-2.jpg"
          city={t`Joliette`}
        />
        <Testimonial
          text={t`J'ai offert un pass M ta région à ma fille, étudiante à Gatineau, et elle adore! M ta région lui permet de faire des activités à prix réduit tout en lui faisant visiter le Québec dès qu'elle le peut... Je recommande pour tous les parents en panne d'idées cadeaux pour leurs enfants!`}
          author={t`Nadine`}
          authorImage="/images/idee-cadeau/testimonial-3.jpg"
          city={t`Gatineau`}
        />
        <Testimonial
          text={t`Mes parents m'ont offert un pass M ta région pour Noël et j'en suis fou! Je fais des économies et je n'ai pas besoin de trouver des idées ce qui me permet de tester une nouvelle expérience avec ma compagne chaque fin de semaine!`}
          author={t`Pierre`}
          authorImage="/images/idee-cadeau/testimonial-4.jpg"
          city={t`Montréal`}
        />
        <Testimonial
          text={t`J'ai acheté à mes petits-enfants un pass M ta région “L'aventurier” pour qu'ils profitent de leur séjour à Québec sans avoir à trouver des idées de visites, de restaurants ou d'activités. Ils ont adoré le concept et se sont abonnés à l'année!`}
          author={t`Josiane`}
          authorImage="/images/idee-cadeau/testimonial-5.jpg"
          city={t`Brossard`}
        />
      </SideScroller>
    </>
  );
};

const Testimonial: ReactFC<{ text: string; author: string; city: string; authorImage: string }> = ({
  text,
  author,
  city,
  authorImage,
}) => {
  return (
    <div className="mb-5 flex w-3/5 min-w-[400px] flex-none items-center gap-4 rounded-3xl border border-gray-lighter p-4 shadow-lg">
      <div className="flex flex-none basis-1/5 flex-col items-center">
        <img className="mb-2 !w-32" src={authorImage} alt="M ta région témoignage" />
        <div className="text-center text-lg">
          <p className=" text-center font-bold leading-tight">{author}</p>
          <p>{city}</p>
        </div>
      </div>
      <p className="text-lg">{text}</p>
    </div>
  );
};
