import { colorFn, ColorType } from '../utils';

function SvgComponent({ fill = 'currentColor' as ColorType, ...props }) {
  const _fill = colorFn(fill);
  return (
    <svg viewBox="-2 -12 100 100" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.471 2a2 2 0 114 0v6.698a2 2 0 11-4 0V2zM26.798 5.026c-.584-.903-.283-2.034.674-2.526.956-.493 2.206-.16 2.79.743l3.883 5.995c.584.903.283 2.034-.674 2.526-.956.493-2.206.16-2.79-.743l-3.883-5.995zM62.74 11.59c-.956-.493-1.257-1.624-.673-2.527L65.95 3.07c.584-.904 1.834-1.236 2.79-.744.957.492 1.258 1.623.674 2.527l-3.882 5.994c-.585.903-1.835 1.236-2.792.744z"
        fill={_fill}
      />
      <path d="M22.24 22h52.52L94.5 38.92V71h-92V38.92L22.24 22z" stroke={_fill} strokeWidth={4} />
      <path
        d="M2.5 40h27.06c1.616 0 2.977 1.3 3.56 2.807C34.375 46.067 38.01 51 48.5 51c10.49 0 14.124-4.933 15.38-8.193C64.464 41.3 65.825 40 67.44 40H94.5"
        stroke={_fill}
        strokeWidth={4}
        strokeLinecap="round"
      />
    </svg>
  );
}

export { SvgComponent as EmptyInbox };
