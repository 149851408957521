import { Trans } from '@lingui/macro';
import config from '../../config';
import { ExternalLink } from '../../icons';
import { MiloFull } from '../../logos';
import { ReactFC } from '../../types';
import { Link2 } from '../Link';

type FlowContainerProps = {
  showHomeLink?: boolean;
};

export const FlowContainer: ReactFC<FlowContainerProps> = ({ children, showHomeLink = true }) => {
  return (
    <div className="flex h-full min-h-[100vh] items-center">
      <div className="mx-auto animate-fade-in-top lg:w-1/2">
        <div className="mx-auto max-h-[100vh] w-[95%] max-w-lg">
          {children}
          {showHomeLink && (
            <Link2 href="/">
              <div className="my-5 flex items-center justify-center text-center text-medium-gray underline">
                <Trans>Page d'accueil</Trans> <ExternalLink className="ml-2 h-3 w-3" />
              </div>
            </Link2>
          )}
        </div>
      </div>
      <div className="mx-auto hidden animate-fade-in-top text-white lg:block lg:w-1/2">
        <div className="fixed bottom-0 right-0 top-0 w-1/2 bg-red"></div>
        <div className="relative mx-auto mt-10 max-h-[100vh] w-full animate-fade-in-top">
          <div className="mx-auto w-[80%] space-y-6 text-center">
            <MiloFull className="mx-auto w-48" circleColor="white" mColor="red" textColor="white" />
            <h2 className="mx-auto w-[90%] text-xl">
              <Trans>
                Le compagnon idéal pour découvrir les meilleures expériences locales tout en participant au
                développement économique de votre région.
              </Trans>
            </h2>
            <img
              className="mx-auto max-w-md"
              src={`https://${config.APP_DOMAIN}/images/home/phone-array.png`}
              alt="Milo application mobile"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
