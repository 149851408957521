import { Trans } from '@lingui/macro';
import { useState } from 'react';
import { useAuthentication } from '../../hooks/useAuthentication';
import { CheckCircle } from '../../icons';
import { Link2 } from '../Link';
import { Modal, ModalBody } from '../Modal';
import { YoutubeEmbed } from '../Video';

export const HowToUseContent = () => {
  const { loggedIn, subscribed, isLoading } = useAuthentication();

  if (isLoading) return null;

  return (
    <ol className="list-inside list-decimal space-y-5 text-lg font-bold">
      <li>
        Créez vous un compte gratuit en une seule étape{' '}
        <Link2 href="/login" underline>
          ici
        </Link2>
        {loggedIn && (
          <p className="mt-2 flex items-center gap-2 text-gray-500">
            <CheckCircle className="w-7 text-success" />
            <Trans>C'est fait, vous êtes connecté sur votre compte</Trans>
          </p>
        )}
      </li>
      <li>
        <Trans>Procurez vous un pass M ta région</Trans>
        <span className="text-gray-500">
          {(subscribed && (
            <p className="mt-2 flex items-center gap-2">
              <CheckCircle className="w-7 text-success" />
              <Trans>C'est fait, vous avez actuellement un pass M ta région actif</Trans>
            </p>
          )) || (
            <ul className="list-inside list-disc space-y-2 ">
              <li>
                <Trans>
                  Option 1: Achetez le pass M ta région de votre choix sur notre site web{' '}
                  <Link2 href="/purchase">
                    <span className="underline">ici</span>
                  </Link2>
                </Trans>
              </li>
              <li>
                <Trans>
                  Option 2: Recevez le gratuitement par le biais d'une promotion ou de l'un de nos partenaires (par
                  exemple, le Programme Rabais Dollars de CAA){' '}
                  <Link2 href="/profile/promotional-partners">
                    <span className="underline">ici</span>
                  </Link2>
                </Trans>
              </li>
            </ul>
          )}
        </span>
      </li>
      <li>
        <Trans>
          Téléchargez l'application mobile sur iPhone ou Android{' '}
          <Link2 href="/app-download">
            <span className="underline">ici</span>
          </Link2>
          . (Vous pouvez aussi utiliser M ta région via le site web sur mobile mais l'application offre une meilleure
          expérience!)
        </Trans>
      </li>
      <li>
        <Trans>
          Découvrez nos expériences à l'aide du{' '}
          <Link2 href="/experiences">
            <span className="underline">répertoire</span>
          </Link2>{' '}
          ou de la{' '}
          <Link2 href="/map">
            <span className="underline">carte interactive</span>
          </Link2>
          .
        </Trans>
      </li>
      <li>
        <Trans>
          Lors de votre visite chez l'expérience de votre choix, rendez vous sur la fiche de l'expérience et appuyez sur
          "obtenir la réduction-découverte" pour confirmer votre visite et profiter de votre réduction.
        </Trans>
      </li>
      <YoutubeEmbed
        src={'https://www.youtube.com/embed/RwVBPswV65I'}
        className="mb-8 min-h-[200px] w-full overflow-hidden rounded-xl"
      />
    </ol>
  );
};

export const HowToUse = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal open={open} onRequestClose={() => setOpen(!open)}>
        <ModalBody className="!max-h-[550px]">
          <HowToUseContent />
        </ModalBody>
      </Modal>
      <button
        onClick={() => setOpen(!open)}
        className="fixed bottom-5 right-5 flex h-24 w-24 items-center justify-center rounded-full bg-red text-sm font-bold text-white"
      >
        <Trans>Comment Utiliser M ta région?</Trans>
      </button>
    </>
  );
};
