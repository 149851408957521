import { Vertical } from '@milo/types/index';
import { VerticalsList } from '../../graphql-queries';
import { HybridGraphqlClient, useGraphqlClient } from '../useGraphqlClient';
import { useQuery } from '../useQuery';

export const getVerticals: (graphqlClient: HybridGraphqlClient) => Promise<Vertical[]> = async (graphqlClient) => {
  const data = await graphqlClient.request(VerticalsList);
  return data?.VerticalsList;
};

export const useVerticals = () => {
  const { client } = useGraphqlClient();
  const response = useQuery<Vertical[]>(['VerticalsList'], async () => await getVerticals(client), {
    staleTime: Infinity,
  });

  return response;
};
