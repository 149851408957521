import { ExperienceFragment } from './fragments/Experience';

export const GuideGet = /* GraphQL */ `
  query GuideGet($id: Int!) {
    guide: GuideGet(id: $id) {
      id
      title
      meta_description
      long_description
      slug
      region {
        name
      }
      city_id
      vertical {
        id
        name
      }
      tags {
        id
        name
        description
      }
      related_guides {
        id
				title
        slug
        main_image_url
      }
      experiences {
          ${ExperienceFragment}
      }
    }
  }
`;
