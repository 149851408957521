import { Trans } from '@lingui/macro';
import { Experience } from '@milo/types/index';
import { FC, useMemo, useRef } from 'react';
import { useAuthentication } from '../../hooks';
import { Heart } from '../../icons';
import { convertCentsIntegerToClosestFiveDollarString } from '../../utils';
import { AddWishlistModal } from '../AddWishlistModal';
import { ImageKitImage } from '../Image/ImageKitImage';
import { Link2 } from '../Link';
import { Text } from '../Text';

export type StyledResultExperienceCardProps = {
  experience: Experience;
};

export const ResultExperienceCard: FC<StyledResultExperienceCardProps> = ({ experience, ...rest }) => {
  const ref = useRef();
  const { user } = useAuthentication();
  const displayedImage = experience?.thumbnail_image || experience?.main_image;
  const visitedDate = user?.visits_this_year?.find((visit) => visit?.experience?.id === experience.id)?.date_created;
  const inWishlist = user?.wishlists?.some((wishlist) =>
    wishlist?.experiences?.some((exp) => exp?.id === experience.id)
  );

  const Image = useMemo(
    () => (
      <ImageKitImage
        transforms={`w-250,h-250,${visitedDate ? ',bl-5' : ''}`}
        imageFileName={displayedImage}
        className="h-full w-full rounded-3xl"
      />
    ),
    [visitedDate]
  );

  return (
    <Link2 className="flex w-full animate-fade-in-top flex-col" href={`/experiences/${experience.slug}`} {...rest}>
      <div className="flex animate-fade-in-top flex-col ">
        <div className="relative mb-2 h-48 w-full overflow-hidden rounded-3xl bg-white " ref={ref}>
          {visitedDate && (
            <div className="absolute h-full w-full">
              <Text className="flex h-full w-full items-center justify-center rounded-3xl bg-black/40 p-1 text-center font-extrabold text-white">
                <Trans>Privilège obtenu</Trans>
              </Text>
            </div>
          )}
          {Image}
          <div className="absolute top-0 flex w-full flex-row items-center justify-between bg-gradient-to-b from-medium-dark">
            <Text size="h3" className="p-4 font-bold text-white">
              -{convertCentsIntegerToClosestFiveDollarString(experience?.savings_max)}
            </Text>
            <AddWishlistModal experience={experience}>
              <button className="p-4">
                <Heart
                  className="h-[24px] w-[24px]"
                  fill={inWishlist ? 'red' : 'transparent'}
                  stroke={inWishlist ? 'red' : 'white'}
                />
              </button>
            </AddWishlistModal>
          </div>
        </div>

        <Text size="h6" className="mb-1 font-semibold">
          {experience.name}
        </Text>

        <Text className="text-medium-gray">
          {experience?.location?.city}
          {(() => {
            if (experience.distance === undefined || experience.distance === null) return null;
            if (experience.distance < 1) return ` < 1 km`;
            return ` à ${experience.distance} km`;
          })()}
        </Text>
      </div>
    </Link2>
  );
};
