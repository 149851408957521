import { LooseObject } from '@milo/types/index';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

export const objectDiff: (object: LooseObject, base: LooseObject) => LooseObject = (object, base) => {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
};

export const objectIsDifferent: (object: LooseObject, base: LooseObject) => boolean = (object, base) =>
  Object.keys(objectDiff(object, base)).length > 0;
