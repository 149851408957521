import { Share as CapacitorShare } from '@capacitor/share';
import { t, Trans } from '@lingui/macro';
import config from '@milo/config/config-public.json';
import { MouseEventHandler, useState } from 'react';
import { isNativeApp } from '../../hooks';
import { Enveloppe, FacebookIcon, LinkedInIcon, LinkIcon, TwitterIcon } from '../../icons';
import { ReactFC } from '../../types';
import { colorFn, ColorType, copyToClipboard } from '../../utils';
import { Button2 } from '../Button';
import { Modal, ModalBody } from '../Modal';
import { Text } from '../Text';

const shareWithNativeApi = async (shareData: ShareProps) => {
  return await CapacitorShare.share(shareData);
};

const getShareUrl = ({ text, title, url, method }: ShareProps) => {
  if (method === 'twitter') {
    return `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(
      url
    )}&hashtags=${encodeURIComponent(title)}`;
  }

  if (method === 'facebook') {
    return `https://www.facebook.com/sharer/sharer.php?p[url]=${encodeURIComponent(url)}&p[title]=${encodeURIComponent(
      title
    )}&p[text]=${encodeURIComponent(text)}`;
  }

  // if (method === 'instagram') {
  //  Instagram does not support sharing via Web
  // }

  if (method === 'email') {
    return `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(text)}%0A%0A${encodeURIComponent(
      url
    )}`;
  }

  if (method === 'linkedin') {
    return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(
      title
    )}&summary=${encodeURIComponent(text)}`;
  }
};

const customShareFn = (shareData: ShareProps) => {
  const shareUrl = getShareUrl(shareData);
  window.open(shareUrl, '_blank');
};

type ShareProps = {
  title: string;
  text: string;
  url: string;
  method?: 'twitter' | 'facebook' | 'instagram' | 'email' | 'linkedin';
};

export const Share: ReactFC<ShareProps> = ({
  children,
  text = t`Découvrez cette application!`,
  title = t`M ta région: Sortez, Découvrez, Économisez!`,
  url = `https://${config.APP_DOMAIN}`,
}) => {
  const useNativeShare = isNativeApp() || navigator?.share ? true : false;
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);

  const closeModal = () => {
    setShareModalOpen(false);
  };

  return (
    <>
      <button
        className="relative w-0"
        onClick={useNativeShare ? () => shareWithNativeApi({ text, title, url }) : () => setShareModalOpen(true)}
      >
        {children}
      </button>
      <Modal open={shareModalOpen} onRequestClose={closeModal}>
        <ModalBody>
          <Text size="h2" className="mb-8 text-center font-bold">
            <Trans>Partager à l'extérieur de M ta région</Trans>
          </Text>

          <div className="flex flex-col">
            <ShareItem
              color="facebook"
              Icon={FacebookIcon}
              displayText={t`Partager sur Facebook`}
              onClick={() => customShareFn({ text, title, url, method: 'facebook' })}
            />
            <ShareItem
              color="twitter"
              Icon={TwitterIcon}
              displayText={t`Partager sur Twitter`}
              onClick={() => customShareFn({ text, title, url, method: 'twitter' })}
            />
            <ShareItem
              color="linkedin"
              Icon={LinkedInIcon}
              displayText={t`Partager sur LinkedIn`}
              onClick={() => customShareFn({ text, title, url, method: 'linkedin' })}
            />
            <ShareItem
              Icon={LinkIcon}
              displayText={t`Copier le lien`}
              onClick={(e) => copyToClipboard(e, url, t`Lien copié!`)}
            />
            <ShareItem
              color="medium-dark"
              Icon={Enveloppe}
              displayText={t`Partager par courriel`}
              onClick={() => customShareFn({ text, title, url, method: 'email' })}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const ShareItem: ReactFC<{ Icon: ReactFC; displayText: string; onClick: MouseEventHandler; color?: ColorType }> = ({
  Icon,
  displayText,
  onClick,
  color = 'charcoal',
}) => {
  const _color = colorFn(color);

  return (
    <Button2
      size="md"
      className="mb-2 grid grid-cols-12"
      onClick={onClick}
      style={{ color: _color, backgroundColor: 'white', borderColor: _color, borderWidth: 3 }}
    >
      <Icon className="col-span-1 col-start-3 h-8 w-8 p-1" />
      <span className="col-span-full col-start-5 text-start">{displayText}</span>
    </Button2>
  );
};
